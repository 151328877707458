<template>
  <div class="main-container">
    <dd-form :form-config="formBasicList" ref="addPopForm"> </dd-form>
  </div>
</template>
  
  <script>
import { postApi, getApi, loadApi, delApi } from "@/api/request";
export default {
  name: "add-pop",
  props: {
    rowDataList: [],
    rowId: null,
    rowData:{},   // 基本数据
    editData:{},  // 编辑数据
  },
  data() {
    return {
      formBasicList: [],
    };
  },
  created() {},
  mounted() {
    if(this.rowData.standardWorkpackage == 0){
      this.formBasicList.push({
        label: '工作量',
        value: 'reviewWorkload',
        inputType: "text",
        placeholderText: "请输入工作量",
        defaultValue: this.editData.wbsReviewWorkload,
        rules: [
          { required: true, message: "请输入工作量", trigger: "blur" },
          {
            pattern: /^(0|([1-9][0-9]*))(\.[\d]{1,4})?$/,
            required: true,
            message: "请输入正确的格式",
            trigger: "change",
          },
        ],
      });
    }
    this.rowDataList.map((item) => {
      if (item.workMetering == "PRJ-GZBTXLX-NUMBER") {
        this.formBasicList.push({
          label: item.key,
          value: item.value,
          inputType: "text",
          placeholderText: `请输入${item.key}`,
          defaultValue: item.defaultValue,
          rules: [
            { required: true, message: `请输入${item.key}`, trigger: "blur" },
            {
              pattern: /^(0|([1-9][0-9]*))(\.[\d]{1,4})?$/,
              required: true,
              message: "请输入正确的格式",
              trigger: "change",
            },
          ],
        });
      } else if (item.workMetering == "PRJ-GZBTXLX-OPTIONS") {
        this.formBasicList.push({
          label: item.key,
          value: item.value,
          inputType: "select",
          optionsList: item.options,
          defaultValue: item.defaultValue,
          placeholderText: "请选择",
          rules: [{ required: true, message: "请选择", trigger: "blur" }],
        });
      }
    });
  },
  methods: {},
};
</script>
  <style lang='less' scoped>
</style>
  