<template>
  <div class="main-container">
    <div class="serach-container">
      <dd-card title="要素列表" spacer>
        <div class="search-wrap">
          <dd-search-form
            :form-config="formList"
            @search-click="handleSearchEvent"
            ref="ddSearchForm"
          ></dd-search-form>
        </div>
      </dd-card>
    </div>
    <dd-card title="签到列表" cardStyle="height: 720px; overflow: auto;">
      <dd-table
        :columns="columnsList"
        :data="tableData"
        height="500"
        border
        ref="dTableRef"
        @select-change="handleUserSelect"
        :isSelection="true"
      >
        <template #table-pagination>
          <dd-pagination
            :total="total"
            :page-size="size"
            :page-number="current"
            @page-change="handleCurrentChange"
            align="right"
          >
          </dd-pagination>
        </template>
      </dd-table>
    </dd-card>
  </div>
</template>

<script>
import { postApi, getApi, putApi } from "@/api/request";
export default {
  name: "add-user-pop",
  props: { areaId: "" },
  data() {
    return {
      total: 0,
      current: 1,
      size: 11,
      formData: {},
      formList: [
        {
          label: "部门",
          value: "deptId",
          inputType: "cascader",
          filterable: "filterable",
          placeholderText: "请选择部门",
          optionsList: [],
          showAllSelcet: false,
          prop: {
            emitPath: false,
            label: "name",
            value: "id",
          },
        },
        {
          label: "用户名称",
          value: "fullName",
          inputType: "text",
          filterable: "filterable",
          placeholderText: "请输入员工姓名",
        },
        {
          label: "工号",
          value: "username",
          inputType: "text",
          filterable: "filterable",
          placeholderText: "请输入员工工号",
        },
      ],
      columnsList: [
        {
          title: "姓名",
          key: "fullName",
          align: "center",
          overflow: true,
        },
        {
          title: "工号",
          key: "username",
          align: "center",
        },
        {
          title: "部门",
          key: "deptName",
          align: "center",
        },
        {
          title: "电话",
          key: "phone",
          align: "center",
        },
        {
          title: "岗位",
          key: "postName",
          align: "center",
        },
        {
          title: "邮箱",
          key: "email",
          align: "center",
        },
      ],
      tableData: [],
      selection: [],
      jsonStringArray: "",
    };
  },
  mounted() {
    this.getUserList();
    this.getDeptTree(); //组织机构树
  },
  methods: {
    //查询按钮
    handleSearchEvent(obj) {
      this.formData = obj;
      this.current = 1;
      this.getUserList();
    },
    /* 分页页码回调 */
    handleCurrentChange(size, number) {
      this.current = number;
      this.size = size;
      this.getUserList();
    },
    getUserList() {
      let { current, size, formData } = this;
      let params = {
        current,
        size,
        ...formData,
      };
      postApi(`/admin/user/uservos/data`, params).then((res) => {
        let { data } = res;
        if (data && data.code == 0) {
          this.tableData = data.data.records;
          this.total = data.data.total;
        }
      });
    },
    // 获取组织机构树数据
    getDeptTree() {
      getApi(`/admin/dept/tree`, {}).then((res) => {
        let { data } = res;
        if (data && data.code == 0) {
          this.formList.forEach((item) => {
            if (item.value == "deptId") {
              item.optionsList = data.data[0].children;
            }
          });
        }
      });
    },

    handleUserSelect(selection, row) {
      this.selection = selection;
      if (selection.length == 0) return;
      this.$refs.dTableRef.$refs.baseTable.setCurrentRow(row);
    },
  },
};
</script>

<style lang="less" scoped>
.main-container {
  /deep/ .search-form-input {
    width: 25% !important;
  }
}
</style>