<template>
  <div class="main-container">
    <dd-card title="条件查询" spacer>
      <dd-search-form
        :form-config="formList"
        @search-click="handleSearchEvent"
      ></dd-search-form>
    </dd-card>
    <dd-card title="汇总单列表" :height="tableHeight">
      <dd-table
        :columns="columnsList"
        :data="tableDataSources"
        border
        ref="dTableRef"
        tableRef="dTableRef"
        show-page
        @select-change="handleSelectionChange"
      >
        <template #table-btn>
          <dd-button
            type="primary"
            size="medium"
            autoConfirm
            icon="add"
            @click="handleAddEdit(0)"
            >生成汇总单
          </dd-button>

          <dd-button
            type="warning"
            size="medium"
            icon="update"
            @click="handleAddEdit(1)"
            >编辑
          </dd-button>

          <dd-button
            type="danger"
            icon="delete"
            size="medium"
            autoConfirm
            @click="handleDelete"
            >删除
          </dd-button>
          <dd-button
            icon="export"
            type="primary"
            size="medium"
            autoConfirm
            @click="handleExport"
            >导出
          </dd-button>
        </template>
        <template #table-btn-right>
          <el-button
            icon="el-icon-s-operation"
            circle
            type="info"
            @click="drawerVisible = true"
            size="small"
            title="列筛选"
          ></el-button>
        </template>
        <template #table-pagination>
          <dd-pagination
            :total="total"
            :page-size="pageSize"
            :page-number="pageNumber"
            @page-change="handlePageChange"
            align="right"
          >
          </dd-pagination>
        </template>
      </dd-table>
    </dd-card>
    <!-- 查看明细 生成汇总单 -->
    <dd-popup
      v-if="dialogVisible"
      width="85%"
      height="90%"
      :title-name="addEditTitle"
      :show="dialogVisible"
      @popup-close="dialogVisible = false"
    >
      <SummarySheet
        slot="main"
        :type="type"
        :id="id"
        :code="code"
        :collectDepName="collectDepName"
        :collectDepIdProp="collectDepId"
        :applyTime="applyTime"
        @diaChange="diaChange"
      ></SummarySheet>
    </dd-popup>
    <!-- 汇总详情 -->
    <dd-popup
      v-if="dialogDetailVisible"
      width="85%"
      height="90%"
      :title-name="addEditTitle"
      :show="dialogDetailVisible"
      @popup-close="dialogDetailVisible = false"
    >
      <SummaryDetail
        slot="main"
        @diaChange="diaChange"
        :type="type"
        :id="id"
        :code="code"
        :applyTime="applyTime"
      ></SummaryDetail>
    </dd-popup>
    <dd-filter-drawer
      v-if="drawerVisible"
      width="30%"
      title-name="列筛选"
      :show="drawerVisible"
      :tableColumns="tableColumns"
      :columns="columnsList"
      @popup-close="drawerVisible = false"
      @handleColumnsChange="handleColumnsChange"
    >
    </dd-filter-drawer>
  </div>
</template>

<script>
import SummarySheet from "./components/SummarySheet"; //查看明细
import SummaryDetail from "../Summary/components/SummaryDetail"; //汇总详情
import { formatNumber } from "@/utils/number";
import { downloadBinaryFile } from "@/utils/index";
import { postApi, getApi, loadApi } from "@/api/request";
export default {
  name: "withoutCarMothlySummarySheet",
  $dynamicRoute: {
    name: "外部租车部门月度汇总单",
    path: "/withoutCar/mothlySummarySheet/list",
  },
  components: { SummaryDetail, SummarySheet },
  data() {
    return {
      tableHeight: null,
      addEditTitle: null,
      drawerVisible: false,
      dialogDetailVisible: false,
      id: null,
      code: null,
      applyTime: "",
      type: null,
      // 页码
      pageNumber: 1,
      // 每页的数据条数
      pageSize: 10,
      total: 0,
      dialogVisible: false,
      // 表格选择数据集合
      multipleSelection: [],
      // 接收表单返回数据
      formData: {},
      // 条件查询表单配置配置
      formList: [
        {
          label: "汇总单编号",
          value: "collectCode",
          inputType: "text",
          placeholderText: "请输入汇总单编号",
          title: true,
        },
        {
          label: "月份",
          value: "reportMonth",
          inputType: "date",
          dateType: "monthrange",
          format: "yyyy-MM",
        },
        {
          label: "上报状态",
          value: "reportStatus",
          inputType: "select",
          optionsList: [],
          placeholderText: "请选择或搜索单据状态",
        },
      ],
      collectDepId: null,
      collectDepName: null,
      // 表格头部配置数据
      tableColumns: [
        {
          title: "汇总单编号",
          key: "collectCode",
          align: "center",
          event: [
            {
              type: "primary",
              href: (row) => {
                return row.collectCode;
              },
              method: (row) => {
                this.handleDetailInfo(row, 0);
              },
            },
          ],
        },
        {
          title: "单据名称",
          key: "name",
          align: "center",
          overflow: true,
        },
        {
          title: "组织",
          key: "collectOrgName",
          align: "center",
        },
        {
          title: "部门",
          key: "collectDepName",
          align: "center",
        },
        {
          title: "月份",
          key: "reportMonth",
          align: "center",
        },
        {
          title: "上报状态",
          key: "reportStatusName",
          align: "center",
          width: "150px",
          overflow: true,
        },
        {
          title: "操作",
          align: "center",
          event: [
            // {
            //   label: "查看明细",
            //   isShow: true,
            //   isAllNull: true,
            //   showKey: "reportStatus",
            //   showValue:
            //     "wbzchzdsbzt_dxzqr,wbzchzdsbzt_xzyqr,wbzchzdsbzt_wsb,wbzchzdsbzt_yth",
            //   method: (row) => {
            //     this.handleDetailInfo(row, 0);
            //   },
            // },
            {
              label: "汇总详情",
              isShow: true,
              isAllNull: true,
              showKey: "reportStatus",
              showValue:
                "wbzchzdsbzt_dxzqr,wbzchzdsbzt_xzyqr,wbzchzdsbzt_wsb,wbzchzdsbzt_yth",
              method: (row) => {
                this.handleDetailInfo(row, 1);
              },
            },
          ],
        },
      ],
      columnsList: [], //筛选列表格头部配置
      // 表格数据集合
      tableDataSources: [],
      // 是否显示删除确认弹框
      autoConfirm: false,
    };
  },
  created() {},
  mounted() {
    // 自动计算table高度
    this.$nextTick(() => {
      this.tableHeight = this.autoTableHeight(0);
    });
    this.getStateList();
    this.getApplyList();
    this.columnsList = [...this.tableColumns];
  },
  methods: {
    diaChange(e) {
      this.dialogDetailVisible = e;
      this.dialogVisible = e;
      this.id = null;
      this.$refs.dTableRef.$refs.baseTable.clearSelection();
      this.getApplyList();
    },
    // 查看明细 汇总详情
    handleDetailInfo(row, type) {
      if (type == "0") {
        // 查看详情
        this.id = row.id;
        this.code = row.collectCode;
        this.applyTime = row.reportMonth;
        this.addEditTitle = `${row.collectDepName}${row.reportMonth}租车补贴汇总单`;
        this.type = "detail";
        this.dialogVisible = true;
      } else {
        if (
          row.reportStatus != "wbzchzdsbzt_dxzqr" &&
          row.reportStatus != "wbzchzdsbzt_xzyqr"
        ) {
          this.$message.warning(
            "只能查看上报状态为待行政确认或行政已确认的数据"
          );
          return;
        }
        this.id = row.id;
        this.code = row.code;
        this.addEditTitle = `汇总详情`;
        this.type = "hz";
        this.dialogDetailVisible = true;
        //汇总详情
      }
    },
    // 总金额（预算明细)
    handleDetail(val) {
      this.applyDetailTitle = "预算明细";
      this.applyId = val.id;
      this.projectId = val.projectId;
      this.dialogDetailVisible = true;
      this.amountType = "totalAmount";
    },
    handleColumnsChange(val) {
      let list = new Set();
      this.tableColumns.map((item) => {
        val.map((itm) => {
          if (item.event || item.key == itm.key) {
            list.add(item);
          }
        });
      });
      this.columnsList = [...list];
    },
    handleFileExport() {
      let downKeyList = [];
      this.columnsList.map((item) => {
        if (!item.event) {
          downKeyList.push({ code: item.key, name: item.title });
        }
      });
      let ids = [];
      this.multipleSelection.map((item) => {
        ids.push(item.id);
      });
      console.log(downKeyList);
      console.log(this.formData);
      console.log(ids);
      let formData = {
        ...this.formData,
        pageNumber: 1,
        pageSize: 99999,
      };
      let params = {
        formData,
        ids,
        downKeyList,
      };
      loadApi(`/finance/apply/front/export`, params).then((res) => {
        let { data } = res;
        downloadBinaryFile(
          data,
          decodeURIComponent(
            res.headers["content-disposition"].split(";")[1].split("=")[1]
          )
        );
      });
    },
    // 预算申请列表请求
    getApplyList() {
      let _collectCode = this.formData.collectCode || "";
      var _reportMonth = [];
      if (this.formData.reportMonth) {
        _reportMonth = this.formData.reportMonth;
      }
      let _reportStatus = this.formData.reportStatus || "";
      let _collectOrgId = sessionStorage.getItem("orgId");
      if (_collectOrgId.indexOf("/") != -1) {
        _collectOrgId = _collectOrgId.replaceAll("/", "____");
      }
      if (_collectOrgId.indexOf("+") != -1) {
        _collectOrgId = _collectOrgId.replaceAll("+", "___");
      }
      getApi(
        `/hr/rentalcar/collectsheet/dept/page?current=` +
          this.pageNumber +
          `&size=` +
          this.pageSize +
          `&collectCode=` +
          _collectCode +
          `&reportMonthStr=` +
          _reportMonth +
          `&reportStatus=` +
          _reportStatus +
          `&collectOrgId=` +
          _collectOrgId
      ).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          this.tableDataSources = data.data.records;
          this.total = data.data.total;
        }
      });
    },
    // 单据状态下拉数据请求
    getStateList() {
      postApi(`/hr/hrdict/list?typeCode=wbzchzdsbzt`, {}).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          this.formList.forEach((item) => {
            if (item.value == "reportStatus") {
              item.optionsList = data.data;
            }
          });
        }
      });
    },
    handleSearchEvent(obj) {
      this.$refs.dTableRef.$refs.baseTable.clearSelection();
      this.formData = obj;
      console.log(obj);
      this.pageNumber = 1;
      this.getApplyList();
    },
    /* 导出 */
    handleExport() {
      let ids = [];
      if (
        this.multipleSelection.length &&
        (this.multipleSelection[0].reportStatus === "wbzchzdsbzt_yth" ||
          this.multipleSelection[0].reportStatus === "wbzchzdsbzt_wsb")
      ) {
        this.$message.warning("未上报/已退回的汇总单无汇总信息！");
        return;
      }

      this.multipleSelection.length &&
        this.multipleSelection.map((item) => {
          ids.push(item.id);
        });
      if (this.multipleSelection.length != 1) {
        this.$message.warning("请选择一条数据！");
        return;
      }
      let params = {
        formData: {
          current: 1,
          size: 99999,
          collectId: this.multipleSelection[0].id,
        },
      };
      loadApi(`/hr/rentalcar/collectsheet/export/statistics/person`, params)
        .then((res) => {
          let { data } = res;
          downloadBinaryFile(
            data,
            decodeURIComponent(
              res.headers["content-disposition"].split(";")[1].split("=")[1]
            )
          );
        })
        .catch((error) => {
          this.$message.error("请求出错");
        });
    },
    // type 0: 生成汇总；1：修改
    handleAddEdit(type) {
      if (type == 0) {
        if (this.multipleSelection.length > 0) {
          this.$message.warning("请取消选择数据！");
          return;
        }

        this.id = null;
        this.addEditTitle = "生成汇总单";
        this.type = "add";
        this.dialogVisible = true;
      } else {
        //修改
        if (this.multipleSelection.length !== 1) {
          this.$message.warning("请选择一条数据！");
          return;
        }
        for (var i = 0; i < this.multipleSelection.length; i++) {
          if (
            this.multipleSelection[i].reportStatus != "wbzchzdsbzt_wsb" &&
            this.multipleSelection[i].reportStatus != "wbzchzdsbzt_yth"
          ) {
            this.$message.warning("只能选择未上报或已退回状态数据！");
            return;
          }
        }
        this.id = this.multipleSelection[0].id;
        this.type = "update";
        this.applyTime = this.multipleSelection[0].reportMonth;
        console.log('this.multipleSelection[0]', this.multipleSelection[0])
        this.collectDepName = this.multipleSelection[0].collectDepName;
        this.collectDepId = this.multipleSelection[0].collectDepId;
        this.addEditTitle = "修改汇总单";
        this.dialogVisible = true;
      }
    },
    handleDelete() {
      var idArr = [];
      if (this.multipleSelection.length < 1) {
        this.$message.warning("请选择要删除的数据！");
        return;
      }
      for (var i = 0; i < this.multipleSelection.length; i++) {
        console.log(this.multipleSelection[i].reportStatus);
        if (
          this.multipleSelection[i].reportStatus != "wbzchzdsbzt_wsb" &&
          this.multipleSelection[i].reportStatus != "wbzchzdsbzt_yth"
        ) {
          this.$message.warning("只能选择未上报或已退回状态数据！");
          return;
        } else {
          idArr.push(this.multipleSelection[i].id);
        }
      }
      var param = { id: idArr.join(",") };
      this.$confirm("是否确定删除汇总单？", "提示", {
        confirmButtonClass: "btnsearch",
        confirmButtonText: "确认",
        cancelButtonClass: "btnreset",
        cancelButtonText: "取消",
        showClose: true,
        type: "warning",
      })
        .then(() => {
          postApi(`/hr/rentalcar/collectsheet/delete`, param)
            .then((res) => {
              let { data } = res;
              if (data.code == 0) {
                this.$message({
                  type: "success",
                  message: "删除成功!",
                });
                this.getApplyList();
                this.$refs.dTableRef.$refs.baseTable.clearSelection();
              }
            })
            .catch(() => {
              this.$message.error("请求出错");
            });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 表格多选事件
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handlePageChange(pageSize, pageNumber) {
      this.pageSize = pageSize;
      this.pageNumber = pageNumber;
      this.getApplyList();
    },
    //获取项目信息
    getProject() {
      getApi(`/project/prj/list/all`).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          this.options = {
            value: "projectId",
            optionsList: data.data,
          };
          this.projectCodeOptions = {
            value: "projectId",
            optionsList: data.data,
          };
          this.setOPtionsList(this.formList, this.options);
        }
      });
    },
    //条件查询表单--下拉选择数据填充（optionsList）
    setOPtionsList(formList, options) {
      formList.forEach((item) => {
        if (options) {
          if (item.value == options.value) {
            item.optionsList = options.optionsList;
          }
        }
      });
    },
  },
};
</script>

<style lang="less" scoped></style>

<style>
.el-select-dropdown {
  max-width: 48%;
}

.el-select-dropdown li {
  height: auto;
}

.el-select-dropdown li span {
  white-space: normal;
  word-break: break-all;
  word-wrap: break-word;
}
</style>
