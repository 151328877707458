<template>
    <div class="container">
            <dd-card title="条件搜索" spacer>
                <dd-search-form :form-config="formList" :labelWidth="'170px'" @search-click="handleSearchEvent"
                    ref="ddSearchForm"></dd-search-form>
            </dd-card>


            <dd-card title="条件搜索" :height="tableHeight">
                <dd-table :columns="columnsList" :data="tableData" border :isSelection="false" :topBtn="true"
                    show-page :loading="loading">
                    <template #table-btn>
                        <dd-button
                            type="primary"
                            size="medium"
                            icon="add"
                            @click="handleAdd()"
                            >新增</dd-button
                        >
                    </template>
                    <template #table-pagination>
                    <dd-pagination v-show="total > 0" :total="total" :page-size="size" :page-number="current"
                        @page-change="handleCurrentChange" align="right">
                    </dd-pagination>
                </template>
                </dd-table>
            </dd-card>

            <dd-popup
                v-if="addEditVisible"
                    width="60%"
                    height="30%"
                    showBtn
                    inPopup
                    :saveBtn="saveBtn"
                    :submitBtn="false"
                    :show="addEditVisible"
                    :title-name="titleName"
                    @popup-save="handleSave()"
                    @popup-close="handleClose"
                    >
                <div slot="main">
                    <dd-card title="" >
                        <dd-form :form-config="formList_form" :flex="2" :labelWidth="'170px'" 
                            ref="addEditForm" formRef="addEditForm"></dd-form>
                    </dd-card>
                </div>
            </dd-popup>
    </div>
</template>

<script>
import { getApi, delApi, postApi, putApi } from "@/api/request";
    export default {
        name: 'editPop',
        props: ['crmId'],
        data() {
            return {
                formData: {},
                saveBtn: true,
                formList: [
                {
                    label: "客户联系人名称",
                    value: "name",
                    inputType: "text",
                    placeholderText: "请输入客户联系人名称",
                },
                {
                },
                {
                },
                ],
                formList_form: [
                    {
                    label: "客户联系人名称",
                    value: "name",
                    inputType: "text",
                    placeholderText: "请输入客户联系人名称",
                    disabled: false,
                    rules: [
                        {
                        required: true,
                        message: "请输入客户联系人名称",
                        trigger: "blur",
                        },
                    ],
                    },
                    {
                    label: "个人联系方式",
                    value: "personalContact",
                    inputType: "text",
                    placeholderText: '请输入个人联系方式',
                    disabled: false,
                    },
                    {
                    label: "工作联系方式",
                    value: "workContact",
                    inputType: "text",
                    placeholderText: '请输入工作联系方式',
                    disabled: false,
                    },
                ],
                loading: false,
                tableHeight: null,
                tableData: [],
                columnsList: [
                    {
                        title: "客户联系人名称",
                        key: "name",
                        align: "center",
                    },
                    {
                        title: "个人联系方式",
                        key: "personalContact",
                        align: "center",
                    },
                    {
                        title: "工作联系方式",
                        key: "workContact",
                        align: "center",
                    },
                    {
                    title: "操作",
                    align: "center",
                    width: 80,
                    event: [
                        {
                            label: "编辑",
                          type: "warning",
                            state: "default",
                            method: (row) => {
                                this.handleEdit(row);
                            },
                            //   rules: (row) => {
                            //     let show = false;
                            //     if (row.status == "CW-YSBZ-ZT-BZZ" || row.status == "CW-YSBZ-ZT-SPTH" || row.status == 'CW-YSBZ-ZT-XDBZZ') {
                            //       show = true;
                            //     } else {
                            //       show = false;
                            //     }
                            //     return show;
                            //   },
                        },
                    ],
                },
                ],
                addEditVisible: false,
                titleName: '',
                current: 1,
                size: 10,
                total: null,
            }
        },
        created() {
            this.getTableData();
        },
        mounted() {
            this.$nextTick(() => {
                this.tableHeight = this.autoTableHeight(0);
            });
        },
        methods: {
            getTableData() {
                let { formData, current, size } = this;
                let params = {
                ...formData,
                crmId: this.crmId,
                };
                postApi(`/project/customercontactdetail/page/${current}/${size}`, params).then(res => {
                    if(res.data.code == 0) {
                        this.tableData = res.data.data.records;
                        this.total = res.data.data.total;
                    }
                })
            },
            handleEdit(row) {
                this.titleName = '编辑'
                this.addEditVisible = true;
                setTimeout(()=> {
                    this.$refs.addEditForm.formData = row;
                }, 0)
            },
            handleAdd() {
                this.titleName = '新增'
                this.addEditVisible = true;
                // 
                
            },
            handleClose() {
                this.addEditVisible = false;
            },
            handleSearchEvent(e) {
                this.formData = e;
                this.current = 1;
                this.getTableData();
            },
            /* 分页页码回调 */
            handleCurrentChange(size, number) {
                this.current = number;
                this.size = size;
                this.getTableData();
            },
            handleSave() {
                let flag = false;
                this.$refs.addEditForm.$refs.addEditForm.validate(
                    (valid) => {
                    if (valid) {
                        flag = true;
                    }
                    }
                );
                if(!flag) {
                    return false
                }
                if(this.titleName == '编辑') {
                    let { id, name, personalContact, workContact } = this.$refs.addEditForm.formData
                    let params = {
                        crmId: this.crmId,
                        name,
                        id,
                        personalContact,
                        workContact
                    }
                    putApi(`/project/customercontactdetail`, params).then(res => {
                        if(res.data.code == 0) {
                            this.$message.success('操作成功')
                        }
                        else {
                            this.$message.warning('操作失败，请稍后重试')
                        }
                        this.getTableData();
                    })
                }
                else {
                    let { name, personalContact, workContact } = this.$refs.addEditForm.formData
                    let params = {
                        crmId: this.crmId,
                        name,
                        personalContact,
                        workContact
                    }
                    postApi(`/project/customercontactdetail`, params).then(res => {
                        if(res.data.code == 0) {
                            this.$message.success('操作成功')
                        }
                        else {
                            this.$message.warning('操作失败，请稍后重试')
                        }
                        this.getTableData();
                    })
                }
                this.addEditVisible = false;
                
            },
        }
    }
</script>

<style lang="less" scoped>

</style>