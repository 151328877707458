<template>
    <div class="container">
        <dd-card title="">
            <dd-form
                :form-config="formList"
                :labelWidth="'170px'"
                :flex="3"
                ref="assignForm_1"
                formRef="assignForm_1"
            ></dd-form>
        </dd-card>
        <el-tabs v-model="activeName" type="card"  @tab-click="handleClick">
        <el-tab-pane label="基本信息" name="基本信息">
        <dd-card title="">
            <dd-form
                :form-config="formList_jbxx"
                :labelWidth="'170px'"
                :flex="3"
                ref="assignForm_jbxx"
                formRef="assignForm_jbxx"
            ></dd-form>
        </dd-card>
        <dd-card title="">
            <dd-form
                :form-config="formList_jbxx_1"
                :labelWidth="'170px'"
                :flex="3"
                ref="assignForm_jbxx_1"
                formRef="assignForm_jbxx_1"
            ></dd-form>
        </dd-card>
        </el-tab-pane>
        <el-tab-pane label="签订信息" name="签订信息">
            <dd-card title="">
                <dd-form
                    :form-config="formList_sign_information"
                    :labelWidth="'170px'"
                    :flex="3"
                    ref="assignForm_sign_information"
                    formRef="assignForm_sign_information"
                ></dd-form>
            </dd-card>
        </el-tab-pane>
      </el-tabs>
    </div>
</template>

<script>
    export default {
        name: 'contractDetail',
        props: ['detailData'],
        data() {
             return {
                formList: [
                    {
                    label: "单据编码",
                    value: "fnumber",
                    inputType: "text",
                    disabled: true,
                    },
                    {
                    label: "合同名称",
                    value: "fname",
                    inputType: "text",
                    disabled: true,
                    },
                    {
                    label: "工程项目",
                    value: "fprojectName",
                    inputType: "text",
                    disabled: true,
                    },
                    {
                    label: "旧合同编号",
                    value: "cfkdtextfield4",
                    inputType: "text", 
                    disabled: true,
                    },
                ],
                activeName: '基本信息',
                formList_jbxx: [
                {
                    label: "项目组织",
                    value: "fprojectorgName",
                    inputType: "text",
                    disabled: true,
                    },
                ],
                formList_jbxx_1: [
                    {
                        label: "合同类型",
                        value: "fcontractstatusName",
                        defaultValue: '',
                        inputType: "text",
                        disabled: true,
                    },
                    {
                        label: "合同状态",
                        value: "fcontractkindName",
                        defaultValue: '',
                        inputType: "text",
                        disabled: true,
                    },
                    {
                        label: "合同性质",
                        value: "fcontracttypeName",
                        defaultValue: '',
                        inputType: "text",
                        disabled: true,
                    },
                    {
                        label: "收支性质",
                        defaultValue: '',
                        value: " inconExpen",
                        inputType: "text",
                        disabled: true,
                    },
                    {
                        label: "合同重要性",
                        value: "fcontractheftName",
                        defaultValue: '',
                        inputType: "text",
                        disabled: true,
                    },
                    {
                        label: "原始合同",
                        defaultValue: '',
                        value: "fsourcebillName",
                        inputType: "text",
                        disabled: true,
                    },
                    {
                        label: "合同甲方",
                        value: "fpartaName",
                        inputType: "text",
                        disabled: true,
                    },
                    {
                        label: "合同乙方",
                        value: "fpartbName",
                        inputType: "text",
                        disabled: true,
                    },

                    {
                        label: "供应商纳税人身份",
                        value: "ftaxrateinfoName",
                        inputType: "text",
                        disabled: true,
                    },
                    {
                        label: "合同期限(天)",
                        value: "fcontractterm",
                        inputType: "text",
                        disabled: true,
                    },
                    {
                        label: "开始日期",
                        value: "fbegindate",
                        inputType: "text",
                        disabled: true,
                    },
                    {
                        label: "结束日期",
                        value: "ffinishdate",
                        inputType: "text",
                        disabled: true,
                    },
                    {
                        label: "业主合同",
                        value: "fmainownerbillName",
                        inputType: "text",
                        disabled: true,
                    },
                    {
                        label: "付款比例(%)",
                        value: "fpayrate",
                        inputType: "text",
                        disabled: true,
                    },
                    {
                        label: "付款方式",
                        value: "fpaytypeName",
                        inputType: "text",
                        disabled: true,
                    },
                    {
                        label: "合同总金额(元)",
                        value: "foriginalamount",
                        inputType: "text",
                        disabled: true,
                    },
                    {
                        label: "预计税额(元)",
                        value: "fplantax",
                        inputType: "text",
                        disabled: true,
                    },
                    {
                        label: "不含税合同价(元)",
                        value: "fnotaxdataamount",
                        inputType: "text",
                        disabled: true,
                    },
                    {
                        label: "实际承包人",
                        value: "factcontractorName",
                        inputType: "text",
                        disabled: true,
                    },
                    {
                        label: "适用税率",
                        value: "ftaxraterate",
                        inputType: "text",
                        disabled: true,
                    },
                    {
                        label: "含税合同价(元)",
                        value: "foftax",
                        inputType: "text",
                        disabled: true,
                    },
                    {
                        label: "合同属性",
                        value: "cfproperty",
                        inputType: "text",
                        disabled: true,
                    },
                    {
                        label: "采购子类型",
                        value: "cfproperty1",
                        inputType: "text",
                        disabled: true,
                    },
                ],
                formList_sign_information: [
                    {
                        label: "甲方签约日期",
                        value: "fsigndatea",
                        inputType: "text",
                        disabled: true,
                    },
                    {
                        label: "乙方签约日期",
                        value: "fsigndateb",
                        inputType: "text",
                        disabled: true,
                    },
                    {
                        label: "其他方签约日期",
                        value: "fsigndatec",
                        inputType: "text",
                        disabled: true,
                    },
                    {
                        label: "乙方签约人",
                        value: "fpersonb",
                        inputType: "text",
                        disabled: true,
                    },
                    {
                        label: "其他签约单位",
                        value: "fpartcName",
                        inputType: "text",
                        disabled: true,
                    },
                    {
                        label: "甲方法定代表",
                        value: "fdeputya",
                        inputType: "text",
                        disabled: true,
                    },
                    {
                        label: "乙方法定代表",
                        value: "fdeputyb",
                        inputType: "text",
                        disabled: true,
                    },
                    {
                        label: "其他方签约人",
                        value: "fpersonc",
                        inputType: "text",
                        disabled: true,
                    },
                    {
                        label: "甲方经办人",
                        value: "fhandlerpersonName",
                        inputType: "text",
                        disabled: true,
                    },
                    {
                        label: "乙方签约人电话",
                        value: "fphonenob",
                        inputType: "text",
                        disabled: true,
                    },
                    {
                        label: "年度",
                        value: "cfyear",
                        inputType: "text",
                        disabled: true,
                    },
                    {
                        label: "经办日期",
                        value: "fbizdate",
                        inputType: "text",
                        disabled: true,
                    },
                    {
                        label: "乙方通讯地址",
                        value: "faddressb",
                        inputType: "text",
                        disabled: true,
                    },
                    {
                        label: "签订地点",
                        value: "fsignplace",
                        inputType: "text",
                        disabled: true,
                    },
                    {
                        label: "乙方经办人",
                        value: "cfkdtextfield6",
                        inputType: "text",
                        disabled: true,
                    },
                    {
                        label: "签订份数",
                        value: "fsigncopies",
                        inputType: "text",
                        disabled: true,
                    },
                    {
                        label: "预付款金额(元)",
                        value: "fpreamount",
                        inputType: "text",
                        disabled: true,
                    },
                    {
                        label: "原件返回日期",
                        value: "cfkddatepicker",
                        inputType: "text",
                        disabled: true,
                    },
                    {
                        label: "质保期(月)",
                        value: "fqualityterm",
                        inputType: "text",
                        disabled: true,
                    },
                    {
                        label: "专业类别",
                        value: "cfsort",
                        inputType: "text",
                        disabled: true,
                    },
                    {
                        label: "质保金比例(%)",
                        value: "fqarate",
                        inputType: "text",
                        disabled: true,
                    },
                    {
                        label: "专业子类别",
                        value: "cfclasses",
                        inputType: "text",
                        disabled: true,
                    },
                    {
                        label: "质保金金额(元)",
                        value: "fqaamount",
                        inputType: "text",
                        disabled: true,
                    },
                    {
                        label: "期初累计收票金额",
                        value: "cfkdtextfield5",
                        inputType: "text",
                        disabled: true,
                    },
                    {
                        label: "履约保证金(元)",
                        value: " fexecamount",
                        inputType: "text",
                        disabled: true,
                    },
                    {
                        label: "累计收票金额",
                        value: "cfkdnumbertextfield",
                        inputType: "text",
                        disabled: true,
                    },
                    {},
                    {
                        label: "备注",
                        value: "fdescription",
                        inputType: "text",
                        type: "textarea",
                        defaultValue: "",
                        rows: "3",
                        disabled: true,
                    },
                ],
             }
        },
        watch: {
            detailData() {
                this.initPageData()
            },
        },
        methods: {
            handleClick(e) {

            },
            initPageData() {
                if(!this.detailData) {
                    return false;
                }
                this.$refs.assignForm_1.formData = {
                    fnumber: this.detailData.fnumber,    
                    fname: this.detailData.fname,
                    cfkdtextfield4: this.detailData.cfkdtextfield4,
                    fprojectid: this.detailData.fprojectid,
                    fprojectName: this.detailData.fprojectName,
                }
                this.$refs.assignForm_jbxx.formData = {
                    fprojectorgid: this.detailData.fprojectorgid,
                    fprojectorgName: this.detailData.fprojectorgName,
                }
                this.$refs.assignForm_jbxx_1.formData = { ...this.detailData }
                this.$refs.assignForm_sign_information.formData = { ...this.detailData }
                this.$forceUpdate()
            },
        }
    }
</script>

<style lang="less" scoped>
/deep/ .el-range-editor.is-disabled {
  background-color: #fbfbfb;
  color: @defaultFontColor;
}

/deep/ .el-range-editor.is-disabled input {
  color: @defaultFontColor;
}

/deep/ .el-descriptions-item__label {
  color: #606266;
  font-weight: bold;
}

/deep/ .el-descriptions-item__content {
  color: @defaultFontColor;
}
/deep/.el-input.is-disabled .el-input__inner {
  background-color: #fbfbfb;
  color: @defaultFontColor;
}

/deep/.el-textarea.is-disabled .el-textarea__inner {
  background-color: #fbfbfb;
  color: @defaultFontColor;
  resize: none;
}
</style>