<template>
  <div class="tab-container">
    <div class="left-tree container-left-tree">
      <el-tree
        ref="tree"
        :data="TreeList"
        accordion
        :props="defaultProps"
        default-expand-all
        highlight-current
        :expand-on-click-node="false"
        node-key="id"
        :current-node-key="treeNodeId"
        @node-click="handleNodeClick"
      >
      </el-tree>
    </div>
    <div class="right-table">
      <dd-card class="table-tab">
        <el-tabs
          v-model="workActiveName"
          type="card"
          @tab-click="handleTabClick"
        >
          <el-tab-pane
            v-for="val in workTabList"
            :key="val.value"
            :label="val.label"
            :name="val.value"
          ></el-tab-pane>
        </el-tabs>
      </dd-card>
      <dd-card v-if="workActiveName == 'scry'" :height="tableHeight">
        <dd-table
          :columns="nodeTableColumns"
          :data="nodeTableData"
          :topBtn="false"
          border
          :isSelection="false"
          :loading="loading"
          :tree-props="{ children: 'children' }"
          default-expand-all
        >
        </dd-table>
      </dd-card>
      <!-- 新增页面弹窗 -->
      <dd-popup
        width="60%"
        height="65%"
        showBtn
        :show="addPopupVisible"
        title-name="添加生产人员"
        @popup-close="addPopupVisible = false"
        @popup-save="handleSave('addPopForm', 'baseForm', 1)"
      >
        <add-person-pop
          v-if="addPopupVisible"
          slot="main"
          ref="addPop"
          :rowData="rowData"
        ></add-person-pop>
      </dd-popup>
      <dd-card title="条件搜索" spacer v-if="workActiveName == 'qtgxr'">
        <dd-search-form
          :form-config="formList"
          @search-click="handleSearchEvent"
          ref="ddSearchForm"
        ></dd-search-form>
      </dd-card>
      <dd-card v-if="workActiveName == 'qtgxr'" :height="tableHeight">
        <dd-table
          :columns="qtTableColumns"
          :data="qtTableData"
          border
          :isSelection="false"
          show-page
          :loading="loading"
        >
          <template #table-btn>
            <dd-button
              type="primary"
              size="medium"
              icon="add"
              @click="handleAdd"
              >新增</dd-button
            >
          </template>
          <template #table-pagination>
            <dd-pagination
              v-show="total > 0"
              :total="total"
              :page-size="size"
              :page-number="current"
              @page-change="handleCurrentChange"
              align="right"
            >
            </dd-pagination>
          </template>
        </dd-table>
      </dd-card>
      <!-- 其他干系人新增编辑页面弹窗 -->
      <dd-popup
        width="30%"
        height="60%"
        showBtn
        :show="qtAddPopupVisible"
        :title-name="poptitleName"
        @popup-close="qtAddPopupVisible = false"
        @popup-save="handleQtSave('addPopForm', 'baseForm', 1)"
      >
        <add-qt-person-pop
          v-if="qtAddPopupVisible"
          slot="main"
          ref="qtAddPop"
          :rowData="qtRowData"
        ></add-qt-person-pop>
      </dd-popup>
    </div>
  </div>
</template>

<script>
import { getApi, postApi, putApi, delApi } from "@/api/request";
import addPersonPop from "./AddPersonPop.vue";
// 其他干系人新增编辑弹框
import AddQtPersonPop from "./AddQtPersonPop.vue";
import { h } from "vue";
export default {
  props: {
    resolveType: {
      type: String,
      default: "",
    },
    rowId: {
      type: String,
      default: "",
    },
  },
  components: {
    addPersonPop,
    AddQtPersonPop,
  },
  data() {
    return {
      tableHeight: null,
      TreeList: [],
      defaultProps: {
        children: "children",
        label: "nodeName",
      },
      treeNodeId: "",
      loading: true,
      addPopupVisible: false,
      rowData: null,
      workActiveName: "scry",
      workTabList: [
        {
          label: "生产人员",
          value: "scry",
        },
        {
          label: "其他干系人",
          value: "qtgxr",
        },
      ],
      nodeTableColumns: [
        {
          title: "节点编号",
          key: "nodeCode",
        },
        {
          title: "节点名称",
          key: "nodeName",
          align: "center",
          overflow: true,
        },
        {
          title: "节点类型",
          key: "nodeTypeName",
          align: "center",
        },
        {
          title: "生产负责部门",
          key: "nodeDeptName",
          align: "center",
        },
        {
          title: "负责人",
          key: "nodeUser",
          align: "center",
        },
        {
          title: "生产人员",
          key: "subplanStakeholderArr",
          align: "center",
          formatterArr: (row) => {
            return this.getArrItem(row);
          },
        },
        {
          title: "操作",
          key: "handle",
          align: "center",
          event: [
            {
              label: "添加生产人员",
              icon: "el-icon-plus",
              method: (row) => {
                this.handleRowAdd(row);
              },
              type: "primary",
              rules: (row) => {
                let show = false;
                if (
                  row.nodeType != "PRJ-RWJHJDLX-GJD" &&
                  row.nodeType != "PRJ-RWJHJDLX-ZRW"
                ) {
                  show = true;
                } else {
                  show = false;
                }
                return show;
              },
            },
          ],
        },
      ],
      nodeTableData: [],

      poptitleName: "新增",
      qtAddPopupVisible: false,
      qtRowData: null,
      formData: {},
      formList: [
        {
          label: "干系人名称",
          value: "holderName",
          inputType: "text",
          placeholderText: "请输入干系人名称",
        },
        {
          label: "干系人类型",
          value: "holderType",
          inputType: "select",
          optionsList: [],
          filterable: true,
          placeholderText: "请选择干系人类型",
          optionsListConfig: {
            name: "name",
            code: "code",
          },
        },
        {
          label: "干系人单位",
          value: "holderCompany",
          inputType: "text",
          placeholderText: "请输入干系人单位",
        },
      ],
      qtTableColumns: [
        {
          title: "干系人类型",
          key: "holderTypeName",
          align: "center",
        },
        {
          title: "干系人名称",
          key: "holderName",
          align: "center",
        },
        {
          title: "联系方式",
          key: "holderTel",
          align: "center",
        },
        {
          title: "干系人职务",
          key: "holderPost",
          align: "center",
        },
        {
          title: "干系人单位",
          key: "holderCompany",
          align: "center",
        },
        {
          title: "说明",
          key: "holderDes",
          align: "center",
        },
        {
          title: "创建时间",
          key: "createTime",
          align: "center",
        },
        {
          title: "操作",
          key: "handle",
          align: "center",
          event: [
            {
              label: "编辑",
              icon: "el-icon-edit",
              method: (row) => {
                this.handleRowEditor(row);
              },
              type: "warning",
            },
            {
              label: "删除",
              icon: "el-icon-delete",
              method: (row) => {
                this.handleRowDel(row);
              },
              type: "danger",
            },
          ],
        },
      ],
      qtTableData: [],
      current: 1,
      size: 20,
      total: null,
      userList: [],
      treeAssignId: null,
      leftTreeVisible: true,
    };
  },
  created() {
    this.getDataType();
  },
  mounted() {
    // 自动计算table高度
    this.$nextTick(() => {
      this.tableHeight = this.autoTableHeight(0);
    });
    this.treeAssignId = this.rowId;
    this.getTableData();
  },
  methods: {
    //条件查询表单--下拉选择数据填充（optionsList）
    setOPtionsList(formList, options) {
      formList.forEach((item) => {
        if (options) {
          if (item.value == options.value) {
            item.optionsList = options.optionsList;
          }
        }
      });
    },
    getDataType() {
      postApi(`/admin/user/getver/list`, {}).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          this.userList = data.data;
        }
      });
    },
    // 获取表格列表数据
    getTableData() {
      this.loading = true;
      let params = {
        id: this.treeNodeId,
      };
      getApi(
        `/project/prjassignsubplanstakeholder/tree/${this.treeAssignId}`,
        params
      ).then((res) => {
        let { data } = res;
        if (data && data.code == 0 && data.data) {
          if (!this.treeNodeId) {
            this.TreeList = data.data;
            this.treeNodeId = data.data[0].id;
            this.$nextTick(() => {
              this.$refs.tree.setCurrentKey(this.treeNodeId); //一定要加这个选中了否则样式没有出来
            });
          }
          this.nodeTableData = data.data;
          this.loading = false;
        } else {
          this.loading = false;
        }
      });
    },
    handleNodeClick(data) {
      if (this.workActiveName == "scry") {
        this.treeNodeId = data.id;
        this.treeAssignId = data.assignId;
        this.getTableData();
      }
    },
    handleTabClick(tab, event) {
      this.$nextTick(() => {
        this.tableHeight = this.autoTableHeight(0);
      });
      this.workActiveName = tab.name;
      if (this.workActiveName == "qtgxr") {
        this.leftTreeVisible = false;
        this.$nextTick(() => {
          let height1 = this.autoTableHeight(0).split("-");
          let height2 = this.autoTableHeight(1).split("-");
          let height =
            Number(height1[1].split("px)")[0]) +
            Number(height2[1].split("px)")[0]);
          this.tableHeight = `calc(100% - ${height}px)`;
        });
        this.getQtPersonData();
        this.getGxrType();
      } else {
        this.leftTreeVisible = true;
      }
    },
    getGxrType() {
      // 干系人类型
      postApi(`/project/prj/dict/list/all`, { typeCode: "PRJ-GXRLX" }).then(
        (res) => {
          let { data } = res;
          if (data && data.code == 0) {
            let options = {
              value: "holderType",
              optionsList: data.data,
            };
            this.setOPtionsList(this.formList, options);
          }
        }
      );
    },
    // 获取其他干系人数据
    getQtPersonData() {
      let { formData, current, size } = this;
      let params = {
        ...formData,
        current,
        size,
        assignId: this.rowId,
      };
      getApi(`/project/prjassignotherstakeholder/page`, params).then((res) => {
        let { data } = res;
        if (data && data.code == 0) {
          this.qtTableData = data.data.records;
          this.total = data.data.total;
          this.loading = false;
        }
      });
    },
    handleRowAdd(row) {
      this.rowData = row;
      this.addPopupVisible = true;
    },
    // 生产干系人新增提交
    handleSave() {
      let personArr = this.$refs.addPop.selectPersonDataList;
      let params = {
        assignId: this.rowData.assignId,
        nodeCode: this.rowData.nodeCode,
        userIds: personArr,
      };
      postApi(`/project/prjassignsubplanstakeholder`, params).then((res) => {
        let { data } = res;
        if (data && data.code == 0) {
          this.$message.success("保存成功");
          this.addPopupVisible = false;
          this.getTableData();
        }
      });
    },
    // 其他干系人新增按钮
    handleAdd() {
      this.qtAddPopupVisible = true;
      this.poptitleName = "新增";
    },
    // 表格行内编辑事件
    handleRowEditor(row) {
      this.qtRowData = row;
      this.qtAddPopupVisible = true;
      this.poptitleName = "编辑";
    },
    //列表单行删除
    handleRowDel(row) {
      this.$confirm(`确认删除该数据吗？`, "提示", {
        confirmButtonClass: "btnsearch",
        confirmButtonText: "确认",
        cancelButtonClass: "btnreset",
        cancelButtonText: "取消",
        showClose: true,
        type: "warning",
      }).then(() => {
        this.handleConfirmDelete(row.id);
      });
    },
    // 确认删除请求
    handleConfirmDelete(delId) {
      delApi(`/project/prjassignotherstakeholder/${delId}`).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          this.$message.success("删除成功");
        }
        this.getQtPersonData();
      });
    },
    // 其他干系人新增提交
    handleQtSave(formName, formNameBase) {
      let flag = false;
      this.$refs.qtAddPop.$refs[formName].$refs[formNameBase].validate(
        (valid) => {
          if (valid) {
            flag = true;
          }
        }
      );
      let params = this.$refs.qtAddPop.$refs[formName].formData;
      if (flag) {
        params.assignId = this.rowId;
        if (this.qtRowData != null && this.poptitleName == "编辑") {
          params.id = this.qtRowData.id;
          putApi(`/project/prjassignotherstakeholder`, params).then((res) => {
            let { data } = res;
            if (data.code == 0) {
              this.$message.success("修改成功");
              this.qtAddPopupVisible = false;
              this.getQtPersonData();
            }
          });
        } else {
          postApi(`/project/prjassignotherstakeholder`, params).then((res) => {
            let { data } = res;
            if (data.code == 0) {
              this.$message.success("保存成功");
              this.qtAddPopupVisible = false;
              this.getQtPersonData();
            }
          });
        }
      }
    },
    /* 搜索栏 */
    handleSearchEvent(obj) {
      this.formData = obj;
      this.current = 1;
      this.getQtPersonData();
    },
    /* 分页页码回调 */
    handleCurrentChange(size, number) {
      this.current = number;
      this.size = size;
      this.getQtPersonData();
    },
    getArrItem(row) {
      let arr = [];
      this.userList.forEach((item) => {
        row &&
          row.forEach((val) => {
            if (val == item.userId) {
              arr.push(item.fullName);
            }
          });
      });

      return arr;
    },
  },
};
</script>

<style lang="less" scoped>
.tab-container {
  height: 100%;
  display: flex;
  .left-tree {
    width: 20%;
    height: 100%;
    padding: 10px 15px;
    box-sizing: border-box;
    border-right: 5px solid #e7eaec;
    overflow-y: auto;
    .custom-tree-node {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: @fs14;

      .node-hanlde {
        color: @defaultFontColor;
        i {
          margin: 0 5px;
        }
      }
    }
  }
  .right-table {
    width: 80%;
    height: 100%;
    /deep/.el-table__expand-icon {
      display: none !important;
    }
    /deep/ .el-table__indent {
      display: none !important;
    }
    /deep/.el-table__placeholder {
      display: none !important;
    }
    .table-tab {
      /deep/ .card-contant {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 0;
        .el-tabs {
          width: 100%;
        }
      }
      /deep/ .el-tabs__header {
        margin: 0;
      }
    }
  }
}
</style>
