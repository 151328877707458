<template>
  <div class="container">
      <dd-card title="基本信息">
        <dd-form  :form-config="formList" :flex="2" :ref="formRef"></dd-form>
      </dd-card>
  </div>
</template>

<script>
import { postApi, getApi, loadApi } from "@/api/request";
import qs from "qs";
import { mapState, mapActions } from "vuex";

export default {
  props: {
    type: "",
    id: "",
  },
  computed: {
    ...mapState(["btnAuthorityList"]),
  },
  data() {
    return {
      detailiInfo: {},
      hrVehicleApplySheetFileList: [],
      formRef: "baseForm",
      headers: {}, // 请求头信息
      limit: 10, //文件上次个数限制
      ruleForm: {},
      activeName: "first",
      fileList: [],
      applyId: "",
      value: "",
      tableData: [],
      formList: [
        {
          label: "人员",
          style: "width:50%",
          value: "userId",
          inputType: "select",
          optionsList: [],
          optionsListConfig: {
            code: "userId",
            name: "fullName",
          },
          change: this.handleSelectChange,
          clear: this.handleSelectClear,
          defaultValue: "",
          filterable: true,
          placeholderText: "请选择或搜索人员",
          rules: [
            { required: true, message: "请选择或搜索人员", trigger: "blur" },
          ],
        },
        {
          label: "工号",
          style: "width:50%",
          value: "account",
          inputType: "select",
          disabled: true,
          optionsList: [],
          optionsListConfig: {
            code: "username",
            name: "account",
          },
          change: this.handleSelectChange,
          clear: this.handleSelectClear,
          defaultValue: "",
          placeholderText: "请选择或搜索工号",
          rules: [
            { required: true, message: "请选择或搜索工号", trigger: "blur" },
          ],
        },
        {
          label: "所属年份",
          style: "width:50%",
          value: "year",
          placeholderText: "所属年份",
          dateType: "year",
          format: "yyyy",
          inputType: "date",
          defaultValue: this.formatDate(new Date()).slice(0, 4),
          rules: [
            { required: true, message: "请选择工资年份", trigger: "blur" },
          ],
        },
        {
          label: "所属月份",
          style: "width:50%",
          value: "month",
          inputType: "select",
          optionsList: [],
          // optionsListConfig: {
          //   code: "projectId",
          //   name: "projectName",
          // },
          defaultValue: "",
          placeholderText: "请选择或搜索工资周期",
          rules: [
            {
              required: true,
              message: "请选择或搜索工资周期",
              trigger: "blur",
            },
          ],
        },
        {
          label: "工资余额",
          value: "salary",
          style: "width:50%",
          inputType: "text",
          placeholderText: "请输入起始工资余额,例如1000",
          defaultValue: "",
          rules: [
            { required: true, message: "请输入工资余额", trigger: "blur" },
            {
              pattern:
                /^(([1-9]\d+(.[0-9]{1,4})?|\d(.[0-9]{1,4})?)|([-]([1-9]\d+(.[0-9]{1,4})?|\d(.[0-9]{1,4})?)))$/,
              required: true,
              message: "请输入正确的格式",
              trigger: "blur",
            },
          ],
        },
        {
          label: "备注",
          value: "remark",
          inputType: "text",
          style: "width:49.9%",
          type: "textarea",
          placeholderText: "请输入备注",
          defaultValue: "",
        },
      ],
    };
  },
  created() {
    console.log(this.id, this.type);
    if (this.type == "detail") {
      this.formList.forEach((item) => {
        item.inputType = "detail";
        item.rules = [];
      });
      this.getDetailInfo();
    } else {
      this.getCollectionList();
      this.getMonthList();
    }
  },
  mounted() {},
  methods: {
    //查询详情
    getDetailInfo() {
      getApi(`/hr/balance/${this.id}`, {}).then((res) => {
        let { data } = res;
        console.log(data);
        if (data.code == 0) {
          this.formList.forEach((item) => {
            if (item.value == "userName") {
              item.defaultValue = data.data.username;
            } else if (item.value == "account") {
              item.defaultValue = data.data.account;
            } else if (item.value == "year") {
              item.defaultValue = data.data.year;
            } else if (item.value == "month") {
              item.defaultValue = data.data.monthName;
            } else if (item.value == "salary") {
              item.defaultValue = data.data.salary;
            } else if (item.value == "remark") {
              item.defaultValue = data.data.remark;
            }
          });
        }
      });
    },
    //工号列表
    getCollectionList() {
      postApi(`/admin/user/getver/list`,{
      }).then(res => {
        let {data} = res;
        if (data.code == 0) {
          let options = {
            value: "userId",
            optionsList: data.data,
          };
          this.setOPtionsList(this.formList, options);
        }
      })
    },
    setOPtionsList(formList, options) {
      formList.forEach((item) => {
        if (options) {
          if (item.value == options.value) {
            item.optionsList = options.optionsList;
          }
        }
      });
    },
    // 所属月份状态下拉数据请求
    getMonthList() {
      postApi(
        `/finance/dict/list`,
        qs.stringify({ typeCode: "balance_month" })
      ).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          this.formList.forEach((item) => {
            if (item.value == "month") {
              item.optionsList = data.data;
            }
          });
        }
      });
    },

    getRowColumn({ row, column, rowIndex, columnIndex }) {
      row.index = rowIndex;
      column.index = columnIndex;
    },
    //监听搜索下拉
    handleSelectChange(e, type) {
      if (type == "userId" || type == "account") {
        this.formList[0].optionsList.map((item) => {
          if (type == "userId") {
            if (item.userId == e) {
              this.$refs.baseForm.formData.account = item.username;
            }
          } else if (type == "account") {
            if (item.account == e) {
              this.$refs.baseForm.formData.username = item.fullName;
            }
          }
        });
      }
      console.log(this.$refs.baseForm.formData);
    },
    //监听下拉清除
    handleSelectClear(e, type) {
      console.log(e);
      if (type == "userId" || type == "account") {
        this.$refs.baseForm.formData.userId = "";
        this.$refs.baseForm.formData.account = "";
      }
    },
    //提交
    submitOrSave() {
      let { formData } = this.$refs.baseForm;
      for (let [key, value] of Object.entries(formData)) {
        this.formList.map((item) => {
          if (item.value == key && !value && item.rules) {
            this.$message.warning(`${item.rules[0].message}`);
            throw new Error();
          }
        });
      }
      console.log(formData);
      let params = {
        ...formData,
      };
      console.log("params", params);
      postApi(`/hr/balance`, params)
        .then((res) => {
          let { data } = res;
          if (data.code == 0) {
            this.$message.success("提交成功！");
            this.$emit("diaChange", false);
          } else {
            this.$message.error(data.msg);
            this.$message({
              message: data.msg,
              type: "error",
              duration: 6000,
            });
          }
        })
        .catch((data) => {
          if (data.response && data.response.data && data.response.data.msg) {
            this.$message({
              message: data.response.data.msg,
              type: "error",
              duration: 6000,
            });
          } else {
            this.$message({
              message: data,
              type: "error",
              duration: 6000,
            });
          }
        });
    },
    getText(value) {
      if (value == "applier") {
        console.log(sessionStorage.getItem("username"));
        return sessionStorage.getItem("username");
      } else if (value == "applyDep") {
        return sessionStorage.getItem("depname");
      } else if (value == "maker") {
        return sessionStorage.getItem("username");
      } else if (value == "orgName") {
        return sessionStorage.getItem("orgName");
      }
    },
    // 格式化日期
    formatDate(date) {
      const year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDate();
      if (month < 10) {
        month = `0${month}`;
      }
      if (day < 10) {
        day = `0${day}`;
      }
      return `${year}-${month}-${day}`;
    },
    cancel() {
      this.$emit("diaChange", false);
    },
  },
};
</script>

<style lang='less' scoped>
.container {
  width: 100%;

  .form_wrap {
    position: relative;

    /deep/ .el-tag {
      margin-bottom: 15px !important;
    }

    .avatar-uploader {
      /deep/ .el-upload {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
      }
    }

    .avatar-uploader .el-upload:hover {
      border-color: #409eff;
    }

    .avatar-uploader-icon {
      font-size: 28px;
      color: #8c939d;
      width: 80px;
      height: 80px;
      line-height: 80px;
      text-align: center;
    }

    .avatar {
      width: 80px;
      height: 80px;
      display: block;
    }

    .foot_wrap {
      margin-bottom: 0 !important;
      margin-top: 20px;
      display: flex;
      justify-content: center;

      /deep/ .el-form-item__content {
        margin-left: 0 !important;
      }

      /deep/ .el-button {
        padding: 10px 50px;
      }

      /deep/ .el-button--default {
        color: #fff;
        background-color: #ff6f45;
      }
    }

    /deep/ .el-form-item__label {
      min-width: 60px !important;
    }

    /deep/ .el-form-item__content {
      margin-left: 80px !important;
    }
  }
}
</style>

<style>
.el-select-dropdown {
  max-width: 48%;
}

.el-select-dropdown li {
  height: auto;
}

.el-select-dropdown li span {
  white-space: normal;
  word-break: break-all;
  word-wrap: break-word;
}
</style>