<template>
  <div class="container">
    <dd-card :title="rowData.name"></dd-card>

    <dd-card title="基本信息" class="base-form-collect-info">
      <dd-form
        :form-config="formList"
        :flex="3"
        labelWidth="160px"
      ></dd-form>
    </dd-card>

    <dd-card title="工作量明细">
      <dd-search-form
        :form-config="searchFormList"
        @search-click="handleSearchEvent"
        ref="ddSearchForm"
      ></dd-search-form>
      <dd-table
        :columns="columnsList"
        show-summary
        :summary-method="getSummaries"
        :data="tableDataSources"
        border
        :isSelection="false"
        ref="dTableRef"
        show-page
      >
        <template #table-pagination>
          <dd-pagination
            v-show="total > 0"
            :total="total"
            :page-size="size"
            :page-number="current"
            @page-change="handlePageChange"
            align="right"
          >
          </dd-pagination>
        </template>
      </dd-table>
    </dd-card>
    <!-- 自定义工作包 编辑 -->
    <dd-popup
      v-if="customizableVisible"
      width="40%"
      height="50%"
      :inPopup="true"
      :showBtn="true"
      :title-name="popTitle"
      :show="customizableVisible"
      @popup-close="handleClose"
      @popup-save="handleSave('addPopForm', 'baseForm')"
    >
      <AddPopDetailEidt
        slot="main"
        ref="customizable"
        :rowData="rowData"
        :editData="editData"
      ></AddPopDetailEidt>
    </dd-popup>
    <!-- 详情 -->
    <dd-popup
      v-if="dialogVisible"
      width="80%"
      height="80%"
      :inPopup="true"
      :showBtn="false"
      :title-name="popTitle"
      :show="dialogVisible"
      @popup-close="handleClose"
    >
      <AddPopDetailShow
        slot="main"
        ref="addPop"
        :id="detailId"
        :rowData="rowData"
        :isEdit="isEdit"
      ></AddPopDetailShow>
    </dd-popup>
  </div>
</template>

<script>
import { postApi, getApi, putApi } from "@/api/request";
import { getNowDate, getNowYear } from "@/utils/date";
import AddPopDetailShow from "./AddPopDetailShow.vue";
import  AddPopDetailEidt from "./AddPopDetailEidt.vue";

export default {
  name: "add-pop",
  props: {
    rowData: [],
    titleName: "",
  },
  components: { AddPopDetailShow,AddPopDetailEidt },
  data() {
    return {
      dialogVisible: false,
      customizableVisible:false,
      editData:{},
      isEdit:false,
      formList: [
        {
          label: "部门",
          value: "deptId",
          inputType: "select",
          optionsList: [],
          optionsListConfig: {
            name: "name",
            code: "deptId",
          },
          defaultValue: sessionStorage.getItem("depId"),
          placeholderText: "请选择部门",
          rules: [{ required: true, message: "请选择部门", trigger: "blur" }],
          // change: this.handleChange,
        },
        {
          label: "所属年月",
          value: "reportMonth",
          inputType: "text",
          // dateType: "month",
          // format: "yyyy-MM",
          defaultValue: "",
          // placeholderText: "请选择所属年月",
          // rules: [
          //   { required: true, message: "请选择所属年月", trigger: "blur" },
          // ],
        },
        {
          label: "统计工作量类型",
          value: "isStandardWorkpackage",
          inputType: "select",
          filterable: true,
          optionsList: [],
          defaultValue: "",
          placeholderText: "请选择统计工作量类型",
          rules: [
            {
              required: true,
              message: "请选择统计工作量类型",
              trigger: "blur",
            },
          ],
        },
        {
          label: "起始时间点",
          value: "startTime",
          inputType: "date",
          dateType: "datetime",
          format: "yyyy-MM-dd HH:mm:ss",
          defaultValue: new Date(),
          placeholderText: "请选择起始时间点",
          rules: [
            { required: true, message: "请选择起始时间点", trigger: "blur" },
          ],
        },
        {
          label: "截止时间点",
          value: "deadLine",
          inputType: "date",
          dateType: "datetime",
          format: "yyyy-MM-dd HH:mm:ss",
          defaultValue: new Date(),
          placeholderText: "请选择截止时间点",
          rules: [
            { required: true, message: "请选择截止时间点", trigger: "blur" },
          ],
        },
      ],
      searchFormList: [
        {
          label: "区域",
          value: "areaName",
          inputType: "text",
          placeholderText: "请输入区域名称",
          title: true,
        },
        {
          label: "任务书",
          value: "assignName",
          inputType: "text",
          placeholderText: "请输入任务书名称",
          title: true,
        },
        {
          label: "姓名",
          value: "userName",
          inputType: "text",
          placeholderText: "请输入姓名",
          title: true,
        },
      ],
      tableDataSources: [],
      columnsList: [
        {
          title: "区域",
          key: "areaName",
          align: "center",
          width: 100,
          overflow: true,
        },
        {
          title: "任务书编码",
          key: "assignCode",
          align: "center",
          width: 180,
          overflow: true,
        },
        {
          title: "任务书名称",
          key: "assignName",
          width: 180,
          align: "center",
          overflow: true,
        },
        {
          title: "工作包名称",
          key: "nodeName",
          align: "center",
          width: 180,
          overflow: true,
        },
        {
          title: "工号",
          key: "userCode",
          width: 100,
          align: "center",
        },
        {
          title: "姓名",
          key: "userName",
          align: "center",
          width: 100,
          overflow: true,
        },
        {
          title: "绩效金额",
          key: "reviewPerformance",
          width: 160,
          align: "center",
        },
        {
          title: "工作量",
          key: "workload",
          align: "center",
          width: 160,
          overflow: true,
        },
        {
          title: "工作量单位",
          key: "unitName",
          width: 100,
          align: "center",
        },
        
        {
          title: "操作",
          align: "center",
          fixed: "right",
          width: "150px",
          event: [
            {
              label: "编辑",
              type: "warning",
              icon: "el-icon-edit",
              method: (row) => {
                // this.rowData.standardWorkpackage 0 wbs模式 - 自定义工作包 1子任务模式 - 标准工作包
                if(this.rowData.standardWorkpackage == 0){
                  this.handleCustomizableEdit(row);
                }else if(this.rowData.standardWorkpackage == 1){
                  this.handleAddEdit(row);
                }
              },
              rules: (row) => {
                let show = false;
                if (this.rowData.status == "PRJ-ASSIGN-PERFORMANCE-ACCOUNTING-HSZ" || this.rowData.status == "PRJ-ASSIGN-PERFORMANCE-ACCOUNTING-YTH") {
                  show = true;
                } else {
                  show = false;
                }
                return show;
              },
            },
            {
              label: "查看详细",
              icon: "el-icon-view",
              state: "default",
              method: (row) => {
                this.handleDetail(row);
              },
              rules: (row) => {
                let show = false;
                // this.rowData.standardWorkpackage 0 wbs模式 - 自定义工作包 1子任务模式 - 标准工作包
                if (this.rowData.status != "PRJ-ASSIGN-PERFORMANCE-ACCOUNTING-HSZ" && this.rowData.status != "PRJ-ASSIGN-PERFORMANCE-ACCOUNTING-YTH") {
                  show = true;
                } else {
                  show = false;
                }
                return show;
              },
            },
          ],
        },
      ],
      // 页码
      current: 1,
      // 每页的数据条数
      size: 10,
      total: null,
      detailId:'',
      id:'',
    };
  },
  created() {},
  mounted() {
    if (this.rowData != null) {
      // this.getDataDetail(this.rowData.id);
      this.init();
      if (this.rowData.standardWorkpackage == 0){
        this.initTablecolumnsList()
      }
      console.log('this.rowData.status', this.rowData.status)
    }
    
  },
  methods: {
    init(){
      // this.popTitle = row.standardWorkpackage ? "子任务模式-明细列表" : "wbs模式-明细列表";
      this.getWorkloadDetailList();
      this.formList.forEach((item) => {
        if(item.value == "deptId"){
          item.defaultValue = this.rowData.deptName;
        }
        if(item.value == "reportMonth"){
          item.defaultValue = this.rowData.reportMonth;
        }
        if(item.value == "isStandardWorkpackage"){
          item.defaultValue = this.rowData.standardWorkpackageType;
        }
        if(item.value == "startTime"){
          item.defaultValue = this.rowData.startTime;
        }
        if(item.value == "deadLine"){
          item.defaultValue = this.rowData.deadLine;
        }
        item.disabled = true;
      });
      // this.rowData.standardWorkpackage 0 wbs模式 - 自定义工作包 添加列
      
    },
    initTablecolumnsList() {
      this.columnsList.push(
          {
            title: "效率",
            key: "efficiency",
            width: 180,
            align: "center",
          },
          {
            title: "标准工天",
            key: "standardWorkday",
            width: 180,
            align: "center",
          },
          {
            title: "计划标准工天单价",
            key: "planStandardWorkdayPrice",
            width: 180,
            align: "center",
          },
          {
            title: "执行标准工天单价",
            key: "carryStandardWorkdayPrice",
            width: 180,
            align: "center",
          },
          {
            title: "系数调整",
            key: "coefficientAdjustment",
            width: 180,
            align: "center",
          },
          {
            title: "固定调整",
            key: "fixedAdjustment",
            width: 180,
            align: "center",
          },
          {
            title: "绩效计算备注说明",
            key: "performanceRemark",
            width: 180,
            align: "center",
          }
        )
    },
    getSummaries(param) { // 自定义项表格合计方法
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "合计";
          return;
        } else if (column.property == "reviewPerformance"
        ) {
          //如果是经费（正常的加减法）
          const values = data.map((item) => item[column.property] ? item[column.property] * 1 : 0);
          if (!values.every((value) => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr);
              var sum = 0;
              if (!isNaN(value)) {
                sum = Number(Number(prev) + Number(curr)).toFixed(2);
                return sum;
              } else {
                return prev;
              }
            }, 0);
            sums[index] += " ";
          }
        }
      });
      return sums
    },
    //条件查询表单--下拉选择数据填充（optionsList）
    setOPtionsList(formList, options) {
      formList.forEach((item) => {
        if (options) {
          if (item.value == options.value) {
            item.optionsList = options.optionsList;
          }
        }
      });
    },
    handleSearchEvent(obj) {
      this.formData = obj;
      this.current = 1;
      this.init();
    },
    getWorkloadDetailList() {
      let { current, size, formData } = this,
          url = this.rowData.standardWorkpackage == 0 
            ? `/project/performanceaccounting/zdydetail/list/${current}/${size}` 
            : `/project/performanceaccounting/detail/list/${current}/${size}`;
      let params = {
        ...formData,
      };
      params.workloadSumId = this.rowData.workloadSumId;
      postApi(url,params).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          this.tableDataSources = data.data.records;
          this.total = data.data.total;
        }
      });
    },
    // 开启明细列表弹窗
    handleAddEdit(row){
      this.popTitle = row.standardWorkpackage
        ? "编辑"
        : "编辑";
      this.detailId = row.id;
      this.dialogVisible = true;
      this.isEdit = true;
    },
    handleDetail(row){
      this.popTitle = row.standardWorkpackage
        ? "查看详细"
        : "查看详细";
      this.detailId = row.id;
      this.dialogVisible = true;
      this.isEdit = false;
    },
    // 弹框关闭事件
    handleClose() {
      this.dialogVisible = false;
      this.customizableVisible = false;
      this.init();
    },
    // 自定义工作包编辑
    handleCustomizableEdit(row){
      this.id = row.id;
      this.customizableVisible = true;
      this.popTitle = "编辑";
      this.editData = row;
    },
    handlePageChange(size, current){
      this.current = current;
      this.size = size;
      this.getWorkloadDetailList();
    },
    // 自定义工作包保存
    handleSave(formName, formNameBase){      
      let flag = false;
      this.$refs.customizable.$refs[formName].$refs[formNameBase].validate(
        (valid) => {
          if (valid) {
            flag = true;
          }
        }
      );
      let params = this.$refs.customizable.$refs[formName].formData;
      if(params.carryStandardWorkdayPrice == ''){
        return this.$message.warning("请输入执行标准工天单价");
      }
      if(params.coefficientAdjustment == ''){
        return this.$message.warning("请输入系数调整");
      }
      if(params.fixedAdjustment == ''){
        return this.$message.warning("请输入固定调整");
      }
      if(params.performanceRemark == ''){
        return this.$message.warning("请输入绩效计算备注说明");
      }
      if (flag) {
        if (this.rowData) {
          params.id = this.id;
        }
        postApi(`/project/performanceaccounting/zdy/update`, params).then((res) => {
          let { data } = res;
          if (data.code == 0) {
            this.$message.success("保存成功");
            this.handleClose();
            this.getWorkloadDetailList();
          }
        });
      }
    },
  },
};
</script>
<style lang="less" scoped>
/deep/.el-input.is-disabled .el-input__inner {
  background-color: #fbfbfb;
  color: @defaultFontColor;
}
/deep/.el-textarea.is-disabled .el-textarea__inner {
  background-color: #fbfbfb;
  color: @defaultFontColor;
  resize: none;
}
// /deep/ .el-input__suffix {
//   display: none;
// }
/deep/ .el-range-editor.is-disabled {
  background-color: #fbfbfb;
  color: @defaultFontColor;
}
/deep/ .el-range-editor.is-disabled input {
  color: @defaultFontColor;
}
/deep/ .el-descriptions-item__label {
  color: #606266;
  font-weight: bold;
}
/deep/ .el-descriptions-item__content {
  color: @defaultFontColor;
}
</style>
