<template>
  <div class="tab-container">
    <div class="left-tree container-left-tree">
      <el-tree
        ref="tree"
        :data="TreeList"
        accordion
        :props="defaultProps"
        default-expand-all
        highlight-current
        :expand-on-click-node="false"
        node-key="id"
        :current-node-key="treeNodeId"
        @node-click="handleNodeClick"
      >
        <div class="custom-tree-node" slot-scope="{ node, data }">
          <div
            :class="
              data.nodeType == 'PRJ-RWJHJDLX-ZRW'
                ? 'zrw'
                : data.nodeType == 'PRJ-RWJHJDLX-GZB'
                ? 'gzb'
                : data.nodeType == 'PRJ-RWJHJDLX-GZBBZ'
                ? 'gzb'
                : null
            "
          >
            {{ node.label }}
          </div>
          <div class="node-hanlde">
            <i
              v-if="
                (resolveType == 'wbs' || resolveType == 'childTask') &&
                  (versionType == '暂存' || versionType == '已退回') &&
                  data.assignId == assignId &&
                  data.nodeType != 'PRJ-RWJHJDLX-GZB' &&
                  data.nodeType != 'PRJ-RWJHJDLX-GZBBZ' &&
                  data.nodeType != 'PRJ-RWJHJDLX-ZHJDBZ'
              "
              class="iconfont icon-add"
              @click.stop="handelNodeAdd(data)"
            ></i>
            <i
              v-if="
                data.addNodeType != 0 &&
                (versionType == '暂存' || versionType == '已退回') &&
                data.assignId == assignId &&
                data.isStandardWorkpackage != 1
              "
              class="iconfont icon-edit"
              @click.stop="handelNodeEdit(data)"
            ></i>
            <i
              v-if="
                data.addNodeType != 0 &&
                (versionType == '暂存' || versionType == '已退回') &&
                data.assignId == assignId &&
                data.isStandardWorkpackage != 1
              "
              class="iconfont icon-delete"
              @click.stop="handelDelete(data)"
            ></i>
          </div>
        </div>
      </el-tree>
    </div>
    <!-- 节点树新增编辑弹窗 -->
    <dd-popup
      width="30%"
      height="50%"
      class="treeNodePop"
      showBtn
      :saveBtn="true"
      :cancelBtn="true"
      :show="addPopupVisible"
      :title-name="poptitleName"
      @popup-close="handleClose"
      @popup-save="handleTreeNodeSave('addPopForm', 'baseForm')"
    >
      <add-node-pop
        v-if="addPopupVisible"
        :rowId="rowId"
        :poptitleName="poptitleName"
        :treeRowData="treeRowData"
        :dictType="TreeList"
        :resolveType="resolveType"
        slot="main"
        ref="addPop"
      >
      </add-node-pop>
    </dd-popup>
    <div class="right-table" v-if="!loading">
      <dd-card
        class="table-tab"
        :title="`当前显示版本号：v${versionNum} 版本类型：${versionType}`"
      >
        <el-tabs
          v-model="workActiveName"
          type="card"
          @tab-click="handleTabClick"
        >
          <el-tab-pane
            v-for="val in workTabList"
            :key="val.value"
            :label="val.label"
            :name="val.value"
          ></el-tab-pane>
        </el-tabs>
        <dd-button
          v-if="
            !isTableEdit && (versionType == '暂存' || versionType == '已退回')
          "
          type="warning"
          size="medium"
          icon="update"
          @click="handleEdit"
          >编辑</dd-button
        >
        <dd-button
          v-if="
            isTableEdit && (versionType == '暂存' || versionType == '已退回')
          "
          type="warning"
          size="medium"
          @click="handleEditCancel"
          >取消编辑</dd-button
        >
        <dd-button
          v-if="versionType == '最新版本' || versionType == '审核拒绝'"
          type="primary"
          size="medium"
          icon="add"
          @click="handleRevise"
          >修订</dd-button
        >
        <dd-button
          v-if="versionType == '暂存' || versionType == '已退回'"
          type="success"
          size="medium"
          icon="import"
          @click="handleSubmit"
          >提交发布</dd-button
        >
        <dd-button
          v-if="resolveType != 'init'"
          type="warning"
          size="medium"
          icon="view"
          @click="handelVersionChange"
          >切换查看其他版本</dd-button
        >
        <dd-button
          v-if="!isChangeVersion"
          type="primary"
          size="medium"
          icon="export"
          @click="handleAllExport"
          >导出</dd-button
        >
      </dd-card>
      <!-- 版本管理弹窗 -->
      <dd-popup
        width="80%"
        height="70%"
        :showBtn="false"
        :show="wbsVersionPopupVisible"
        title-name="版本列表"
        @popup-close="handleClose"
      >
        <wbs-version-pop
          v-if="wbsVersionPopupVisible"
          :rowId="assignId"
          :resolveType="resolveType"
          @versionChange="versionChange"
          @delChange="delChange"
          slot="main"
          ref="addPop"
        >
        </wbs-version-pop>
      </dd-popup>
      <!-- 节点管理信息 -->
      <dd-card v-if="workActiveName == 'jdgl'" :height="tableHeight">
        <dd-table
          :columns="nodeTableColumns"
          :data="nodeTableData"
          :topBtn="false"
          border
          :isSelection="false"
          :loading="loading"
          :isEdit="isTableEdit"
          :tree-props="{ children: 'children' }"
          node-key="id"
          :expand-row-keys="treeData"
          :row-class-name="tableRowClassName"
          :cell-class-name="tableCellClassName"
        >
        </dd-table>
      </dd-card>
      <!-- 文档控制信息 -->
      <dd-card v-if="workActiveName == 'wdkz'" :height="tableHeight">
        <dd-table
          :columns="docTableColumns"
          :data="docTableData"
          :topBtn="false"
          border
          :isSelection="false"
          :loading="loading"
          :isEdit="isTableEdit"
          :tree-props="{ children: 'children' }"
          default-expand-all
          :row-class-name="tableRowClassName"
          :cell-class-name="tableCellClassName"
        >
        </dd-table>
      </dd-card>
      <!-- 估算与计划信息 -->
      <dd-card v-if="workActiveName == 'gsyjh'" :height="tableHeight">
        <dd-table
          :columns="planInfoTableColumns"
          :data="planInfoTableData"
          :topBtn="false"
          border
          :isSelection="false"
          :loading="loading"
          :isEdit="isTableEdit"
          :tree-props="{ children: 'children' }"
          default-expand-all
          :row-class-name="tableRowClassName"
          :cell-class-name="tableCellClassName"
        >
        </dd-table>
      </dd-card>
      <!-- 全部字段 -->
      <dd-card v-if="workActiveName == 'all'" :height="tableHeight">
        <dd-table
          :columns="allTableColumns"
          :data="allTableData"
          :topBtn="false"
          border
          :isSelection="false"
          :loading="loading"
          :tree-props="{ children: 'children' }"
          default-expand-all
          :row-class-name="tableRowClassName"
          :cell-class-name="tableCellClassName"
        >
        </dd-table>
      </dd-card>
    </div>
    <!-- 节点管理编辑信息弹窗 -->
    <dd-popup
      width="30%"
      height="50%"
      class="treeNodePop"
      showBtn
      :saveBtn="true"
      :cancelBtn="true"
      :show="editNodeInfoPopupVisible"
      title-name="编辑"
      @popup-close="handleClose"
      @popup-save="handleNodeInfoSave('addPopForm', 'baseForm')"
    >
      <edit-node-info-pop
        v-if="editNodeInfoPopupVisible"
        :rowData="rowData"
        slot="main"
        ref="editPop"
      >
      </edit-node-info-pop>
    </dd-popup>
  </div>
</template>

<script>
import { getApi, postApi, putApi, postDownBlobApi } from "@/api/request";
import addNodePop from "./components/AddNodePop.vue";
import wbsVersionPop from "./components/WbsVersionPop.vue";
import editNodeInfoPop from "./components/EditNodeInfo.vue";
import $ from "jquery";
export default {
  name: "name",
  props: {
    resolveType: {
      type: String,
      default: "",
    },
    sourceType: {
      type: String,
      default: "",
    },
    rowId: {
      type: String,
      default: "",
    },
  },
  components: {
    addNodePop,
    wbsVersionPop,
    editNodeInfoPop,
  },
  data() {
    return {
      tableHeight: null,
      isTableEdit: false,
      TreeList: [],
      defaultProps: {
        children: "children",
        label: "nodeName",
      },
      treeNodeId: "",
      treeNodeParentId: "",
      treeRowData: null,
      addPopupVisible: false, //节点树添加编辑弹窗
      wbsVersionPopupVisible: false, //节点管理编辑弹窗
      editNodeInfoPopupVisible: false, // 编辑节点管理信息弹窗
      rowData: null,
      poptitleName: "新增",
      loading: true,
      versionNum: "",
      versionType: "",
      workActiveName: "jdgl",
      workTabList: [
        {
          label: "节点管理信息",
          value: "jdgl",
        },
        {
          label: "文档控制信息",
          value: "wdkz",
        },
        {
          label: "估算与计划信息",
          value: "gsyjh",
        },
        {
          label: "全部字段",
          value: "all",
        },
      ],
      nodeTableColumns: [
        {
          title: "节点编号",
          key: "nodeCode",
        },
        {
          title: "节点名称",
          key: "nodeName",
          align: "left",
          overflow: true,
        },
        {
          title: "节点类型",
          key: "nodeTypeName",
          align: "center",
        },
        {
          title: "业务类型",
          key: "businessTypeName",
          align: "center",
          overflow: true,
        },
        {
          title: "节点状态",
          key: "nodeStateName",
          align: "center",
        },
        // 第四条 现生产负责部门
        {
          title: "生产分管部门",
          key: "nodeDeptName",
          align: "center",
        },
        {
          title: "部门",
          key: "nodeDeptBranchName",
          align: "center",
        },
        {
          title: "负责人",
          key: "nodeUser",
          align: "center",
        },
        {
          title: "供应商",
          key: "purName",
          align: "center",
        },
      ],
      treeData: [],
      nodeTableData: [],
      docTableColumns: [
        {
          title: "节点编号",
          key: "nodeCode",
        },
        {
          title: "节点名称",
          key: "nodeName",
          align: "left",
          overflow: true,
        },
        {
          title: "节点类型",
          key: "nodeTypeName",
          align: "center",
        },
        {
          title: "必须提交的文档",
          key: "documentArr",
          align: "center",
          formatterArr: (row) => {
            return this.getArrItem(row);
          },
        },
        {
          title: "其他成果备注",
          key: "achieveRemark",
          align: "center",
          input: [
            {
              placeholderText: "请输入",
              method: (value, row, idx) => {
                this.changeAmount(value, row, idx);
              },
              isEdit: (value) => {
                let state = false;
                return state;
              },
              // rules: (value) => {
              //   return value.replace(/[^\d.]/g, "");
              // },
            },
          ],
        },
      ],
      docTableData: [],
      documentArr: [],
      planInfoTableColumns: [
        {
          title: "节点编号",
          key: "nodeCode",
          width:"140",
        },
        {
          title: "节点名称",
          key: "nodeName",
          align: "left",
          overflow: true,
          width:"140",
        },
        {
          title: "节点类型",
          key: "nodeTypeName",
          align: "center",
        },
        // {
        //   title: "已分配父节点预估标准工天",
        //   key: "assignedStandardWorkday",
        //   align: "center",
        //   overflow: true,
        //   width:"200",
        // },
        // {
        //   title: "剩余父节点预估标准工天",
        //   key: "remainStandardWorkday",
        //   align: "center",
        //   overflow: true,
        //   width:"180",
        // },
        {
          title: "预估总工作量",
          key: "planWorkload",
          align: "center",
          overflow: true,
          width:"120",
          
          input: [
            {
              placeholderText: "请输入",
              method: (value, row, idx) => {
                this.changeAmount(value, row, idx);
              },
              isEdit: (value) => {
                let state = false;
                return state;
              },
              rules: (value) => {
                return value.replace(/[^\d^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^(\-)*(\d+)\.(\d\d).*$/,'$1$2.$3');
              },
            },
          ],
        },
        {
          title: "工作量单位",
          key: "nodeUnit",
          align: "center",
          overflow: true,
          width:"100",
          formatter: (row) => {
            return this.getUnitItem(row);
          },
        },
        {
          title: "效率",
          key: "efficiency",
          align: "center",
          overflow: true,
          input: [
            {
              placeholderText: "请输入",
              method: (value, row, idx) => {
                this.changeAmount(value, row, idx);
              },
              isEdit: (value) => {
                let state = false;
                return state;
              },
              rules: (value) => {
                return value.replace(/[^\d^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^(\-)*(\d+)\.(\d\d\d\d).*$/,'$1$2.$3');
              },
            },
          ],
        },
        {
          title: "预估标准工天",
          key: "planStandardWorkday",
          align: "center",
          tooltip:true,
          overflow: true,
          width:"120",
        },
        {
          title: "标准工天单价",
          key: "standardWorkdayPrice",
          align: "center",
          overflow: true,
          input: [
            {
              placeholderText: "请输入",
              method: (value, row, idx) => {
                this.changeAmount(value, row, idx);
              },
              isEdit: (value) => {
                let state = false;
                return state;
              },
              rules: (value) => {
                return value.replace(/[^\d^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^(\-)*(\d+)\.(\d\d).*$/,'$1$2.$3');
              },
            },
          ],
        },
        {
          title: "预估绩效",
          key: "planPriceTotal",
          align: "center",
          overflow: true,
          width:"100",
          // input: [
          //   {
          //     placeholderText: "请输入",
          //     method: (value, row, idx) => {
          //       this.updateTableCellData(row);
          //     },
          //     isEdit: (value) => {
          //       let state = false;
          //       if (value.isFather == "1") {
          //         state = true;
          //       }
          //       return state;
          //     },
          //     rules: (value) => {
          //       return value.replace(/[^\d.]/g, "");
          //     },
          //   },
          // ],
        },
        {
          title: "预估计工作量单价(元)",
          key: "planPriceUnit",
          align: "center",
          overflow: true,
          width:"180",
        },
        // {
        //   title: "预估工天",
        //   key: "planWorkday",
        //   align: "center",
        //   input: [
        //     {
        //       placeholderText: "请输入",
        //       method: (value, row, idx) => {
        //         this.updateTableCellData(row);
        //       },
        //       isEdit: (value) => {
        //         let state = false;
        //         if (value.isFather == "1") {
        //           state = true;
        //         }
        //         return state;
        //       },
        //       rules: (value) => {
        //         return value.replace(/[^\d.]/g, "");
        //       },
        //     },
        //   ],
        // },
        // {
        //   title: "预估工作量权重",
        //   key: "planWorkloadPercent",
        //   align: "center",
        // },
        {
          title: "计划开始时间",
          key: "planStarttime",
          align: "center",
          date: true,
          overflow: true,
          width:"120",
          change: (val, row) => {
            var date1 = new Date(row.planStarttime);
            var date2 = new Date(row.planEndtime);
            if(row.planStarttime != null && row.planEndtime != null){
              if (date1 > date2) {
                this.$message.warning("计划开始日期不能大于计划结束日期")
                this.getTaskResolveData();
              }else{
              this.updateTableCellData(row);
            }
            }else{
              this.updateTableCellData(row);
            }
          },
        },
        {
          title: "计划结束时间",
          key: "planEndtime",
          align: "center",
          date: true,
          overflow: true,
          width:"120",
          change: (val, row) => {
            var date1 = new Date(row.planStarttime);
            var date2 = new Date(row.planEndtime);
            if(row.planStarttime != null && row.planEndtime != null){
              if (date1 > date2) {
                this.$message.warning("计划开始日期不能小于计划结束日期")
                this.getTaskResolveData();
              }else{
              this.updateTableCellData(row);
            }
            }else{
              this.updateTableCellData(row);
            }
          },
        },
        {
          title: "计划权重",
          key: "planSchedulePercent",
          align: "center",
          overflow: true,
          width:"100",
        },
      ],
      planInfoTableData: [],
      allTableColumns: [
        {
          title: "节点编号",
          key: "nodeCode",
          fixed: "left",
          overflow: true,
          width:"150",
        },
        {
          title: "节点名称",
          key: "nodeName",
          align: "left",
          overflow: true,
          fixed: "left",
          width:"150",
        },
        {
          title: "节点类型",
          key: "nodeTypeName",
          align: "center",
          fixed: "left",
          overflow: true,
          width:"150",
        },
        {
          title: "业务类型",
          key: "businessTypeName",
          align: "center",
          overflow: true,
        },
        {
          title: "节点状态",
          key: "nodeStateName",
          align: "center",
          overflow: true,
        },
        {
          title: "生产负责部门",
          key: "nodeDeptName",
          align: "center",
          overflow: true,
          width:'159',
        },
        {
          title: "部门",
          key: "nodeDeptBranchName",
          align: "center",
          overflow: true,
          width:'159',
        },
        {
          title: "负责人",
          key: "nodeUser",
          align: "center",
          overflow: true,
          width:'159',
        },
        {
          title: "供应商",
          key: "purName",
          align: "center",
          overflow: true,
          width:'159',
        },
        {
          title: "必须提交的文档",
          key: "documentArr",
          align: "center",
          overflow: true,
          width:'130',
          formatterArr: (row) => {
            return this.getArrItem(row);
          },
        },
        {
          title: "其他成果备注",
          key: "achieveRemark",
          align: "center",
          overflow: true,
          width:'130',
        },
        {
          title: "预估总工作量",
          key: "planWorkload",
          align: "center",
          overflow: true,
          width:'130',
        },
        {
          title: "工作量单位",
          key: "nodeUnit",
          align: "center",
          overflow: true,
          width:'130',
          formatter: (row) => {
            return this.getUnitItem(row);
          },
        },
        {
          title: "效率",
          key: "efficiency",
          align: "center",
          overflow: true,
          width:'80',
        },
        {
          title: "预估标准工天",
          key: "planStandardWorkday",
          align: "center",
          overflow: true,
          width:'130',
        },
        // {
        //   title: "已分配父节点预估标准工天",
        //   key: "assignedStandardWorkday",
        //   align: "center",
        //   overflow: true,
        //   width:"200",
        // },
        // {
        //   title: "剩余父节点预估标准工天",
        //   key: "remainStandardWorkday",
        //   align: "center",
        //   overflow: true,
        //   width:"180",
        // },
        {
          title: "标准工天单价",
          key: "standardWorkdayPrice",
          align: "center",
          overflow: true,
          width:'130',
        },
        {
          title: "预估绩效",
          key: "planPriceTotal",
          align: "center",
          overflow: true,
          width:"100",
        },
        {
          title: "预估计工作量单价(元)",
          key: "planPriceUnit",
          align: "center",
          overflow: true,
          width:"180",
        },
        {
          title: "计划开始时间",
          key: "planStarttime",
          align: "center",
          overflow: true,
          width:'120',
        },
        {
          title: "计划结束时间",
          key: "planEndtime",
          align: "center",
          overflow: true,
          width:'120',
        },
        {
          title: "计划权重",
          key: "planSchedulePercent",
          align: "center",
          overflow: true,
          width:"100",
        },
      ],
      allTableData: [],
      subplanId: null,
      // 状态
      verifyStatus: null,
      assignId: null,
      // 是否开启修订
      reviseState: false,
      // 是否已切换版本
      isChangeVersion: false,
      isVersionData: false,
      // 选择的版本数据
      versionDataId: null,
      newVersionNum: "",
      unitArr: [],

      newAssignId: null,
      newTreeNodeId: null,
    };
  },
  watch: {
    docTableColumns(n, o) {
      this.docTableColumns = n;
    },
  },
  created() {
    this.getDataType();
    this.assignId = this.rowId;
    
  },
  mounted() {
    this.getTaskResolveData();
  },
  methods: {
    changeAmount(val, row, idx) {
      this.updateTableCellData(row);
    },
    // 获取任务分解数据
    getTaskResolveData(type) {
      let params = {
        id: this.treeNodeId,
      };
      getApi(
        `/project/subplan/getsubplannewtree/${this.assignId}`,
        params
      ).then((res) => {
        let { data } = res;
        if (data && data.code == 0) {
          if (!this.treeNodeId) {
            this.TreeList = data.data;
            this.treeNodeId = data.data[0].id;
            this.treeNodeParentId = data.data[0].parentId;
            // this.$nextTick(() => {
            //   this.$refs.tree.setCurrentKey(this.treeNodeId); //一定要加这个选中了否则样式没有出来
            // });
          }
          if (type) {
            this.TreeList = data.data;
            this.treeNodeId = data.data[0].id;
            this.treeNodeParentId = data.data[0].parentId;
            // this.$nextTick(() => {
            //   this.$refs.tree.setCurrentKey(this.treeNodeId); //一定要加这个选中了否则样式没有出来
            // });
          }
          // wbs 和 子任务 相同
          // if (this.resolveType == "wbs") {
            this.subplanId = data.data[0].subplanId;
            this.versionNum = data.data[0].subplanVision;
            this.newVersionNum = data.data[0].subplanVision;
            if (data.data[0].verifyStatus == "PRJ-RWSHZT-DTJ") {
              this.versionType = "暂存";
            } else if (data.data[0].verifyStatus == "PRJ-RWSHZT-SHTG") {
              this.versionType = "最新版本";
            } else {
              this.versionType = data.data[0].verifyStatusName;
            }
          // }
          this.nodeTableData = data.data;
          this.filterTreeData(this.nodeTableData)
          this.docTableData = data.data;
          this.planInfoTableData = data.data;
          this.allTableData = data.data;
          this.loading = false;
          // 自动计算table高度
          this.$nextTick(() => {
            this.tableHeight = this.autoTableHeight(0);
          });
        }
      });
    },
    filterTreeData(data) {
      this.treeData = [];
      if(data?.length > 0) {
          data.forEach(item => {
          this.treeData.push(item.id)
        })
      }
    },
    // 获取数据字典类型
    getDataType() {
      // 文档类型列表
      postApi(`/project/prj/dict/list/all`, { typeCode: "PRJ-RWJHJDWD" }).then(
        (res) => {
          let { data } = res;
          if (data && data.code == 0) {
            let arr = [];
            data.data.forEach((item) => {
              let obj = {
                label: item.name,
                value: item.code,
              };
              arr.push(obj);
            });
            this.documentArr = arr;
          }
        }
      );
      // 工作量单位
      postApi(`/project/prj/dict/list/all`, { typeCode: "PRJ-GZBDW" }).then(
        (res) => {
          let { data } = res;
          if (data && data.code == 0) {
            let arr = [];
            data.data.forEach((item) => {
              let obj = {
                label: item.name,
                value: item.code,
              };
              arr.push(obj);
            });
            this.unitArr = arr;
          }
        }
      );
    },
    // 左侧树节点点击事件
    handleNodeClick(data) {
      this.treeNodeId = data.id;
      this.treeNodeParentId = data.parentId;
      if (this.resolveType == "wbs") {
        if (this.isChangeVersion) {
          this.getSelectVersionData(data.subplanId, this.treeNodeId);
        } else {
          this.getTaskResolveData();
        }
      }

      if (this.resolveType == "childTask") {
        if (data.nodeType == "PRJ-RWJHJDLX-ZRW" && !this.isChangeVersion) {
          this.newAssignId = data.assignId;
          this.newTreeNodeId = data.id;
        }
        if (this.isChangeVersion) {
          if (this.assignId != data.assignId) {
            this.assignId = data.assignId;
            this.subplanId = data.subplanId;
            this.isChangeVersion = false;
            this.refreshZrwNode();
            this.getZrwNode();
          } else {
            this.getSelectVersionData(data.subplanId, this.treeNodeId);
          }
        } else {
          this.reviseState = false;
          this.assignId = data.assignId;
          this.subplanId = data.subplanId;
          if (this.isTableEdit) {
            this.handleEditCancel();
          }
          this.loading = true;
          this.getZrwNode();
        }
      }
    },
    getZrwNode(type) {
      let params = {
        id: this.treeNodeId,
      };
      getApi(
        `/project/subplan/getsubplannewtree/${this.assignId}`,
        params
      ).then((res) => {
        let { data } = res;
        if (data && data.code == 0) {
          this.nodeTableData = data.data;
          this.filterTreeData(this.nodeTableData)
          this.docTableData = data.data;
          this.planInfoTableData = data.data;
          this.allTableData = data.data;
          this.newVersionNum = data.data[0].subplanVision;
          this.versionNum = data.data[0].subplanVision;
          this.subplanId = data.data[0].subplanId;
          this.versionDataId = data.data[0].assignId;
          if (type) {
            this.TreeList = data.data;  // 子任务
            // this.TreeList[0].children.forEach((item, index) => {
            //   if (item.assignId == data.data[0].assignId) {
            //     this.TreeList[0].children[index].children =
            //       data.data[0].children;
            //   }
            // });
          }
          if (data.data[0].verifyStatus == "PRJ-RWSHZT-DTJ") {
            this.versionType = "暂存";
          } else if (data.data[0].verifyStatus == "PRJ-RWSHZT-SHTG") {
            this.versionType = "最新版本";
          } else {
            this.versionType = data.data[0].verifyStatusName;
          }
          this.loading = false;
        }
      });
    },
    refreshZrwNode() {
      let params = {
        id: this.newTreeNodeId,
      };
      getApi(
        `/project/subplan/getsubplannewtree/${this.newAssignId}`,
        params
      ).then((res) => {
        let { data } = res;
        if (data && data.code == 0) {
          this.TreeList = data.data;
          // this.TreeList[0].children.forEach((item, index) => {
          //   if (item.assignId == data.data[0].assignId) {
          //     this.TreeList[0].children.splice(index, 1, data.data[0]);
          //   }
          // });
        }
      });
    },
    // 节点树添加
    handelNodeAdd(data) {
      console.log('data.', data)
      console.log('rowData', this.rowId)
      this.treeRowData = data;
      this.addPopupVisible = true;
      this.poptitleName = "新增";
    },
    // 节点树编辑
    handelNodeEdit(data) {
      this.treeRowData = data;
      this.addPopupVisible = true;
      this.poptitleName = "编辑";
    },
    // 节点树删除
    handelDelete(data) {
      this.$confirm(`确认删除该数据吗？`, "提示", {
        confirmButtonClass: "btnsearch",
        confirmButtonText: "确认",
        cancelButtonClass: "btnreset",
        cancelButtonText: "取消",
        showClose: true,
        type: "warning",
      }).then(() => {
        this.handleConfirmDelete(data.id);
      });
    },
    // 确认删除请求
    handleConfirmDelete(delId) {
      postApi(`/project/subplannode/del/node/${delId}`).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          this.$message.success("删除成功");
        }
        if (!this.isChangeVersion && !this.reviseState) {
          this.treeNodeId = null;
          if (this.resolveType == "childTask") {
            this.getZrwNode("node");
          } else {
            this.getTaskResolveData("node");
          }
        } else {
          this.versionChange(this.versionDataId);
        }
      });
    },
    // 节点树新增编辑弹窗关闭
    handleClose() {
      this.addPopupVisible = false;
      this.editNodeInfoPopupVisible = false;
      this.wbsVersionPopupVisible = false;
      console.log('this.$refs.addPop.activeName', this.$refs.addPop.activeName)
      if (this.$refs.addPop && this.$refs.addPop.activeName == "bzjd") {
        if (this.isChangeVersion) {
          this.getSelectVersionData(this.subplanId, this.treeNodeId, "node");
        }
        else{
          this.getZrwNode("node");
        }
      }
      if (this.$refs.addPop && this.$refs.addPop.activeName == "bzgzbtz")  {
        this.getSelectVersionData(this.subplanId, this.treeNodeId, "node");
      }
      $(".treeNodePop .popup-box").attr(
        "style",
        "width: 30%;height: 50% !important"
      );
    },
    // Tab切换事件
    handleTabClick(tab, event) {
      this.$nextTick(() => {
        this.tableHeight = this.autoTableHeight(0);
      });
      this.workActiveName = tab.name;
    },
    // 提交发布
    handleSubmit() {
      // if (this.resolveType == "childTask" && this.treeNodeParentId == "-1") {
      //   this.$message.warning("请选择一条子任务进行操作！");
      //   return;
      // }
      let url = null;
      if (this.resolveType == "childTask") {
        url = `/project/subplannode/subplan/submit/${this.subplanId}`;
      } else {
        url = `/project/subplannode/start/process/${this.subplanId}`;
      }
      postApi(url).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          this.$message.success("提交发布成功");
          if (this.reviseState) {
            this.reviseState = false;
          }
          if (!this.isChangeVersion && !this.reviseState) {
            if (this.resolveType == "childTask") {
              this.getZrwNode();
            } else {
              this.getTaskResolveData();
            }
          } else {
            this.versionChange(this.versionDataId);
          }
          this.handleEditCancel();
          this.reviseState = false;
        }
      });
    },
    // 分解编辑
    handleEdit() {
      // if (this.resolveType == "childTask" && this.treeNodeParentId == "-1") {
      //   this.$message.warning("请选择一条子任务进行操作！");
      //   return;
      // }
      this.isTableEdit = true;
      let handleObj = {
        title: "操作",
        key: "handle",
        align: "center",
        event: [
          {
            label: "编辑",
            icon: "el-icon-edit",
            method: (row) => {
              this.handleRowNodeInfoEditor(row);
            },
            type: "warning",
            rules: (row) => {
              let show = false;
              if (
                row.nodeType != "PRJ-RWJHJDLX-ZRW" &&
                row.nodeType != "PRJ-RWJHJDLX-GZB" &&
                row.nodeType != "PRJ-RWJHJDLX-GJD" &&
                  row.nodeType != "PRJ-RWJHJDLX-ZHJDBZ" &&
                  row.nodeType != "PRJ-RWJHJDLX-GZBBZ"
              ) {
                show = true;
              } else {
                show = false;
              }
              return show;
            },
          },
        ],
      };
      this.nodeTableColumns.push(handleObj);
      let docObj = {
        title: "必须提交的文档",
        key: "documentArr",
        align: "center",
        selectInput: {
          placeholderText: "请选择",
          multiple: true,
          options: this.documentArr,
          blur: (state, row) => {
            if (!state) {
              this.updateTableCellData(row);
            }
          },
          remove: (val, row) => {
            this.updateTableCellData(row);
          },
        },
      };
      this.docTableColumns.forEach((item, index) => {
        if (item.key == "documentArr") {
          this.docTableColumns.splice(index, 1, docObj);
          if (!this.isChangeVersion && !this.reviseState) {
            if (this.resolveType == "childTask") {
              this.getZrwNode();
            } else {
              this.getTaskResolveData();
            }
          } else {
            this.versionChange(this.versionDataId);
          }
        }
      });
      let unitObj = {
        title: "工作量单位",
        key: "nodeUnit",
        align: "center",
        filterable: true,
        selectInput: {
          placeholderText: "请选择",
          options: this.unitArr,
        },
        change: (val, row) => {
          this.updateTableCellData(row);
        },
      };
      this.planInfoTableColumns.forEach((item, index) => {
        if (item.key == "nodeUnit") {
          this.planInfoTableColumns.splice(index, 1, unitObj);
        }
      });
    },
    // 节点管理编辑事件
    handleRowNodeInfoEditor(row) {
      this.rowData = row;
      this.editNodeInfoPopupVisible = true;
    },
    // 取消编辑
    handleEditCancel() {
      this.nodeTableColumns.forEach((item, index) => {
        if (item.key == "handle") {
          this.nodeTableColumns.splice(index, 1);
        }
      });
      let docObj = {
        title: "必须提交的文档",
        key: "documentArr",
        align: "center",
        formatterArr: (row) => {
          return this.getArrItem(row);
        },
      };
      this.docTableColumns.forEach((item, index) => {
        if (item.key == "documentArr") {
          this.docTableColumns.splice(index, 1, docObj);
          if (!this.isChangeVersion && !this.reviseState) {
            if (this.resolveType == "childTask") {
              this.getZrwNode();
            } else {
              this.getTaskResolveData();
            }
          } else {
            this.versionChange(this.versionDataId);
          }
        }
      });
      let unitObj = {
        title: "工作量单位",
        key: "nodeUnit",
        align: "center",
        formatter: (row) => {
          return this.getUnitItem(row);
        },
      };
      this.planInfoTableColumns.forEach((item, index) => {
        if (item.key == "nodeUnit") {
          this.planInfoTableColumns.splice(index, 1, unitObj);
        }
      });
      this.isTableEdit = false;
    },
    // 修订
    handleRevise() {
      if (this.resolveType == "wbs") {
        postApi(`/project/subplan/revise/${this.subplanId}`).then((res) => {
          let { data } = res;
          if (data.code == 0) {
            this.reviseState = true;
            this.versionDataId = data.data.id;
            this.handleEdit();
          }
        });
      } else {
        postApi(
          `/project/subplan/subplan/revise/${this.subplanId}/${this.assignId}`
        ).then((res) => {
          let { data } = res;
          if (data.code == 0) {
            this.reviseState = true;
            this.versionDataId = data.data.id;
            this.handleEdit();
          }
        });
      }
    },
    handleBack() {
      this.$emit("handleBackPage");
    },
    // 更新单元格数据提交
    updateTableCellData(data) {
      let params = {
        ...data,
      };
      putApi(`/project/subplannode`, params).then((res) => {
        if (!this.isChangeVersion && !this.reviseState) {
          if (this.resolveType == "childTask") {
            this.getZrwNode();
          } else {
            this.getTaskResolveData();
          }
        } else {
          this.versionChange(this.versionDataId);
        }
      });
    },
    handleTreeNodeSave(formName, formNameBase) {
      if (this.$refs.addPop.activeName == "bzjd") {
        this.handleClose();
        this.$message.success("保存成功");
        return;
      }
      let flag = false;
      this.$refs.addPop.$refs[formName].$refs[formNameBase].validate(
        (valid) => {
          if (valid) {
            flag = true;
          }
        }
      );
      let params = this.$refs.addPop.$refs[formName].formData;
      if (flag) {
        if (this.poptitleName == "新增") {
          postApi(`/project/subplannode/add/node`, params).then((res) => {
            let { data } = res;
            if (data.code == 0) {
              this.handleClose();
              this.$message.success("保存成功");
              if (!this.isChangeVersion && !this.reviseState) {
                this.treeNodeId = null;
                if (this.resolveType == "childTask") {
                  this.getZrwNode("node");
                } else {
                  this.getTaskResolveData("node");
                }
              } else {
                this.versionChange(this.versionDataId);
              }
            }
          });
        } else {
          if (this.treeRowData) {
            params.id = this.treeRowData.id;
            params.subplanId = this.subplanId;
          }
          putApi(`/project/subplannode`, params).then((res) => {
            let { data } = res;
            if (data.code == 0) {
              this.handleClose();
              this.$message.success("保存成功");
              if (!this.isChangeVersion && !this.reviseState) {
                this.treeNodeId = null;
                if (this.resolveType == "childTask") {
                  this.getZrwNode("node");
                } else {
                  this.getTaskResolveData("node");
                }
              } else {
                this.versionChange(this.versionDataId);
              }
            }
          });
        }
      }
    },
    delChange(){
      this.getTaskResolveData();
    },
    handleNodeInfoSave(formName, formNameBase) {
      let flag = false;
      this.$refs.editPop.$refs[formName].$refs[formNameBase].validate(
        (valid) => {
          if (valid) {
            flag = true;
          }
        }
      );
      let params = this.$refs.editPop.$refs[formName].formData;
      if (flag) {
        if (this.rowData) {
          params.id = this.rowData.id;
          params.subplanId = this.subplanId;
        }
        putApi(`/project/subplannode`, params).then((res) => {
          let { data } = res;
          if (data.code == 0) {
            this.handleClose();
            this.$message.success("保存成功");
            if (!this.isChangeVersion && !this.reviseState) {
              if (this.resolveType == "childTask") {
                this.getZrwNode();
              } else {
                this.getTaskResolveData();
              }
            } else {
              this.versionChange(this.versionDataId);
            }
          }
        });
      }
    },
    // 切换查看其他版本
    handelVersionChange() {
      // if (this.resolveType == "childTask" && this.treeNodeParentId == "-1") {
      //   this.$message.warning("请选择一条子任务进行操作！");
      //   return;
      // }
      this.handleEditCancel();
      this.wbsVersionPopupVisible = true;
    },
    // 版本切换刷新事件
    versionChange(id) {
      this.isChangeVersion = true;
      this.versionDataId = id;
      this.wbsVersionPopupVisible = false;
      // this.versionNum = row.subplanVision;
      this.getSelectVersionData(id, null, "init");
    },
    // 请求当前所选择版本数据
    getSelectVersionData(subplanId, treeNodeId, type) {
      let params = {
        id: treeNodeId,
      };
      getApi(`/project/subplannode/tree/${subplanId}`, params).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          this.isVersionData = true;
          if (type) {
            // if (this.resolveType == "wbs") {
              this.TreeList = data.data;
            // } else {
            //   this.TreeList[0].children.forEach((item, index) => {
            //     if (item.assignId == data.data[0].assignId) {
            //       this.TreeList[0].children.splice(index, 1, data.data[0]);
            //     }
            //   });
            // }
          }
          this.treeNodeId = data.data[0].id;
          this.treeNodeParentId = data.data[0].parentId;
          // this.$nextTick(() => {
          //   this.$refs.tree.setCurrentKey(this.treeNodeId); //一定要加这个选中了否则样式没有出来
          // });
          this.subplanId = data.data[0].subplanId;
          console.log('data.data', data.data)
          this.versionNum = data.data[0].subplanVision;
          if (data.data[0].verifyStatus == "PRJ-RWSHZT-DTJ") {
            this.versionType = "暂存";
          } else if (
            data.data[0].verifyStatus == "PRJ-RWSHZT-SHTG" &&
            this.newVersionNum <= this.versionNum
          ) {
            this.versionType = "最新版本";
            this.newVersionNum = data.data[0].subplanVision;
          } else if (
            data.data[0].verifyStatus == "PRJ-RWSHZT-SHJJ" &&
            this.newVersionNum <= this.versionNum
          ) {
            this.versionType = "审核拒绝";
            this.newVersionNum = data.data[0].subplanVision;
          } else if (
            data.data[0].verifyStatus == "PRJ-RWSHZT-SHTG" &&
            this.newVersionNum > this.versionNum
          ) {
            this.versionType = "历史版本";
          } else {
            this.versionType = data.data[0].verifyStatusName;
          }
          this.nodeTableData = data.data;
          this.filterTreeData(this.nodeTableData)
          this.docTableData = data.data;
          this.planInfoTableData = data.data;
          this.allTableData = data.data;
        }
      });
    },
    getArrItem(row) {
      let arr = [];
      this.documentArr.forEach((item) => {
        row &&
          row.forEach((val) => {
            if (val == item.value) {
              arr.push(item.label);
            }
          });
      });

      return arr;
    },
    getUnitItem(row) {
      let label = null;
      this.unitArr.forEach((item) => {
        if (row == item.value) {
          label = item.label;
        }
      });
      return label;
    },
    tableRowClassName({ row, rowIndex }) {
      if (row.nodeType === "PRJ-RWJHJDLX-ZRW") {
        return "zrw";
      } else if (row.nodeType === "PRJ-RWJHJDLX-GZB") {
        return "gzb";
      } else if (row.nodeType === "PRJ-RWJHJDLX-GZBBZ") {
        return "gzb";
      } else if (row.nodeState === "PRJ-RWJHJDZT-JY") {
        return "jy";
      }
    },
    tableCellClassName({ row, column, rowIndex, columnIndex }) {
      if (
        column.property == "nodeStateName" &&
        row.nodeState == "PRJ-RWJHJDZT-SCZ"
      ) {// 生产中
        return "scz";
      }
      else if (
        column.property == "nodeStateName" &&
        row.nodeState == "PRJ-RWJHJDZT-WC"
      ) { // 完成
        return "wc";
      }
      else if (
        column.property == "nodeStateName" &&
        row.nodeState == "PRJ-RWJHJDZT-DSH"
      ) { // 待审核
        return "dsh";
      }
      // else if (
      //   column.property == "nodeStateName" &&
      //   row.nodeState == "PRJ-RWJHJDZT-JY"
      // ) { // 禁用
      //   return "jy";
      // }
      // else if (
      //   column.property == "nodeStateName" &&
      //   row.nodeState == "PRJ-RWJHJDZT-ZC"
      // ) { // 正常
      //   return "zc";
      // }
      if(this.workActiveName == "gsyjh"){
        // 已分配父节点预估标准工天
        if(column.property == "assignedStandardWorkday" && row.assignedStandardWorkday < 0){
            return "dsh";
        }
        // 剩余父节点预估标准工天
        if(column.property == "remainStandardWorkday" && row.remainStandardWorkday < 0){
          return "dsh";
        }
      }
    },
    // 导出
    handleAllExport() {
      let params = {
        assignId: this.assignId,
      };
      postDownBlobApi(
        `/project/subplannode/subplan/assign/export`,
        params,
        "工作分解"
      ).then((res) => {});
    },
  },
};
</script>

<style lang="less" scoped>
.tab-container {
  height: 100%;
  display: flex;
  .left-tree {
    width: 20%;
    height: 100%;
    padding: 10px 15px;
    box-sizing: border-box;
    border-right: 5px solid #e7eaec;
    overflow-y: auto;
    .custom-tree-node {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: @fs14;

      .zrw {
        color: #0020c5;
      }

      .gzb {
        color: #159d9d;
      }

      .node-hanlde {
        color: @defaultFontColor;
        i {
          margin: 0 5px;
        }
      }
    }
  }
  .right-table {
    width: 80%;
    height: 100%;
    .table-tab {
      /deep/ .card-contant {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 0;
        .el-tabs {
          width: 100%;
        }
      }
      /deep/ .el-tabs__header {
        margin: 0;
      }
    }
  }
  .right-table {
    /deep/ .el-input__suffix {
      display: none !important;
    }
    /deep/ .el-input__prefix {
      display: none !important;
    }
    // /deep/.el-table__expand-icon {
    //   display: none !important;
    // }
    // /deep/ .el-table__indent {
    //   display: none !important;
    // }
    // /deep/.el-table__placeholder {
    //   display: none !important;
    // }
    /deep/.el-input.is-disabled .el-input__inner {
      background-color: #ededed;
    }
  }
  /deep/ .el-date-editor--date {
    width: 100% !important;
  }
  /deep/ .el-input__inner {
    padding: 0 5px !important;
  }
  /deep/.el-table .zrw {
    td.el-table__cell {
      background-color: #cadaff;
      color: #0020c5;
    }
  }
  /deep/.el-table .green{
      td.el-table__cell {
      background-color: #67C23A;
      color: #fff;
    }
  }
  /deep/.el-table .red{
      td.el-table__cell {
      background-color: #F56C6C;
      color: #fff;
    }
  }
  /deep/.el-table .blur{
      td.el-table__cell {
      background-color: #409EFF;
      color: #fff;
    }
  }
  /deep/.el-table .gzb {
    td.el-table__cell {
      background-color: #159d9d;
      color: #fff;
    }
  }

  /deep/.el-table .jy {
    td.el-table__cell {
      background-color: #a9a9a9;
      color: #fff;
    }
  }
  /deep/ .el-table {
    td.dsh{
      // background-color: #0f55ab !important;
      color: #F56C6C !important;
    }
    td.scz {
      // background-color: #0f55ab !important;
      color: #E6A23C !important;
    }
    td.wc {
      // background-color: #07b107 !important;
      color: #67C23A !important;
    }
    td.zc {
      // background-color: #df8e3b !important;
      color: #fff !important;
    }
  }
}
</style>
