<template>
  <div class="container">
    <dd-card title="基本信息" class="base-form-collect-info">
      <dd-form
        :form-config="formList"
        :ref="formRef"
        :formRef="formRef"
        :flex="3"
        labelWidth="160px"
      ></dd-form>
      <dd-button
        v-if="this.type == 'add' && this.handDisabled == false"
        class="add-workload-btn"
        type="primary"
        icon="add"
        size="medium"
        :loading="btloading"
        @click="handleWorkloadDetail"
        >添加工作量明细</dd-button
      >
    </dd-card>
    <dd-card title="工作量明细" v-if="isWorkloadDetail">
      <dd-search-form
        :form-config="searchFormList"
        @search-click="handleSearchEvent"
        ref="ddSearchForm"
      ></dd-search-form>
      <dd-table
        :columns="columnsList"
        :data="tableDataSources"
        border
        :isSelection="false"
        ref="dTableRef"
        show-page
      >
        <template #table-pagination>
          <dd-pagination
            :total="total"
            :page-size="size"
            :page-number="current"
            @page-change="handlePageChange"
            align="right"
          >
          </dd-pagination>
        </template>
      </dd-table>
    </dd-card>
    <!-- 新增/修改/详情-->
    <dd-popup
      v-if="dialogVisible"
      width="80%"
      height="80%"
      :inPopup="true"
      :showBtn="false"
      :title-name="popTitle"
      :show="dialogVisible"
      @popup-close="handleClose"
    >
      <workloadDetailPop
        slot="main"
        ref="addPop"
        :standardWorkpackage="standardWorkpackage"
        :id="detailId"
        :type="type"
        :isEdit="isEdit"
        :rowData="rowData"
        @close_btn="close_btn"
      ></workloadDetailPop>
    </dd-popup>
  </div>
</template>

<script>
import { postApi, getApi, loadApi } from "@/api/request";
import { formatDateTime } from "@/utils/date";
import { mapState, mapActions } from "vuex";
import workloadDetailPop from "../workloadDetailPop/index.vue";
export default {
  props: {
    type: "",
    id: "",
    rowData: {},
    isEdit:false,
  },
  components: { workloadDetailPop },
  computed: {
    ...mapState(["btnAuthorityList"]),
  },
  data() {
    return {
      btloading:false,
      handDisabled:false,
      detailId: "",
      dialogVisible: false,
      isWorkloadDetail: false,
      popTitle: "",
      standardWorkpackage: null,
      formData: {},
      formRef: "baseForm",
      workloadSumId: "",
      // 页码
      current: 1,
      // 每页的数据条数
      size: 10,
      total: 0,
      //筛选列表格头部配置
      columnsList: [
        {
          title: "区域",
          key: "areaName",
          align: "center",
          width: 100,
          overflow: true,
        },
        {
          title: "任务书编码",
          key: "assignCode",
          align: "center",
          width: 180,
          overflow: true,
        },
        {
          title: "任务书名称",
          key: "assignName",
          width: 180,
          align: "center",
          overflow: true,
        },
        // {
        //   title: "任务书类型",
        //   key: "",
        //   width: 180,
        //   align: "center",
        // },
        {
          title: "工作包名称",
          key: "nodeName",
          align: "center",
          width: 180,
          overflow: true,
        },
        {
          title: "工号",
          key: "userCode",
          width: 100,
          align: "center",
        },
        {
          title: "姓名",
          key: "userName",
          align: "center",
          width: 100,
          overflow: true,
        },
        // {
        //   title: "员工状态",
        //   key: "userName",
        //   align: "center",
        //   width: 180,
        //   overflow: true,
        // },
        {
          title: "工作量",
          key: "workload",
          align: "center",
          width: 100,
          overflow: true,
        },
        {
          title: "工作量单位",
          key: "unitName",
          width: 100,
          align: "center",
        },
        {
          title: "效率",
          key: "efficiency",
          align: "center",
          width: 100,
        },
        {
          title: "标准工天",
          key: "planStandardWorkday",
          align: "center",
          width: 100,
        },
        {
          title: "操作",
          align: "center",
          fixed: "right",
          width: "200px",
          event: [
            {
              label: `${this.isEdit ? '编辑' : '查看详情'}`,
              icon: `${this.isEdit ? 'el-icon-edit' : 'el-icon-view'}`,
              state: "default",
              method: (row) => {
                if(this.isEdit){
                  this.handleEdit(row);
                }else{
                  this.handleDetail(row);
                }
              },
            },
          ],
        },
      ],
      // 表格数据集合
      tableDataSources: [],
      searchFormList: [
        {
          label: "区域",
          value: "areaName",
          inputType: "text",
          placeholderText: "请输入区域名称",
          title: true,
        },
        {
          label: "任务书",
          value: "assignName",
          inputType: "text",
          placeholderText: "请输入任务书名称",
          title: true,
        },
        {
          label: "姓名",
          value: "userName",
          inputType: "text",
          placeholderText: "请输入姓名",
          title: true,
        },
      ],
      formList: [
        {
          label: "部门",
          value: "deptId",
          inputType: "select",
          optionsList: [],
          filterable: true,
          disabled:false,
          optionsListConfig: {
            name: "name",
            code: "deptId",
          },
          defaultValue: '',
          placeholderText: "请选择部门",
          rules: [
            {
              required: true,
              message: "请选择部门",
              trigger: "blur"
            }
          ],
          // change: this.handleChange,
        },
        // {
        //   label: "核算组",
        //   value: "accountingGroupId",
        //   inputType: "select",
        //   filterable: true,
        //   optionsList: [],
        //   optionsListConfig: {
        //     code: "id",
        //     name: "name",
        //   },
        //   defaultValue: "",
        //   placeholderText: "请选择核算组",
        //   rules: [{ required: true, message: "请选择核算组", trigger: "blur" }],
        // },
        {
          label: "所属年月",
          value: "reportMonth",
          inputType: "date",
          dateType: "month",
          format: "yyyy-MM",
          disabled:false,
          defaultValue: null,
          placeholderText: "请选择所属年月",
          rules: [
            { required: true, message: "请选择所属年月", trigger: "blur" },
          ],
        },
        {
          label: "统计工作量类型",
          value: "isStandardWorkpackage",
          inputType: "select",
          filterable: true,
          optionsList: [],
          disabled:false,
          defaultValue: "",
          placeholderText: "请选择统计工作量类型",
          rules: [
            {
              required: true,
              message: "请选择统计工作量类型",
              trigger: "blur",
            },
          ],
        },
        {
          label: "起始时间点",
          value: "startTime",
          inputType: "dateStr",
          dateType: "datetime",
          disabled:false,
          format: "yyyy-MM-dd HH:mm:ss",
          defaultValue: '',
          placeholderText: "请选择起始时间点",
          rules: [
            { required: true, message: "请选择起始时间点", trigger: "blur" },
          ],
        },
        {
          label: "截止时间点",
          value: "deadLine",
          inputType: "dateStr",
          dateType: "datetime",
          defaultTime: '23:59:59',
          format: "yyyy-MM-dd HH:mm:ss",
          disabled:false,
          defaultValue: '',
          placeholderText: "请选择截止时间点",
          rules: [
            { required: true, message: "请选择截止时间点", trigger: "blur" },
          ],
        },
      ],
    };
  },
  mounted() {
    this.getBaseInfo();
    // this.getAccountingList(sessionStorage.getItem("depId"));
    if (this.type == "detail") {
      this.formList.forEach((item) => {
        item.disabled = true;
        item.rules = [];
      });
      this.getDetailInfo();
    }
  },
  methods: {
    //获取基本信息数据列表
    getBaseInfo() {
      getApi(`/admin/dept/getcollectdeptlist`).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          let options = {
            value: "deptId",
            optionsList: data.data,
          };
          this.setOPtionsList(this.formList, options);
        }
      });
      postApi(`/project/prj/dict/list/all`, {
        typeCode: "PRJ_WORKLOAD_TYPE",
      }).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          this.formList.forEach((item) => {
            if (item.value == "isStandardWorkpackage") {
              item.optionsList = data.data;
            }
          });
        }
      });
    },
    //核算组列表
    // getAccountingList(deptId) {
    //   getApi(`/project/accounting/list`, { deptId }).then((res) => {
    //     let { data } = res;
    //     if (data.code == 0) {
    //       let { formData } = this.$refs.baseForm;
    //       this.formList.forEach((item) => {
    //         if (item.value == "accountingGroupId") {
    //           item.optionsList = data.data;
    //         } else {
    //           item.defaultValue = formData[item.value];
    //         }
    //       });
    //     }
    //   });
    // },
    //添加工作量明细
    handleWorkloadDetail() {
      let that = this;
      let flag = false;
      this.$refs.baseForm.$refs.baseForm.validate((valid) => {
        if (valid) {
          flag = true;
        }
      });
      if (flag) {
        that.$confirm(`请确认基本信息，提交后不可修改？`, "提示", {
          confirmButtonClass: "btnsearch",
          confirmButtonText: "确认",
          cancelButtonClass: "btnreset",
          cancelButtonText: "取消",
          showClose: true,
          type: "warning",
        }).then(() => {
          this.btloading = true;
          that.AddWorkloadBreakdown();
        });
      }
    },
    // 添加工作量明细请求
    AddWorkloadBreakdown(){
      let { formData } = this.$refs.baseForm;
      let params = {
        ...formData,
      };
      params.startTime = formatDateTime(formData.startTime);
      params.deadLine = formatDateTime(formData.deadLine);
      postApi(`/project/workloadsum/save/detail`, params).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          this.btloading = false;
          this.formList.forEach(item=>{
            item.defaultValue = this.$refs.baseForm.formData[item.value]
            item.disabled = true;
          })
          this.handDisabled = true;
          this.isWorkloadDetail = true;
          this.workloadSumId = data.data;
          this.getWorkloadDetailList();
        }        
      }).catch((err) => {
          this.btloading = false;
      })
    },
    getWorkloadDetailList() {
      let { current, size, formData } = this;
      let params = {
        ...formData,
      };
      params.workloadSumId = this.workloadSumId;
      postApi(
        `/project/workloadsum/detail/list/${current}/${size}`,
        params
      ).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          this.tableDataSources = data.data.records;
          console.log('this.tableDataSources', this.tableDataSources)
          this.total = data.data.total;
        }
      });
    },
    handleSearchEvent(obj) {
      this.formData = obj;
      this.current = 1;
      this.getWorkloadDetailList();
    },
    handlePageChange(size, current) {
      this.size = size;
      this.current = current;
      this.getWorkloadDetailList();
    },
    //条件查询表单--下拉选择数据填充（optionsList）
    setOPtionsList(formList, options) {
      formList.forEach((item) => {
        if (options) {
          if (item.value == options.value) {
            item.optionsList = options.optionsList;
          }
        }
      });
    },
    // handleChange(e, type) {
    //   if (type == "deptId") {
    //     // this.getAccountingList(e);
    //   }
    // },
    //开启明细列表弹窗
    handleDetail(row) {
      // this.rowData = row;
      this.popTitle = row.standardWorkpackage
        ? "明细列表"
        : "明细列表";
      this.standardWorkpackage = row.standardWorkpackage;
      this.detailId = row.id;
      this.dialogVisible = true;
    },
    // 修改明细列表弹窗
    handleEdit(row){
      this.rowData = row;
      this.popTitle = row.standardWorkpackage
        ? "编辑"
        : "编辑";
      this.standardWorkpackage = row.standardWorkpackage;
      this.detailId = row.id;
      this.dialogVisible = true;
    },
    // 关闭明细列表弹窗
    handleClose() {
      this.dialogVisible = false;
      this.close_btn();
    },
    //编辑页面信息回显
    getDetailInfo() {
      let url = `/project/workloadsum/${this.id}`;
      if(this.id == null){
        url = `/project/workloadsum/${this.workloadSumId}`;
      }
      getApi(url).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          this.formList.forEach((item) => {
            item.defaultValue = `${data.data[item.value]}`;
          });
          this.workloadSumId = this.id;
          this.isWorkloadDetail = true;
          this.getWorkloadDetailList();
        }
      });
    },
    // 关闭弹窗
    close_btn(){
      this.dialogVisible = false;
      this.getWorkloadDetailList();
      // this.getDetailInfo();
    },
  },
};
</script>

<style lang='less' scoped>
.base-form-collect-info {
  position: relative;
  .add-workload-btn {
    position: absolute;
    right: 154px;
    bottom: 23px;
  }
}
/deep/ .el-table__header-wrapper {
  background-color: @tableHeaderBg !important;
}
/deep/ .el-table .has-gutter .el-table__cell {
  background-color: @tableHeaderBg;
  color: @defaultFontColor;
}
/deep/.el-input.is-disabled .el-input__inner {
  background-color: #fbfbfb;
  color: @defaultFontColor;
}
/deep/.el-textarea.is-disabled .el-textarea__inner {
  background-color: #fbfbfb;
  color: @defaultFontColor !important;
  resize: none;
}
// /deep/ .el-input__suffix {
//   display: none;
// }
/deep/.el-table__fixed-body-wrapper{
  top:40px !important;
  height: calc(100% - 48px) !important;
}
</style>