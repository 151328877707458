<template>
  <div class="container">
    <dd-card title="基本信息">
      <dd-form
        v-if="type != 'detail'"
        :form-config="formList"
        :ref="formRef"
        :formRef="formRef"
        :flex="2"
        labelWidth="130px"
      ></dd-form>
      <dd-form
        v-else
        :form-config="detailFormList"
        :ref="formRef"
        :formRef="formRef"
        :flex="2"
        labelWidth="130px"
      ></dd-form>
    </dd-card>
    <dd-card title="填报工作量内容" v-if="baseFormList.length">
      <dd-form
        :form-config="baseFormList"
        ref="workloadForm"
        formRef="workloadForm"
        :flex="2"
        labelWidth="130px"
      ></dd-form>
      <div v-if="tableDataSources.length" style="width: 100%; margin: 20px 0">
        <el-table :data="[{}]" style="width: 100%" border>
          <el-table-column
            :label="
              item.workMetering == 'PRJ-GZBTXLX-NUMBER'
                ? `${item.workName}(${item.workUnitName})`
                : item.workName
            "
            v-for="(item, index) in tableDataSources"
            :key="index"
            align="center"
          >
            <template v-if="item.workMetering == 'PRJ-GZBTXLX-NUMBER'">
              <el-input
                v-model="item.applyWorkload"
                placeholder="请输入详细计量内容"
                :disabled="type == 'detail'"
              ></el-input>
            </template>
            <template v-if="item.workMetering == 'PRJ-GZBTXLX-TEXT'">
              <el-input
                v-model="item.applyWorkload"
                placeholder="请输入详细计量内容"
                :disabled="type == 'detail'"
              ></el-input>
            </template>
            <template v-if="item.workMetering == 'PRJ-GZBTXLX-OPTIONS'">
              <el-select
                v-model="item.applyWorkload"
                :disabled="type == 'detail'"
                placeholder="请选择详细计量内容"
              >
                <el-option
                  v-for="val in item.options"
                  :key="val.dictCode"
                  :label="val.dictName"
                  :value="val.dictCode"
                >
                </el-option>
              </el-select>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <dd-form
        :form-config="descriptionFormList"
        ref="notes"
        formRef="notes"
        labelWidth="115px"
      ></dd-form>
    </dd-card>
  </div>
</template>

<script>
import { postApi, getApi, loadApi } from "@/api/request";
import { getNowDate } from "@/utils/date";
import { mapState, mapActions } from "vuex";

export default {
  props: {
    type: "",
    id: "",
    rowData: {},
  },
  computed: {
    ...mapState(["btnAuthorityList"]),
  },
  data() {
    return {
      formRef: "baseForm",
      workloadDetailInfo: {},
      tableDataSources: [],
      baseFormList: [],
      subFormList: [
        {
          label: "工作包名称",
          value: "nodeName",
          inputType: "text",
          disabled: true,
          defaultValue: "",
        },
        {
          label: "所属主节点",
          value: "nodeNameParent",
          inputType: "text",
          disabled: true,
          defaultValue: "",
        },
      ],
      wbsFormList: [
        {
          label: "工作包名称",
          value: "nodeName",
          inputType: "text",
          disabled: true,
          defaultValue: "",
        },
        {
          label: "所属主节点",
          value: "nodeNameParent",
          inputType: "text",
          disabled: true,
          defaultValue: "",
        },
        {
          label: "工作量单位",
          value: "nodeUnitName",
          inputType: "text",
          disabled: true,
          defaultValue: "",
        },
        {
          label: "详细计量内容",
          value: "applyWorkload",
          inputType: "text",
          defaultValue: "",
          rules: [
            { required: true, message: "请输入详细计量内容", trigger: "blur" },
          ],
        },
      ],
      descriptionFormList: [
        {
          label: "备注说明",
          value: "description",
          inputType: "text",
          type: "textarea",
          placeholderText: "请输入备注说明",
          defaultValue: "",
        },
      ],
      detailFormList: [
        {
          label: "任务书编码",
          value: "assignCode",
          inputType: "text",
          disabled: true,
          defaultValue: "",
        },
        {
          label: "任务书名称",
          value: "assignName",
          inputType: "text",
          disabled: true,
          defaultValue: "",
        },

        {
          label: "填报时间",
          value: "applyTime",
          inputType: "text",
          disabled: true,
          defaultValue: "",
        },
        {
          label: "状态",
          value: "workloadStateName",
          inputType: "text",
          disabled: true,
          defaultValue: "",
        },
      ],
      formList: [
        {
          label: "任务书",
          value: "assignId",
          inputType: "select",
          filterable:true,
          optionsList: [],
          optionsListConfig: {
            code: "id",
            name: "assignName",
          },
          defaultValue: "",
          placeholderText: "请选择或搜索任务书",
          rules: [{ required: true, message: "请选择任务书", trigger: "blur" }],
          change: this.handleChange,
        },
        {
          label: "工作包",
          value: "workPackageId",
          inputType: "select",
          filterable: true,
          optionsList: [],
          optionsListConfig: {
            code: "id",
            name: "nodeName",
          },
          defaultValue: "",
          placeholderText: "请选择或搜索工作包",
          rules: [{ required: true, message: "请选择工作包", trigger: "blur" }],
          change: this.handleChange,
        },
      ],
      nodeCode: "",
    };
  },
  mounted() {
    if (this.type == "update" || this.type == "detail") {
      this.getDetailInfo();
      this.type == "detail" &&
        this.detailFormList.forEach((item) => {
          item.defaultValue = this.rowData[item.value] || "/";
        });
    } else {
      this.getTaskList();
    }
  },
  methods: {
    handleChange(e, type) {
      let { formData } = this.$refs.baseForm;
      if (type == "assignId") {
        this.formList.forEach((item) => {
          if (item.value == "workPackageId") {
            item.optionsList = [];
            item.defaultValue = "";
          } else {
            item.defaultValue = formData[item.value];
          }
        });
        this.tableDataSources = [];
        this.baseFormList = [];
        this.getWorkPackageList(e);
      } else if (type == "workPackageId") {
        this.tableDataSources = [];
        this.baseFormList = [];
        this.getWorkPackageDetail(e);
      }
    },
    //条件查询表单--下拉选择数据填充（optionsList）
    setOPtionsList(formList, options) {
      formList.forEach((item) => {
        if (options) {
          if (item.value == options.value) {
            item.optionsList = options.optionsList;
          }
        }
      });
    },
    //任务书
    getTaskList(id) {
      getApi(`/project/assign/getusersubassignlist`).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          this.formList.forEach((item) => {
            if (item.value == "assignId") {
              item.optionsList = data.data;
              if (id) {
                item.defaultValue = id;
              }
            }
          });
        }
      });
    },
    //工作包
    getWorkPackageList(assignId) {
      getApi(
        `/project/subplannode/getsubplanworknodelistbyassignid/${assignId}`,
        {}
      ).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          this.formList.forEach((item) => {
            if (item.value == "workPackageId") {
              data.data.map((itm) => {
                if (this.nodeCode == itm.nodeCode) {
                  item.defaultValue = itm.id;
                }
              });
              item.optionsList = data.data;
            }
          });
        }
      });
    },
    //工作包详情
    getWorkPackageDetail(subplanNodeId) {
      getApi(
        `/project/subplannode/getworknodedetailbynode/${subplanNodeId}`
      ).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          this.nodeCode = data.data.nodeCode;
          if (data.data.isStandardWorkpackage) {
            this.baseFormList = this.subFormList;
            let { workloadDetailList } = data.data.prjNodeWorkDetailVO;
            workloadDetailList &&
              workloadDetailList.length &&
              workloadDetailList.forEach((item) => {
                item.applyWorkload = "";
              });
            this.tableDataSources = workloadDetailList || [];
            this.baseFormList.forEach((item) => {
              item.defaultValue = data.data[item.value];
            });
          } else {
            this.baseFormList = this.wbsFormList;
            this.baseFormList.forEach((item) => {
              item.defaultValue = data.data[item.value];
            });
          }
        }
      });
    },
    //编辑页面信息回显
    getDetailInfo() {
      getApi(`/project/real/workload/${this.id}`).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          this.nodeCode = data.data.nodeCode;
          this.getTaskList(data.data.assignId);
          this.getWorkPackageList(data.data.assignId);
          this.descriptionFormList.forEach((item) => {
            if (item.value == "description") {
              item.defaultValue = data.data.description;
              if (this.type == "detail") {
                item.disabled = true;
                item.defaultValue = data.data.description || '暂无';
              }
            }
          });
          if (data.data.isStandardWorkpackage) {
            this.baseFormList = this.subFormList;
            let { workloadDetailList } = data.data;
            this.tableDataSources = workloadDetailList || [];
            this.baseFormList.forEach((item) => {
              if (this.type == "detail") {
                item.rules = [];
                item.disabled = true;
              }
              item.defaultValue = data.data[item.value];
            });
          } else {
            this.baseFormList = this.wbsFormList;
            this.baseFormList.forEach((item) => {
              if (this.type == "detail") {
                item.rules = [];
                item.disabled = true;
              }
              item.defaultValue = data.data[item.value];
            });
          }
        }
      });
    },
  },
};
</script>

<style lang='less' scoped>
/deep/ .el-table__header-wrapper {
  background-color: @tableHeaderBg !important;
}
/deep/ .el-table .has-gutter .el-table__cell {
  background-color: @tableHeaderBg;
  color: @defaultFontColor;
}
/deep/.el-input.is-disabled .el-input__inner {
  background-color: #fbfbfb;
  color: @defaultFontColor;
}
/deep/.el-textarea.is-disabled .el-textarea__inner {
  background-color: #fbfbfb;
  color: @defaultFontColor !important;
  resize: none;
}
/deep/ .el-input__suffix {
  display: none;
}
</style>