<template>
  <div class="main-container">
    <dd-card title="条件查询" spacer>
      <dd-search-form
        :form-config="formList"
        @search-click="handleSearchEvent"
        ref="ddSearchForm"
      ></dd-search-form>
    </dd-card>
    <dd-card title="申请单列表" :height="tableHeight">
      <dd-table
        :columns="columnsList"
        :data="tableDataSources"
        border
        ref="dTableRef"
        :summary-method="getSummaries"
        show-summary
        show-page
        @select-change="handleSelectionChange"
      >
        <template #table-btn>
          <dd-button
            type="primary"
            icon="add"
            size="medium"
            @click="handleAddEdit(0)"
            >新增</dd-button
          >
          <dd-button
            type="warning"
            icon="update"
            size="medium"
            @click="handleAddEdit(1)"
            >编辑</dd-button
          >
          <dd-button
            icon="delete"
            type="danger"
            size="medium"
            autoConfirm
            @click="handleDelete"
            >删除</dd-button
          >
        </template>
        <template #table-btn-right>
          <el-button
            icon="el-icon-s-operation"
            circle
            type="info"
            @click="drawerVisible = true"
            size="small"
            title="列筛选"
          ></el-button>
        </template>
        <template #table-pagination>
          <dd-pagination
            :total="total"
            :page-size="pageSize"
            :page-number="pageNumber"
            @page-change="handlePageChange"
            align="right"
          >
          </dd-pagination>
        </template>
      </dd-table>
    </dd-card>
    <dd-popup
      v-if="dialogVisible"
      width="70%"
      height="80%"
      :title-name="popTitle"
      :show="dialogVisible"
      :showBtn="type == 'detail' ? false : true"
      saveBtn
      restBtn
      submitBtn
      @popup-save="handleSubmit(0)"
      @popup-submit="handleSubmit(1)"
      @popup-rest="handleRest"
      @popup-close="handleClose"
    >
      <AddUpdateDetailInfoPop
        slot="main"
        ref="addPop"
        :type="type"
        :id="id"
      ></AddUpdateDetailInfoPop>
    </dd-popup>
    <dd-filter-drawer
      v-if="drawerVisible"
      width="30%"
      title-name="列筛选"
      :show="drawerVisible"
      :tableColumns="tableColumns"
      :columns="columnsList"
      @popup-close="drawerVisible = false"
      @handleColumnsChange="handleColumnsChange"
    >
    </dd-filter-drawer>
  </div>
</template>

<script>
import AddUpdateDetailInfoPop from "./components/AddUpdateDetailInfoPop";
import { getNowDate } from "@/utils/date";
import { downloadBinaryFile } from "@/utils/index";
import { postApi, getApi, loadApi } from "@/api/request";
export default {
  name: "outCar",
  $dynamicRoute: {
    name: " 外部租车申请单",
    path: "/personnel/administration/out/car",
  },
  components: { AddUpdateDetailInfoPop },
  data() {
    return {
      tableHeight: null,
      popTitle: "",
      drawerVisible: false,
      processInstanceId: null,
      loading: false,
      // 页码
      pageNumber: 1,
      // 每页的数据条数
      pageSize: 10,
      total: 0,

      dialogVisible: false,
      // 表格选择数据集合
      multipleSelection: [],
      // 接收表单返回数据
      formData: {},
      // 条件查询表单配置配置
      formList: [
        {
          label: "项目名称",
          value: "projectName",
          inputType: "select",
          optionsList: [],
          filterable: "filterable",
          placeholderText: "请选择或搜索项目名称",
          optionsListConfig: {
            name: "projectName",
            code: "projectId",
          },
        },
        {
          label: "项目编号",
          value: "projectId",
          inputType: "select",
          optionsList: [],
          filterable: "filterable",
          placeholderText: "请选择或搜索项目编号",
          optionsListConfig: {
            name: "projectCode",
            code: "projectId",
          },
        },
        {
          label: "申请日期",
          value: "applyDate",
          inputType: "date",
          dateType: "daterange",
          format: "yyyy-MM-dd",
        },
        {
          label: "单据状态",
          value: "formStatus",
          inputType: "select",
          optionsList: [],
          placeholderText: "请选择或搜索单据状态",
        },
        {
          label: "上报状态",
          value: "reportStatus",
          inputType: "select",
          optionsList: [],
          placeholderText: "请选择或搜索上报状态",
        },
        {
          label: "上报日期",
          value: "reportDate",
          inputType: "date",
          dateType: "monthrange",
          format: "yyyy-MM",
        },
      ],
      // 表格头部配置数据
      tableColumns: [
        {
          title: "申请编号",
          key: "applyCode",
          align: "center",
          overflow: true,
        },
        {
          title: "项目名称",
          key: "projectName",
          align: "center",
          overflow: true,
        },
        {
          title: "项目编号",
          key: "projectCode",
          align: "center",
        },
        {
          title: "申请日期",
          key: "applyTime",
          align: "center",
        },
        {
          title: "用车公里数",
          key: "useKilometer",
          align: "center",
        },
        {
          title: "租车金额",
          key: "rentalcarAmount",
          align: "center",
        },
        {
          title: "核定金额",
          key: "approvedAmount",
          align: "center",
        },
        {
          title: "单据状态",
          key: "formStatus",
          align: "center",
        },
        {
          title: "上报状态",
          key: "reportStatus",
          align: "center",
        },
        {
          title: "上报日期",
          key: "reportMonth",
          align: "center",
        },
        {
          title: "操作",
          align: "center",
          event: [
            {
              label: "查看详情",
              state: "default",
              method: (row) => {
                this.handleAddEdit(3, row);
              },
            },
          ],
        },
      ],
      columnsList: [], //筛选列表格头部配置
      // 表格数据集合
      tableDataSources: [],
      // 是否显示删除确认弹框
      autoConfirm: false,
      // 选择符合删除条件数据id集合
      delDataArr: [],
    };
  },
  created() {},
  mounted() {
    // 自动计算table高度
    this.$nextTick(() => {
      this.tableHeight = this.autoTableHeight(0);
    });
    //获取项目信息
    this.getProject();
    // 获取单据状态
    this.getStateList();
    this.getReportStateList();
    this.getApplyList();
    this.columnsList = [...this.tableColumns];
  },
  methods: {
    //条件查询表单--下拉选择数据填充（optionsList）
    setOPtionsList(formList, options) {
      formList.forEach((item) => {
        if (options) {
          if (item.value == options.value) {
            item.optionsList = options.optionsList;
          }
        }
      });
    },
    handleColumnsChange(val) {
      let list = new Set();
      this.tableColumns.map((item) => {
        val.map((itm) => {
          if (item.event || item.key == itm.key) {
            list.add(item);
          }
        });
      });
      this.columnsList = [...list];
    },
    //获取项目信息
    getProject() {
      getApi(`/project/prj/list/all`).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          this.formList.forEach((item) => {
            if (item.value == "projectName" || item.value == "projectId") {
              item.optionsList = data.data;
            }
          });
        }
      });
    },
    // 预算申请列表请求
    getApplyList() {
      this.$refs.dTableRef.$refs.baseTable.clearSelection();
      postApi(
        `/hr/rentalcar/applysheet/page/${this.pageNumber}/${this.pageSize}`,
        this.formData
      ).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          this.tableDataSources = data.data.records;
          this.total = data.data.total;
        }
      });
    },
    // 单据状态下拉数据请求
    getStateList() {
      postApi(`/hr/hrdict/list?typeCode=wbzcdjzt`, {}).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          this.formList.forEach((item) => {
            if (item.value == "formStatus") {
              item.optionsList = data.data;
            }
          });
        }
      });
    },
    // 单据上报下拉数据请求
    getReportStateList() {
      postApi(`/hr/hrdict/list?typeCode=wbzchzdsbzt`, {}).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          this.formList.forEach((item) => {
            if (item.value == "reportStatus") {
              item.optionsList = data.data;
            }
          });
        }
      });
    },
    handleClose() {
      this.dialogVisible = false;
      this.getApplyList();
    },
    handleSearchEvent(obj) {
      this.$refs.dTableRef.$refs.baseTable.clearSelection();
      this.formData = obj;
      this.pageNumber = 1;
      this.getApplyList();
    },
    // 保存/提交
    handleSubmit(type) {
      let flag = false;
      this.$refs.addPop.$refs.baseForm.$refs.baseForm.validate((valid) => {
        if (valid) {
          flag = true;
        }
      });
      if (!flag) {
        return;
      }
      let hrRentalcarApplySheetFileList =
        this.$refs.addPop.hrRentalcarApplySheetFileList;
      let { formData } = this.$refs.addPop.$refs.baseForm;
      formData.applierId = sessionStorage.getItem("userId");
      formData.applierDepId = sessionStorage.getItem("depId");
      formData.expenseBearOrgId = sessionStorage.getItem("orgId");
      let params = {
        ...formData,
        hrRentalcarApplySheetFileList,
      };
      if (this.id) {
        params.id = this.id;
      }
      postApi(`/hr/rentalcar/applysheet/save/${type}`, params).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          if (type == 0) {
            this.$message.success("保存成功！");
          } else {
            this.$message.success("提交成功！");
          }
          this.handleClose();
        } else {
          this.$message.error(data.msg);
          this.$message({
            message: data.msg,
            type: "error",
            duration: 6000,
          });
        }
      });
    },
    //新增/编辑页面重置
    handleRest() {
      let basicFormData = {
        applierName: sessionStorage.getItem("fullName"),
        date: getNowDate(),
        rentalcarStartDate: getNowDate(),
        rentalcarEndDate: getNowDate(),
        applierDepName: sessionStorage.getItem("depName"),
        expenseBearOrgName: sessionStorage.getItem("orgName"),
        expenseBearDepId: sessionStorage.getItem("depId"),
      };
      this.$refs.addPop.$refs.baseForm.formData = basicFormData;
      this.$refs.addPop.fileList = [];
      this.$refs.addPop.fileListParam = [];
    },
    // type 0: 新建；1：修改；3：详情
    handleAddEdit(type, row) {
      if (type == 0) {
        this.id = null;
        this.type = "add";
        this.popTitle = "新增";
        this.dialogVisible = true;
      } else if (type == 1) {
        if (this.multipleSelection.length !== 1) {
          this.$message.warning("请选择一条数据！");
          return;
        } else if (
          this.multipleSelection[0].formStatus != "暂存" &&
          this.multipleSelection[0].formStatus != "退回"
        ) {
          this.$message.warning("只能修改暂存和退回状态数据！");
          return;
        }
        this.id = this.multipleSelection[0].id;
        this.type = "update";
        this.popTitle = "修改";
        this.dialogVisible = true;
      } else {
        this.id = row.id;
        this.type = "detail";
        this.popTitle = "详情";
        this.dialogVisible = true;
      }
    },
    handleDelete() {
      let idArr = [];
      if (this.multipleSelection.length < 1) {
        this.$message.warning("请选择要删除的数据！");
        return;
      }
      for (var i = 0; i < this.multipleSelection.length; i++) {
        let item = this.multipleSelection[i];
        if (item.formStatus != "暂存" && item.formStatus != "退回") {
          this.$message.warning("只能选择暂存或退回状态数据删除！");
          return;
        } else {
          idArr.push(item.id);
        }
      }
      var id = { ids: idArr.join() };
      this.$confirm("是否确定删除租车申请？", "提示", {
        confirmButtonClass: "btnsearch",
        confirmButtonText: "确认",
        cancelButtonClass: "btnreset",
        cancelButtonText: "取消",
        showClose: true,
        type: "warning",
      })
        .then(() => {
          postApi(`/hr/rentalcar/applysheet/delete`, id)
            .then((res) => {
              let { data } = res;
              if (data.code == 0) {
                this.$message({
                  type: "success",
                  message: "删除成功!",
                });
                this.getApplyList();
                this.$refs.dTableRef.$refs.baseTable.clearSelection();
              }
            })
            .catch(() => {
              this.$message.error("请求出错");
            });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 格式化表格单据状态
    getState(cellValue) {
      if (cellValue == "dtj") {
        return "待提交";
      } else if (cellValue == "shz") {
        return "审核中";
      } else if (cellValue == "shtg") {
        return "审核通过";
      } else if (cellValue == "shbtg") {
        return "审核不通过";
      }
    },
    // 计算表格合计行函数
    getSummaries(param) {
      const { columns, data } = param;
      let sums = [param];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "合计";
          return;
        } else if (
          column.property == "useKilometer" ||
          column.property == "approvedAmount" ||
          column.property == "rentalcarAmount"
        ) {
          const values = data.map((item) => Number(item[column.property]));
          if (!values.every((value) => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr);
              if (!isNaN(value)) {
                return prev + curr;
              } else {
                return prev;
              }
            }, 0);
            sums[index] += "";
          }
        }
      });
      return sums;
    },
    // 表格多选事件
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handlePageChange(pageSize, pageNumber) {
      this.pageSize = pageSize;
      this.pageNumber = pageNumber;
      this.getApplyList();
    },
  },
};
</script>

<style lang="less" scoped></style>

<style>
.el-select-dropdown {
  max-width: 48%;
}

.el-select-dropdown li {
  height: auto;
}

.el-select-dropdown li span {
  white-space: normal;
  word-break: break-all;
  word-wrap: break-word;
}
</style>
