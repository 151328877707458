<template>
  <div class="container">
    <div class="top_box">
      <span class="top_box_name">附件：</span>
      <div class="ibox-content" style="height: 100%">
        <el-upload
          class="upload-demo"
          action="#"
          :http-request="httpRequest"
          :headers="headers"
          :on-remove="handleRemove"
          :before-remove="beforeRemove"
          :limit="1"
          :on-exceed="handleExceed"
          :on-success="handleFileSuccess"
          :before-upload="beforeAvatarUpload"
          :file-list="fileList"
          :disabled="pageProp.pageType == 'detail'"
        >
          <!-- <el-button size="small" type="primary" :disabled="pageProp.pageType == 'detail'"
            ><span
              class="iconfont icon-shangchuan"
              style="margin-right: 5px"
            ></span>
            点击上传
          </el-button> -->
          <el-button size="small" type="primary" v-if="pageProp.pageType != 'detail'"
            ><span
              class="iconfont icon-shangchuan"
              style="margin-right: 5px"
            ></span>
            点击上传
          </el-button>
          <el-button size="small" type="primary" v-else @click="handleView"
            ><span
              class="iconfont icon-shangchuan"
              style="margin-right: 5px"
            ></span>
            发票预览
          </el-button>
          <div slot="tip" class="el-upload__tip" v-if="pageProp.pageType != 'detail'">上传附件自动识别填写以下信息,只能上传pdf或图片文件</div>
        </el-upload>
      </div>
    </div>
    <dd-card title="">
      <dd-form
        :form-config="formList"
        :labelWidth="'170px'"
        :flex="3"
        ref="assignForm_1"
        formRef="assignForm_1"
      ></dd-form>
    </dd-card>
    <dd-card title="标题">
      <dd-form
        :form-config="formList_title"
        :labelWidth="'170px'"
        :flex="3"
        ref="assignForm_title" 
        formRef="assignForm_title"
      ></dd-form>
    </dd-card>
    <dd-card title="购买方">
      <dd-form
        :form-config="formList_purchaser"
        :labelWidth="'170px'"
        :flex="2"
        ref="assignForm_purchaser"
        formRef="assignForm_purchaser"
      ></dd-form>
    </dd-card>
    <dd-card title="详细">
      <dd-table
        :columns="tableColumns"
        :data="tableData"
        show-summary
        :summary-method="getSummaries"
        border
        :isSelection="false"
      >
      </dd-table>
      <dd-form
        :form-config="formList_detail"
        :labelWidth="'170px'"
        :flex="2"
        ref="assignForm_detail"
        formRef="assignForm_detail"
      ></dd-form>
    </dd-card>
    <dd-card title="销售方">
      <dd-form
        :form-config="formList_seller"
        :labelWidth="'170px'"
        :flex="2"
        ref="assignForm_seller"
        formRef="assignForm_seller"
      ></dd-form>
    </dd-card>
    <dd-card title="其他">
      <dd-form
        :form-config="formList_other"
        :labelWidth="'170px'"
        :flex="2"
        ref="assignForm_other"
        formRef="assignForm_other"
      ></dd-form>
    </dd-card>
  </div>
</template>

<script>
import { getApi, delApi, postApi, putApi } from "@/api/request";
export default {
  name: "addEditDetails",
  props: ['pageProp'],
  data() {
    return {
      totalTax: null,
      totalAmount: null,
      fileData: {},
      formList: [
        {
          label: "单据编码",
          value: "code",
          inputType: "text",
          placeholderText: "单据编码自动生成",
          disabled: true,
        },
        {
          label: "标题",
          value: "invoiceTypeOrg",
          inputType: "text",
          placeholderText: "请输入标题",
          disabled: this.pageProp.pageType == 'detail',
        },
      ],
      formList_title: [
        {
          label: "发票代码",
          value: "invoiceCode",
          inputType: "text",
          placeholderText: "请输入发票代码",
          disabled: this.pageProp.pageType == 'detail',
        },
        {
          label: "发票号码",
          value: "invoiceNum",
          inputType: "text",
          placeholderText: "请输入发票号码",
          disabled: this.pageProp.pageType == 'detail',
        },

        {
          label: "发票类型",
          value: "invoiceType",
          inputType: "text",
          placeholderText: "请输入发票类型",
          disabled: this.pageProp.pageType == 'detail',
        },
        {
          label: "开票日期",
          value: "invoiceDate",
          inputType: "dateStr",
          dateType: "date",
          defaultValue: '',
          format: 'yyyy-MM-dd',
          placeholderText: "请选择开票日期",
          disabled: this.pageProp.pageType == 'detail',
        },

        {
          label: "校验码",
          value: "checkCode",
          inputType: "text",
          placeholderText: "请输入校验码",
          disabled: this.pageProp.pageType == 'detail',
        },
        {
          label: "机器编码",
          value: "machineCode",
          inputType: "text",
          placeholderText: "请输入机器编码",
          disabled: this.pageProp.pageType == 'detail',
        },
      ],
      formList_purchaser: [
        {
          label: "名称",
          value: "purchaserName",
          inputType: "text",
          placeholderText: "请输入名称",
          disabled: this.pageProp.pageType == 'detail',
        },
        {
          label: "纳税人识别号",
          value: "purchaserRegisterNum",
          inputType: "text",
          placeholderText: "请输入纳税人识别号",
          disabled: this.pageProp.pageType == 'detail',
        },
        {
          label: "地址、电话",
          value: "purchaserAddress",
          inputType: "text",
          placeholderText: "请输入地址、电话",
          disabled: this.pageProp.pageType == 'detail',
        },
        {
          label: "开户行及账号",
          value: "purchaserBank",
          inputType: "text",
          placeholderText: "请输入开户行及账号",
          disabled: this.pageProp.pageType == 'detail',
        },
      ],
      tableColumns: [
        {
          title: "货物或应税劳务、服务名称",
          key: "commodityName",
          align: "center",
          overflow: true,
          width: 260,
          input: [
            {
                method: (value, row, index) => {
                },
                isEdit: () => {
                let state = false;
                if (this.pageProp.pageType == 'detail') {
                  state = true;
                }
                return state;
              },
            }
          ]
        },
        {
          title: "规格型号",
          key: "commodityType",
          align: "center",
          overflow: true,
          input: [
            {
                method: (value, row, index) => {
                },
                isEdit: () => {
                let state = false;
                if (this.pageProp.pageType == 'detail') {
                  state = true;
                }
                return state;
              },
            }
          ]
        },
        {
          title: "单位",
          key: "commodityUnit",
          align: "center",
          overflow: true,
          input: [
            {
                method: (value, row, index) => {
                },
                isEdit: () => {
                let state = false;
                if (this.pageProp.pageType == 'detail') {
                  state = true;
                }
                return state;
              },
            }
          ]
        },
        {
          title: "数 量",
          key: "commodityNum",
          align: "center",
          overflow: true,
          input: [
            {
                method: (value, row, index) => {
                },
                isEdit: () => {
                let state = false;
                if (this.pageProp.pageType == 'detail') {
                  state = true;
                }
                return state;
              },
            }
          ]
        },
        {
          title: "单 价",
          key: "commodityPrice",
          align: "center",
          overflow: true,
          input: [
            {
                method: (value, row, index) => {
                },
                isEdit: () => {
                let state = false;
                if (this.pageProp.pageType == 'detail') {
                  state = true;
                }
                return state;
              },
            }
          ]
        },
        {
          title: "金 额",
          key: "commodityAmount",
          align: "center",
          overflow: true,
          input: [
            {
                method: (value, row, index) => {
                },
                isEdit: () => {
                let state = false;
                if (this.pageProp.pageType == 'detail') {
                  state = true;
                }
                return state;
              },
            }
          ]
        },
        {
          title: "税率",
          key: "commodityTaxRate",
          align: "center",
          overflow: true,
          input: [
            {
                method: (value, row, index) => {
                },
                isEdit: () => {
                let state = false;
                if (this.pageProp.pageType == 'detail') {
                  state = true;
                }
                return state;
              },
            }
          ]
        },
        {
          title: "税 额",
          key: "commodityTax",
          align: "center",
          overflow: true,
          input: [
            {
                method: (value, row, index) => {
                },
                isEdit: () => {
                let state = false;
                if (this.pageProp.pageType == 'detail') {
                  state = true;
                }
                return state;
              },
            }
          ]
        },
      ],
      formList_detail: [
        {
          label: "价税合计(大写)",
          value: "amountInWords",
          inputType: "text",
          placeholderText: "请输入价税合计(大写)",
          disabled: this.pageProp.pageType == 'detail',
        },
        {
          label: "价税合计(小写)",
          value: "amountInFiguers",
          inputType: "text",
          placeholderText: "请输入价税合计(小写)",
          disabled: this.pageProp.pageType == 'detail',
        },
      ],
      formList_seller: [
        {
          label: "名称",
          value: "sellerName",
          inputType: "text",
          placeholderText: "请输入名称",
          disabled: this.pageProp.pageType == 'detail',
        },
        {
          label: "纳税人识别号",
          value: "sellerRegisterNum",
          inputType: "text",
          placeholderText: "请输入纳税人识别号",
          disabled: this.pageProp.pageType == 'detail',
        },
        {
          label: "地址、电话",
          value: "sellerAddress",
          inputType: "text",
          placeholderText: "请输入地址、电话",
          disabled: this.pageProp.pageType == 'detail',
        },
        {
          label: "开户行及账号",
          value: "sellerBank",
          inputType: "text",
          placeholderText: "请输入开户行及账号",
          disabled: this.pageProp.pageType == 'detail',
        },
      ],
      formList_other: [
        {
          label: "备注",
          value: "remarks",
          inputType: "text",
          type: "textarea",
          defaultValue: "",
          rows: "2",
          placeholderText: "请输入备注",
          disabled: this.pageProp.pageType == 'detail',
        },
        {
          label: "密码区",
          value: "password",
          inputType: "text",
          type: "textarea",
          defaultValue: "",
          rows: "2",
          placeholderText: "请输入密码",
          disabled: this.pageProp.pageType == 'detail',
        },
        {
          label: "收款人",
          value: "payee",
          inputType: "text",
          placeholderText: "请输入收款人",
          disabled: this.pageProp.pageType == 'detail',
        },
        {
          label: "复核",
          value: "checker",
          inputType: "text",
          placeholderText: "请输入复核",
          disabled: this.pageProp.pageType == 'detail',
        },
        {
          label: "开票人",
          value: "noteDrawer",
          inputType: "text",
          placeholderText: "请输入开票人",
          disabled: this.pageProp.pageType == 'detail',
        },
      ],
      tableData: [],
      invoiceData: {},
      headers: {},
      //附件列表
      fileList: [],
      file: null,
      token: null,
      id: null,
    };
  },
  created() {
    this.getAccess_token();
    if(this.pageProp.pageType == 'edit' || this.pageProp.pageType == 'detail') {
      this.id = this.pageProp.id || '';
      this.getPageData();
    }
    console.log('this.pageProp', this.pageProp)
  },
  mounted() {
    let headers = {
      "Content-Type": "application/x-www-form-urlencoded",
    };
    this.headers = headers;
  },
  methods: {
    getPageData() {
      getApi(`/contract/invoice/registration/query?id=${this.id}`).then(res => {
        if(res.data.code == 0) {
          let data = res.data.data;
          if(!data) {
            return false
          }
          this.tableData = data.detailList
          const fileArr = []
          fileArr.push(data.file)
          this.fileData = data.file
          this.fileList = fileArr;
          this.initDetailData(data);
        }
      })
    },
    viewOriginalInvoice() {

    },
    getAccess_token() {
      const apiId = "8C3eQkewiFr8EE20JsjrZ5Hu";
      const SecretKey = "5566n2KNBgY1NfC52MxIZ0zyw1DwmJwE";
      postApi(
        `/baiduApi/oauth/2.0/token?grant_type=client_credentials&client_id=${apiId}&client_secret=${SecretKey}`
      ).then((res) => {
        if (res.status == 200) {
          this.token = res.data.access_token;
        }
      });
    },
    getBase64(file) {
      return new Promise(function (resolve, reject) {
        let reader = new FileReader();
        let imgResult = "";
        reader.readAsDataURL(file);
        reader.onload = function () {
          imgResult = reader.result;
        };
        reader.onerror = function (error) {
          reject(error);
        };
        reader.onloadend = function () {
          resolve(imgResult);
        };
      });
    },
    lowerCaseFirstKeys(obj) { // 将第三方返回的数据 key 进行重组
      return Object.keys(obj).reduce((result, key) => {
        const lowerCaseKey = key.charAt(0).toLowerCase() + key.slice(1);
        result[lowerCaseKey] = obj[key];
        return result;
      }, {});
    },
    initDetailData(data) {
          this.$refs.assignForm_1.formData = {
            code: data.code ? data.code : '',
            invoiceTypeOrg: data.invoiceTypeOrg || '',
            id: data.id || null,
          }; 
          var reg = /(\d{4})年(\d{2})月(\d{2})日/g;
          this.$refs.assignForm_title.formData = {
            invoiceCode: data.invoiceCode || '',
            invoiceNum: data.invoiceNum || '',
            invoiceType: data.invoiceType || '',
            invoiceDate:  data.invoiceDate.replace(reg, "$1-$2-$3") || '',
            checkCode: data.checkCode || '',
            machineCode: data.machineCode || '',
          };
          this.$refs.assignForm_purchaser.formData = {
            purchaserName: data.purchaserName || '',
            purchaserRegisterNum: data.purchaserRegisterNum || '',
            purchaserAddress: data.purchaserAddress || '',
            purchaserBank: data.purchaserBank || '',
          };
          this.$refs.assignForm_detail.formData = {
            amountInWords: data.amountInWords || '',
            amountInFiguers: data.amountInFiguers || '',
          };
          this.$refs.assignForm_seller.formData = {
            sellerName: data.sellerName || '',
            sellerRegisterNum: data.sellerRegisterNum || '',
            sellerAddress: data.sellerAddress || '',
            sellerBank: data.sellerBank || '',
          };
          this.$refs.assignForm_other.formData = {
            remarks: data.remarks || '',
            password: data.password || '',
            payee: data.payee || '',
            checker: data.checker || '',
            noteDrawer: data.noteDrawer || '',
          };
    },
    initPageData() {
      let data = this.lowerCaseFirstKeys(JSON.parse(JSON.stringify(this.invoiceData.words_result)));
      this.initDetailData(data);
      const tableDataArr = [];
        const arrKey = ['commodityName', 'commodityType', 'commodityUnit', 'commodityNum', 'commodityPrice', 'commodityAmount', 'commodityTaxRate', 'commodityTax']
        if(data.commodityName && data.commodityName.length > 0) {
          data.commodityName.forEach((item, index) => {
              const obj = {};
              arrKey.forEach(it => {
                  obj[it] = data[it][index]?.word
              })
              tableDataArr.push(obj)
          })
        }

      
      this.tableData = tableDataArr
    },
    httpRequest(e) {
      this.getBase64(e.file).then((res) => {
        let header = {
          "Content-Type": "application/x-www-form-urlencoded",
        };
        let params;
        if (e.file.type === 'application/pdf'){
          this.file = res.replace(/^data:application\/pdf;base64,/, "");
          params = {
            pdf_file: this.file
          }
        }else{
          this.file = res.replace(/^data:image\/png;base64,/, "");
          params = {
            image: this.file
          }
        }
        let fileData = new FormData();
        fileData.append('file', e.file)

        postApi('/admin/sys-file/upload', fileData).then(res => {
          if(res.data.code == 0) {
            this.fileData = res.data.data
          }
        })
        postApi(
          `/baiduApi/rest/2.0/ocr/v1/vat_invoice?access_token=${this.token}`,
          params,
          header
        ).then((res) => {
          if (res.status == 200) {
            this.invoiceData = res.data;
            this.initPageData();
          }
        });
      });
    },
    //附件上传移除
    beforeRemove(file, fileList) {
      if(this.pageProp.pageType !== 'detail') { 
          this.$refs.assignForm_1.formData = {
              code: '',
              invoiceTypeOrg:  '',
              id:  null,
            }; 
            var reg = /(\d{4})年(\d{2})月(\d{2})日/g;
            this.$refs.assignForm_title.formData = {
              invoiceCode:  '',
              invoiceNum: '',
              invoiceType:  '',
              invoiceDate:   '',
              checkCode:  '',
              machineCode:'',
            };
            this.$refs.assignForm_purchaser.formData = {
              purchaserName:  '',
              purchaserRegisterNum: '',
              purchaserAddress:  '',
              purchaserBank: '',
            };
            this.$refs.assignForm_detail.formData = {
              amountInWords:  '',
              amountInFiguers:  '',
            };
            this.$refs.assignForm_seller.formData = {
              sellerName: '',
              sellerRegisterNum:  '',
              sellerAddress:  '',
              sellerBank: '',
            };
            this.$refs.assignForm_other.formData = {
              remarks: '',
              password: '',
              payee: '',
              checker: '',
              noteDrawer:  '',
            };
            this.tableData = [];
      }
      else {
        return false
      }
    },
    handleRemove(file, fileList) {
      if(this.pageProp.pageType !== 'detail') { 
        this.fileList.map((item, index) => {
          if (item.fileName == file.fileName) {
            this.fileList.splice(index, 1);
          }
        });
      }
    },
    //附件限制
    handleExceed(files, fileList) {
      this.$message.warning(`当前限制选择 1 个文件`);
    },
    //附件上传成功
    handleFileSuccess(res, file) {
      if (res.code == 0) {
        this.fileList.push(res.data);
      }
    },
    handleView(){
        getApi(`/admin/sys-file/online/ssh/${this.fileData.fileName}`).then((res) => {
        let { data } = res;
        if (data && data.code == 0) {
          let url = data.data;
          window.open(url);
        }
      });
    },
    //只能上传图片类型
    beforeAvatarUpload(file) {
      const isJPG =
        file.type === "image/jpeg" ||
        file.type === "image/png" ||
        file.type === "image/jpg" || 
        file.type === "application/pdf";
      const isLt5M = file.size / 1024 / 1024 < 8;
      
      if (!isJPG) {
        this.$message.error("只能上传pdf/jpeg/png/jpg格式文件!");
      }
      if (!isLt5M) {
        this.$message.error("上传文件大小不能超过 8MB!");
      }
      return isJPG && isLt5M;
    },
    getSummaries(param) { // 自定义项表格合计方法
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "合计";
          return;
        } else if (column.property == "commodityAmount" || column.property == "commodityTax"
        ) {
          //如果是经费（正常的加减法）
          const values = data.map((item) => item[column.property] ? item[column.property] * 1 : 0);
          if (!values.every((value) => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr);
              var sum = 0;
              if (!isNaN(value)) {
                sum = Number(Number(prev) + Number(curr)).toFixed(2);
                return sum;
              } else {
                return prev;
              }
            }, 0);
            sums[index] += " ";
          }
        }
      });
      if(sums.length && sums.length > 0) {
        this.$refs.assignForm_title.formData.totalAmount = sums[5];
        this.$refs.assignForm_title.formData.totalTax = sums[7];
      }
      return sums
    },
  },
};
</script>

<style lang="less" scoped>
.top_box {
  display: flex;

  .top_box_name {
    padding: 0 15px;
    box-sizing: border-box;
    height: 45px;
    line-height: 45px;
    font-weight: bold;
    font-size: 14px;
    color: #606266;
  }

  .right_box {
    display: flex;
    align-items: center;

    .content {
      margin-right: 20px;
    }
  }
}
/deep/.el-input.is-disabled .el-input__inner {
  background-color: #fbfbfb;
  color: @defaultFontColor;
}
/deep/.el-textarea.is-disabled .el-textarea__inner {
  background-color: #fbfbfb;
  color: @defaultFontColor;
  resize: none;
}
// /deep/ .el-input__suffix {
//   display: none;
// }
/deep/ .el-range-editor.is-disabled {
  background-color: #fbfbfb;
  color: @defaultFontColor;
}
/deep/ .el-range-editor.is-disabled input {
  color: @defaultFontColor;
}
</style>