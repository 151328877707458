<template>
    <div class="container">
        <el-tabs v-model="activeName" type="card" class="tabs_card" @tab-click="handleClick">
            <el-tab-pane label="合同信息" name="合同信息">
                <contractInformation :pageProp="pageProp"></contractInformation>
            </el-tab-pane>
            <el-tab-pane label="合同修改记录" name="合同修改记录">
                <dd-table :columns="tableColumns" :data="tableData" border isSort :isSelection="false" :loading="loading">
                </dd-table>
            </el-tab-pane>
            <el-tab-pane label="关联金蝶合同列表" name="关联金蝶合同列表">
                <dd-table :columns="tableColumns_kingDee" :data="tableData_kingDee" show-summary  border :isSelection="false" :loading="loading">
                </dd-table>
            </el-tab-pane>
            <el-tab-pane label="合同结算信息" name="合同结算信息">
                <dd-table :columns="tableColumns_settlement" :data="tableData_settlement"  border :isSelection="false" :loading="loading">
                </dd-table>
            </el-tab-pane>
            <el-tab-pane label="合同付款信息" name="合同付款信息">
                <dd-table :columns="tableColumns_payment" :data="tableData_payment"  border :isSelection="false" :loading="loading">
                </dd-table>
            </el-tab-pane>
            <el-tab-pane label="开票信息" name="开票信息">
                <dd-table :columns="tableColumns_invoicing" :data="tableData_invoicing"  border :isSelection="false" :loading="loading">
                </dd-table>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
import { getApi } from '@/api/request';
import contractInformation from '../../selfBuiltContract/components/contractInformation.vue';
export default {
    name: 'contractDetailZj',
    props: ['pageProp'],
    components: { contractInformation },
    data() {
        return {
            activeName: '合同信息',
            loading: false,
            tableColumns: [
                {
                    title: "修改原因",
                    key: "modifierReason",
                    align: "center",
                    overflow: true,
                },
                {
                    title: "修改者",
                    key: "modifierName",
                    align: "center",
                    overflow: true,
                },
                {
                    title: "修改日期",
                    key: "modifyDate",
                    align: "center",
                    overflow: true,
                },
                {
                    title: "采购合同变更后总金额",
                    key: "procurementFinallyAmount",
                    align: "center",
                    overflow: true,
                },
            ],
            tableColumns_kingDee: [
                {
                    title: "关联金蝶合同编码",
                    key: "fnumber",
                    align: "center",
                    overflow: true,
                },
                {
                    title: "关联金蝶合同编码",
                    key: "fname",
                    align: "center",
                    overflow: true,
                },
                {
                    title: "合同乙方",
                    key: "fpartbname",
                    align: "center",
                    overflow: true,
                },
                {
                    title: "金蝶合同总合同额",
                    key: "ftotalamount",
                    align: "center",
                    overflow: true,
                },
                {
                    title: "关联金额",
                    key: "allotmentAmount",
                    align: "center",
                    overflow: true,
                },
                {
                    title: "操作",
                    align: "center",
                    overflow: true,
                    width: 100,
                    event: [
                        {
                            state: "default",
                            label: "查看详情",
                            width: 100,
                            method: (row) => {
                                this.handleRowDetail(row);
                            },
                        },
                    ],
                },
            ],
            tableColumns_settlement: [
                {
                    title: "合同编码",
                    key: "fnumber",
                    align: "center",
                    overflow: true,
                },
                {
                    title: "结算单据编号",
                    key: "settlefnumber",
                    align: "center",
                    overflow: true,
                },
                {
                    title: "结算单据名称",
                    key: "settlefname",
                    align: "center",
                    overflow: true,
                },
                {
                    title: "业务日期",
                    key: "fbizdate",
                    align: "center",
                    overflow: true,
                },
                {
                    title: "期间",
                    key: "fperiod",
                    align: "center",
                    overflow: true,
                },
                {
                    title: "结算总金额",
                    key: "foriginalamount",
                    align: "center",
                    overflow: true,
                },
                {
                    title: "甲方",
                    key: "fparta",
                    align: "center",
                    overflow: true,
                },
                {
                    title: "乙方",
                    key: "fpartb",
                    align: "center",
                    overflow: true,
                },
                {
                    title: "制单人",
                    key: "documenter",
                    align: "center",
                    overflow: true,
                },
                {
                    title: "制单日期",
                    key: "fcreatetime",
                    align: "center",
                    overflow: true,
                },
                {
                    title: "说明",
                    key: "fdescription",
                    align: "center",
                    overflow: true,
                },
            ],
            tableColumns_payment: [
                {
                    title: "单据编号",
                    key: "paymentfnumber",
                    align: "center",
                    overflow: true,
                },
                {
                    title: "单据名称",
                    key: "paymentfname",
                    align: "center",
                    overflow: true,
                },
                {
                    title: "合同名称",
                    key: "fname",
                    align: "center",
                    overflow: true,
                },
                {
                    title: "合同编码",
                    key: "fnumber",
                    align: "center",
                    overflow: true,
                },
                {
                    title: "供应商",
                    key: "fparta",
                    align: "center",
                    overflow: true,
                },
                {
                    title: "支付类型",
                    key: "payType",
                    align: "center",
                    overflow: true,
                },
                {
                    title: "本期实付金额",
                    key: "foriginalamount",
                    align: "center",
                    overflow: true,
                    width: 120
                },
                {
                    title: "不含税金额",
                    key: "fnotaxdataamount",
                    align: "center",
                    overflow: true,
                    width: 120
                },
                {
                    title: "税额",
                    key: "ftax",
                    align: "center",
                    overflow: true,
                },
                {
                    title: "付款期间",
                    key: "fperiod",
                    align: "center",
                    overflow: true,
                },
                {
                    title: "付款日期",
                    key: "fauditdate",
                    align: "center",
                    overflow: true,
                },
                {
                    title: "工程项目",
                    key: "prjName",
                    align: "center",
                    overflow: true,
                },
                {
                    title: "制单人",
                    key: "documenter",
                    align: "center",
                    overflow: true,
                },
                {
                    title: "关联付款金额",
                    key: "allotmentAmount",
                    align: "center",
                    overflow: true,
                    width: 120
                },
            ],
            tableColumns_invoicing: [
                {
                    title: "合同编码",
                    key: "fnumber",
                    align: "center",
                    overflow: true,
                },
                {
                    title: "合同名称",
                    key: "fname",
                    align: "center",
                    overflow: true,
                },
                {
                    title: "发票代码",
                    key: "invoiceCode",
                    align: "center",
                    overflow: true,
                },
                {
                    title: "发票号码",
                    key: "invoiceNum",
                    align: "center",
                    overflow: true,
                },
                {
                    title: "开票日期",
                    key: "invoiceDate",
                    align: "center",
                    overflow: true,
                },
                {
                    title: "金额",
                    key: "totalAmount",
                    align: "center",
                    overflow: true,
                },
                // {
                //     title: "税率",
                //     key: "code",
                //     align: "center",
                //     overflow: true,
                // },
                // {
                //     title: "税额",
                //     key: "code",
                //     align: "center",
                //     overflow: true,
                // },
                {
                    title: "价税合计金额",
                    key: "amountInFiguers",
                    align: "center",
                    overflow: true,
                },
                {
                    title: "发票类型",
                    key: "invoiceType",
                    align: "center",
                    overflow: true,
                },
                {
                    title: "有效发票金额",
                    key: "validInvoiceAmount",
                    align: "center",
                    overflow: true,
                },
                {
                    title: "开票供应商名称",
                    key: "sellerName",
                    align: "center",
                    overflow: true,
                },
                {
                    title: "制单人",
                    key: "noteDrawer",
                    align: "center",
                    overflow: true,
                },
                {
                    title: "制单日期",
                    key: "applyDate",
                    align: "center",
                    overflow: true,
                },
                {
                    title: "附件",
                    align: "center",
                    overflow: true,
                    image: true,
                    AttachFiles: "file",
                },
            ],
            tableData_invoicing: [],
            tableData_payment: [],
            tableData_settlement: [],
            tableData_kingDee: [],
            tableData: [],
        }
    },
    created() {
        this.getPageData();
    },
    mounted() {

    },
    methods: {
        handleClick(e) {},
        handleRowDetail(row) {},
        async getPageData() {
            let typeArr = ['change', 'purchase', 'settle', 'payment', 'invoice']
            for(let item of typeArr) {
                let res = await getApi(`/contract/selfestablished/procurement/self/purchase`, {id: this.pageProp.id, type: item});
                if(res.data.code == 0) {
                    let { data } = res
                    // console.log(item, data.data)
                    if(item == 'change') {
                        this.tableData = data.data
                    }else if(item == 'purchase') {
                        this.tableData_kingDee = data.data;
                    }else if(item == 'settle') {
                        this.tableData_settlement = data.data;
                    }else if(item == 'payment') {
                        this.tableData_payment = data.data;
                    }else {
                        data.data.forEach(item => {
                            item.file = [item.file]
                        })
                        this.tableData_invoicing = data.data;
                    }
                }
                
            }
        },
        getSummaries() {
            const { columns, data } = param;
            const sums = [];
            columns.forEach((column, index) => {
                if (index === 0) {
                sums[index] = "合计";
                return;
                } else if (column.property == "allotmentAmount"
                ) {
                //如果是经费（正常的加减法）
                const values = data.map((item) => item[column.property] ? item[column.property] * 1 : 0);
                if (!values.every((value) => isNaN(value))) {
                    sums[index] = values.reduce((prev, curr) => {
                    const value = Number(curr);
                    var sum = 0;
                    if (!isNaN(value)) {
                        sum = Number(Number(prev) + Number(curr)).toFixed(2);
                        return sum;
                    } else {
                        return prev;
                    }
                    }, 0);
                    sums[index] += " ";
                }
                }
            });
            return sums
        },
    }
}
</script>

<style lang="less" scoped>
  /deep/ .tabs_card .el-tabs__item {
    // width: 30% !important;
  }
</style>