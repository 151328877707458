<template>
  <div class="main-container">
    <dd-card title="条件查询" spacer>
      <dd-search-form
        :form-config="formList"
        @search-click="handleSearchEvent"
        ref="ddSearchForm"
      ></dd-search-form>
    </dd-card>
    <dd-card title="申请单列表" :height="tableHeight">
      <dd-table
        :columns="columnsList"
        :data="tableDataSources"
        border
        ref="dTableRef"
        :summary-method="getSummaries"
        show-summary
        show-page
        @select-change="handleSelectionChange"
      >
        <template #table-btn>
          <dd-button
            type="primary"
            icon="add"
            size="medium"
            @click="handleApply(0)"
            >批量确认</dd-button
          >
          <dd-button
            type="success"
            icon="update"
            size="medium"
            @click="handleAddEdit(1, row, false)"
            >修改核定金额
          </dd-button>
          <dd-button
            type="danger"
            size="medium"
            icon="back"
            autoConfirm
            @click="handleApply(1)"
            >批量退回</dd-button
          >
        </template>
        <template #table-btn-right>
          <el-button
            icon="el-icon-s-operation"
            circle
            type="info"
            @click="drawerVisible = true"
            size="small"
            title="列筛选"
          ></el-button>
        </template>
        <template #table-pagination>
          <dd-pagination
            :total="total"
            :page-size="pageSize"
            :page-number="pageNumber"
            @page-change="handlePageChange"
            align="right"
          >
          </dd-pagination>
        </template>
      </dd-table>
    </dd-card>
    <dd-popup
      v-if="dialogVisible"
      width="80%"
      height="80%"
      :title-name="popTitle"
      :show="dialogVisible"
      :showBtn="type == 'detail' ? false : true"
      :saveBtn="false"
      @popup-close="handleClose"
    >
      <dd-button
        v-if="type == 'approve'"
        slot="button"
        type="primary"
        size="medium"
        @click="aprroveOrback(0)"
        >确认
      </dd-button>
      <dd-button
        v-if="type == 'approve'"
        slot="button"
        type="warning"
        size="medium"
        @click="aprroveOrback(1)"
        >退回
      </dd-button>
      <AddUpdateDetailInfoPop
        slot="main"
        ref="addPop"
        :type="type"
        :id="id"
        @diaChange="diaChange"
      ></AddUpdateDetailInfoPop>
    </dd-popup>
    <dd-filter-drawer
      v-if="drawerVisible"
      width="30%"
      title-name="列筛选"
      :show="drawerVisible"
      :tableColumns="tableColumns"
      :columns="columnsList"
      @popup-close="drawerVisible = false"
      @handleColumnsChange="handleColumnsChange"
    >
    </dd-filter-drawer>
  </div>
</template>
<script>
import AddUpdateDetailInfoPop from "../../../PersonalOffice/Hr/WithoutCar/components/AddUpdateDetailInfoPop";
import { formatNumber } from "@/utils/number";
import { downloadBinaryFile } from "@/utils/index";
import { postApi, getApi, loadApi } from "@/api/request";
import { mapState, mapActions } from "vuex";
import qs from "qs";
export default {
  name: "withoutCarApply",
  $dynamicRoute: {
    name: "外部租车申请单管理",
    path: "/withoutCar/carApply/list",
  },
  components: { AddUpdateDetailInfoPop },
  data() {
    return {
      tableHeight: null,
      type: "",
      id: "",
      popTitle: "",
      drawerVisible: false,
      // 页码
      pageNumber: 1,
      // 每页的数据条数
      pageSize: 10,
      total: 0,
      dialogVisible: false,
      // 表格选择数据集合
      multipleSelection: [],
      // 接收表单返回数据
      formData: {},
      // 条件查询表单配置配置
      formList: [
        {
          label: "项目名称",
          value: "projectName",
          inputType: "select",
          optionsList: [],
          filterable: "filterable",
          placeholderText: "请选择或搜索项目名称",
          optionsListConfig: {
            name: "projectName",
            code: "projectId",
          },
        },
        {
          label: "项目编号",
          value: "projectId",
          inputType: "select",
          optionsList: [],
          filterable: "filterable",
          placeholderText: "请选择或搜索项目编号",
          optionsListConfig: {
            name: "projectCode",
            code: "projectId",
          },
        },
        {
          label: "申请人",
          value: "applierId",
          inputType: "select",
          optionsList: [],
          filterable: "filterable",
          placeholderText: "请选择或搜索申请人",
          optionsListConfig: {
            name: "fullName",
            code: "userId",
          },
        },
        {
          label: "申请日期",
          value: "applyDate",
          inputType: "date",
          dateType: "daterange",
          format: "yyyy-MM-dd",
        },
        {
          label: "单据状态",
          value: "formStatus",
          inputType: "select",
          optionsList: [],
          placeholderText: "请选择或搜索单据状态",
        },
        {
          label: "上报状态",
          value: "reportStatus",
          inputType: "select",
          optionsList: [],
          placeholderText: "请选择或搜索上报状态",
        },
        {
          label: "上报日期",
          value: "reportDate",
          inputType: "date",
          dateType: "monthrange",
          format: "yyyy-MM",
        },
        {
          label: "费用承担部门",
          value: "expenseBearDepId",
          inputType: "select",
          optionsList: [],
          filterable: true,
          placeholderText: "请选择或搜索费用承担部门",
          optionsListConfig: {
            name: "name",
            code: "deptId",
          },
        },
      ],
      // 表格头部配置数据
      tableColumns: [
        {
          title: "申请编号",
          key: "applyCode",
          align: "center",
          overflow: true,
        },
        {
          title: "项目名称",
          key: "projectName",
          align: "center",
          overflow: true,
        },
        {
          title: "项目编号",
          key: "projectCode",
          align: "center",
        },
        {
          title: "申请人",
          key: "applierName",
          align: "center",
        },
        {
          title: "申请日期",
          key: "applyTime",
          align: "center",
        },
        {
          title: "用车公里数",
          key: "useKilometer",
          align: "center",
        },
        {
          title: "租车金额",
          key: "rentalcarAmount",
          align: "center",
        },
        {
          title: "核定金额",
          key: "approvedAmount",
          align: "center",
        },
        {
          title: "单据状态",
          key: "formStatus",
          align: "center",
        },
        {
          title: "上报状态",
          key: "reportStatus",
          align: "center",
        },
        {
          title: "上报日期",
          key: "reportMonth",
          align: "center",
        },
        {
          title: "操作",
          align: "center",
          width: "240px",
          event: [
            {
              label: "确认",
              type: "primary",
              method: (row) => {
                this.handleAddEdit(1, row, true);
              },
              rules: (row) => {
                let show = false;
                if (row.formStatus == "已验证") {
                  show = true;
                }
                return show;
              },
            },
            {
              label: "查看详情",
              state: "default",
              method: (row) => {
                this.handleAddEdit(3, row);
              },
            },
          ],
        },
      ],
      columnsList: [], //筛选列表格头部配置
      // 表格数据集合
      tableDataSources: [],
      // 是否显示删除确认弹框
      autoConfirm: false,
    };
  },
  created() {},
  mounted() {
    // 自动计算table高度
    this.$nextTick(() => {
      this.tableHeight = this.autoTableHeight(0);
    });
    //获取项目信息
    this.getProject();
    // 获取单据状态
    this.getStateList();
    //上报状态
    this.getReportStateList();
    //申请人
    this.getApplierData();
    //申请列表
    this.getApplyList();
    this.getConditionData(); // 部门
    this.columnsList = [...this.tableColumns];
  },
  methods: {
    //获取部门数据
    getConditionData() {
      postApi(`/admin/dept/getdeptlistbyorgid`, {
        orgId: sessionStorage.getItem("orgId"),
      }).then((res) => {
        this.formList.forEach((item) => {
          if (item.value == "expenseBearDepId") {
            item.optionsList = res.data.data;
          }
        });
      });
    },
    //申请人
    getApplierData() {
      postApi(`/admin/user/getver/list`, {}).then((res) => {
        let { data } = res;
        console.log('res', res);
        this.formList.forEach((item) => {
          if (item.value == "applierId") {
            item.optionsList = data.data;
          }
        });
      });
    },
    //获取项目信息
    getProject() {
      getApi(`/project/prj/list/all`).then((res) => {
        let { data } = res;
        console.log(data);
        if (data.code == 0) {
          this.formList.forEach((item) => {
            if (item.value == "projectName" || item.value == "projectId") {
              item.optionsList = data.data;
            }
          });
        }
      });
    },
    // 单据上报下拉数据请求
    getReportStateList() {
      postApi(`/hr/hrdict/list?typeCode=wbzchzdsbzt`, {}).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          this.formList.forEach((item) => {
            if (item.value == "reportStatus") {
              item.optionsList = data.data;
            }
          });
          this.getApplyList();
        }
      });
    },
    // 单据状态下拉数据请求
    getStateList() {
      postApi(`/hr/hrdict/list?typeCode=wbzcdjzt`, {}).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          this.formList.forEach((item) => {
            if (item.value == "formStatus") {
              item.optionsList = data.data;
            }
          });
        }
      });
    },
    handleColumnsChange(val) {
      let list = new Set();
      this.tableColumns.map((item) => {
        val.map((itm) => {
          if (item.event || item.key == itm.key) {
            list.add(item);
          }
        });
      });
      this.columnsList = [...list];
    },
    // 申请列表请求
    getApplyList() {
      this.$refs.dTableRef.$refs.baseTable.clearSelection();
      postApi(
        `/hr/rentalcar/applysheet/audit/page/${this.pageNumber}/${this.pageSize}`,
        this.formData
      ).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          data.data.records.forEach((item) => {
            item.approvedAmount = formatNumber(item.approvedAmount);
            item.rentalcarAmount = formatNumber(item.rentalcarAmount);
          });
          this.tableDataSources = data.data.records;
          this.total = data.data.total;
        }
      });
    },
    //核定金额 退回 确认1退回0
    aprroveOrback(type) {
      let flag = false;
      this.$refs.addPop.$refs.baseForm.$refs.baseForm.validate((valid) => {
        if (valid) {
          flag = true;
        }
      });
      if (!flag) {
        return;
      }
      let params = {
        id: this.id,
        approvedAmount:
          this.$refs.addPop.$refs.baseForm.formData.approvedAmount,
        type: type,
      };
      postApi(`/hr/rentalcar/applysheet/examine`, params).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          if (type == 0) {
            this.$message.success("金额核定成功！");
          } else {
            this.$message.success("退回成功！");
          }
          this.handleClose();
        } else {
          this.$message.error(data.msg);
          this.$message({
            message: data.msg,
            type: "error",
            duration: 6000,
          });
        }
      });
    },
    handleClose() {
      this.dialogVisible = false;
      this.getApplyList();
    },
    handleSearchEvent(obj) {
      console.log('obj', obj)
      this.formData = obj;
      this.pageNumber = 1;
      this.getApplyList();
    },
    //核定金额 查看详情
    handleAddEdit(type, row, bool) {
      // type 1：核定金额；3：详情
      if (type == 1) {
        if (!bool) {
          if (this.multipleSelection.length !== 1) {
            this.$message.warning("请选择一条数据！");
            return;
          } else if (
            this.multipleSelection[0].formStatus === "退回" ||
            (this.multipleSelection[0].reportStatus !== "未上报" &&
              this.multipleSelection[0].reportStatus !== "已退回")
          ) {
            this.$message.warning("只能核定未退回或未上报的数据！");
            return;
          }
          this.id = this.multipleSelection[0].id;
          this.type = "approve";
          this.popTitle = "核定金额";
          this.dialogVisible = true;
        } else {
          if (row.formStatus != "已验证") {
            this.$message.warning("只能审核单据状态为已验证的数据！");
            return;
          }
          this.id = row.id;
          this.type = "approve";
          this.popTitle = "核定金额";
          this.dialogVisible = true;
        }
      } else {
        this.id = row.id;
        this.type = "detail";
        this.popTitle = "详情";
        this.dialogVisible = true;
      }
    },
    // type: 0 批量审批; 1 批量退回
    handleApply(type) {
      if (this.multipleSelection.length < 1) {
        this.$message.warning(`请选择要${type == 0 ? "审批" : "退回"}的数据`);
        return;
      }
      // 所有操作只能针对未上报状态数据
      for (var i = 0; i < this.multipleSelection.length; i++) {
        if (
          this.multipleSelection[i].reportStatus === "待行政确认" ||
          this.multipleSelection[i].reportStatus === "行政已确认" ||
          this.multipleSelection[i].reportStatus === "已退回"
        ) {
          this.$message.warning("只能选择未上报数据！");
          return;
        }
      }

      // 批量审批 只能审批验证状态数据
      if (0 === type) {
        for (var j = 0; j < this.multipleSelection.length; j++) {
          if (this.multipleSelection[j].formStatus !== "已验证") {
            this.$message.warning("只能选择已验证状态数据！");
            return;
          }
        }
      }

      // 批量退回 可以退回已验证/已核准状态数据
      if (1 === type) {
        for (var k = 0; k < this.multipleSelection.length; k++) {
          if (
            this.multipleSelection[k].formStatus !== "已验证" &&
            this.multipleSelection[k].formStatus !== "已核准"
          ) {
            this.$message.warning("只能选择已验证或已核准状态数据！");
            return;
          }
        }
      }

      let idArr = [];
      // 所有条件都满足时
      for (var m = 0; m < this.multipleSelection.length; m++) {
        idArr.push(this.multipleSelection[m].id);
      }
      //idArr转为数组对象
      var id = { ids: idArr.join() };

      this.$confirm(
        `是否确定${type == 0 ? "通过" : "退回"}租车车申请?`,
        "提示",
        {
          confirmButtonClass: "btnsearch",
          confirmButtonText: "确认",
          cancelButtonClass: "btnreset",
          cancelButtonText: "取消",
          showClose: true,
          type: "warning",
        }
      )
        .then(() => {
          postApi(`/hr/rentalcar/applysheet/approve/${type}`, id)
            .then((res) => {
              let { data } = res;
              if (data.code == 0) {
                this.$message({
                  type: "success",
                  message: "退回成功",
                });
                this.getApplyList();
                this.$refs.dTableRef.$refs.baseTable.clearSelection();
              }
            })
            .catch((e) => {
              this.$message.error(e);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 计算表格合计行函数
    getSummaries(param) {
      const { columns, data } = param;
      let sums = [param];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "合计";
          return;
        } else if (
          column.property == "useKilometer" ||
          column.property == "approvedAmount" ||
          column.property == "rentalcarAmount"
        ) {
          const values = data.map((item) => Number(item[column.property]));
          if (!values.every((value) => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr);
              if (!isNaN(value)) {
                return prev + curr;
              } else {
                return prev;
              }
            }, 0);
            sums[index] += "";
            if (
              column.property == "rentalcarAmount" ||
              column.property == "approvedAmount"
            ) {
              sums[index] = formatNumber(sums[index]);
            }
          }
        }
      });
      return sums;
    },
    // 表格多选事件
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    //分页
    handlePageChange(pageSize, pageNumber) {
      this.pageSize = pageSize;
      this.pageNumber = pageNumber;
      this.getApplyList();
    },
  },
};
</script>

<style lang="less" scoped></style>
