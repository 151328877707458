<template>
  <div class="main-container">
    <div class="left-box">
      <dd-card title="已选人员">
        <dd-table
          :columns="leftTableColumns"
          :data="leftTableData"
          max-height="530"
          border
          :isSelection="false"
          :show-page="false"
          :loading="leftLoading"
        >
        </dd-table>
      </dd-card>
    </div>
    <div class="right-box">
      <div class="head_wrap">
        <dd-card title="条件搜索" spacer>
          <dd-search-form
            :form-config="formList"
            @search-click="handleSearchEvent"
            ref="ddSearchForm"
          ></dd-search-form>
        </dd-card>
      </div>
      <dd-card title="人员列表" :height="tableHeight">
        <dd-table
          :columns="tableColumns"
          :data="tableData"
          ref="rightTable"
          border
          isSelection
          show-page
          :loading="loading"
          @row-click="handleTableRow"
          @select-change="handleSelect"
        >
          <template #table-btn>
            <dd-button
              type="primary"
              size="medium"
              icon="add"
              @click="handleAdd"
              >新增</dd-button
            >
          </template>
          <template #table-pagination>
            <dd-pagination
              v-show="total > 0"
              :total="total"
              :page-size="size"
              :page-number="current"
              @page-change="handleCurrentChange"
              align="right"
            >
            </dd-pagination>
          </template>
        </dd-table>
      </dd-card>
    </div>
  </div>
</template>

<script>
import { getApi, delApi, postApi, putApi } from "@/api/request";
export default {
  props: {
    areaId: {
      type: String,
      default: "",
    },
  },
  components: {},
  computed: {},
  data() {
    return {
      tableHeight: null,
      loading: false,
      leftLoading: false,
      formData: {},
      formList: [
        {
          label: "员工姓名",
          value: "fullName",
          inputType: "text",
          placeholderText: "请输入目录名称",
        },
        {
          label: "部门选择",
          value: "deptId",
          inputType: "select",
          optionsList: [],
          filterable: true,
          placeholderText: "请选择部门",
          optionsListConfig: {
            name: "deptName",
            code: "deptId",
          },
        },
      ],
      tableColumns: [
        {
          title: "工号",
          key: "username",
          align: "center",
        },
        {
          title: "姓名",
          key: "fullName",
          align: "center",
        },
        {
          title: "手机号",
          key: "phone",
          align: "center",
        },
      ],
      leftTableColumns: [
        {
          title: "工号",
          key: "account",
          align: "center",
        },
        {
          title: "姓名",
          key: "username",
          align: "center",
        },
      ],
      tableData: [],
      leftTableData: [],
      current: 1,
      leftCurrent: 1,
      size: 20,
      leftSize: 999,
      total: null,
      leftTotal: null,
      rowData: null,
      poptitleName: "新增",
      selection: [],
      dictType: [],
    };
  },
  created() {},
  mounted() {
    // 自动计算table高度
    this.$nextTick(() => {
      this.tableHeight = this.autoTableHeight(0);
    });
    this.getDataType();
    this.getTableData();
    this.getLeftTableData();
  },
  methods: {
    //条件查询表单--下拉选择数据填充（optionsList）
    setOPtionsList(formList, options) {
      formList.forEach((item) => {
        if (options) {
          if (item.value == options.value) {
            item.optionsList = options.optionsList;
          }
        }
      });
    },
    // 获取数据字典类型
    getDataType() {
      getApi(`/admin/dept/getAllNameList`).then((res) => {
        let { data } = res;
        if (data && data.code == 0) {
          let options = {
            value: "deptId",
            optionsList: data.data,
          };
          this.dictType = data.data;
          this.setOPtionsList(this.formList, options);
        }
      });
    },
    // 获取表格列表数据
    getTableData() {
      let { formData, current, size } = this;
      let params = {
        ...formData,
        current,
        size,
      };
      this.loading = true;
      getApi(`/admin/user/page`, params).then((res) => {
        let { data } = res;
        if (data && data.code == 0) {
          this.loading = false;
          this.tableData = data.data.records;
          this.total = data.data.total;
        }
      });
    },
    getLeftTableData() {
      let { leftCurrent, leftSize } = this;
      let params = {
        current: leftCurrent,
        size: leftSize,
        areaId: this.areaId,
      };
      this.leftLoading = true;
      getApi(`/project/catalogarea/area/user/page`, params).then((res) => {
        let { data } = res;
        if (data && data.code == 0) {
          this.leftLoading = false;
          this.leftTableData = data.data.records;
          this.leftTotal = data.data.total;
        }
      });
    },
    handleTableSelect(selection) {
      this.selection = selection;
    },
    /* 搜索栏 */
    handleSearchEvent(obj) {
      this.formData = obj;
      this.current = 1;
      this.getTableData();
    },
    /* 分页页码回调 */
    handleCurrentChange(size, number) {
      this.current = number;
      this.size = size;
      this.getTableData();
    },
    // 表格行点击选择事件
    handleTableRow(row) {
      let data = [];
      if (row == this.rowData) {
        row = null;
      } else {
        this.rowData = row;
        data.push(row);
      }
      this.handleSelect(data, row);
    },
    // 复选框单选事件
    handleSelect(selection, row) {
      this.selection = selection;
      this.rowData = row;
      this.$refs.rightTable.$refs.baseTable.clearSelection();
      if (selection.length == 0) {
        this.rowData = null;
        this.$refs.rightTable.$refs.baseTable.setCurrentRow(null);
        return;
      }
      this.$refs.rightTable.$refs.baseTable.toggleRowSelection(row, true);
      this.$refs.rightTable.$refs.baseTable.setCurrentRow(row);
    },
    handleAdd() {
      let { rowData } = this;
      if (rowData == null) {
        this.$message.warning("请选择要增加的数据");
        return;
      }
      let params = {
        areaId: this.areaId,
        userId: rowData.userId,
        account: rowData.username,
        username: rowData.fullName,
      };
      postApi(`/project/catalogarea/addAreaUser`, params).then((res) => {
        let { data } = res;
        if (data && data.code == 0) {
          this.$message.success("添加成功");
          this.getLeftTableData();
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.main-container {
  display: flex;
  justify-content: space-between;
  .left-box {
    width: 23%;
    /deep/ .has-gutter {
      .el-table-column--selection {
        .cell {
          display: none;
        }
      }
    }
  }
  .right-box {
    width: 77%;
  }
  /deep/ .search-form-input {
    width: 33% !important;
  }
}
</style>
