<template>
  <div class="main-container">
    <dd-card title="条件查询" spacer>
      <dd-search-form
        :form-config="formList"
        @search-click="handleSearchEvent"
      ></dd-search-form>
    </dd-card>
    <dd-card title="预算申请列表" :height="tableHeight">
      <dd-table
        :columns="columnsList"
        :data="tableDataSources"
        :loading="loading"
        border
        ref="dTableRef"
        tableRef="dTableRef"
        :summary-method="getSummaries"
        show-summary
        show-page
        @select-change="handleSelectionChange"
      >
        <template #table-btn>
          <dd-button
            type="primary"
            size="medium"
            icon="add"
            @click="handleAddEdit(0)"
            >新增</dd-button
          >
          <dd-button
            type="warning"
            size="medium"
            icon="update"
            @click="handleAddEdit(1)"
            >编辑</dd-button
          >
          <dd-button
            type="danger"
            size="medium"
            icon="delete"
            autoConfirm
            @click="handleDelete"
            >删除</dd-button
          >
        </template>
        <template #table-btn-right>
          <el-button
            icon="el-icon-s-operation"
            circle
            type="info"
            @click="drawerVisible = true"
            size="small"
            title="列筛选"
          ></el-button>
          <!-- <el-button
            icon="el-icon-download"
            circle
            type="info"
            @click="handleFileExport"
            size="small"
            title="下载"
          ></el-button> -->
        </template>
        <template #table-pagination>
          <dd-pagination
            :total="total"
            :page-size="size"
            :page-number="current"
            @page-change="handlePageChange"
            align="right"
          >
          </dd-pagination>
        </template>
      </dd-table>
    </dd-card>
    <!-- 新增编辑弹窗 -->
    <dd-popup
      v-if="dialogAddEditVisible"
      width="60%"
      height="80%"
      showBtn
      submitBtn
      :show="dialogAddEditVisible"
      :title-name="addEditTitle"
      @popup-save="handleSubmit(0, 'budgetBasicForm')"
      @popup-submit="handleSubmit(1, 'budgetBasicForm')"
      @popup-close="dialogAddEditVisible = false"
    >
      <addEditPop
        ref="addEditPop"
        slot="main"
        :addEdit="addEdit"
        :type="type"
      ></addEditPop>
    </dd-popup>

    <dd-popup
      v-if="dialogDetailVisible"
      width="60%"
      :title-name="applyDetailTitle"
      :show="dialogDetailVisible"
      @popup-close="dialogDetailVisible = false"
    >
      <applyDetail
        slot="main"
        :rowData="rowData"
        :amountType="amountType"
      ></applyDetail>
    </dd-popup>
    <!-- 详情弹窗 -->
    <dd-popup
      v-if="dialogApplyBasicInfoVisible"
      width="60%"
      height="80%"
      title-name="预算详情"
      :show="dialogApplyBasicInfoVisible"
      @popup-close="dialogApplyBasicInfoVisible = false"
    >
      <applyBasicInfo slot="main" :rowData="rowData"></applyBasicInfo>
    </dd-popup>

    <dd-filter-drawer
      v-if="drawerVisible"
      width="30%"
      title-name="列筛选"
      :show="drawerVisible"
      :tableColumns="tableColumns"
      :columns="columnsList"
      @popup-close="drawerVisible = false"
      @handleColumnsChange="handleColumnsChange"
    >
    </dd-filter-drawer>
  </div>
</template>

<script>
import addEditPop from "./components/AddEditPop";
import applyDetail from "./components/applyDetail";
import applyBasicInfo from "./components/applyBasicInfo";
import { postApi, getApi, delApi, loadApi } from "@/api/request";
import { formatNumber } from "@/utils/number";
import { downloadBinaryFile } from "@/utils/index";
import qs from "qs";
export default {
  name: "personalBudgetApply",
  $dynamicRoute: {
    name: "项目预算申请",
    path: "/finance/personal/budgetApply",
    // meta: {
    //   keepAlive: true,
    // },
  },
  components: { addEditPop, applyDetail, applyBasicInfo },
  data() {
    return {
      tableHeight: null,
      processInstanceId: null,
      loading: false,
      id: null,
      // 页码
      current: 1,
      // 每页的数据条数
      size: 10,
      total: 0,
      addEdit: {
        id: null,
        type: "",
      }, //子组件传值
      dialogAddEditVisible: false,
      dialogDetailVisible: false,
      dialogApplyBasicInfoVisible: false,
      drawerVisible: false,
      addEditTitle: "",
      applyDetailTitle: "",
      projectId: "",
      type: "addOredit",
      state: null,
      rowData: null,
      // 表格选择数据集合
      multipleSelection: [],
      // 接收表单返回数据
      formData: {},
      // 条件查询表单配置配置
      formList: [
        {
          label: "编号",
          value: "code",
          inputType: "text",
          placeholderText: "请输入编号",
        },
        {
          label: "项目名称",
          value: "projectId",
          inputType: "select",
          optionsList: [],
          filterable: "filterable",
          placeholderText: "请选择或搜索项目名称",
          optionsListConfig: {
            name: "projectName",
            code: "projectId",
          },
        },
        {
          label: "项目编号",
          value: "projectId",
          inputType: "select",
          optionsList: [],
          filterable: "filterable",
          placeholderText: "请选择或搜索项目编号",
          optionsListConfig: {
            name: "projectCode",
            code: "projectId",
          },
        },
        {
          label: "单据状态",
          value: "state",
          inputType: "select",
          optionsList: [],
          placeholderText: "请选择或搜索单据状态",
        },
        {
          label: "申请日期",
          value: "applyDate",
          inputType: "date",
          dateType: "daterange",
        },
        {},
        {},
      ],
      // 表格头部配置数据
      tableColumns: [
        {
          title: "编号",
          key: "code",
          align: "center",
        },
        {
          title: "项目名称",
          key: "projectName",
          align: "center",
          width: 350,
          overflow: true,
        },
        {
          title: "项目编号",
          key: "projectCode",
          align: "center",
        },
        // {
        //   title: "总金额(元)",
        //   key: "totalAmount",
        //   align: "center",
        //   href: [
        //     {
        //       method: (row) => {
        //         this.handleDetail(row);
        //       },
        //     },
        //   ],
        // },
        {
          title: "单据状态",
          key: "stateName",
          align: "center",
        },
        {
          title: "节点",
          key: "taskFlag",
          align: "center",
          width: "150px",
          overflow: true,
        },
        {
          title: "申请日期",
          key: "applyDate",
          align: "center",
        },
        {
          title: "详情",
          align: "center",
          event: [
            {
              label: "查看详情",
              state: "default",
              method: (row) => {
                this.handleDetailInfo(row);
              },
            },
          ],
        },
      ],
      columnsList: [], //筛选列表格头部配置
      // 表格数据集合
      tableDataSources: [],
      // 是否显示删除确认弹框
      autoConfirm: false,
      // 选择符合删除条件数据id集合
      delDataArr: [],
    };
  },
  created() {},
  mounted() {
    // 自动计算table高度
    this.$nextTick(() => {
      this.tableHeight = this.autoTableHeight(0);
    });
    this.columnsList = [...this.tableColumns];
    this.getDataList();
    setTimeout(() => {
      this.getConditionData();
    }, 500);
  },
  methods: {
    //条件查询表单--下拉选择数据填充（optionsList）
    setOPtionsList(formList, options) {
      formList.forEach((item) => {
        if (options) {
          if (item.value == options.value) {
            item.optionsList = options.optionsList;
          }
        }
      });
    },
    //条件数据查询
    getConditionData() {
      //获取项目信息
      getApi(`/project/prj/get/list/all`).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          let options = {
            value: "projectId",
            optionsList: data.data,
          };
          this.projectTree = data.data;
          this.setOPtionsList(this.formList, options);
        }
      });
      postApi(
        `/finance/dict/list`,
        qs.stringify({ typeCode: "xmyssqdzt" })
      ).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          data.data = data.data.filter((item) => {
            return item.code !== "shtg";
          });
          this.formList.forEach((item) => {
            if (item.value == "state") {
              item.optionsList = data.data;
            }
          });
        }
      });
    },
    // 预算申请列表请求
    getDataList() {
      this.loading = true;
      let { formData, current, size } = this;
      let params = {
        ...formData,
        current,
        size,
      };
      postApi(`/finance/apply/list`, params).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          data.data.records.forEach((item) => {
            item.totalAmount = formatNumber(item.totalAmount);
          });
          this.tableDataSources = data.data.records;
          this.total = data.data.total;
          this.loading = false;
        }
      });
    },
    handleSubmit(type, formName) {
      let flag = false;
      let { tabArr } = this.$refs.addEditPop;

      this.$refs.addEditPop.$refs[formName].$refs[formName].validate(
        (valid) => {
          if (valid) {
            flag = true;
          }
        }
      );
      if (!flag) {
        return;
      }
      let oneTypeVOList = [];
      let amount = 0;
      tabArr.forEach((item) => {
        item.children.forEach((val) => {
          amount += val.amount;
        });
        oneTypeVOList.push(item.children[0]);
      });
      if (type == 1 && amount == 0) {
        this.$message.warning("请填写预算费用明细");
        return;
      }
      if (type == 1 && !this.$refs.addEditPop.fileListParam.length) {
        this.$message.warning("请上传电子版的预算明细");
        return;
      }
      let formBasicData = this.$refs.addEditPop.$refs[formName].formData;
      let params = {
        ...formBasicData,
        applierId: sessionStorage.getItem("userId"),
        deptId: sessionStorage.getItem("depId"),
        makerId: sessionStorage.getItem("userId"),
        orgId: sessionStorage.getItem("orgId"),
        orgName: sessionStorage.getItem("orgName"),
        oneTypeVOList: oneTypeVOList,
        prjBudgetApplyAttachFileList: this.$refs.addEditPop.fileListParam,
      };
      if (this.addEdit.id) {
        params.id = this.addEdit.id;
      }
      postApi(`/finance/apply/save/${type}`, params).then((res) => {
        if (res.data.code == 0) {
          if (type == 0) {
            this.$message.success("保存成功！");
          } else {
            this.$message.success("提交成功！");
          }
          this.dialogAddEditVisible = false;
          this.getDataList();
        }
      });
    },
    // 预算详情
    handleDetailInfo(row) {
      this.rowData = row;
      this.dialogApplyBasicInfoVisible = true;
    },
    // 总金额（预算明细)
    handleDetail(val) {
      this.applyDetailTitle = "预算明细";
      this.rowData = val.id;
      this.projectId = val.projectId;
      this.dialogDetailVisible = true;
      this.amountType = "totalAmount";
    },
    handleColumnsChange(val) {
      let list = new Set();
      this.tableColumns.map((item) => {
        val.map((itm) => {
          if (item.event || item.key == itm.key) {
            list.add(item);
          }
        });
      });
      this.columnsList = [...list];
    },
    handleFileExport() {
      let downKeyList = [];
      this.columnsList.map((item) => {
        if (!item.event) {
          downKeyList.push({ code: item.key, name: item.title });
        }
      });
      let ids = [];
      this.multipleSelection.map((item) => {
        ids.push(item.id);
      });
      let formData = {
        ...this.formData,
        pageNumber: 1,
        pageSize: 99999,
      };
      let params = {
        formData,
        ids,
        downKeyList,
      };
      loadApi(`/finance/apply/front/export`, params).then((res) => {
        let { data } = res;
        downloadBinaryFile(
          data,
          decodeURIComponent(
            res.headers["content-disposition"].split(";")[1].split("=")[1]
          )
        );
      });
    },

    /**
     * @description: 条件查询表单点击事件
     * @return {*}
     * @param {*} obj 表单参数
     */
    handleSearchEvent(obj) {
      this.$refs.dTableRef.$refs.baseTable.clearSelection();
      this.formData = obj;
      this.pageNumber = 1;
      this.getDataList();
    },
    handleAddEdit(type) {
      // type 0: 新建；1：编辑
      if (type == 0) {
        this.addEdit.id = null;
        this.addEditTitle = "添加项目预算单";
        this.addEdit.type = "add";
        this.dialogAddEditVisible = true;
      } else {
        if (this.multipleSelection.length !== 1) {
          this.$message.warning("请选择一条数据！");
          return;
        }
        // dtj: 项目预算状态 待提交 状态编码
        if (
          this.multipleSelection[0].state !== "dtj" &&
          this.multipleSelection[0].state !== "yth-2"
        ) {
          this.$message.warning("只能选择待提交或已退回状态数据编辑！");
          return;
        }
        this.addEdit.id = this.multipleSelection[0].id;
        this.addEdit.type = "edit";
        this.addEditTitle = "编辑项目预算单";
        this.dialogAddEditVisible = true;
      }
    },
    handleDelete() {
      var idArr = [];
      if (this.multipleSelection.length < 1) {
        this.$message.warning("请选择要删除的数据！");
        return;
      }
      for (var i = 0; i < this.multipleSelection.length; i++) {
        if (
          this.multipleSelection[i].state != "dtj" &&
          this.multipleSelection[i].state != "yth-2"
        ) {
          this.$message.warning("只能选择待提交或已退回状态数据删除！");
          return;
        } else {
          idArr.push(this.multipleSelection[i].id);
        }
      }
      var id = { ids: idArr.join() };
      this.$confirm("是否确定删除预算申请？", "提示", {
        confirmButtonClass: "btnsearch",
        confirmButtonText: "确认",
        cancelButtonClass: "btnreset",
        cancelButtonText: "取消",
        showClose: true,
        type: "warning",
      })
        .then(() => {
          postApi(`/finance/apply/deleteApply`, id)
            .then((res) => {
              let { data } = res;
              if (data.code == 0) {
                this.$message({
                  type: "success",
                  message: "删除成功!",
                });
                this.getDataList();

                this.$refs.dTableRef.$refs.baseTable.clearSelection();
              }
            })
            .catch(() => {
              this.$message.error("请求出错");
            });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 格式化表格单据状态
    getState(cellValue) {
      if (cellValue == "dtj") {
        return "待提交";
      } else if (cellValue == "shz") {
        return "审核中";
      } else if (cellValue == "shtg") {
        return "审核通过";
      } else if (cellValue == "shbtg") {
        return "审核不通过";
      }
    },
    // 计算表格合计行函数
    getSummaries(param) {
      const { columns, data } = param;
      let sums = [param];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "合计";
          return;
        } else if (column.property == "totalAmount") {
          const values = data.map((item) => Number(item[column.property]));
          if (!values.every((value) => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr);
              if (!isNaN(value)) {
                return prev + curr;
              } else {
                return prev;
              }
            }, 0);
            sums[index] += "";
          }
        }
      });
      let list = [...sums];
      list.map((item, index) => {
        if (!isNaN(+item)) {
          sums[index] = formatNumber(sums[index]);
        }
      });
      return sums;
    },
    // 表格多选事件
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    /**
     * @description: 分页改变事件请求刷新数据列表
     * @return {*}
     * @param {*} pageSize 每页显示条数
     * @param {*} pageNumber 第几页
     */
    handlePageChange(pageSize, pageNumber) {
      this.size = pageSize;
      this.current = pageNumber;
      this.getDataList();
    },
    // 弹框保存回调事件
    handlePopupSave() {
      this.$refs.addEditPop.submitOrSave();
    },
  },
};
</script>

<style lang="less" scoped></style>

<style>
/* .el-select-dropdown {
  max-width: 48%;
}

.el-select-dropdown li {
  height: auto;
}

.el-select-dropdown li span {
  white-space: normal;
  word-break: break-all;
  word-wrap: break-word;
} */
</style>
