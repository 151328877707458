<template>
  <div class="main-container">
    <dd-card title="条件查询" spacer>
      <dd-search-form
        :form-config="formList"
        @search-click="handleSearchEvent"
        ref="expenseClaimsListSearchForm"
      ></dd-search-form>
    </dd-card>
    <dd-card title="费用报账单列表" :height="tableHeight">
      <dd-table
        ref="reportBillTable"
        :columns="columnsList"
        :data="tableDataSources"
        :loading="loading"
        border
        show-page
        @select-change="handleSelectionChange"
        :summary-method="getSummaries"
        show-summary
      >
        <template #table-btn>
          <dd-button type="primary" icon="add" size="medium" @click="handleAdd"
            >新增</dd-button
          >
          <dd-button
            type="success"
            icon="import"
            size="medium"
            @click="handleBatchSubmit"
            >提交</dd-button
          >
          <dd-button
            type="danger"
            size="medium"
            icon="delete"
            :autoConfirm="autoConfirm"
            @click="handleDelete"
            >删除</dd-button
          >
        </template>
        <template #table-btn-right>
          <el-button
            icon="el-icon-s-operation"
            circle
            type="info"
            @click="drawerVisible = true"
            size="small"
            title="列筛选"
          ></el-button>
          <!-- <el-button
            icon="el-icon-download"
            circle
            type="info"
            @click="handleFileExport"
            size="small"
            title="下载"
          ></el-button> -->
        </template>
        <template #table-pagination>
          <dd-pagination
            :total="total"
            :page-size="size"
            :page-number="current"
            @page-change="handlePageChange"
            align="right"
          >
          </dd-pagination>
        </template>
      </dd-table>
    </dd-card>
    <!-- 新增页面弹窗 -->
    <dd-popup
      width="70%"
      height="80%"
      showBtn
      :show="addPopupVisible"
      title-name="费用报账单"
      @popup-close="handleClose"
      @popup-save="handleSubmit(0)"
      @popup-submit="handleSubmit(1)"
      @popup-rest="handleRest()"
    >
      <add-pop
        v-if="addPopupVisible"
        slot="main"
        ref="addPop"
        :rowEditId="rowEditId"
      ></add-pop>
    </dd-popup>
    <!-- 审核页面弹窗 -->
    <dd-popup
      width="70%"
      height="80%"
      :showBtn="popupType == 'Audit' ? true : false"
      :saveBtn="false"
      :show="auditPopupVisible"
      :title-name="auditTitleName"
      @popup-close="auditPopupVisible = false"
    >
      <dd-button
        slot="button"
        type="primary"
        size="medium"
        @click="handleApplySubmit(0)"
        >确认</dd-button
      >
      <dd-button
        slot="button"
        type="warning"
        size="medium"
        @click="handleApplySubmit(1)"
        >退回</dd-button
      >
      <audit-pop
        v-if="auditPopupVisible"
        slot="main"
        ref="audit"
        :popupType="popupType"
        :rowAuditId="rowAuditId"
        :rowCheck="rowCheck"
      ></audit-pop>
    </dd-popup>
    <!-- 日志记录弹窗 -->
    <dd-popup
      width="65%"
      :show="logPopupVisible"
      title-name="日志"
      @popup-close="logPopupVisible = false"
    >
      <log-pop
        v-if="logPopupVisible"
        slot="main"
        ref="logPop"
        :rowLogId="rowLogId"
      ></log-pop>
    </dd-popup>
    <dd-filter-drawer
      v-if="drawerVisible"
      width="30%"
      title-name="列筛选"
      :show="drawerVisible"
      :tableColumns="tableColumns"
      :columns="columnsList"
      @popup-close="drawerVisible = false"
      @handleColumnsChange="handleColumnsChange"
    >
    </dd-filter-drawer>
  </div>
</template>

<script>
// 新增页面弹窗
import addPop from "./components/AddPop.vue";
// 审核页面弹窗
import AuditPop from "./components/AuditPop.vue";
// 日志记录弹窗
import LogPop from "./components/LogPop.vue";
import { postApi, getApi, delApi, loadApi } from "@/api/request";
import { mapState, mapActions } from "vuex";
import { downloadBinaryFile } from "@/utils/index";
export default {
  name: "reportBillApply",
  $dynamicRoute: {
    name: "报账单申请",
    path: "/finance/reportBill/apply",
    meta: {
      keepAlive: true,
    },
  },
  components: {
    addPop,
    LogPop,
    AuditPop,
  },
  computed: {
    ...mapState(["btnAuthorityList"]),
  },
  data() {
    return {
      tableHeight: null,
      loading: true,
      // 表格选择数据集合
      multipleSelection: [],
      // 接收表单返回数据
      formData: {},
      // 条件查询表单配置配置
      formList: [
        {
          label: "报账单号",
          value: "code",
          inputType: "text",
          placeholderText: "请输入报账单号",
        },
        {
          label: "项目名称",
          value: "projectId",
          inputType: "select",
          optionsList: [],
          filterable: true,
          placeholderText: "请选择或搜索项目名称",
          optionsListConfig: {
            name: "projectName",
            code: "projectId",
          },
        },
        {
          label: "项目编号",
          value: "projectId",
          inputType: "select",
          optionsList: [],
          filterable: true,
          placeholderText: "请选择或搜索项目编号",
          optionsListConfig: {
            name: "projectCode",
            code: "projectId",
          },
        },
        {
          label: "单据状态",
          value: "state",
          inputType: "select",
          optionsList: [],
          placeholderText: "请选择或搜索单据状态",
        },
        {
          label: "承担部门",
          value: "expenseBearDepId",
          inputType: "select",
          optionsList: [],
          defaultValue: "",
          filterable: true,
          optionsListConfig: {
            name: "name",
            code: "deptId",
          },
          placeholderText: "请选择或搜索费用承担部门",
        },
        {
          label: "时间段",
          value: "applyDate",
          inputType: "date",
          dateType: "daterange",
        },
        {},
      ],
      // 表格头部配置数据
      tableColumns: [
        {
          title: "报账单号",
          key: "code",
          align: "center",
          width: 150,
        },
        {
          title: "项目名称",
          key: "projectName",
          align: "center",
          overflow: true,
          width: 320,
        },
        {
          title: "项目编号",
          key: "projectCode",
          align: "center",
          overflow: true,
        },
        {
          title: "报账人",
          key: "createBy",
          align: "center",
        },
        {
          title: "金额(元)",
          key: "amount",
          align: "center",
        },
        {
          title: "核定金额(元)",
          key: "reviewAmount",
          align: "center",
          width: 120,
        },
        {
          title: "单据状态",
          key: "stateName",
          align: "center",
          width: 80,
        },
        {
          title: "费用承担部门",
          key: "expenseBearDepName",
          align: "center",
          overflow: true,
        },
        {
          title: "报账日期",
          key: "createTimeStr",
          align: "center",
        },
        {
          title: "操作",
          key: "file",
          align: "center",
          width: 270,
          event: [
            {
              label: "编辑",
              method: (row) => {
                this.handleRowEdit(row);
              },
              type: "warning",
              rules: (row) => {
                let show = false;
                if (row.state == "dtj-2" || row.state == "yth") {
                  show = true;
                } else {
                  show = false;
                }
                return show;
              },
            },
            {
              label: "提交",
              method: (row) => {
                this.handleRowSubmit(row);
              },
              type: "success",
              rules: (row) => {
                let show = false;
                if (row.state == "dtj-2" || row.state == "yth") {
                  show = true;
                } else {
                  show = false;
                }
                return show;
              },
            },
            {
              label: "删除",
              method: (row) => {
                this.handleRowDel(row);
              },
              type: "danger",
              rules: (row) => {
                let show = false;
                if (row.state == "dtj-2") {
                  show = true;
                } else {
                  show = false;
                }
                return show;
              },
            },
            {
              label: "查看",
              method: (row) => {
                this.handleRowCheck(row);
              },
            },
            {
              label: "日志",
              method: (row) => {
                this.handleRowLog(row);
              },
            },
          ],
        },
      ],
      // 表格数据集合
      tableDataSources: [],
      // 页码
      current: 1,
      // 每页的数据条数
      size: 10,
      total: 0,
      // 新增弹窗显隐
      addPopupVisible: false,
      // 审核弹窗显隐
      auditPopupVisible: false,
      //审核界面显示标题
      auditTitleName: "",
      // 日志弹窗显隐
      logPopupVisible: false,
      //编辑数据ID
      rowEditId: null,
      //是否为编辑界面标识
      rowEdit: false,
      //审核数据ID
      rowAuditId: null,
      rowCheck: null,
      //日志数据Id
      rowLogId: null,
      //查看/审核类型弹窗显示类型
      popupType: "",
      //批量删除数据id集合
      deleteIds: {},
      //删除确认显隐
      autoConfirm: false,
      drawerVisible: false,
      //筛选列表格头部配置
      columnsList: [],
    };
  },
  created() {},
  mounted() {
    // 自动计算table高度
    this.$nextTick(() => {
      this.tableHeight = this.autoTableHeight(0);
    });
    this.columnsList = [...this.tableColumns];
    //初始化报账单列表
    this.getDataList();
    setTimeout(() => {
      this.getConditionData();
    }, 500);
  },
  methods: {
    //条件查询表单--下拉选择数据填充（optionsList）
    setOPtionsList(formList, options) {
      formList.forEach((item) => {
        if (options) {
          if (item.value == options.value) {
            item.optionsList = options.optionsList;
          }
        }
      });
    },
    // 条件数据查询
    getConditionData() {
      // 获取项目信息
      getApi(`/project/prj/list/all`).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          let options = {
            value: "projectId",
            optionsList: data.data,
          };
          this.setOPtionsList(this.formList, options);
        }
      });
      //获取部门数据
      postApi(`/admin/dept/getdeptlistbyorgid`, {
        orgId: sessionStorage.getItem("orgId"),
      }).then((res) => {
        let { data } = res;
        // this.depList = data.data;
        if (data.code == 0) {
          let options = {
            value: "expenseBearDepId",
            optionsList: data.data,
          };
          this.setOPtionsList(this.formList, options);
        }
      });
      // 获取单据状态字典
      getApi(`/finance/claims/state`).then((res) => {
        let state = [];
        let data = res.data;
        for (let key in data) {
          state.push(data[key]);
        }
        this.formList.forEach((item) => {
          if (item.value == "state") {
            item.optionsList = state;
          }
        });
      });
    },
    //获取报账单列表
    getDataList() {
      let { formData, current, size } = this;
      this.loading = true;
      postApi(`/finance/claims/list/${current}/${size}`, formData).then(
        (res) => {
          let { data } = res;
          if (data.code == 0 && data.data) {
            if (data.data.records && data.data.records.length > 0) {
              data.data.records.forEach((item) => {
                if (item.amount) {
                  item.amount = parseFloat(item.amount).toFixed(2);
                }
                if (item.reviewAmount) {
                  item.reviewAmount = parseFloat(item.reviewAmount).toFixed(2);
                }
              });
            }
            this.total = data.data.total;
            this.tableDataSources = data.data.records;
            this.clearTableSelection();
            this.loading = false;
          }
        }
      );
    },
    //审核界面---确认（0）或者退回（1）事件
    handleApplySubmit(type) {
      let description = "";
      let multipleSelection = [];
      if (type == 1) {
        description =
          this.$refs.audit.$refs.reportBillNoteForm.formData.description;
        if (description == "" || description == null) {
          this.$message.warning("请先填写退回原因");
          return;
        }
      } else {
        description =
          this.$refs.audit.$refs.reportBillNoteForm.formData.auditDescription;
      }
      let auditData = {};
      let finExpenseApplyList = [];
      let returnList = [];
      auditData.id = this.rowAuditId;
      auditData.reviewAmount = Number(
        this.$refs.audit.$refs.reportBillBasicForm.formData.reviewAmount
      );
      auditData.description = description;
      if (type == 1) {
        multipleSelection =
          this.$refs.audit.$refs.reportBillNoteForm.formData.multipleSelection;
        if (multipleSelection && multipleSelection.length > 0) {
          multipleSelection.forEach((item) => {
            returnList.push(item.id);
          });
        }
        auditData.returnList = returnList;
      }
      let dataSource = this.$refs.audit.tableDataSources;
      dataSource.forEach((item) => {
        item.children.forEach((itm) => {
          let child = { id: itm.id, reviewAmount: Number(itm.reviewAmount) };
          finExpenseApplyList.push(child);
        });
      });
      auditData.finExpenseApplyList = finExpenseApplyList;
      postApi(`/finance/claims/audit/${type}`, auditData).then((res) => {
        if (res.data.code == 0 && res.data.data) {
          this.$message.success(res.data.data);
          this.auditPopupVisible = false;
        } else {
          this.$message.error(res.data.data);
        }
        this.getDataList();
      });
    },
    //报账单列表查询事件
    handleSearchEvent(obj) {
      this.current = 1;
      this.size = 10;
      this.formData = obj;
      this.getDataList();
    },
    // 表格多选回调事件
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    //新增事件
    handleAdd() {
      this.rowEditId = null;
      this.addPopupVisible = true;
    },
    //新增报账单事件----保存0/提交1 (type)  ----编辑--(rowEdit--true/false)
    handleSubmit(type) {
      let finExpenseApplyDataSources = this.$refs.addPop.tableDataSources;
      let params = {
        finExpenseApplySheetNList: finExpenseApplyDataSources,
      };
      if (this.rowEdit) {
        params.id = this.rowEditId;
      }
      postApi(`/finance/claims/combine/${type}`, params).then((res) => {
        if (res.data.code == 0 && res.data.data) {
          this.$message.success(res.data.msg);
          this.addPopupVisible = false;
        } else {
          this.$message.error(res.data.msg);
        }
        this.getDataList();
      });
    },
    //批量提交
    handleBatchSubmit() {
      let submitSection = this.multipleSelection;
      let ids = [];
      if (submitSection.length < 1) {
        this.$message.warning("请至少选择一条数据进行提交!");
        return;
      } else {
        let isOk = true;
        submitSection.length &&
          submitSection.map((item) => {
            if (item.state != "dtj-2" && item.state != "yth") {
              isOk = false;
              this.$message.warning("只能选择待提交状态数据进行提交!");
              ids = [];
            }
            if (isOk) {
              ids.push(item.id);
            }
          });
      }
      if (ids && ids.length > 0) {
        let idsMap = {
          ids: ids,
        };
        postApi(`/finance/claims/submitOrCancel/${1}`, idsMap).then((res) => {
          let { data } = res;
          if (data.code == 0 && data.data == true) {
            this.$message.success("提交成功");
          }
          this.getDataList();
        });
      }
    },
    //新增报账单事件---报账单重置
    handleRest() {
      this.$refs.addPop.tableDataSources = [];
    },
    //新增报账单事件---报账单界面关闭（取消按钮事件）
    handleClose() {
      this.addPopupVisible = false;
      if (this.$refs.addPop) {
        this.$refs.addPop.tableDataSources = [];
      }
    },
    //报账单列表反审事件
    handleBack(row) {
      this.$confirm("是否对已选择数据进行反审操作？", "提示", {
        confirmButtonClass: "btnsearch",
        confirmButtonText: "确认",
        cancelButtonClass: "btnreset",
        cancelButtonText: "取消",
        showClose: true,
        type: "warning",
      }).then(() => {
        getApi(`/finance/claims/reverse/audit/${row.id}`).then((res) => {
          let { data } = res;
          if (data.code == 0) {
            this.$message.success(data.msg || data.data);
          }
          this.getDataList();
        });
      });
    },

    //报账单列表单行编辑
    handleRowEdit(row) {
      if (row.state != "dtj-2" && row.state != "yth") {
        this.$message.warning("只能选择待提交的数据进行编辑!");
        return;
      }
      this.rowEditId = row.id;
      this.rowEdit = true;
      this.addPopupVisible = true;
    },
    //报账单列表单行提交
    handleRowSubmit(row) {
      if (row.state != "dtj-2" && row.state != "yth") {
        this.$message.warning("只能选择待提交的数据!");
        return;
      }
      let ids = [];
      ids.push(row.id);
      let idsMap = {
        ids: ids,
      };
      postApi(`/finance/claims/submitOrCancel/1`, idsMap).then((res) => {
        let { data } = res;
        if (data.code == 0 && data.data == true) {
          this.$message.success("提交成功");
        }
        this.getDataList();
      });
    },
    //报账单列表单行取消
    handleRowCancel(row) {
      if (row.state != "dsh") {
        this.$message.warning("只能选择待审核的数据!");
        return;
      }
      let ids = [];
      ids.push(row.id);
      let idsMap = {
        ids: ids,
      };
      this.$confirm("是否对已选择数据进行取消操作？", "提示", {
        confirmButtonClass: "btnsearch",
        confirmButtonText: "确认",
        cancelButtonClass: "btnreset",
        cancelButtonText: "取消",
        showClose: true,
        type: "warning",
      }).then(() => {
        postApi(`/finance/claims/submitOrCancel/${0}`, idsMap).then((res) => {
          let { data } = res;
          if (data.code == 0 && data.data == true) {
            this.$message.success("取消成功");
          }
          this.getDataList();
        });
      });
    },
    //报账单列表单行审核
    handleRowAudit(row) {
      if (row.state != "dsh") {
        this.$message.warning("只能选择待审核的数据!");
        return;
      }
      this.auditTitleName = "审核";
      this.auditPopupVisible = true;
      this.popupType = "Audit";
      this.rowAuditId = row.id;
      this.rowCheck = row;
    },
    //报账单列表单行查看
    handleRowCheck(row) {
      this.popupType = "Check";
      this.auditTitleName = "详情";
      this.rowAuditId = row.id;
      this.rowCheck = row;
      this.auditPopupVisible = true;
    },
    //报账单列表单行日志
    handleRowLog(row) {
      this.logPopupVisible = true;
      this.rowLogId = row.id;
    },
    //报账单列表单行删除
    handleRowDel(row) {
      if (row.state != "dtj-2") {
        this.$message.warning("只能选择待提交的数据进行删除!");
        return;
      }
      let deleteIds = {
        ids: [],
      };
      deleteIds.ids.push(row.id);
      this.$confirm("确认删除该数据？", "提示", {
        confirmButtonClass: "btnsearch",
        confirmButtonText: "确认",
        cancelButtonClass: "btnreset",
        cancelButtonText: "取消",
        showClose: true,
        type: "warning",
      }).then(() => {
        this.handleConfirmDelete(deleteIds);
      });
    },
    //报账单列表批量删除事件
    handleDelete() {
      // this.autoConfirm = false;
      let deleteSection = this.multipleSelection;
      this.deleteIds.ids = [];
      if (deleteSection.length < 1) {
        this.$message.warning("请选择一条数据进行删除!");
        return;
      } else {
        let isOk = true;
        deleteSection.length &&
          deleteSection.map((item) => {
            if (item.state != "dtj-2" && item.state != "yth") {
              isOk = false;
              this.$message.warning("只能选择待提交或已退回状态数据进行删除!");
              this.deleteIds.ids = [];
            }
            if (isOk) {
              this.deleteIds.ids.push(item.id);
            }
          });
      }
      if (this.deleteIds.ids && this.deleteIds.ids.length > 0) {
        this.$confirm("是否确定删除费用报账单？", "提示", {
          confirmButtonClass: "btnsearch",
          confirmButtonText: "确认",
          cancelButtonClass: "btnreset",
          cancelButtonText: "取消",
          showClose: true,
          type: "warning",
        }).then(() => {
          this.handleConfirmDelete();
        });
      }
    },
    // 确认删除请求
    handleConfirmDelete(delIds) {
      let params = null;
      if (delIds) {
        params = delIds;
      } else {
        params = this.deleteIds;
      }
      postApi(`/finance/claims/delete`, params).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          this.$message.success(data.data || data.msg);
        }
        this.getDataList();
        this.autoConfirm = false;
      });
    },
    // 报账单列表分页切换事件
    handlePageChange(pageSize, pageNumber) {
      this.size = pageSize;
      this.current = pageNumber;
      this.getDataList();
    },
    handleColumnsChange(val) {
      let list = new Set();
      this.tableColumns.map((item) => {
        val.map((itm) => {
          if (item.event || item.key == itm.key) {
            list.add(item);
          }
        });
      });
      this.columnsList = [...list];
    },
    handleFileExport() {
      let downKeyList = [];
      this.columnsList.map((item) => {
        if (!item.event) {
          downKeyList.push({ code: item.key, name: item.title });
        }
      });
      let ids = [];
      this.multipleSelection.map((item) => {
        ids.push(item.id);
      });
      let formData = {
        ...this.formData,
        pageNumber: 1,
        pageSize: 99999,
      };
      let params = {
        formData,
        ids,
        downKeyList,
      };
      loadApi(`/finance/claims/front/export`, params).then((res) => {
        let { data } = res;
        downloadBinaryFile(
          data,
          decodeURIComponent(
            res.headers["content-disposition"].split(";")[1].split("=")[1]
          )
        );
      });
    },
    //数值合并
    getSummaries(param) {
      // this.$nextTick(() => {
      //   let { expenseApplyTable } = this.$refs.expenseApplyTable.$refs;
      //   expenseApplyTable.doLayout();
      // });
      const { columns, data } = param;
      const sums = [param];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "合计";
          return;
        } else if (index === 5 || index === 6) {
          const values = data.map((item) => Number(item[column.property]));
          if (!values.every((value) => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr);
              if (!isNaN(value)) {
                return prev + curr;
              } else {
                return prev;
              }
            }, 0);
            sums[index] += "";
          } else {
            sums[index] = " ";
          }
        } else {
          sums[index] = " ";
          return;
        }
      });
      sums[5] = parseFloat(sums[5]).toFixed(2);
      sums[6] = parseFloat(sums[6]).toFixed(2);
      return sums;
    },
    // 格式化单据状态
    getState(row) {
      switch (row) {
        case "dtj-2":
          return "待提交";
        case "dqr":
          return "待确认";
        case "yqr":
          return "已确认";
        case "yth":
          return "已退回";
        case "dsh":
          return "待审核";
      }
    },
    // 清除表格多选状态
    clearTableSelection() {
      this.$refs.reportBillTable.$refs.baseTable.clearSelection();
      this.multipleSelection = [];
    },
  },
};
</script>

<style lang="less" scoped></style>
