<template>
  <div class="main-container">
    <dd-card title="条件查询" spacer>
      <dd-search-form
        :form-config="formList"
        @search-click="handleSearchEvent"
        ref="ddSearchForm"
      ></dd-search-form>
    </dd-card>
    <dd-card title="申请单列表" :height="tableHeight">
      <dd-table
        :columns="columnsList"
        :data="tableDataSources"
        border
        ref="dTableRef"
        :summary-method="getSummaries"
        show-summary
        show-page
        @select-change="handleSelectionChange"
      >
        <template #table-btn>
          <dd-button
            type="primary"
            icon="add"
            size="medium"
            @click="handleApply(0)"
            >批量确认</dd-button
          >
          <dd-button
            type="success"
            icon="update"
            size="medium"
            @click="handleAddEdit(1)"
            >核定公里数</dd-button
          >
          <dd-button
            type="danger"
            size="medium"
            autoConfirm
            @click="handleApply(1)"
            >批量退回</dd-button
          >
        </template>
        <template #table-btn-right>
          <el-button
            icon="el-icon-s-operation"
            circle
            type="info"
            @click="drawerVisible = true"
            size="small"
            title="列筛选"
          ></el-button>
        </template>
        <template #table-pagination>
          <dd-pagination
            :total="total"
            :page-size="size"
            :page-number="current"
            @page-change="handlePageChange"
            align="right"
          >
          </dd-pagination>
        </template>
      </dd-table>
    </dd-card>
    <dd-popup
      v-if="dialogVisible"
      width="70%"
      height="80%"
      :title-name="popTitle"
      :showBtn="type == 'detail' ? false : true"
      :saveBtn="false"
      :show="dialogVisible"
      @popup-close="dialogVisible = false"
    >
      <dd-button
        slot="button"
        type="primary"
        size="medium"
        @click="submitOrSave(0)"
        >确认
      </dd-button>
      <dd-button
        slot="button"
        type="primary"
        size="medium"
        @click="submitOrSave(1)"
        >退回
      </dd-button>
      <AddUpdateDetailInfoPop
        slot="main"
        ref="applyPop"
        :type="type"
        :id="id"
      ></AddUpdateDetailInfoPop>
    </dd-popup>
    <dd-filter-drawer
      v-if="drawerVisible"
      width="30%"
      title-name="列筛选"
      :show="drawerVisible"
      :tableColumns="tableColumns"
      :columns="columnsList"
      @popup-close="drawerVisible = false"
      @handleColumnsChange="handleColumnsChange"
    >
    </dd-filter-drawer>
  </div>
</template>

<script>
import AddUpdateDetailInfoPop from "./components/AddUpdateDetailInfoPop";
import { formatNumber } from "@/utils/number";
import { downloadBinaryFile } from "@/utils/index";
import { postApi, getApi, loadApi } from "@/api/request";
import { mapState, mapActions } from "vuex";
import qs from "qs";
export default {
  name: "carApply",
  $dynamicRoute: {
    name: "项目用车申请单管理",
    path: "/personalCar/carApply/list",
  },
  components: { AddUpdateDetailInfoPop },
  data() {
    return {
      tableHeight: null,
      type: "",
      id: "",
      popTitle: "",
      drawerVisible: false,
      processInstanceId: null,
      loading: false,
      id: null,
      // 页码
      current: 1,
      // 每页的数据条数
      size: 10,
      total: 0,

      dialogVisible: false,
      // 表格选择数据集合
      multipleSelection: [],
      // 接收表单返回数据
      formData: {},
      // 条件查询表单配置配置
      formList: [
        {
          label: "申请编号",
          value: "applyCode",
          inputType: "text",
          placeholderText: "请输入申请编号",
        },
        {
          label: "项目名称",
          value: "proName",
          inputType: "select",
          optionsList: [],
          filterable: true,
          placeholderText: "请选择或搜索项目名称",
          optionsListConfig: {
            name: "projectName",
            code: "projectId",
          },
        },
        {
          label: "项目编号",
          value: "projectId",
          inputType: "select",
          optionsList: [],
          filterable: true,
          placeholderText: "请选择或搜索项目编号",
          optionsListConfig: {
            name: "projectCode",
            code: "projectId",
          },
        },
        {
          label: "申请人",
          value: "applierId",
          inputType: "select",
          optionsList: [],
          filterable: true,
          placeholderText: "请选择或搜索申请人",
          optionsListConfig: {
            name: "fullName",
            code: "userId",
          },
        },
        {
          label: "申请日期",
          value: "applyDate",
          inputType: "date",
          dateType: "daterange",
          format: "yyyy-MM-dd",
        },
        {
          label: "单据状态",
          value: "formStatus",
          inputType: "select",
          optionsList: [],
          filterable: true,
          placeholderText: "请选择或搜索单据状态",
          optionsListConfig: {
            name: "name",
            code: "code",
          },
        },
        {
          label: "上报状态",
          value: "reportStatus",
          inputType: "select",
          optionsList: [],
          filterable: true,
          placeholderText: "请选择或搜索上报状态",
          optionsListConfig: {
            name: "name",
            code: "code",
          },
        },
        {
          label: "上报日期",
          value: "reportMonth",
          inputType: "date",
          dateType: "monthrange",
          format: "yyyy-MM",
        },
        {
          label: "费用承担部门",
          value: "deptId",
          inputType: "select",
          optionsList: [],
          filterable: true,
          placeholderText: "请选择或搜索费用承担部门",
          optionsListConfig: {
            name: "name",
            code: "deptId",
          },
        },
        {},
      ],
      // 表格头部配置数据
      tableColumns: [
      {
          title: "用车补助类型",
          key: "carSubsidyTypeName",
          align: "center",
          width: 130,
          overflow: true,
        },
        {
          title: "申请编号",
          key: "applyCode",
          align: "center",
          width: 200,
          overflow: true,
        },
        {
          title: "项目名称",
          key: "projectName",
          align: "center",
          overflow: true,
        },
        {
          title: "项目编号",
          key: "projectCode",
          width: 150,
          align: "center",
        },
        {
          title: "申请日期",
          key: "applyTime",
          align: "center",
          width: 120,
        },
        {
          title: "申请人",
          key: "applierName",
          align: "center",
        },
        {
          title: "用车公里数",
          key: "useKilometer",
          align: "center",
          width: 100,
        },
        {
          title: "核定公里数",
          key: "approvedKilometer",
          align: "center",
          width: 100,
        },
        {
          title: "出发地",
          key: "departurePlace",
          align: "center",
          overflow: true,
        },
        {
          title: "目的地",
          key: "destinationPlace",
          align: "center",
          overflow: true,
        },
        {
          title: "用车事宜",
          key: "vehicleMatter",
          align: "center",
          overflow: true,
        },
        {
          title: "单据状态",
          key: "formStatus",
          align: "center",
        },
        {
          title: "上报状态",
          key: "reportStatus",
          align: "center",
          width: 120,
        },
        {
          title: "上报日期",
          key: "reportMonth",
          align: "center",
        },
        {
          title: "操作",
          align: "center",
          width: 150,
          event: [
            // {
            //   label: "审批",
            //   state: "default",
            //   method: (row) => {
            //     this.handleAddEdit(3, row);
            //   },
            // },
            {
              label: "查看详情",
              state: "default",
              method: (row) => {
                this.handleAddEdit(3, row);
              },
            },
          ],
        },
      ],
      columnsList: [], //筛选列表格头部配置
      // 表格数据集合
      tableDataSources: [],
      // 是否显示删除确认弹框
      autoConfirm: false,
      // 选择符合删除条件数据id集合
      delDataArr: [],
    };
  },
  created() {},
  mounted() {
    // 自动计算table高度
    this.$nextTick(() => {
      this.tableHeight = this.autoTableHeight(0);
    });
    this.getApplierData();
    //获取项目信息
    this.getProject();
    // 获取单据状态
    this.getStateList();
    this.getReportStateList();
    this.getApplyList();
    this.getConditionData(); //获取部门数据
    this.columnsList = [...this.tableColumns];
  },
  // computed: {
  //   ...mapState(["btnAuthorityList"]), //权限标识数组
  // },
  methods: {
    //条件查询表单--下拉选择数据填充（optionsList）
    setOPtionsList(formList, options) {
      formList.forEach((item) => {
        if (options) {
          if (item.value == options.value) {
            item.optionsList = options.optionsList;
          }
        }
      });
    },
    //获取部门数据
    getConditionData() {
      postApi(`/admin/dept/getdeptlistbyorgid`, {
        orgId: sessionStorage.getItem("orgId"),
      }).then((res) => {
        this.formList.forEach((item) => {
          if (item.value == "deptId") {
            item.optionsList = res.data.data;
          }
        });
      });
    },
    //申请人
    getApplierData() {
      postApi(`/admin/user/getver/list`, {}).then((res) => {
        let { data } = res;
        console.log('res', res);
        this.formList.forEach((item) => {
          if (item.value == "applierId") {
            item.optionsList = data.data;
          }
        });
      });
    },
    //获取项目信息
    getProject() {
      getApi(`/project/prj/list/all`).then((res) => {
        let { data } = res;
        console.log(data);
        if (data.code == 0) {
          this.formList.forEach((item) => {
            if (item.value == "proName" || item.value == "projectId") {
              item.optionsList = data.data;
            }
          });
        }
      });
    },
    // 单据状态下拉数据请求
    getStateList() {
      postApi(`/hr/hrdict/list?typeCode=sqddjzt`, {}).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          this.formList.forEach((item) => {
            if (item.value == "formStatus") {
              item.optionsList = data.data;
            }
          });
        }
      });
    },
    // 单据上报下拉数据请求
    getReportStateList() {
      postApi(`/hr/hrdict/list?typeCode=djsbzt`, {}).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          this.formList.forEach((item) => {
            if (item.value == "reportStatus") {
              item.optionsList = data.data;
            }
          });
          this.getApplyList();
        }
      });
    },
    // 预算申请列表请求
    getApplyList() {
      this.handleClose();
      // this.loading = true;
      let { formData, current, size } = this;
      let params = {
        ...formData,
        current,
        size,
      };
      postApi(`/hr/applysheet/page/audit`, params).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          this.tableDataSources = data.data.records;
          this.total = data.data.total;
          // this.loading = false;
        }
      });
    },
    // 预算详情
    handleDetailInfo(row) {
      this.dialogVisible = true;
    },
    // 新增弹框关闭事件
    handleClose() {
      this.dialogVisible = false;
      this.drawerVisible = false;
      this.$refs.dTableRef.$refs.baseTable.clearSelection();
      this.multipleSelection = [];
    },
    handleColumnsChange(val) {
      let list = new Set();
      this.tableColumns.map((item) => {
        val.map((itm) => {
          if (item.event || item.key == itm.key) {
            list.add(item);
          }
        });
      });
      this.columnsList = [...list];
    },
    handleFileExport() {
      let downKeyList = [];
      this.columnsList.map((item) => {
        if (!item.event) {
          downKeyList.push({ code: item.key, name: item.title });
        }
      });
      let ids = [];
      this.multipleSelection.map((item) => {
        ids.push(item.id);
      });
      let formData = {
        ...this.formData,
        pageNumber: 1,
        pageSize: 99999,
      };
      let params = {
        formData,
        ids,
        downKeyList,
      };
      loadApi(`/finance/apply/front/export`, params).then((res) => {
        let { data } = res;
        downloadBinaryFile(
          data,
          decodeURIComponent(
            res.headers["content-disposition"].split(";")[1].split("=")[1]
          )
        );
      });
    },
    /**
     * @description: 条件查询表单点击事件
     * @return {*}
     * @param {*} obj 表单参数
     */
    handleSearchEvent(obj) {
      this.formData = obj;
      console.log('obj', obj)
      this.pageNumber = 1;
      this.getApplyList();
    },
    handleAddEdit(type, row) {
      // type 1：编辑；3：详情
      if (type == 1) {
        if (this.multipleSelection.length !== 1) {
          this.$message.warning("请选择一条数据！");
          return;
        } else if (
          this.multipleSelection[0].formStatus === "退回" ||
          (this.multipleSelection[0].reportStatus !== "未上报" &&
            this.multipleSelection[0].reportStatus !== "已退回")
        ) {
          this.$message.warning("只能核定未退回或未上报数据！");
          return;
        }
        this.id = this.multipleSelection[0].id;
        this.type = "update";
        this.popTitle = "核定公里数";
        this.dialogVisible = true;
      } else {
        this.id = row.id;
        this.type = "detail";
        this.popTitle = "详情";
        this.dialogVisible = true;
      }
    },
    // type: 0 审批; 1 退回
    handleApply(type) {
      if (this.multipleSelection.length < 1) {
        this.$message.warning(`请选择要${type == 0 ? "审批" : "退回"}的数据`);
        return;
      }
      console.log(this.multipleSelection);

      // 所有操作只能针对未上报状态数据
      for (var i = 0; i < this.multipleSelection.length; i++) {
        if (
          this.multipleSelection[i].reportStatus === "待行政确认" ||
          this.multipleSelection[i].reportStatus === "行政已确认"
        ) {
          this.$message.warning("只能选择未上报数据！");
          return;
        }
      }

      // 批量审批 只能审批已提交状态数据
      if (0 === type) {
        for (var j = 0; j < this.multipleSelection.length; j++) {
          if (this.multipleSelection[j].formStatus !== "已提交") {
            this.$message.warning("只能选择已提交状态数据！");
            return;
          }
        }
      }

      // 批量退回 可以退回已提交/已核准状态数据
      if (1 === type) {
        for (var k = 0; k < this.multipleSelection.length; k++) {
          if (
            this.multipleSelection[k].formStatus !== "已提交" &&
            this.multipleSelection[k].formStatus !== "已核准"
          ) {
            this.$message.warning("只能选择已提交或已核准状态数据！");
            return;
          }
        }
      }

      let idArr = [];

      // 所有条件都满足时
      for (var m = 0; m < this.multipleSelection.length; m++) {
        idArr.push(this.multipleSelection[m].id);
      }

      var id = { ids: idArr.join() };
      this.$confirm(`是否确定${type == 0 ? "通过" : "退回"}私车申请?`, "提示", {
        confirmButtonClass: "btnsearch",
        confirmButtonText: "确认",
        cancelButtonClass: "btnreset",
        cancelButtonText: "取消",
        showClose: true,
        type: "warning",
      }).then(() => {
        postApi(`/hr/applysheet/approve/${type}`, id)
          .then((res) => {
            let { data } = res;
            if (data.code == 0) {
              if (data.data && data.data.indexOf("成功") != -1) {
                this.$message({
                  type: "success",
                  message: data.data,
                });
              } else {
                this.$message({
                  type: "warning",
                  message: data.data,
                });
              }

              this.getApplyList();
            }
          })
          .catch(() => {
            this.$message.error("请求出错");
          });
      });
    },
    // 计算表格合计行函数
    getSummaries(param) {
      const { columns, data } = param;
      let sums = [param];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "合计";
          return;
        } else if (
          column.property == "useKilometer" ||
          column.property == "approvedKilometer"
        ) {
          const values = data.map((item) => Number(item[column.property]));
          if (!values.every((value) => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr);
              if (!isNaN(value)) {
                return prev + curr;
              } else {
                return prev;
              }
            }, 0);
            sums[index] += "";
          }
        }
      });
      return sums;
    },
    // 表格多选事件
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    /**
     * @description: 分页改变事件请求刷新数据列表
     * @return {*}
     * @param {*} pageSize 每页显示条数
     * @param {*} pageNumber 第几页
     */
    handlePageChange(pageSize, pageNumber) {
      this.size = pageSize;
      this.current = pageNumber;
      this.getApplyList();
    },
    //核定公里数提交1保存0
    submitOrSave(type) {
      let formData = this.$refs.applyPop.$refs.baseFormDetail.formData;
      console.log(formData);
      if (type) {
        //退回
        let params = {
          ids: [this.id],
        };
        postApi(`/hr/applysheet/approve/${type}`, params)
          .then((res) => {
            let { data } = res;
            if (data.code == 0) {
              this.$message.success("退回成功！");
              this.dialogVisible = false;
              this.getApplyList();
            } else {
              this.$message.error(data.msg);
              this.$message({
                message: data.msg,
                type: "error",
                duration: 6000,
              });
            }
          })
          .catch((data) => {
            if (data.response && data.response.data && data.response.data.msg) {
              this.$message({
                message: data.response.data.msg,
                type: "error",
                duration: 6000,
              });
            } else {
              this.$message({
                message: data,
                type: "error",
                duration: 6000,
              });
            }
          });
      } else {
        //确认
        // if (!formData.approvedKilometer) {
        //   this.$message.warning("请输入核定公里数");
        //   return;
        // }
        this.$refs.applyPop.$refs.baseFormDetail.$refs.baseFormDetail.validate(
          (valid) => {
            if (valid) {
              let params = {
                id: this.id,
                type,
                approvedKilometer: Number(formData.approvedKilometer),
                approvedAmount: Number(formData.approvedAmount),
              };
              postApi(`/hr/applysheet/examine`, params)
                .then((res) => {
                  let { data } = res;
                  if (data.code == 0) {
                    this.$message.success("确认成功！");
                    this.dialogVisible = false;
                    this.getApplyList();
                  } else {
                    this.$message.error(data.msg);
                    this.$message({
                      message: data.msg,
                      type: "error",
                      duration: 6000,
                    });
                  }
                })
                .catch((data) => {
                  if (
                    data.response &&
                    data.response.data &&
                    data.response.data.msg
                  ) {
                    this.$message({
                      message: data.response.data.msg,
                      type: "error",
                      duration: 6000,
                    });
                  } else {
                    this.$message({
                      message: data,
                      type: "error",
                      duration: 6000,
                    });
                  }
                });
            } else {
              console.log("error submit!!");
              return false;
            }
          }
        );
      }
    },
  },
};
</script>

<style lang="less" scoped></style>

<style>
.el-select-dropdown {
  max-width: 48%;
}

.el-select-dropdown li {
  height: auto;
}

.el-select-dropdown li span {
  white-space: normal;
  word-break: break-all;
  word-wrap: break-word;
}
</style>
