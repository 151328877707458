<template>
  <div class="main-container">
    <dd-card title="角色列表" height="100%">
      <dd-table
        :columns="tableColumns"
        :data="tableData"
        isSort
        border
        :isSelection="false"
        show-page
        :loading="loading"
      >
        <template #table-btn>
          <dd-button type="primary" size="medium" icon="add" @click="handleAdd"
            >新增</dd-button
          >
        </template>
        <template #table-btn-right>
          <el-button
            icon="el-icon-s-operation"
            circle
            type="info"
            size="small"
            @click="drawerVisible = true"
            title="列筛选"
          ></el-button>
        </template>
        <template #table-pagination>
          <dd-pagination
            v-show="total > 0"
            :total="total"
            :page-size="size"
            :page-number="current"
            @page-change="handleCurrentChange"
            align="right"
          >
          </dd-pagination>
        </template>
      </dd-table>
    </dd-card>
    <dd-filter-drawer
      v-if="drawerVisible"
      width="30%"
      title-name="列筛选"
      :show="drawerVisible"
      :tableColumns="tableColumns"
      :columns="columnsList"
      @popup-close="drawerVisible = false"
      @handleColumnsChange="handleColumnsChange"
    >
    </dd-filter-drawer>
    <!-- 新增页面弹窗 -->
    <dd-popup
      width="30%"
      height="40%"
      showBtn
      :saveBtn="true"
      :cancelBtn="true"
      :show="addPopupVisible"
      :title-name="poptitleName"
      @popup-close="handleClose"
      @popup-save="handleSave('addPopForm', 'baseForm')"
    >
      <add-pop
        v-if="addPopupVisible"
        slot="main"
        ref="addPop"
        :dictType="dictType"
        :rowData="rowData"
      ></add-pop>
    </dd-popup>
    <!-- 查看用户弹窗 -->
    <dd-popup
      width="70%"
      height="80%"
      :showBtn="false"
      :show="userPopVisible"
      title-name="查看用户"
      @popup-close="handleClose"
    >
      <user-pop
        v-if="userPopVisible"
        slot="main"
        ref="userPop"
        :roleId="rowData.roleId"
      ></user-pop>
    </dd-popup>
    <!-- 权限弹窗 -->
    <dd-popup
      width="30%"
      height="60%"
      showBtn
      :saveBtn="false"
      :cancelBtn="true"
      :show="permissionPopupVisible"
      title-name="分配权限"
      @popup-close="handleClose"
    >
      <dd-button
        slot="button"
        type="primary"
        size="medium"
        @click="handleUpdatePermession"
        >更新</dd-button
      >
      <el-tabs
        slot="main"
        v-model="activeName"
        type="card"
        @tab-click="handleTabClick"
      >
        <el-tab-pane label="菜单权限" name="1">
          <el-tree
            v-if="permissionPopupVisible"
            ref="menuTree"
            :data="menuTreeData"
            :check-strictly="false"
            :props="defaultProps"
            :filter-node-method="filterNode"
            :default-checked-keys="treeCheckedKeys"
            node-key="id"
            highlight-current
            show-checkbox
            default-expand-all
          />
        </el-tab-pane>
        <el-tab-pane label="数据权限" name="2">
          <el-tree
            v-if="permissionPopupVisible"
            ref="dataTree"
            :data="dataTreeData"
            :check-strictly="false"
            :props="defaultProps"
            :filter-node-method="filterNode"
            :default-checked-keys="dataCheckedKeys"
            node-key="id"
            highlight-current
            show-checkbox
            default-expand-all
          />
        </el-tab-pane>
        <el-tab-pane label="移动端菜单权限" name="3">
          移动端菜单权限
        </el-tab-pane>
        <el-tab-pane label="移动端数据权限" name="4">
          移动端数据权限
        </el-tab-pane>
      </el-tabs>
    </dd-popup>
  </div>
</template>

<script>
import { getApi, delApi, postApi, putApi } from "@/api/request";
// 新增页面弹窗
import addPop from "./components/AddPop.vue";
import userPop from "./components/UserPop.vue";
export default {
  name: "role",
  $dynamicRoute: {
    name: "角色管理",
    path: "/admin/role/index",
  },
  components: {
    addPop,
    userPop,
  },
  computed: {},
  data() {
    return {
      drawerVisible: false,
      loading: true,
      tableColumns: [
        {
          title: "角色名称",
          key: "roleName",
          align: "center",
        },
        {
          title: "角色标识",
          key: "roleCode",
          align: "center",
        },
        {
          title: "角色描述",
          key: "roleDesc",
          align: "center",
        },
        {
          title: "创建时间",
          key: "createTime",
          align: "center",
        },
        {
          title: "操作",
          key: "handle",
          align: "center",
          width: 360,
          event: [
            {
              show: "true",
              label: "权限",
              icon: "el-icon-plus",
              method: (row) => {
                this.handleAddPermission(row);
              },
              type: "primary",
            },
            {
              show: "true",
              label: "编辑",
              icon: "el-icon-edit",
              method: (row) => {
                this.handleRowEditor(row);
              },
              type: "warning",
            },
            {
              show: "true",
              label: "删除",
              icon: "el-icon-delete",
              method: (row) => {
                this.handleRowDel(row);
              },
              type: "danger",
            },
            {
              show: "true",
              label: "查看用户",
              icon: "el-icon-view",
              method: (row) => {
                this.handleViewUser(row);
              },
              type: "info",
            },
          ],
        },
      ],
      tableData: [],
      current: 1,
      size: 20,
      total: null,
      dictType: [],
      addPopupVisible: false,
      rowData: null,
      poptitleName: "新增",
      permissionPopupVisible: false,
      userPopVisible: false,
      menuTreeData: [],
      dataTreeData: [],
      treeCheckedKeys: [],
      dataCheckedKeys: [],
      defaultProps: {
        children: "children",
        label: "name",
        value: "id",
      },
      activeName: "1",
    };
  },
  created() {},
  mounted() {
    this.getTableData();
  },
  methods: {
    // 获取表格列表数据
    getTableData() {
      let { current, size } = this;
      let params = {
        current,
        size,
      };
      this.loading = true;
      getApi(`/admin/role/page`, params).then((res) => {
        let { data } = res;
        if (data && data.code == 0) {
          this.loading = false;
          this.tableData = data.data.records;
          this.total = data.data.total;
        }
      });
    },
    // 新增按钮事件
    handleAdd() {
      this.addPopupVisible = true;
      this.poptitleName = "新增";
    },
    // 新增弹框关闭事件
    handleClose() {
      this.rowData = null;
      this.addPopupVisible = false;
      this.permissionPopupVisible = false;
      this.userPopVisible = false;
    },
    // 权限分配按钮事件
    handleAddPermission(row) {
      this.rowData = row;
      this.getPermissionTree(row);
    },
    handleTabClick(tab, event) {
      this.activeName = tab.name;
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },
    //   获取权限主键集合
    getPermissionTree(row) {
      getApi(`/admin/menu/scopetree/${row.roleId}`).then((res) => {
        let { data } = res;
        if (data && data.code == 0) {
          this.treeCheckedKeys = data.data.treeMenuKeys;
          this.dataCheckedKeys = data.data.scopetreeMenuKeys;
        }
        this.getKeyTree();
      });
    },
    //   获取权限树
    getKeyTree() {
      getApi(`/admin/menu/scopetree`).then((res) => {
        let { data } = res;
        if (data && data.code == 0) {
          this.menuTreeData = data.data.treeMenu;
          this.dataTreeData = data.data.scopetreeMenu;
          // 解析出所有的太监节点
          this.treeCheckedKeys = this.resolveAllEunuchNodeId(
            this.menuTreeData,
            this.treeCheckedKeys,
            []
          );
          this.dataCheckedKeys = this.resolveAllEunuchNodeId(
            this.dataTreeData,
            this.dataCheckedKeys,
            []
          );
          this.permissionPopupVisible = true;
        }
      });
    },
    /**
     * 解析出所有的太监节点id
     * @param json 待解析的json串
     * @param idArr 原始节点数组
     * @param temp 临时存放节点id的数组
     * @return 太监节点id数组
     */
    resolveAllEunuchNodeId(json, idArr, temp) {
      for (let i = 0; i < json.length; i++) {
        const item = json[i];
        // 存在子节点，递归遍历;不存在子节点，将json的id添加到临时数组中
        if (item.children && item.children.length !== 0) {
          this.resolveAllEunuchNodeId(item.children, idArr, temp);
        } else {
          temp.push(idArr.filter((id) => id === item.id));
        }
      }
      return temp;
    },
    //   权限更新事件
    handleUpdatePermession() {
      let menuIds = "";
      if (this.activeName == 1) {
        menuIds = this.$refs.menuTree
          .getCheckedKeys()
          .join(",")
          .concat(",")
          .concat(this.$refs.menuTree.getHalfCheckedKeys().join(","));
      }
      if (this.activeName == 2) {
        menuIds = this.$refs.dataTree
          .getCheckedKeys()
          .join(",")
          .concat(",")
          .concat(this.$refs.dataTree.getHalfCheckedKeys().join(","));
      }
      let params = {
        menuIds: menuIds,
        roleId: this.rowData.roleId,
        typeScopeFlag: this.activeName,
      };
      putApi(`/admin/role/menu`, params).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          this.$message.success("更新成功");
          this.$bus.$emit("refreshMenu", true);
          this.handleClose();
          this.getTableData();
        }
      });
    },
    // 表格行内编辑事件
    handleRowEditor(row) {
      this.addPopupVisible = true;
      this.rowData = row;
      this.poptitleName = "编辑";
    },
    //列表单行删除
    handleRowDel(row) {
      this.$confirm(`确认删除该数据吗？`, "提示", {
        confirmButtonClass: "btnsearch",
        confirmButtonText: "确认",
        cancelButtonClass: "btnreset",
        cancelButtonText: "取消",
        showClose: true,
        type: "warning",
      }).then(() => {
        this.handleConfirmDelete(row.roleId);
      });
    },
    // 确认删除请求
    handleConfirmDelete(delId) {
      delApi(`/admin/role/${delId}`).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          this.$message.success("删除成功");
        }
        this.getTableData();
      });
    },
    //   查看用户
    handleViewUser(row) {
      this.rowData = row;
      this.userPopVisible = true;
    },
    /* 分页页码回调 */
    handleCurrentChange(size, number) {
      this.current = number;
      this.size = size;
      this.getTableData();
    },
    // 新增确认事件
    handleSave(formName, formNameBase) {
      let flag = false;
      this.$refs.addPop.$refs[formName].$refs[formNameBase].validate(
        (valid) => {
          if (valid) {
            flag = true;
          }
        }
      );
      let params = this.$refs.addPop.$refs[formName].formData;
      if (flag) {
        if (this.rowData != null) {
          params.roleId = this.rowData.roleId;
          putApi(`/admin/role`, params).then((res) => {
            let { data } = res;
            if (data.code == 0) {
              this.$message.success("修改成功");
              this.handleClose();
              this.getTableData();
            }
          });
        } else {
          postApi(`/admin/role`, params).then((res) => {
            let { data } = res;
            if (data.code == 0) {
              this.$message.success("保存成功");
              this.handleClose();
              this.getTableData();
            }
          });
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.slot-main {
  overflow: hidden !important;
}

.el-tabs {
  height: 100%;
}
/deep/ .el-tabs__content {
  overflow: auto;
  height: calc(100% - 56px);
}
</style>
