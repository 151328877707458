<template>
  <div class="container">
    <div class="allocation_top">
      <div class="top_box">
        <div class="top_box_item" v-for="(item, index) in topText" :key="index">
          <div class="label">{{ item.label }}：</div>
          <div class="value">{{ item.value }}</div>
        </div>
      </div>
      <!-- <dd-button
        slot="button"
        size="medium"
        type="primary"
        :disabled="pageProp.pageType == 'detail'"
        @click="viewInvoiceDetails()"
        >查看发票详情</dd-button
      > -->
    </div>
    <div class="table_box">
      <dd-table
        ref="baseTable_1"
        :columns="tableColumns"
        :data="tableData"
        show-summary
        :summary-method="getSummaries"
        @select-change="handleSelectionChange"
        border
        :isSelection="true"
        :loading="loading"
      >
        <template #table-btn>
          <dd-button type="primary" v-if="pageProp.pageType != 'detail'" icon="add" size="medium" @click="handleAdd"
            >添加金蝶合同
          </dd-button>
          <dd-button
            type="primary"
            v-if="pageProp.pageType != 'detail'"
            icon="delete"
            :disabled="pageProp.pageType == 'detail'"
            size="medium"
            @click="handleDelete"
            >移除
          </dd-button>
        </template>
      </dd-table>
    </div>

        <dd-popup v-if="addKingDeeVisible" width="78%" height="80%" showBtn inPopup :saveBtn="saveBtn" :submitBtn="false"
            :show="addKingDeeVisible" :title-name="addKingDeeTitle"
            @popup-close="handleClose">
            <dd-button  slot="button" size="medium" type="primary"
            @click="handleSubmit">确认</dd-button>
            <addKingDee :pageProp="pageProp" ref="addKingDee" slot="main"></addKingDee>
        </dd-popup>


        <dd-popup v-if="addEditDetailsVisible" width="78%" height="80%" showBtn inPopup :saveBtn="false" :submitBtn="false"
            :show="addEditDetailsVisible" title-name="发票详情"
            @popup-close="handleClose">
            <addEditDetails :pageProp="PageDetail" ref="addKingDee" slot="main"></addEditDetails>
        </dd-popup>
  </div>
</template>

<script>
import { getApi, delApi, postApi, putApi } from "@/api/request";
import addKingDee from './addKingDee.vue'
import addEditDetails from "./addEditDetails.vue";
export default {
  name: "allocation",
  props: ['pageProp'],
  components: {
    addKingDee, addEditDetails
  },
  data() {
    return {
      addEditDetailsVisible: false,
      PageDetail: {},
      addKingDeeVisible: false,
      loading: false,
      saveBtn: false,
      addKingDeeTitle: '添加金蝶合同',
      topText: [
        {
          label: "有效发票金额",
          value: 0,
        },
        {
          label: "已分配到合同的发票金额",
          value: 0,
        },
        {
          label: "待分配金额",
          value: 0,
        },
      ],
      tableColumns: [
        {
          title: "合同发票编码",
          key: "contractInvoiceCode",
          align: "center",
          overflow: true,
        },
        {
          title: "合同编码",
          key: "fnumber",
          align: "center",
          overflow: true,
        },
        {
          title: "合同名称",
          key: "fname",
          align: "center",
          overflow: true,
        },
        {
          title: "合同乙方",
          key: "fpartbname",
          align: "center",
          overflow: true,
        },
        {
          title: "项目名称",
          key: "prjName",
          align: "center",
          overflow: true,
        },
        {
          title: "金蝶合同总金额",
          key: "foriginalamount",
          align: "center",
          overflow: true,
        },
        {
          title: "金蝶合同已关联其他发票金额",
          key: "linedOtherInvoice",
          align: "center",
          overflow: true,
        },
        {
          title: "金蝶可关联本发票金额",
          key: "correlateTheInvoice",
          align: "center",
          overflow: true,
        },
        {
          title: "发票分配金额",
          key: "allotmentAmount",
          align: "center",
          overflow: true,
          input: [
                {
                  method: (value, row, index) => {
                    this.changeAllotmentAmount(value, row, index);
                  },
                  isEdit: () => {
                    let state = false;
                    if (this.pageProp.pageType == 'detail') {
                      state = true;
                    }
                    return state;
                  },
                  rules: (value) => {
                        let result = value.replace(/[^\d.]/g, '');
                        if (result.charAt(0) === '.') {
                          result = result.slice(1);
                        }
                        const parts = result.split('.');
                        if (parts.length > 2) {
                          result = parts.slice(0, 2).join('.');
                        }
                        if (parts.length > 1) {
                          result = `${parts[0]}.${parts[1].slice(0, 2)}`;
                        }
                        return result;
                      },
                },
              ],
        }
      ],
      tableData: [{}],
      selectTableSource: [],
    };
  },
  created() {
    console.log('this.pageProp', this.pageProp)
    if(this.pageProp.pageType == 'allocation' || this.pageProp.pageType == 'detail')
      this.pageProp.arr.forEach((item, index) => {
        this.topText[index].value = item 
    })
    this.getTableList();
  },
  mounted() {},
  methods: {
    getTableList() {
      getApi(`/contract/invoice/registration/allocate?id=${this.pageProp.id}`).then(res => {
        if(res.data.code == 0) {
          this.tableData = res.data.data || []
        }
      })
    },
    viewInvoiceDetails() {
      this.addEditDetailsVisible = true;
      this.PageDetail.id = this.pageProp.id;
      this.PageDetail.pageType = 'detail'
    },
    handleAdd() {
        this.pageProp.tableData = this.tableData;
        this.addKingDeeVisible = true
    },
    handleDelete() {
      if (this.selectTableSource.length == 0) {
        this.$message.warning("请先至少勾选一条数据");
        return false;
      }

      this.selectTableSource.forEach(item => {  
        const index = this.tableData.findIndex(row => row.id === item.id); // 假设我们以name作为唯一标识  
        if (index > -1) {  
          this.tableData.splice(index, 1);  
        }  
      });  
      // 清空已选择的项  
      this.selectTableSource = [];  
      this.$refs.baseTable_1.$refs.baseTable.clearSelection();
    },
    changeAllotmentAmount(value, row, index) {
      let allotmentAmountSum = 0;
      this.tableData.map(item => {
        allotmentAmountSum += item.allotmentAmount * 1
      })
      if(this.topText[0].value < allotmentAmountSum) {
        this.$message.warning('发票分配金额不能大于待分配金额')
        this.tableData[index].allotmentAmount = 0
        return false;
      }
      this.topText[1].value = allotmentAmountSum
      this.topText[2].value = (this.topText[0].value - allotmentAmountSum).toFixed(2);
    },
    handleSelectionChange(e) {
      this.selectTableSource = e;
    },
    handleClose() {
        this.addKingDeeVisible = false;
    },
    handleSubmit() {
      const ids = this.$refs.addKingDee.selection_kingDee.map(item => item.id)
      postApi(`/contract/invoice/registration/selected/purchase`, {id: this.pageProp.id, purchaseIds: ids}).then(res => {
        if(res.data.code == 0) {
          this.tableData = res.data.data.concat(this.tableData) ;
          this.$refs.addKingDee.$refs.baseTable_1.$refs.baseTable.clearSelection();
          this.addKingDeeVisible = false;
        }
      })
    },
    getSummaries(param) { // 自定义项表格合计方法
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "合计";
          return;
        } else if (column.property == "linedOtherInvoice" || column.property == "allotmentAmount"
        ) {
          //如果是经费（正常的加减法）
          if(data) {
            const values = data.map((item) => item[column.property] ? item[column.property] * 1 : 0);
              if (!values.every((value) => isNaN(value))) {
                sums[index] = values.reduce((prev, curr) => {
                  const value = Number(curr);
                  var sum = 0;
                  if (!isNaN(value)) {
                    sum = Number(Number(prev) + Number(curr)).toFixed(2);
                    return sum;
                  } else {
                    return prev;
                  }
                }, 0);
                sums[index] += " ";
              }
          }
          
        }
      });
      return sums
    },
    handleClose() {
      this.addKingDeeVisible = false
      this.addEditDetailsVisible = false;
    }
  },
};
</script>

<style lang="less" scoped>
.allocation_top {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.top_box {
  width: 70%;
  height: 35px;
  line-height: 35px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-weight: bold;
  font-size: 15px;
  color: #606266;
  .top_box_item {
    display: flex;
    align-items: center;
  }
}
.table_box {
  margin-top: 30px;
}

/deep/.el-input.is-disabled .el-input__inner {
  background-color: #fbfbfb;
  color: @defaultFontColor;
}
/deep/.el-textarea.is-disabled .el-textarea__inner {
  background-color: #fbfbfb;
  color: @defaultFontColor;
  resize: none;
}
// /deep/ .el-input__suffix {
//   display: none;
// }
/deep/ .el-range-editor.is-disabled {
  background-color: #fbfbfb;
  color: @defaultFontColor;
}
/deep/ .el-range-editor.is-disabled input {
  color: @defaultFontColor;
}
</style>