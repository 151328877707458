<template>
  <div class="container">
    <div class="verify-box">
      <div class="verify-label">确认意见</div>
      <el-input
        type="textarea"
        :rows="3"
        :placeholder="`${isVerify ? '暂无' : '请输入确认意见'}`"
        :disabled="isVerify"
        v-model="userOpinion"
      >
      </el-input>
    </div>
    <dd-card title="基本信息" class="base-form-collect-info">
      <dd-form
        :form-config="formList"
        :ref="formRef"
        :formRef="formRef"
        :flex="3"
        labelWidth="160px"
      ></dd-form>
      <dd-button
        v-if="this.type == 'add'"
        class="add-workload-btn"
        type="primary"
        icon="add"
        size="medium"
        @click="handleWorkloadDetail"
        >添加工作量明细</dd-button
      >
    </dd-card>
    <dd-card title="工作量明细">
      <dd-search-form
        :form-config="searchFormList"
        @search-click="handleSearchEvent"
        ref="ddSearchForm"
      ></dd-search-form>
      <dd-table
        :columns="columnsList"
        :data="tableDataSources"
        border
        :isSelection="false"
        ref="dTableRef"
        show-page
      >
        <template #table-pagination>
          <dd-pagination
            :total="total"
            :page-size="size"
            :page-number="current"
            @page-change="handlePageChange"
            align="right"
          >
          </dd-pagination>
        </template>
      </dd-table>
    </dd-card>
    <!-- 新增/修改/详情-->
    <dd-popup
      v-if="dialogVisible"
      width="80%"
      height="80%"
      :inPopup="true"
      :showBtn="false"
      :title-name="popTitle"
      :show="dialogVisible"
      @popup-close="handleClose"
    >
      <workloadDetailPop
        slot="main"
        ref="addPop"
        :standardWorkpackage="standardWorkpackage"
        :id="detailId"
        :type="type"
      ></workloadDetailPop>
    </dd-popup>
  </div>
</template>

<script>
import { postApi, getApi, loadApi } from "@/api/request";
import { formatDateTime } from "@/utils/date";
import { mapState, mapActions } from "vuex";
import workloadDetailPop from "../workloadDetailPop/index.vue";
export default {
  props: {
    type: "",
    isVerify: false,
    rowData: {},
    userOpinions: "",
  },
  components: { workloadDetailPop },
  computed: {
    ...mapState(["btnAuthorityList"]),
  },
  data() {
    return {
      userOpinion: "",
      detailId: "",
      dialogVisible: false,
      isWorkloadDetail: false,
      popTitle: "",
      standardWorkpackage: null,
      formData: {},
      formRef: "baseForm",
      workloadSumId: "",
      // 页码
      current: 1,
      // 每页的数据条数
      size: 10,
      total: 0,
      //筛选列表格头部配置
      columnsList: [
        {
          title: "区域",
          key: "areaName",
          align: "center",
          width: 200,
          overflow: true,
        },
        {
          title: "任务书或子任务编码",
          key: "assignCode",
          align: "center",
          width: 180,
          overflow: true,
        },
        {
          title: "任务书或子任务名称",
          key: "assignName",
          width: 180,
          align: "center",
          overflow: true,
        },
        {
          title: "工作包名称",
          key: "nodeName",
          align: "center",
          width: 180,
          overflow: true,
        },
        {
          title: "工号",
          key: "userCode",
          width: 180,
          align: "center",
        },
        {
          title: "姓名",
          key: "userName",
          align: "center",
          width: 180,
          overflow: true,
        },
        {
          title: "工作量",
          key: "workload",
          align: "center",
          width: 180,
          overflow: true,
        },
        {
          title: "工作量单位",
          key: "unitName",
          width: 180,
          align: "center",
        },
        {
          title: "操作",
          align: "center",
          fixed: "right",
          width: "100px",
          event: [
            {
              label: "查看详情",
              icon: "el-icon-view",
              state: "default",
              method: (row) => {
                this.handleAddEdit(row);
              },
            },
          ],
        },
      ],
      // 表格数据集合
      tableDataSources: [],
      searchFormList: [
        {
          label: "区域",
          value: "areaName",
          inputType: "text",
          placeholderText: "请输入区域名称",
          title: true,
        },
        {
          label: "任务书",
          value: "assignName",
          inputType: "text",
          placeholderText: "请输入任务书或子任务名称",
          title: true,
        },
        {
          label: "姓名",
          value: "userName",
          inputType: "text",
          placeholderText: "请输入姓名",
          title: true,
        },
      ],
      formList: [
        {
          label: "部门",
          value: "deptId",
          inputType: "select",
          optionsList: [],
          optionsListConfig: {
            name: "name",
            code: "deptId",
          },
          defaultValue: sessionStorage.getItem("depId"),
          placeholderText: "请选择部门",
          rules: [{ required: true, message: "请选择部门", trigger: "blur" }],
          change: this.handleChange,
        },
        {
          label: "所属年月",
          value: "reportMonth",
          inputType: "date",
          dateType: "month",
          format: "yyyy-MM",
          defaultValue: null,
          placeholderText: "请选择所属年月",
          rules: [
            { required: true, message: "请选择所属年月", trigger: "blur" },
          ],
        },
        // {
        //   label: "核算组",
        //   value: "accountingGroupId",
        //   inputType: "select",
        //   filterable: true,
        //   optionsList: [],
        //   optionsListConfig: {
        //     code: "id",
        //     name: "name",
        //   },
        //   defaultValue: "",
        //   placeholderText: "请选择核算组",
        //   rules: [{ required: true, message: "请选择核算组", trigger: "blur" }],
        // },
        {
          label: "统计工作量类型",
          value: "standardWorkpackageName",
          inputType: "select",
          filterable: true,
          optionsList: [],
          defaultValue: "",
          placeholderText: "请选择统计工作量类型",
          rules: [
            {
              required: true,
              message: "请选择统计工作量类型",
              trigger: "blur",
            },
          ],
        },
        {
          label: "起始时间点",
          value: "startTime",
          inputType: "date",
          dateType: "datetime",
          format: "yyyy-MM-dd HH:mm:ss",
          defaultValue: new Date(),
          placeholderText: "请选择起始时间点",
          rules: [
            { required: true, message: "请选择起始时间点", trigger: "blur" },
          ],
        },
        {
          label: "截止时间点",
          value: "deadLine",
          inputType: "date",
          dateType: "datetime",
          format: "yyyy-MM-dd HH:mm:ss",
          defaultValue: new Date(),
          placeholderText: "请选择截止时间点",
          rules: [
            { required: true, message: "请选择截止时间点", trigger: "blur" },
          ],
        },
      ],
    };
  },
  mounted() {
    this.getBaseInfo();
    // this.getAccountingList(sessionStorage.getItem("depId"));
    this.userOpinion = this.userOpinions;
    this.formList.forEach((item) => {
      item.disabled = true;
      item.rules = [];
    });
    this.getDetailInfo();
  },
  methods: {
    //编辑页面信息回显
    getDetailInfo() {
      let { current, size, formData } = this;
      let params = {
        performanceAccountingId:this.rowData.performanceAccountingId,
        type:this.rowData.type,
        ...formData
      }
      postApi(`/project/performanceaccounting/confirm/detail/${current}/${size}`,params).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          this.formList.forEach((item) => {
            item.defaultValue = `${data.data[item.value]}`;
          });
          this.tableDataSources = data.data.records.records;
          this.total = data.data.records.total;
        }
      });
    },
    //获取基本信息数据列表
    getBaseInfo() {
      postApi(`/admin/dept/getdeptlistbyorgid`, {
        orgId: sessionStorage.getItem("orgId"),
      }).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          let options = {
            value: "deptId",
            optionsList: data.data,
          };
          this.setOPtionsList(this.formList, options);
        }
      });
      postApi(`/project/prj/dict/list/all`, {
        typeCode: "PRJ_WORKLOAD_TYPE",
      }).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          this.formList.forEach((item) => {
            if (item.value == "isStandardWorkpackage") {
              item.optionsList = data.data;
            }
          });
        }
      });
    },
    // 核算组列表
    // getAccountingList(deptId) {
    //   getApi(`/project/accounting/list`, { deptId }).then((res) => {
    //     let { data } = res;
    //     if (data.code == 0) {
    //       let { formData } = this.$refs.baseForm;
    //       this.formList.forEach((item) => {
    //         if (item.value == "accountingGroupId") {
    //           item.optionsList = data.data;
    //         } else {
    //           item.defaultValue = formData[item.value];
    //         }
    //       });
    //     }
    //   });
    // },
    //添加工作量明细
    handleWorkloadDetail() {
      let { formData } = this.$refs.baseForm;
      let flag = false;
      this.$refs.baseForm.$refs.baseForm.validate((valid) => {
        if (valid) {
          flag = true;
        }
      });
      if (flag) {
        let params = {
          ...formData,
        };
        params.deadLine = formatDateTime(formData.deadLine);
        params.startTime = formatDateTime(formData.startTime);
        postApi(`/project/workloadsum/save/detail`, params).then((res) => {
          let { data } = res;
          if (data.code == 0) {
            this.isWorkloadDetail = true;
            this.workloadSumId = data.data;
            this.getWorkloadDetailList();
          }
        });
      }
    },
    handleSearchEvent(obj) {
      this.formData = obj;
      this.current = 1;
      this.getDetailInfo();
    },
    handlePageChange(size, current) {
      this.size = size;
      this.current = current;
      this.getDetailInfo();
    },
    //条件查询表单--下拉选择数据填充（optionsList）
    setOPtionsList(formList, options) {
      formList.forEach((item) => {
        if (options) {
          if (item.value == options.value) {
            item.optionsList = options.optionsList;
          }
        }
      });
    },
    handleChange(e, type) {
      // if (type == "deptId") {
      //   this.getAccountingList(e);
      // }
    },
    //开启明细列表弹窗
    handleAddEdit(row) {
      this.popTitle = row.standardWorkpackage
        ? "子任务模式-明细列表"
        : "wbs模式-明细列表";
      this.standardWorkpackage = row.standardWorkpackage;
      this.detailId = row.id;
      this.dialogVisible = true;
    },
    // 关闭明细列表弹窗
    handleClose() {
      this.dialogVisible = false;
    },
  },
};
</script>

<style lang='less' scoped>
.verify-box {
  margin: 10px;
  .verify-label {
    font-size: 16px;
    margin-bottom: 10px;
  }
}
.base-form-collect-info {
  position: relative;
  .add-workload-btn {
    position: absolute;
    right: 154px;
    bottom: 23px;
  }
}
/deep/ .el-table__header-wrapper {
  background-color: @tableHeaderBg !important;
}
/deep/ .el-table .has-gutter .el-table__cell {
  background-color: @tableHeaderBg;
  color: @defaultFontColor;
}
/deep/.el-input.is-disabled .el-input__inner {
  background-color: #fbfbfb;
  color: @defaultFontColor;
}
/deep/.el-textarea.is-disabled .el-textarea__inner {
  background-color: #fbfbfb;
  color: @defaultFontColor !important;
  resize: none;
}
/deep/ .el-input__suffix {
  display: none;
}
</style>