<template>
  <div class="main-container">
    <dd-card title="条件查询" spacer>
      <dd-search-form
        :form-config="formList"
        @search-click="handleSearchEvent"
      ></dd-search-form>
    </dd-card>
    <dd-card title="工资管理列表" :height="tableHeight">
      <dd-table
        :columns="columnsList"
        :data="tableDataSources"
        border
        ref="dTableRef"
        :summary-method="getSummaries"
        show-summary
        show-page
        @select-change="handleSelectionChange"
      >
        <template #table-btn>
          <dd-button type="primary" size="medium" icon="add" @click="handleItem"
            >新增</dd-button
          >
          <el-upload
            style="margin: 0 10px; display: inline-block"
            :headers="headers"
            ref="upload"
            action="/hr/balance/import"
            accept=".xlsx"
            :show-file-list="false"
            :on-success="handleFileSuccess"
            :on-error="handleError"
            :limit="1"
          >
            <dd-button type="success" size="medium">导入工资表</dd-button>
          </el-upload>
          <dd-button type="warning" size="medium" @click="handleExport"
            >导出工资表</dd-button
          >
          <dd-button
            type="danger"
            size="medium"
            icon="delete"
            autoConfirm
            @click="handleDelete"
            >删除</dd-button
          >
        </template>
        <template #table-btn-right>
          <el-button
            icon="el-icon-s-operation"
            circle
            type="info"
            @click="drawerVisible = true"
            size="small"
            title="列筛选"
          ></el-button>
        </template>
        <template #table-pagination>
          <dd-pagination
            :total="total"
            :page-size="pageSize"
            :page-number="pageNumber"
            @page-change="handlePageChange"
            align="right"
          >
          </dd-pagination>
        </template>
      </dd-table>
    </dd-card>
    <!-- 新增页面弹窗 -->
    <dd-popup
      v-if="dialogVisible"
      width="50%"
      height="50%"
      showBtn
      :saveBtn="false"
      submitBtn
      :title-name="popTitle"
      :show="dialogVisible"
      @popup-submit="handleSubmit('baseForm')"
      @popup-close="dialogVisible = false"
    >
      <AddPop slot="main" ref="addPop" :type="type" :id="id"></AddPop>
    </dd-popup>
    <!-- 详情页面弹窗 -->
    <dd-popup
      v-if="dialogDetail"
      width="50%"
      height="50%"
      :showBtn="false"
      :title-name="popTitle"
      :show="dialogDetail"
      @popup-close="dialogDetail = false"
    >
      <DetailPop slot="main" ref="detailPop" :type="type" :id="id"></DetailPop>
    </dd-popup>
    <dd-filter-drawer
      v-if="drawerVisible"
      width="30%"
      title-name="列筛选"
      :show="drawerVisible"
      :tableColumns="tableColumns"
      :columns="columnsList"
      @popup-close="drawerVisible = false"
      @handleColumnsChange="handleColumnsChange"
    >
    </dd-filter-drawer>
  </div>
</template>

<script>
import { postApi, getApi, delApi, loadApi } from "@/api/request";
import { formatNumber } from "@/utils/number";
import { downloadBinaryFile } from "@/utils/index";
import AddPop from "./components/addPop";
import DetailPop from "./components/detailPop";
import { mapState, mapActions } from "vuex";
import qs from "qs";
export default {
  name: "wageManagement",
  $dynamicRoute: {
    name: "工资管理",
    path: "/wageManagement/list",
  },
  components: { AddPop, DetailPop },
  data() {
    return {
      tableHeight: null,
      popTitle: "",
      dialogVisible: false,
      dialogDetail: false,
      headers: null,
      loading: false,
      id: "",
      type: "",
      drawerVisible: false,
      // 页码
      pageNumber: 1,
      // 每页的数据条数
      pageSize: 10,
      total: 0,
      // 表格选择数据集合
      multipleSelection: [],
      // 接收表单返回数据
      formData: {},
      // 条件查询表单配置配置
      formList: [
        {
          label: "部门",
          value: "deptId",
          inputType: "select",
          optionsList: [],
          filterable: "filterable",
          placeholderText: "请选择或搜索部门",
          optionsListConfig: {
            name: "name",
            code: "deptId",
          },
          title: true,
        },
        {
          label: "工号",
          value: "account",
          inputType: "text",
          placeholderText: "请输入工号",
        },
        {
          label: "所属年度",
          value: "year",
          inputType: "date",
          dateType: "year",
          format: "yyyy",
          placeholderText: "请选择所属年度",
        },
        {
          label: "所属月份",
          value: "month",
          inputType: "select",
          optionsList: [],
          filterable: "filterable",
          placeholderText: "请选择或搜索所属月份",
          // optionsListConfig: {
          //   name: "projectCode",
          //   code: "projectId",
          // },
          title: true,
        },
        {
          label: "人员",
          value: "username",
          inputType: "text",
          placeholderText: "请输入人员名称",
        },
      ],
      // 表格头部配置数据
      tableColumns: [
        {
          title: "部门名称",
          key: "deptName",
          align: "center",
        },
        {
          title: "工号",
          key: "account",
          align: "center",
          width: 350,
          overflow: true,
        },
        {
          title: "人员",
          key: "username",
          align: "center",
        },
        {
          title: "所属年度",
          key: "year",
          align: "center",
        },
        {
          title: "所属月份",
          key: "month",
          align: "center",
        },
        {
          title: "剩余工资(元)",
          key: "salary",
          align: "center",
        },
        {
          title: "在职状态",
          key: "label",
          align: "center",
        },
        {
          title: "操作",
          align: "center",
          event: [
            {
              label: "查看详情",
              state: "default",
              method: (row) => {
                this.handleDetailInfo(row);
              },
            },
          ],
        },
      ],
      columnsList: [], //筛选列表格头部配置
      // 表格数据集合
      tableDataSources: [],
      // 是否显示删除确认弹框
      autoConfirm: false,
      // 选择符合删除条件数据id集合
      delDataArr: [],
      menuType: null,
    };
  },
  created() {},
  mounted() {
    // 自动计算table高度
    this.$nextTick(() => {
      this.tableHeight = this.autoTableHeight(0);
    });
    let headers = {
      Authorization: "Bearer " + sessionStorage.getItem("authToken") || "",
    };
    this.headers = headers;
    this.getMonthList();
    this.getConditionData();
    this.getWagesList();
    this.columnsList = [...this.tableColumns];
  },
  computed: {
    ...mapState(["btnAuthorityList"]), //权限标识数组
  },
  methods: {
    // 工资列表请求
    getWagesList() {
      this.loading = true;
      let params = {
        ...this.formData,
        current: this.pageNumber,
        size: this.pageSize,
        menuType: this.menuType,
      };
      getApi(`/hr/balance/all/page`, params).then((res) => {
        let { data } = res;
        console.log(data);
        if (data.code == 0) {
          data.data.records.forEach((item) => {
            item.salary = formatNumber(item.salary);
          });
          this.tableDataSources = data.data.records;
          this.total = data.data.total;
          // this.loading = false;
        }
      });
    },
    // 所属月份状态下拉数据请求
    getMonthList() {
      postApi(
        `/finance/dict/list`,
        qs.stringify({ typeCode: "balance_month" })
      ).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          this.formList.forEach((item) => {
            if (item.value == "month") {
              item.optionsList = data.data;
            }
          });
        }
      });
    },
    getConditionData() {
      //获取部门数据
      postApi(`/admin/dept/getdeptlistbyorgid`, {
        orgId: sessionStorage.getItem("orgId"),
      }).then((res) => {
        this.depList = res.data.data;
        this.options = {
          value: "deptId",
          optionsList: this.depList,
        };
        this.setOPtionsList(this.formList, this.options);
      });
    },
    //提交
    handleSubmit(formName) {
      let flag = false;
      this.$refs.addPop.$refs[formName].$refs[formName].validate((valid) => {
        if (valid) {
          flag = true;
        }
      });
      if (flag) {
        let params = this.$refs.addPop.$refs[formName].formData;
        postApi(`/hr/balance`, params).then((res) => {
          let { data } = res;
          if (data.code == 0) {
            this.$message.success("提交成功！");
            this.dialogVisible = false;
            this.getWagesList();
          } else {
            this.$message({
              message: data.msg,
              type: "error",
              duration: 6000,
            });
          }
        });
      }
    },
    //条件查询表单--下拉选择数据填充（optionsList）
    setOPtionsList(formList, options) {
      formList.forEach((item) => {
        if (options) {
          if (item.value == options.value) {
            item.optionsList = options.optionsList;
          }
        }
      });
    },
    handleDetailInfo(row) {
      this.dialogDetail = true;
      this.type = "detail";
      this.id = row.id;
      this.popTitle = "剩余工资详情";
    },
    //成功上传文件
    handleFileSuccess(res) {
      if (res.code == "0" && !res.data) {
        this.$message.success("导入成功");
        this.getWagesList();
      } else {
        this.$message.warning(`${res.data}`);
      }
      this.$refs["upload"].clearFiles();
      console.log("res", res);
    },
    handleError(err) {
      let { msg } = JSON.parse(err.message);
      this.$message.error(`${msg}`);
      console.log(err);
      // debugger;
    },
    //上传前
    handlebeforeUpload() {
      console.log(1);
      this.$refs.targetUpload.clearFiles();
    },
    handleItem() {
      this.dialogVisible = true;
      this.type = "add";
      this.id = "";
      this.popTitle = "新增剩余工资";
    },
    /**
     * @description: 条件查询表单点击事件
     * @return {*}
     * @param {*} obj 表单参数
     */
    diaChange(e) {
      this.dialogVisible = e;
      this.$refs.dTableRef.$refs.baseTable.clearSelection();
      this.getWagesList();
    },
    handleSearchEvent(obj) {
      this.$refs.dTableRef.$refs.baseTable.clearSelection();
      this.formData = obj;
      this.pageNumber = 1;
      this.getWagesList();
    },
    handleExport() {
      let arr = [];
      this.multipleSelection.length &&
        this.multipleSelection.map((item) => {
          arr.push(item.id);
        });
      let params = {
        current: this.pageNumber,
        size: this.pageSize,
        ...this.formData,
        flag: this.multipleSelection.length == 0 ? true : false,
        ids: this.multipleSelection.length ? arr.join() : "",
        menuType: this.menuType,
      };
      console.log("params", params);
      loadApi(`/hr/balance/export`, params).then((res) => {
        let { data } = res;
        downloadBinaryFile(
          data,
          decodeURIComponent(
            res.headers["content-disposition"].split(";")[1].split("=")[1]
          )
        );
      });
    },
    handleDelete() {
      if (this.multipleSelection.length < 1) {
        this.$message.warning("请选择要删除的数据！");
        return;
      }
      let idArr = [];
      console.log(this.multipleSelection);
      this.multipleSelection.map((item) => {
        idArr.push(item.id);
      });
      let ids = { ids: idArr.join() };
      console.log(ids);
      this.$confirm("是否确定删除？", "提示", {
        confirmButtonClass: "btnsearch",
        confirmButtonText: "确认",
        cancelButtonClass: "btnreset",
        cancelButtonText: "取消",
        showClose: true,
        type: "warning",
      })
        .then(() => {
          postApi(`/hr/balance/delete`, ids).then((res) => {
            let { data } = res;
            if (data.code == 0) {
              this.$message({
                type: "success",
                message: "删除成功!",
              });
              this.getWagesList();
              this.$refs.dTableRef.$refs.baseTable.clearSelection();
            }
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 计算表格合计行函数
    getSummaries(param) {
      const { columns, data } = param;
      let sums = [param];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "合计";
          return;
        } else if (column.property == "salary") {
          const values = data.map((item) => Number(item[column.property]));
          console.log(values);
          if (!values.every((value) => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr);
              console.log(value);
              if (!isNaN(value)) {
                return prev + curr;
              } else {
                return prev;
              }
            }, 0);
            sums[index] += "";
          }
        }
      });
      let list = [...sums];
      list.map((item, index) => {
        if (!isNaN(+item)) {
          sums[index] = formatNumber(sums[index]);
        }
      });
      return sums;
    },
    // 表格多选事件
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    /**
     * @description: 分页改变事件请求刷新数据列表
     * @return {*}
     * @param {*} pageSize 每页显示条数
     * @param {*} pageNumber 第几页
     */
    handlePageChange(pageSize, pageNumber) {
      this.pageSize = pageSize;
      this.pageNumber = pageNumber;
      this.getWagesList();
    },
    handleColumnsChange(val) {
      let list = new Set();
      this.tableColumns.map((item) => {
        val.map((itm) => {
          if (item.event || item.key == itm.key) {
            list.add(item);
          }
        });
      });
      this.columnsList = [...list];
    },
  },
};
</script>

<style lang="less" scoped></style>
