<template>
  <div>
    <dd-card :title="rowData.name"></dd-card>
    <dd-card title="基本信息" class="base-form-collect-info">
      <dd-form
        :form-config="formList"
        ref="addPopForm"
        labelWidth="160px"
        :flex="3"
      ></dd-form>
    </dd-card>
    <dd-card title="工作量明细">
      <el-tabs v-model="defaultTabs" type="border-card" sticky="true">
        <el-tab-pane label="绩效汇总表" name="jxhzb" style="height: 400px;overflow: hidden;overflow-y:auto;">
          <dd-search-form
            :form-config="jxFormList"
            @search-click="handleSearchEventhz"
            ref="ddSearchForm"
            labelWidth="180px"
          ></dd-search-form>
          <dd-table
            max-height="360"
            ref="Table"
            show-summary
            :summary-method="getSummaries"
            :columns="jxTableColumns"
            :data="jxTableDataSources"
            :loading="loading"
            border
            show-page
            :isEdit="true"
            :isSelection="false"
          >
            <template #table-pagination>
              <dd-pagination
                v-show="jxTotal > 0"
                :total="jxTotal"
                :page-size="size"
                :page-number="current"
                @page-change="handleCurrentChangehz"
                align="right"
              >
              </dd-pagination>
            </template>
          </dd-table>
        </el-tab-pane>
        <el-tab-pane label="标准工作包核算详情" name="bzgzb" style="height: 400px;overflow: hidden;overflow-y:auto;">
          <dd-search-form
            :form-config="bzFormList"
            @search-click="handleSearchEventBz"
            ref="ddSearchForm"
            labelWidth="180px"
          ></dd-search-form>
          <dd-table
            max-height="360"
            ref="Table"
            show-summary
            :summary-method="getSummaries"
            :columns="bzTableColumns"
            :data="bzTableDataSources"
            :loading="loading"
            border
            :isEdit="true"
            :isSelection="false"
          >
            <template #table-pagination>
              <dd-pagination
                v-show="bzTotal > 0"
                :total="bzTotal"
                :page-size="size"
                :page-number="current"
                @page-change="handleCurrentChangeBz"
                align="right"
              >
              </dd-pagination>
            </template>
          </dd-table>
        </el-tab-pane>
        <el-tab-pane label="自定义工作包核算详情" name="zdygzb" style="height: 400px;overflow: hidden;overflow-y:auto;">
          <dd-search-form
            :form-config="zdyFormList"
            @search-click="handleSearchEventzdy"
            ref="ddSearchForm"
            labelWidth="180px"
          ></dd-search-form>
          <dd-table
            max-height="360"
            ref="Table"
            :columns="zdyTableColumns"
            show-summary
            :summary-method="getSummaries"
            :data="zdyTableDataSources"
            :loading="loading"
            border
            :isEdit="true"
            :isSelection="false"
          >
            <template #table-pagination>
              <dd-pagination
                v-show="zdyTotal > 0"
                :total="zdyTotal"
                :page-size="size"
                :page-number="current"
                @page-change="handleCurrentChangezdy"
                align="right"
              >
              </dd-pagination>
            </template>
          </dd-table>
        </el-tab-pane>
      </el-tabs>
    </dd-card>
    <!-- 详情 -->
    <dd-popup
      v-if="dialogVisible"
      width="80%"
      height="80%"
      :inPopup="true"
      :showBtn="false"
      :title-name="popTitle"
      :show="dialogVisible"
      @popup-close="handleClose"
    >
      <AddPopDetailShow
        slot="main"
        ref="addPop"
        :id="detailId"
        :rowData="rowData"
      ></AddPopDetailShow>
    </dd-popup>
  </div>
</template>

<script>
import { postApi } from "@/api/request";
import AddPopDetailShow from "../../AccountingManage/components/AddPopDetailShow.vue";
export default {
  name: "view-details",
  components: {AddPopDetailShow},
  props: {
    rowData:{
      type: Object,
      default: null,
    }
  },
  data() {
    return {
      loading: false,
      defaultTabs: "jxhzb",
      current: 1,
      size: 20,
      dialogVisible:false,
      detailId:'',
      formList: [
        {
          label: "分管部门",
          value: "fenDeptName",
          inputType: "select",
          optionsList: [],
          defaultValue:'',
          filterable: true,
          disabled:true,
          placeholderText: "分管部门",
          optionsListConfig: {
            name: "name",
            code: "id",
          },
        },
        {
          label: "所属年月",
          value: "reportMonth",
          placeholderText: "所属年月",
          inputType: "text",
          defaultValue: '',
          disabled:true,
        },
        {
          label:"状态",
          value: "statusName",
          placeholderText: "状态",
          inputType: "text",
          defaultValue: '',
          disabled:true,
        }
      ],
      jxFormList:[
        {
          label: "姓名",
          value: "userName",
          inputType: "text",
          placeholderText: "请输入姓名",
          title: true,
        },
      ],
      jxTableColumns:[
        {
          title: "部门",
          key: "deptName",
          align: "center",
        },
        {
          title: "工号",
          key: "username",
          align: "center",
        },
        {
          title: "姓名",
          key: "fullName",
          align: "center",
        },
        {
          title: "绩效金额",
          key: "reviewPerformance",
          align: "center",
        },
      ],
      jxTotal:0,
      jxTableDataSources:[],
      // 标准工作包
      bzFormList:[
        {
          label: "区域",
          value: "areaName",
          inputType: "text",
          placeholderText: "请输入区域名称",
          title: true,
        },
        {
          label: "任务书",
          value: "assignName",
          inputType: "text",
          placeholderText: "请输入任务书名称",
          title: true,
        },
        {
          label: "姓名",
          value: "userName",
          inputType: "text",
          placeholderText: "请输入姓名",
          title: true,
        },
      ],
      bzTableColumns: [
        {
          title: "区域",
          key: "areaName",
          align: "center",
        },
        {
          title: "任务书编码",
          key: "assignCode",
          align: "center",
        },
        {
          title: "任务书名称",
          key: "assignName",
          align: "center",
        },
        // {
        //   title: "任务书类型",
        //   key: "assignType",
        //   align: "center",
        // },
        {
          title: "工作包名称",
          key: "nodeName",
          align: "center",
        },
        {
          title: "工号",
          key: "userCode",
          align: "center",
        },
        {
          title: "姓名",
          key: "userName",
          align: "center",
        },
        {
          title: "绩效金额",
          key: "reviewPerformance",
          align: "center",
        },
        {
          title: "工作量",
          key: "workload",
          align: "center",
        },
        {
          title: "工作量单位",
          key: "unitName",
          align: "center",
        },
        
        {
          title: "所属绩效核算单",
          key: "paname",
          align: "center",
        },
        {
          title: "操作",
          key: "handle",
          align: "center",
          event: [
              {
                label: "查看详情",
                icon: "el-icon-view",
                type: "primary",
                method: (row) => {
                  this.handleDetail(row);
                },
              },
            ],
        },
      ],
      bzTotal:0,
      bzTableDataSources: [],
      // 自定义工作包
      zdyFormList:[
        {
          label: "区域",
          value: "areaName",
          inputType: "text",
          placeholderText: "请输入区域名称",
          title: true,
        },
        {
          label: "任务书",
          value: "assignName",
          inputType: "text",
          placeholderText: "请输入任务书名称",
          title: true,
        },
        {
          label: "姓名",
          value: "userName",
          inputType: "text",
          placeholderText: "请输入姓名",
          title: true,
        },
      ],
      zdyTableColumns: [
        {
          title: "区域",
          key: "areaName",
          align: "center",
        },
        {
          title: "任务书编码",
          key: "assignCode",
          align: "center",
        },
        {
          title: "任务书名称",
          key: "assignName",
          align: "center",
        },
        // {
        //   title: "任务书类型",
        //   key: "assignType",
        //   align: "center",
        // },
        {
          title: "工作包名称",
          key: "nodeName",
          align: "center",
        },
        {
          title: "工号",
          key: "userCode",
          align: "center",
        },
        {
          title: "姓名",
          key: "userName",
          align: "center",
        },
        {
          title: "绩效金额",
          key: "reviewPerformance",
          align: "center",
        },
        {
          title: "工作量",
          key: "workload",
          align: "center",
        },
        {
          title: "工作量单位",
          key: "unitName",
          align: "center",
        },
        {
          title: "效率",
          key: "efficiency",
          align: "center",
          width: 50,
        },
        {
          title: "标准工天",
          key: "standardWorkday",
          align: "center",
          width: 50,
        },
        {
          title: "计划标准工天单价",
          key: "planStandardWorkdayPrice",
          align: "center",
        },
        {
          title: "执行标准工天单价",
          key: "carryStandardWorkdayPrice",
          align: "center",
        },
        {
          title: "系数调整",
          key: "coefficientAdjustment",
          align: "center",
          width: 50,
        },
        {
          title: "固定调整",
          key: "fixedAdjustment",
          align: "center",
          width: 50,
        },
        {
          title: "绩效计算备注说明",
          key: "performanceRemark",
          align: "center",
        },
        {
          title: "所属绩效核算单",
          key: "paname",
          align: "center",
        },
      ],
      zdyTotal:0,
      zdyTableDataSources: [],
      formDatabz: {},
      formDatahz: {},
      formDatazdy: {}
    };
  },
  mounted() {
    this.init();
    if (this.rowData) {
      this.formList.forEach(item => {
        item.defaultValue = this.rowData[item.value];
      })
    }
  },
  methods: {
    init(){
      let { current, size } = this;
      postApi(`/project/accountingsum/table/performance/${this.rowData.id}/${current}/${size}`).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          this.jxTableDataSources = data.data.performanceSumTableVOPage.records;
          this.jxTotal = data.data.performanceSumTableVOPage.total;
          this.bzTableDataSources = data.data.standardWorkpackageTablePage.records;
          this.bzTotal = data.data.standardWorkpackageTablePage.total;
          this.zdyTableDataSources = data.data.customWorkpackageTablePage.records;
          this.zdyTotal = data.data.customWorkpackageTablePage.total;
        }
      });
    },
    handleCurrentChange(size, number) {
      this.current = number;
      this.size = size;
      this.init();
    },
    handleSearchEventhz(obj){
      this.formDatahz = obj;
      this.current = 1;
      this.handleSummary();
    },
    handleCurrentChangehz(size,number){
      this.current = number;
      this.size = size;
      this.handleSummary();
    },
    handleSearchEventBz(obj){
      this.formDatabz = obj;
      this.current = 1;
      this.handleStandard();
    },
    handleCurrentChangeBz(size,number){
      this.current = number;
      this.size = size;
      this.handleStandard();
    },
    handleSearchEventzdy(obj){
      this.formDatazdy = obj;
      this.current = 1;
      this.handleCustomized();
    },
    handleCurrentChangezdy(size,number){
      this.current = number;
      this.size = size;
      this.handleCustomized();
    },
    // 绩效汇总表
    handleSummary(){
      let { current, size } = this;
      postApi(`/project/accountingsum/table/performance/sum/${this.rowData.id}/${current}/${size}`,this.formDatahz).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          this.jxTableDataSources = data.data.performanceSumTableVOPage.records;
          this.jxTotal = data.data.performanceSumTableVOPage.total;
        }
      });
    },
    // 标准工作包
    handleStandard(){
      let { current, size } = this;
      postApi(`/project/accountingsum/table/performance/work/bz/${this.rowData.id}/${current}/${size}`,this.formDatabz).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          this.bzTableDataSources = data.data.standardWorkpackageTablePage.records;
          this.bzTotal = data.data.standardWorkpackageTablePage.total;
        }
      });
    },
    // 自定义工作包
    handleCustomized(){
      let { current, size } = this;
      postApi(`/project/accountingsum/table/performance/work/zdy/${this.rowData.id}/${current}/${size}`,this.formDatazdy).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          this.zdyTableDataSources = data.data.customWorkpackageTablePage.records;
          this.zdyTotal = data.data.customWorkpackageTablePage.total;
        }
      });
    },
    getSummaries(param) { // 自定义项表格合计方法
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "合计";
          return;
        } else if (column.property == "reviewPerformance"
        ) {
          //如果是经费（正常的加减法）
          const values = data.map((item) => item[column.property] ? item[column.property] * 1 : 0);
          if (!values.every((value) => isNaN(value))) {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr);
              var sum = 0;
              if (!isNaN(value)) {
                sum = Number(Number(prev) + Number(curr)).toFixed(2);
                return sum;
              } else {
                return prev;
              }
            }, 0);
            sums[index] += " ";
          }
        }
      });
      return sums
    },
    // 查看详情
    handleDetail(row){
      this.dialogVisible = true;
      this.popTitle = row.standardWorkpackage
        ? "子任务模式-查看详细"
        : "wbs模式-查看详细";
      this.detailId = row.id;
    },
    // 关闭弹窗
    handleClose(){
      this.dialogVisible = false;
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.el-input.is-disabled .el-input__inner{
  background-color:#fff !important;
  color: #606266 !important;
}
/deep/ .table-box{
  height: calc(100% - 40px) !important;
}
</style>
