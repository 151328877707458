<template>
    <div class="main-container">
        <div class="head_wrap">
            <dd-card title="条件搜索" spacer>
                <dd-search-form :form-config="formList" label-width="170px" @search-click="handleSearchEvent"
                    ref="ddSearchForm"></dd-search-form>
            </dd-card>
        </div>
        <dd-card title="合同列表" :height="tableHeight">
            <dd-table :columns="columnsList" :data="tableData" border @select-change="handleSelectionChange" ref="contractTable" :isSelection="true" show-page :loading="loading">
                <template #table-btn>
                    <dd-button type="primary" icon="add" size="medium" @click="handleAdd">新增
                    </dd-button>
                    <dd-button type="warning" icon="update" size="medium" @click="handleAllocation">分配到采购合同
                    </dd-button>
                    <dd-button type="info" size="medium" @click="handleBack">发票退回
                    </dd-button>
                    <dd-button type="danger" size="medium" @click="handleRedBlood">发票冲红
                    </dd-button>
                    <dd-button type="danger" size="medium" icon="delete" @click="handleDeleteInvoice">删除
                    </dd-button>
                </template>
                <template #table-btn-right>
                    <el-button icon="el-icon-s-operation" circle type="info" size="small" @click="drawerVisible = true"
                        title="列筛选"></el-button>
                </template>
                <template #table-pagination>
                    <dd-pagination v-show="total > 0" :total="total" :page-size="size" :page-number="current"
                        @page-change="handlePageChange" align="right">
                    </dd-pagination>
                </template>
            </dd-table>
        </dd-card>
        <dd-filter-drawer v-if="drawerVisible" width="30%" title-name="列筛选" :show="drawerVisible"
            :tableColumns="tableColumns" :columns="columnsList" @popup-close="drawerVisible = false"
            @handleColumnsChange="handleColumnsChange">
        </dd-filter-drawer>

        <dd-popup v-if="AddEditVisible" width="78%" height="80%" showBtn :saveBtn="saveBtn" :submitBtn="false"
            :show="AddEditVisible" :title-name="addEditTitle" @popup-close="handleClose">
            <dd-button slot="button" size="medium" type="primary" @click="handleSubmit(0, 'addEdit')">暂存</dd-button>
            <dd-button slot="button" size="medium" type="primary" @click="handleSubmit(1, 'addEdit')">提交</dd-button>
            <addEditDetails ref="addEditDetails" :pageProp="pageProp" slot="main"></addEditDetails>
        </dd-popup>

        <dd-popup v-if="redBloodVisible" width="78%" height="80%" showBtn :saveBtn="saveBtn" :submitBtn="false"
            :show="redBloodVisible" title-name="发票冲红" @popup-close="handleClose">
            <dd-button slot="button" size="medium" type="primary" @click="handleSubmit(3)">提交</dd-button>
            <redBlood ref="redBlood" :pageProp="pageProp" slot="main"></redBlood>
        </dd-popup>

        <dd-popup v-if="pageDetailVisible" width="78%" height="80%" showBtn :saveBtn="saveBtn" :submitBtn="false"
            :show="pageDetailVisible" title-name="详情" @popup-close="handleClose">
            <pageDetail ref="pageDetail" :pageProp="pageProp" slot="main"></pageDetail>
        </dd-popup>
        <!-- pageDetail -->

        <dd-popup v-if="allocationVisible" width="78%" height="80%" showBtn :saveBtn="saveBtn" :submitBtn="false"
            :show="allocationVisible" :title-name="allocationTitle" @popup-close="handleClose">
            <dd-button slot="button" size="medium" type="primary" @click="handleSubmit(2)">提交</dd-button>
            <allocation :pageProp="pageProp" ref="allocation" slot="main"></allocation>
        </dd-popup>
    </div>
</template>

<script>
import { getApi, delApi, postApi, putApi } from "@/api/request";
import redBlood from './components/redBlood.vue'
import addEditDetails from './components/addEditDetails.vue'
import allocation from './components/allocation.vue'
import pageDetail from './components/pageDetail.vue'
export default {
    name: "invoiceRegistration",
    $dynamicRoute: {
        name: "发票登记",
        path: "/contract/invoiceRegistration/index",
    },
    components: { addEditDetails, allocation, redBlood, pageDetail },
    data() {
        return {
            loading: false,
            formList: [
                {
                    label: "发票代码",
                    value: "invoiceCode",
                    inputType: "text",
                    placeholderText:
                        "请输入发票代码进行搜索",
                },
                {
                    label: "发票号码",
                    value: "invoiceNum",
                    inputType: "text",
                    placeholderText:
                        "请输入发票号码进行搜索",
                },
                {
                    label: "开票供应商名称",
                    value: "sellerName",
                    inputType: "text",
                    placeholderText:
                        "请输入开票供应商名称进行搜索",
                },
                {
                    label: "发票状态",
                    value: "status",
                    inputType: "select",
                    optionsList: [],
                    optionsListConfig: {
                        name: "name",
                        code: "code",
                    },
                    placeholderText:
                        "请输入选择状态进行搜索",
                },{},{},{}
            ],
            AddEditVisible: false,
            allocationVisible: false,
            allocationTitle: '',
            redBloodVisible: false,
            pageDetailVisible: false,
            drawerVisible: false,
            tableHeight: null,
            saveBtn: false,
            addEditTitle: '新增',
            tableColumns: [
                {
                    title: "发票登记单据编码",
                    key: "code",
                    align: "center",
                    overflow: true,
                    width: 180,
                },
                {
                    title: "发票代码",
                    key: "invoiceCode",
                    align: "center",
                    overflow: true,
                    width: 140,
                },
                {
                    title: "发票号码",
                    key: "invoiceNum",
                    align: "center",
                    overflow: true,
                    width: 140,
                },
                {
                    title: "开票日期",
                    key: "invoiceDate",
                    align: "center",
                    overflow: true,
                    width: 140,
                },
                {
                    title: "金额",
                    key: "totalAmount",
                    align: "center",
                    overflow: true,
                    width: 120,
                },
                {
                    title: "税额",
                    key: "totalTax",
                    align: "center",
                    overflow: true,
                    width: 120,
                },
                {
                    title: "价税合计金额",
                    key: "amountInFiguers",
                    align: "center",
                    overflow: true,
                    width: 140,
                },
                {
                    title: "发票类型",
                    key: "invoiceType",
                    align: "center",
                    overflow: true,
                },
                {
                    title: "有效发票金额",
                    key: "validInvoiceAmount",
                    align: "center",
                    overflow: true,
                    width: 140,
                },
                {
                    title: "开票供应商名称",
                    key: "sellerName",
                    align: "center",
                    overflow: true,
                    width: 180,
                },
                {
                    title: "已分配到合同的发票金额",
                    key: "allocatedInvoiceAmount",
                    align: "center",
                    overflow: true,
                    width: 200,
                },
                {
                    title: "待分配金额",
                    key: "tobeInvoiceAmount",
                    align: "center",
                    overflow: true,
                    width: 140,
                },
                {
                    title: "发票状态",
                    key: "statusName",
                    align: "center",
                    overflow: true,
                },
                {
                    title: "制单人",
                    key: "applierName",
                    align: "center",
                    overflow: true,
                },
                {
                    title: "制单日期",
                    key: "applyDate",
                    align: "center",
                    overflow: true,
                },
                {
                    title: "操作",
                    align: "center",
                    fixed: 'right',
                    overflow: true,
                    width: 180,
                    event: [
                        {
                            state: "default",
                            label: "编辑",
                            type: "warning",
                            width: 100,
                            method: (row) => {
                                this.handleEdit(row);
                            },
                            rules: (row) => {
                                let show = false;
                                if (row.status == "PC-INVOICE-STATUS-ZC" ) {
                                show = true;
                                } else {
                                show = false;
                                }
                                return show;
                            },
                        },
                        {
                            state: "default",
                            label: "删除",
                            type: "danger",
                            width: 100,
                            method: (row) => {
                                this.handleDelete(row);
                            },
                            rules: (row) => {
                                let show = false;
                                if (row.status == "PC-INVOICE-STATUS-ZC" ) {
                                show = true;
                                } else {
                                show = false;
                                }
                                return show;
                            },
                        },
                        {
                            state: "default",
                            label: "查看详情",
                            width: 100,
                            method: (row) => {
                                this.handleDetail(row);
                            },
                        },
                    ],
                },
            ],
            searchData: {},
            selectionData: [],
            pageProp: {},
            tableData: [],
            columnsList: [],
            // 当前页码
            current: 1,
            // 总条数
            total: 0,
            // 每页的数据条数
            size: 10,
        };
    },
    created() {
        this.getDataType()
        this.getTableList()
    },
    mounted() {
        this.columnsList = [...this.tableColumns];
        this.$nextTick(() => {
            this.tableHeight = this.autoTableHeight(0);
        });
    },
    methods: {
        getTableList() {
            let params = {...this.searchData};
            postApi(`/contract/invoice/registration/page/${this.current}/${this.size}`, params).then(res => {
                if (res.data.code == 0) {
                    this.tableData = res.data.data.records;
                    this.total = res.data.data.total
                }
            })
        },
        handleSelectionChange(e) {
            this.selectionData = e;
        },
        handleSearchEvent(e) {
            this.current = 1;
            this.searchData = e;
            this.getTableList();
         },
        handleColumnsChange(val) {
            let list = new Set();
            this.tableColumns.map((item) => {
                val.map((itm) => {
                    if (item.event || item.key == itm.key) {
                        list.add(item);
                    }
                });
            });
            this.columnsList = [...list];
        },
        handlePageChange(pageSize, pageNumber) {
            this.size = pageSize;
            this.current = pageNumber;
            this.getTableList()
        },
        // 新增
        handleAdd() {
            this.AddEditVisible = true;
            this.addEditTitle = '新增';
            this.pageProp.pageType = 'add'
            this.pageProp.id = ''
        },
        // 分配
        handleAllocation() {
            if(this.selectionData.length == 0 || this.selectionData.length > 1) {
                this.$message.warning('请勾选一条数据')
                return false
            }
            if(this.selectionData[0].status == 'PC-INVOICE-STATUS-ZC' || this.selectionData[0].status == 'PC-INVOICE-STATUS-CH' || this.selectionData[0].status == 'PC-INVOICE-STATUS-TH') {
                this.$message.warning('只有未分配，已分配，分配中的数据才可分配')
                return false
            }
            this.pageProp.pageType = 'allocation';
            this.pageProp.id = this.selectionData[0].id;
            let tobeInvoiceAmount = 0;
            if(this.selectionData[0].allocatedInvoiceAmount) {
                tobeInvoiceAmount = this.selectionData[0].validInvoiceAmount - this.selectionData[0].allocatedInvoiceAmount
            }
            else {
                tobeInvoiceAmount = this.selectionData[0].validInvoiceAmount
            }


            this.pageProp.arr = [this.selectionData[0].validInvoiceAmount || '', this.selectionData[0].allocatedInvoiceAmount || 0, tobeInvoiceAmount]
            this.allocationVisible = true;
            this.allocationTitle = '分配';
        },
        // 退回
        handleBack() { 
            if(this.selectionData.length == 0 || this.selectionData.length > 1) {
                this.$message.warning('请勾选一条数据')
                return false
            }
            if(this.selectionData[0].status == 'PC-INVOICE-STATUS-ZC' || this.selectionData[0].status == 'PC-INVOICE-STATUS-CH' || this.selectionData[0].status == 'PC-INVOICE-STATUS-TH') {
                this.$message.warning('只有未分配，已分配，分配中的数据才可退回')
                return false
            }

            this.$confirm(`确认退回该数据吗？`, "提示", {
                confirmButtonClass: "btnsearch",
                confirmButtonText: "确认",
                cancelButtonClass: "btnreset",
                cancelButtonText: "取消",
                showClose: true,
                type: "warning",
            }).then(res => {
                postApi(`/contract/invoice/registration/back?id=${this.selectionData[0].id}`).then(res => {
                    if(res.data.code == 0) {
                        this.$message.success('操作成功')
                    }
                    else {
                        this.$message.warning('操作失败')
                    }
                    this.getTableList()
                })
                this.$refs.contractTable.$refs.baseTable.clearSelection();
            })
            
        },
        // 冲红
        handleRedBlood() {
            if(this.selectionData.length == 0 || this.selectionData.length > 1) {
                this.$message.warning('请勾选一条数据')
                return false
            }
            if(this.selectionData[0].status == 'PC-INVOICE-STATUS-ZC' || this.selectionData[0].status == 'PC-INVOICE-STATUS-CH' || this.selectionData[0].status == 'PC-INVOICE-STATUS-TH') {
                this.$message.warning('只有未分配，已分配，分配中的数据才可冲红')
                return false
            }
            this.pageProp.pageType = 'redBlood';
            this.pageProp.id = this.selectionData[0].id;
            this.redBloodVisible = true;
        },
        handleDeleteInvoice(){
            if(this.selectionData.length == 0 || this.selectionData.length > 1) {
                this.$message.warning('请勾选一条数据')
                return false
            }
            console.log("this.selectionData[0].id",this.selectionData[0].id);
            this.$confirm(`确认删除该数据吗？`, "提示", {
                confirmButtonClass: "btnsearch",
                confirmButtonText: "确认",
                cancelButtonClass: "btnreset",
                cancelButtonText: "取消",
                showClose: true,
                type: "warning",
            }).then(() => {
                delApi(`/contract/invoice/registration/delete?id=${this.selectionData[0].id}`).then(res => {
                    if(res.data.code == 0) {
                        this.$message.success('操作成功')
                    }
                    else {
                        this.$message.warning('操作失败，请稍后重试')
                    }
                    this.getTableList()
                })
                this.$refs.contractTable.$refs.baseTable.clearSelection();
            });
        },
        // 编辑
        handleEdit(row) {
            this.pageProp.pageType = 'edit';
            this.pageProp.id = row.id;
            this.AddEditVisible = true;
        },
        // 删除
        handleDelete(row) {
            this.$confirm(`确认删除该数据吗？`, "提示", {
                confirmButtonClass: "btnsearch",
                confirmButtonText: "确认",
                cancelButtonClass: "btnreset",
                cancelButtonText: "取消",
                showClose: true,
                type: "warning",
            }).then(() => {
                delApi(`/contract/invoice/registration/delete?id=${row.id}`).then(res => {
                    if(res.data.code == 0) {
                        this.$message.success('操作成功')
                    }
                    else {
                        this.$message.warning('操作失败，请稍后重试')
                    }
                    this.getTableList()
                })
            });
            
         },
        // 详情
        handleDetail(row) {
            let tobeInvoiceAmount = 0;
            if(row.allocatedInvoiceAmount) {
                tobeInvoiceAmount = row.validInvoiceAmount - row.allocatedInvoiceAmount
            }
            else {
                tobeInvoiceAmount = row.validInvoiceAmount
            }
            this.pageProp.arr = [row.validInvoiceAmount || '', row.allocatedInvoiceAmount || 0, tobeInvoiceAmount]
            this.pageProp.id = row.id;
            this.addEditTitle = '编辑';
            this.pageDetailVisible = true;
            this.pageProp.pageType = 'detail';
        },
        // 暂存0 提交 1
        handleSubmit(type, ref) {
            if(ref == 'addEdit') {
                if(this.$refs.addEditDetails.tableData.length == 0) {
                    this.$message.warning('发票详细不能为空，请上传发票后重试')
                    return false
                }
                let fileData = this.$refs.addEditDetails.fileData;
                delete fileData.id
                let detailList = this.$refs.addEditDetails.tableData.map(item => {
                    delete item.id;
                    return item
                });
                let params = {
                    ...this.$refs.addEditDetails.$refs.assignForm_1.formData,
                    ...this.$refs.addEditDetails.$refs.assignForm_title.formData,
                    ...this.$refs.addEditDetails.$refs.assignForm_purchaser.formData,
                    ...this.$refs.addEditDetails.$refs.assignForm_detail.formData,
                    ...this.$refs.addEditDetails.$refs.assignForm_seller.formData,
                    ...this.$refs.addEditDetails.$refs.assignForm_other.formData,
                    detailList: detailList,
                    file: fileData,
                    originData: JSON.stringify(this.$refs.addEditDetails.invoiceData.words_result),
                };
                params.id = this.pageProp.id;
                postApi(`/contract/invoice/registration/save/${type}`, params).then(res => {
                    if(res.data.code == 0) {
                        this.$message.success('操作成功')
                    }
                    else {
                        this.$message.warning('操作失败，请稍后重试')
                    }
                    this.AddEditVisible = false;
                    this.getTableList()
                })
            }

            if(type == 2) {
                let params = {
                    id: this.pageProp.id,
                    voList: this.$refs.allocation.tableData.map(item => {
                        return {
                            id: item.id,
                            correlateTheInvoice: item.correlateTheInvoice,
                            allotmentAmount: item.allotmentAmount,
                        }
                    })
                };
                postApi(`/contract/invoice/registration/allocate/purcharse`, params).then(res => {
                    if(res.data.code == 0) {
                        this.$message.success('操作成功')
                    }
                    else {
                        this.$message.warning('操作失败，请稍后重试')
                    }
                    
                    this.allocationVisible = false;
                    this.getTableList()
                })
            }
            if(type == 3) {
                if(this.$refs.redBlood.invoiceData.words_result == undefined || this.$refs.redBlood.invoiceData.words_result.AmountInFiguers == undefined){
                    this.$message.warning('发票详细不能为空，请上传发票后重试')
                    return false
                }
                let fileData = this.$refs.redBlood.fileData;
                delete fileData.id
                let detailList = this.$refs.redBlood.tableData.map(item => {
                    delete item.id;
                    return item
                });
                let params = {
                    ...this.$refs.redBlood.$refs.assignForm_1.formData,
                    ...this.$refs.redBlood.$refs.assignForm_title.formData,
                    ...this.$refs.redBlood.$refs.assignForm_purchaser.formData,
                    ...this.$refs.redBlood.$refs.assignForm_detail.formData,
                    ...this.$refs.redBlood.$refs.assignForm_seller.formData,
                    ...this.$refs.redBlood.$refs.assignForm_other.formData,
                    detailList: detailList,
                    originData: JSON.stringify(this.$refs.redBlood.invoiceData.words_result),
                    file: fileData,
                };
                postApi(`/contract/invoice/registration/blush?id=${this.pageProp.id}`, params).then(res => {
                    if(res.data.code == 0) {
                        this.$message.success('操作成功')
                    }
                    else {
                        this.$message.warning('操作失败，请稍后重试')
                    }
                    this.redBloodVisible = false;
                    this.getTableList()
                })
            }

            this.$refs.contractTable.$refs.baseTable.clearSelection();
         },
        // 取消
        handleClose() {
            this.AddEditVisible = false;
            this.allocationVisible = false;
            this.redBloodVisible = false;
            this.pageDetailVisible = false;
            this.$refs.contractTable.$refs.baseTable.clearSelection();
        },
        getDataType() {
            postApi(`/contract/dicttype/list/all`, {code: "PC-INVOICE-STATUS"}).then(res => {
                if(res.data.code == 0) {
                    this.formList.forEach(item => {
                        if(item.value == 'status') {
                            item.optionsList = res.data.data
                        }
                    })
                }
            })
        },
    },
};
</script>

<style lang="less" scoped></style>
