<template>
    <div class="main-container">
        <!-- 基本信息 -->
        <!-- style="height: 500px;overflow: hidden;overflow-y:auto;" -->
        <dd-form :form-config="formBasicList" :flex="3" labelWidth="220px" ref="addPopForm">
        </dd-form>
        <dd-form :form-config="formBasicList1" labelWidth="165px" ref="addPopForm1">
        </dd-form>
        <!-- 客户相关信息 -->
        <dd-form :form-config="formBasicListKh" :flex="3" labelWidth="220px" ref="addPopFormKh">
        </dd-form>
        <dd-popup v-if="addEditVisible" width="80%" height="80%" showBtn inPopup :saveBtn="false" :submitBtn="false"
            :show="addEditVisible" title-name="新增" @popup-save="handleSave()" @popup-close="handleClose">
            <editPop slot="main" :crmId="crmId"></editPop>
        </dd-popup>
    </div>
</template>

<script>
import editPop from "./editPop.vue";
import { postApi, getApi, putApi } from "@/api/request";
import { getNowDate, getNowYear } from "@/utils/date";
import EditRecordPop from "./EditRecordPop.vue";
export default {
    name: "BasicInformationChange",
    props: {
        rowData: [],
        titleName: "",
    },
    components: {
        EditRecordPop, editPop
    },
    data() {
        return {
            crmId: null,
            addEditVisible: false,
            formList_form: [
                {
                    label: "客户联系人名称",
                    value: "name",
                    inputType: "text",
                    placeholderText: "请输入客户联系人名称",
                    disabled: false,
                    rules: [
                        {
                            required: true,
                            message: "请输入客户联系人名称",
                            trigger: "blur",
                        },
                    ],
                },
                {
                    label: "个人联系方式",
                    value: "personalContact",
                    inputType: "text",
                    placeholderText: "请输入个人联系方式",
                    disabled: false,
                },
                {
                    label: "工作联系方式",
                    value: "workContact",
                    inputType: "text",
                    placeholderText: "请输入工作联系方式",
                    disabled: false,
                },
            ],
            relateList: [],
            saveBtn: true,
            jbxxLabel: "基本信息",
            khxgxxLabel: "客户相关信息",
            xmglxxLabel: "项目关联信息",
            defaultTabs: "jbxx",
            formBasicList: [
                {
                    label: "所在区域",
                    value: "regionAreaId",
                    inputType: "select",
                    optionsList: [],
                    filterable: true,
                    placeholderText: "请选择所在区域",
                    optionsListConfig: {
                        name: "name",
                        code: "id",
                    },
                    rules: [
                        {
                            required: true,
                            message: "请选择所在区域",
                            trigger: "blur",
                        },
                    ],
                },
                {
                    label: "项目生产需求级别",
                    value: "assignLevel",
                    inputType: "select",
                    optionsList: [],
                    filterable: false,
                    placeholderText: "请选择项目生产需求级别",
                    optionsListConfig: {
                        name: "name",
                        code: "code",
                    },
                    rules: [
                        {
                            required: true,
                            message: "请选择项目生产需求级别",
                            trigger: "blur",
                        },
                    ],
                },
                {
                    label: "任务工期",
                    value: "taskDuration",
                    placeholderText: "任务工期",
                    inputType: "date",
                    dateType: "daterange",
                    format: "yyyy-MM-dd",
                    defaultValue: getNowDate(),
                    rules: [
                        {
                            type: "array",
                            required: true,
                            message: "请选择任务工期",
                            trigger: "blur",
                        },
                    ],
                },
                // --------------添加开始------------
            ],
            formBasicList1: [
                {
                    label: "任务概况",
                    value: "assignOverview",
                    inputType: "text",
                    type: "textarea",
                    placeholderText: "请输入任务概况",
                    disabled: false,
                    defaultValue: "",
                    autosize: { minRows: 2 },
                    rules: [
                        {
                            required: true,
                            message: "请输入任务概况",
                            trigger: "blur",
                        },
                    ],
                },
                {
                    label: "工作内容及工作量",
                    value: "contentWorkload",
                    inputType: "text",
                    type: "textarea",
                    placeholderText: "请输入工作内容及工作量",
                    disabled: false,
                    defaultValue: "",
                    autosize: { minRows: 2 },
                    rules: [
                        {
                            required: true,
                            message: "请输入工作内容及工作量",
                            trigger: "blur",
                        },
                    ],
                },
                {
                    label: "执行标准及质量要求",
                    value: "qualityRequire",
                    inputType: "text",
                    type: "textarea",
                    placeholderText: "请输入执行标准及质量要求",
                    disabled: false,
                    defaultValue: "",
                    autosize: { minRows: 2 },
                    rules: [
                        {
                            required: true,
                            message: "请输入执行标准及质量要求",
                            trigger: "blur",
                        },
                    ],
                },
                {
                    label: "应提交的资料",
                    value: "resultList",
                    inputType: "text",
                    type: "textarea",
                    placeholderText: "请输入应提交的资料",
                    disabled: false,
                    defaultValue: "",
                    autosize: { minRows: 2 },
                    rules: [
                        {
                            required: true,
                            message: "请输入应提交的资料",
                            trigger: "blur",
                        },
                    ],
                },
                {
                    label: "特殊情况及注意事项",
                    value: "specialPrecautions",
                    inputType: "text",
                    type: "textarea",
                    placeholderText: "请输入特殊情况及注意事项",
                    disabled: false,
                    defaultValue: "",
                    autosize: { minRows: 2 },
                },
            ],
            // 客户相关信息
            formBasicListKh: [
                {
                    label: "客户名称",
                    value: "cmmId",
                    inputType: "select",
                    optionsList: [],
                    filterable: true,
                    placeholderText: "请选择客户名称",
                    optionsListConfig: {
                        name: "name",
                        code: "id",
                    },
                    rules: [
                        {
                            required: true,
                            message: "请选择客户名称",
                            trigger: "blur",
                        },
                    ],
                    remote: true,
                    remoteMethod: (query) => {
                        this.getCrmList(query);
                    },
                    change: this.getCrmPerson,
                },
                {},
                {},
                {
                    label: "客户联系人",
                    value: "crmContactId",
                    inputType: "select",
                    optionsList: [],
                    filterable: true,
                    placeholderText: "请选择客户联系人",
                    optionsListConfig: {
                        code: "id",
                        name: "name",
                    },
                    rules: [
                        {
                            required: true,
                            message: "请选择客户联系人",
                            trigger: "blur",
                        },
                    ],
                    change: this.selectContactPhone,
                },
                {
                    label: "",
                    Btnlabel: "新增客户联系人",
                    inputType: "button",
                    click: () => {
                        this.addCustomerContact();
                    },
                },
                {},
                {
                    label: "个人联系方式",
                    disabled: true,
                    value: "contactPhone",
                    defaultValue: "",
                    inputType: "text",
                    rules: [
                        {
                            required: false,
                            message: "请输入个人联系方式",
                            trigger: "blur",
                        },
                    ],
                },
                {
                    label: "工作联系方式",
                    value: "crmContactDept",
                    inputType: "text",
                    disabled: true,
                    defaultValue: "",
                },
            ],
            contactPhoneList: [],
            detailData: null,
            // 客户信息
            basicCrmContactList: [],
            detailPopupVisible: false,
            assignTypeList: [],
        };
    },
    created() { },
    mounted() {
        if (this.titleName != "详情") {
            this.formBasicList.forEach((item, index) => {
                if (item.value == "contactPhone") {
                    this.formBasicList.splice(index, 1);
                }
            });
        }
        
        this.selectOterData();
        // this.getDataType();
        this.getAssignLevel();
        this.getCrmList("", "init");
        this.getRegionList();
        setTimeout(() => {
            if (this.rowData != null) {
                this.getDataDetail(this.rowData.id);
            }
        }, 500);
    },
    methods: {
        selectOterData(d = "") {
            let data = new Date();
            if (d != "") {
                data = new Date(d);
            }
            let yyyy = data.getFullYear();
            this.formBasicList.forEach((item) => {
                if (item.value == "assignYear") {
                    item.defaultValue = `${yyyy}`;
                } else {
                    item.defaultValue = this.$refs.addPopForm.formData[item.value];
                }
            });
        },

        //条件查询表单--下拉选择数据填充（optionsList）
        setOPtionsList(formList, options) {
            formList.forEach((item) => {
                if (options) {
                    if (item.value == options.value) {
                        item.optionsList = options.optionsList;
                    }
                }
            });
        },

        // 获取数据字典类型 - 项目名称及编号
        getDataType() {
            // 区域列表
            getApi(`/admin/syscompanyregion/list`).then((res) => {
                let { data } = res;
                if (data && data.code == 0) {
                    let options = {
                        value: "regionAreaId",
                        optionsList: data.data,
                    };
                    this.setOPtionsList(this.formBasicList, options);
                }
            });
            // 项目生产需求级别
            postApi(`/project/prj/dict/list/all`, { typeCode: "PRJ-XMJB" }).then(
                (res) => {
                    let { data } = res;
                    if (data && data.code == 0) {
                        let options = {
                            value: "assignLevel",
                            optionsList: data.data,
                        };
                        this.setOPtionsList(this.formBasicList, options);
                    }
                }
            );
            this.getCrmList("", "init");
        },
        getRegionList(){
            // 区域列表
            getApi(`/admin/syscompanyregion/list`).then((res) => {
                let { data } = res;
                if (data && data.code == 0) {
                    let options = {
                        value: "regionAreaId",
                        optionsList: data.data,
                    };
                    this.setOPtionsList(this.formBasicList, options);
                }
            });
        },
        getAssignLevel(){
            postApi(`/project/prj/dict/list/all`, { typeCode: "PRJ-XMJB" }).then(
                (res) => {
                    let { data } = res;
                    if (data && data.code == 0) {
                        let options = {
                            value: "assignLevel",
                            optionsList: data.data,
                        };
                        this.setOPtionsList(this.formBasicList, options);
                    }
                }
            );
        },
        // 获取客户列表
        getCrmList(name, type) {
            let params = {
                name: name,
            };
            postApi(`/project/customerinfo/list`, params).then((res) => {
                let { data } = res;
                if (data.code == 0) {
                    this.formBasicListKh.forEach((item) => {
                        if (item.value == "cmmId") {
                            item.optionsList = data.data;
                            // if (type != "init") {
                            //   item.defaultValue = null;
                            // }
                        } else {
                            // item.defaultValue = this.$refs.addPopFormKh.formData[item.value];
                        }
                    });
                }
            });
        },
        handleClose() {
            this.getCrmPerson(this.crmId);
            this.addEditVisible = false;
        },
        // 获取客户联系人
        getCrmPerson(id, type) {
            this.formBasicListKh.forEach((item) => {
                if (item.value == "crmContactId") {
                    item.defaultValue = "";
                } else if (item.value == "contactPhone") {
                    item.defaultValue = "";
                } else {
                    item.defaultValue = this.$refs.addPopFormKh.formData[item.value];
                }
            });
            if (id != "") {
                let params = {
                    crmId: id,
                };
                this.crmId = id
                postApi(`/project/customercontactdetail/list`, params).then((res) => {
                    let { data } = res;
                    if (data && data.code == 0) {
                        this.contactPhoneList = data.data;
                        this.formBasicListKh.forEach((item) => {
                            if (item.value == "crmContactId") {
                                item.optionsList = data.data;
                                // if (type != "init") {
                                //   item.defaultValue = null;
                                // }
                            } else {
                                item.defaultValue =
                                    this.$refs.addPopFormKh.formData[item.value];
                            }
                        });
                    }
                });
            }
        },
        addCustomerContact() {
            if (!this.$refs.addPopFormKh.formData.cmmId) {
                this.$message.warning("请先选择客户");
                return false;
            }
            this.addEditVisible = true;
        },
        // 选择联系人
        selectContactPhone(id) {
            this.formBasicListKh.forEach((item) => {
                if (item.value == "contactPhone" || item.value == "crmContactDept") {
                    item.defaultValue = "";
                } else {
                    item.defaultValue = this.$refs.addPopFormKh.formData[item.value];
                }
            });
            this.contactPhoneList.forEach(item => {
                if (item.id == id) {
                    this.$refs.addPopFormKh.formData.contactPhone = item.personalContact
                    this.$refs.addPopFormKh.formData.crmContactDept = item.workContact
                    this.formBasicListKh.forEach((it) => {
                        if (it.value == 'contactPhone') {
                            it.defaultValue = item.personalContact;
                        }
                        if (it.value == 'crmContactDept') {
                            it.defaultValue = item.workContact;
                        }
                    })
                    this.$forceUpdate()
                }
            })
            // this.contactPhoneList.forEach(obj => {
            //   if(obj.id == id){
            //     this.formBasicListKh.forEach((item) => {
            //       if (item.value == "crmContactId") {
            //         item.defaultValue = obj.id;
            //       }
            //       if (item.value == "contactPhone") {
            //         item.defaultValue = obj.phone;
            //       }
            //     })
            //   }
            // })
        },
        handleSave() {
            let flag = false;
            this.$refs.addEditForm.$refs.addEditForm.validate((valid) => {
                if (valid) {
                    flag = true;
                }
            });
            if (!flag) {
                return false;
            }
            let { name, personalContact, workContact } =
                this.$refs.addEditForm.formData;
            let params = {
                crmId: this.crmId,
                name,
                personalContact,
                workContact,
            };
            postApi(`/project/customercontactdetail`, params).then((res) => {
                if (res.data.code == 0) {
                    this.$message.success("操作成功");
                } else {
                    this.$message.warning("操作失败，请稍后重试");
                }
                this.getCrmPerson(this.crmId);
                // this.getTableData();
            });
            this.addEditVisible = false;
        },
        // 编辑
        getDataDetail(id) {
            getApi(`/project/assign/${id}`).then((res) => {
                let { data } = res;
                if (data && data.code == 0) {
                    this.detailData = data.data;
                    this.getCrmList(data.data.cmmName, "init");
                    this.getCrmPerson(data.data.cmmId, "init");
                    this.basicCrmContactList = data.data.basicCrmContactList;
                    this.formBasicList.forEach((item, index) => {
                        if (item.value == "taskDuration") {
                            this.$refs.addPopForm.formData.taskDuration = [data.data.startTime, data.data.endTime]
                        }else {
                            this.$refs.addPopForm.formData[item.value] = data.data[item.value];
                        }
                    });
                    this.formBasicList1.forEach((item) => {
                        item.defaultValue = data.data[item.value];
                    });
                    this.formBasicListKh.forEach((item) => {
                        item.defaultValue = data.data[item.value];
                    });

                    // 
                }
            });
        },
        // 查看修改记录
        handleDetail() {
            this.detailPopupVisible = true;
        },
    },
};
</script>
<style lang="less" scoped>
/deep/.el-input.is-disabled .el-input__inner {
    background-color: #fbfbfb;
    color: @defaultFontColor;
}

/deep/.el-textarea.is-disabled .el-textarea__inner {
    background-color: #fbfbfb;
    color: @defaultFontColor;
    resize: none;
}

// /deep/ .el-input__suffix {
//   display: none;
// }
/deep/ .el-range-editor.is-disabled {
    background-color: #fbfbfb;
    color: @defaultFontColor;
}

/deep/ .el-range-editor.is-disabled input {
    color: @defaultFontColor;
}

/deep/ .el-descriptions-item__label {
    color: #606266;
    font-weight: bold;
}

/deep/ .el-descriptions-item__content {
    color: @defaultFontColor;
}

/deep/.el-tabs__item {
    width: 20% !important;
}

// /deep/.el-tabs__item{
//   color:red !important;
// }
.buttonBtn {
    height: 50px;
    line-height: 50px;
}
</style>