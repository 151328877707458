<template>
  <div class="container">
    <dd-table
      :columns="columnsList"
      :data="tableDataSources"
      border
      class="myTable_33"
      ref="dTableRef"
      show-page
      :isSelection="false"
    >
    </dd-table>
    <!-- 标准工作包弹窗 -->
    <dd-popup
      v-if="standardVisible"
      width="40%"
      height="50%"
      :inPopup="true"
      :showBtn="true"
      :title-name="popTitle"
      :show="standardVisible"
      @popup-close="handleClose"
      @popup-save="handleSave('addPopForm', 'baseForm')"
    >
      <AddPopDetailEidt
        slot="main"
        ref="customizable"
        :rowData="rowData"
        :editData="editData"
      ></AddPopDetailEidt>
    </dd-popup>
  </div>
</template>

<script>
import { postApi, getApi, loadApi } from "@/api/request";
import  AddPopDetailEidt from "./AddPopDetailEidt.vue";
export default {
  props: {
    id: "",
    rowData:[],
    isEdit:false,
  },
  components: { AddPopDetailEidt },
  data() {
    return {
      standardVisible:false,
      popTitle:'',
      rowId:'',
      //筛选列表格头部配置
      columnsList: [],
      // 表格数据集合
      tableDataSources: [],
      editData:{},
    };
  },
  mounted() {
    this.getDetailInfo();
  },
  methods: {
    //页面信息回显
    getDetailInfo() {
      getApi(`/project/performanceaccounting/detail/${this.id}`).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          let columnsList = [];
          let rowDataList = [];
          data.data.title.map((item) => {
            if (item.key != "id") {
              columnsList.push({
                title: item.value,
                key: item.key,
                align: "center",
              });
            }
            if (item.workMetering) {
              rowDataList.push({
                key: item.value,
                value: item.key,
                workMetering: item.workMetering,
                options: item.options ? item.options : [],
              });
            }
          });
          if (this.rowData.standardWorkpackage == 1 && this.isEdit) {
            columnsList.push({
              title: "操作",
              align: "center",
              width: "100px",
              fixed: "right",
              event: [
                {
                  label: "编辑",
                  state: "default",
                  icon: "el-icon-edit",
                  type: "warning",
                  method: (row) => {
                    this.handleEdit(row);
                  },
                },
              ],
            });
          }
          this.columnsList = columnsList;
          if(this.rowData.standardWorkpackage == 1 && this.isEdit) {
            this.columnsList.forEach(item => {
              if(item.title.length > 0 && item.title.length >= 5) {
                item.width = `${item.title.length * 20}px`
              }
              else {
                item.width = `${item.title.length * 30}px`
              }
            })
          }
          
          this.rowDataList = rowDataList;
          this.tableDataSources = data.data.dataList;
        }
      });
    },
    // 标准工作包修改弹窗
    handleEdit(row){
      this.rowId = row.id;
      this.standardVisible = true;
      this.popTitle = "编辑";
      this.editData = row;
    },
    // 弹框关闭事件
    handleClose() {
      this.standardVisible = false;
    },
    // 标准工作包保存
    handleSave(formName, formNameBase){
      let flag = false;
      this.$refs.customizable.$refs[formName].$refs[formNameBase].validate(
        (valid) => {
          if (valid) {
            flag = true;
          }
        }
      );
      let params = this.$refs.customizable.$refs[formName].formData;
      if(params.coefficientAdjustment == ''){
        return this.$message.warning("请输入系数调整");
      }
      if(params.fixedAdjustment == ''){
        return this.$message.warning("请输入固定调整");
      }
      if(params.performanceRemark == ''){
        return this.$message.warning("请输入绩效计算备注说明");
      }
      if (flag) {
        if (this.rowData) {
          params.id = this.rowId;
        }
        postApi(`/project/performanceaccounting/update`, params).then((res) => {
          let { data } = res;
          if (data.code == 0) {
            this.$message.success("保存成功");
            this.handleClose();
            this.getDetailInfo();
          }
        });
      }
    },
  },
};
</script>

<style lang='less' scoped>
.base-form-collect-info {
  position: relative;
  .add-workload-btn {
    position: absolute;
    right: 154px;
    bottom: 23px;
  }
}
/deep/ .el-table__header-wrapper {
  background-color: @tableHeaderBg !important;
}
/deep/ .el-table .has-gutter .el-table__cell {
  background-color: @tableHeaderBg;
  color: @defaultFontColor;
}
/deep/.el-input.is-disabled .el-input__inner {
  background-color: #fbfbfb;
  color: @defaultFontColor;
}
/deep/.el-textarea.is-disabled .el-textarea__inner {
  background-color: #fbfbfb;
  color: @defaultFontColor !important;
  resize: none;
}
/deep/ .el-input__suffix {
  display: none;
}
/deep/ .el-table__fixed-body-wrapper {
  // top: 86px !important;
}

/deep/ .myTable_33 .table-box .el-table__fixed-right-patch {
    width: 0px !important;
    background-color: @tableHeaderBg;
    position: static !important;
  }
</style>