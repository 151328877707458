<template>
  <div class="main-container">
    <dd-card title="条件查询" spacer>
      <dd-search-form
        :form-config="formList"
        :labelWidth="'170px'"
        @search-click="handleSearchEvent"
      ></dd-search-form>
    </dd-card>
    <dd-card title="成本分解预算列表" :height="tableHeight">
      <dd-table
        :columns="columnsList"
        :data="tableData"
        border
        ref="dTableRef"
        :loading="loading"
        show-page
        :isSelectable="true"
        @select-change="handleSelectionChange"
        :handleCheckDisable="handleCheckDisable"
      >
        <template #table-btn-right>
          <el-button
            icon="el-icon-s-operation"
            circle
            type="info"
            size="small"
            @click="drawerVisible = true"
            title="列筛选"
          ></el-button>
        </template>
        <!-- <template #table-btn>
          <dd-button type="primary" size="medium" @click="viewDetails()"
            >查看详情</dd-button
          >
          <dd-button
            type="primary"
            size="medium"
            icon="add"
            @click="exportDetail()"
            >导出</dd-button
          >
        </template> -->
        <template #table-pagination>
          <dd-pagination
            :total="total"
            :page-size="size"
            :page-number="current"
            @page-change="handleCurrentChange"
            align="right"
          >
          </dd-pagination>
        </template>
      </dd-table>
    </dd-card>

    <dd-popup
      v-if="dialogEditDetailVisible"
      width="70%"
      height="80%"
      showBtn
      :saveBtn="false"
      :submitBtn="showSubmitBtn"
      :show="dialogEditDetailVisible"
      :title-name="EditDetailTitle"
      @popup-submit="handleSubmit('decomposeForm')"
      @popup-save="handleSubmit(0)"
      @popup-close="dialogEditDetailVisible = false"
    >
      <DecomposeEditDetail
        ref="DecomposeEdit"
        slot="main"
        :propDetail="propDetail"
        :pageType="pageType"
        :type="type"
      ></DecomposeEditDetail>
    </dd-popup>

    <dd-popup
      v-if="dialogDetailVisible"
      width="70%"
      height="80%"
      :title-name="applyDetailTitle"
      :show="dialogDetailVisible"
      @popup-close="dialogDetailVisible = false"
    >
      <applyDetail
        slot="main"
        :applyId="applyId"
        :projectId="projectId"
        :amountType="amountType"
        :pageType="pageType"
        :assignId="assignId"
      ></applyDetail>
    </dd-popup>
    <dd-filter-drawer
      v-if="drawerVisible"
      width="30%"
      title-name="列筛选"
      :show="drawerVisible"
      :tableColumns="tableColumns"
      :columns="columnsList"
      @popup-close="drawerVisible = false"
      @handleColumnsChange="handleColumnsChange"
    >
    </dd-filter-drawer>
  </div>
</template>
  
  <script>
import { postApi, loadApi, getApi, putApi } from "@/api/request";
import DecomposeEditDetail from "./components/DecomposeEditDetail.vue"; // 详情
import applyDetail from './components/applyDetail.vue'
import { formatNumber } from "@/utils/number";
import { downloadBinaryFile } from "@/utils/index";
export default {
  name: "detailed",
  $dynamicRoute: {
    name: " 成本分解预算表",
    path: "/finance/budget/decompose",
    meta: {
      keepAlive: true,
    },
  },
  components: { DecomposeEditDetail, applyDetail },
  data() {
    return {
      drawerVisible: false,
      tableHeight: null,
      amountType: "",
      EditDetailTitle: "",
      loading: false,
      dialogEditDetailVisible: false,
      dialogAddEditSalesVisible: false,
      showSubmitBtn: true,
      selectTable: [],
      propDetail: {
          id: null,
          type: "",
        }, //传参子组件
      type: "edit",
      pageType: 'decompose',
      tableData: [],
      applyId: null,
      dialogDetailVisible: false,
      projectId: null,
      amountType: null,
      applyDetailTitle: '',
      formList: [
        {
          label: "单据编码",
          value: "code",
          inputType: "text",
          placeholderText: "请输入单据编码",
        },
        {
          label: "任务书名称及编码",
          value: "assignName",
          inputType: "text",
          placeholderText: "请选择或搜索任务书名称及编码",
        },
        // {
        //   label: "启用状态",
        //   value: "projectId",
        //   inputType: "select",
        //   optionsList: [],
        //   filterable: "filterable",
        //   placeholderText: "请选择启用状态",
        //   optionsListConfig: {
        //     name: "projectCode",
        //     code: "projectId",
        //   },
        // },
        {
          label: "类型",
          value: "editType",
          inputType: "select",
          optionsList: [],
          defaultValue: "",
          optionsListConfig: {
            name: "name",
            code: "code",
          },
          placeholderText: "请选择类型",
        },
      ],
      // 表格头部配置数据
      tableColumns: [
        {
          title: "单据编码",
          key: "code",
          align: "center",
        },
        {
          title: "任务书编码",
          key: "assignNum",
          align: "center",
          overflow: true,
        },
        {
          title: "任务书名称",
          key: "assignName",
          align: "center",
        },
        {
          title: "预算总金额",
          key: "budgetTotalAmount",
          align: "center",
          event: [
            {
              type: "primary",
              method: (row) => {
                this.handleRowHref(row);
              },
              href: (row) => {
                return (
                  row.budgetTotalAmount
                );
              },
            },
          ],
        },
        {
          title: "关联生产预算版本",
          key: "scBudgetVersion",
          align: "center",
        },
        {
          title: "关联销售预算版本",
          key: "xsBudgetVersion",
          align: "center",
        },
        // {
        //   title: "版本",
        //   key: "isEnableName",
        //   align: "center",
        // },
        // {
        //   title: "启用状态",
        //   key: "applier",
        //   align: "center",
        // },
        {
          title: "类型",
          key: "editTypeName",
          align: "center",
        },
        {
          title: "操作",
          align: "center",
          width: 170,
          event: [
            {
              label: "编辑",
              type: "warning",
              state: "default",
              rules: (row) => {
                let show = false;
                if (row.editType == "CW-CBFJ-LX-KBJ") {
                  show = true;
                } else {
                  show = false;
                }
                return show;
              },
              method: (row) => {
                this.handleEdit(row);
              },
            },
            {
              label: "查看详情",
              state: "default",
              method: (row) => {
                this.handleDetail(row);
              },
            },
          ],
        },
      ],
      searchObj: {},
      columnsList: [],
      current: 1, // 当前页码
      total: 0, // 总条数
      size: 10, // 每页的数据条数
    };
  },
  created() {
    this.getEditType();
    this.getTableList();
  },
  mounted() {
    this.$nextTick(() => {
      this.tableHeight = this.autoTableHeight(0);
    });
    this.columnsList = [...this.tableColumns];
  },
  methods: {
    handleColumnsChange(val) {
      let list = new Set();
      this.tableColumns.map((item) => {
        val.map((itm) => {
          if (item.event || item.key == itm.key) {
            list.add(item);
          }
        });
      });
      this.columnsList = [...list];
    },
    viewDetails() { // 查看详情
      if(this.selectTable.length != 1) {
        this.$message.warning('请选择一条数据');
        return false;
      }
      this.type = "detail";
      this.propDetail.id = this.selectTable[0].id;
      this.showSubmitBtn = false;
      this.EditDetailTitle = "成本分解预算表详情";
      this.dialogEditDetailVisible = true;
    },
    exportDetail() { // 导出
      this.dialogAddEditSalesVisible = true;
    },

    handleDetail(row) { // 查看详情
      this.dialogEditDetailVisible = true;
      this.type = "detail";
      this.propDetail.id = row.id;
      this.showSubmitBtn = false;
      this.EditDetailTitle = "成本分解预算表详情";
    },
    handleEdit(row) { // 编辑
      this.dialogEditDetailVisible = true;
      this.type = "edit";
      this.propDetail.id = row.id;
      this.showSubmitBtn = true;
      this.EditDetailTitle = "成本分解预算表编辑";
    },
    getTableList(obj) { // 获取列表数据
      postApi(`/finance/finbudget/costdisassemble/page/${this.current}/${this.size}`, obj ? obj : {}).then(res => {
        if(res.data.code == 0) {
          this.tableData = res.data.data.records;
          this.total = res.data.data.total;
        }
      })
    },
    handleCurrentChange(size, number) { // 分页切换
      this.current = number;
      this.size = size;
      this.getTableList(this.searchObj);
    },
    handleSearchEvent(e) {
      this.searchObj = e;
      this.getTableList(e);
    },
    handleSelectionChange(e) { // checkbox 框 change 
      this.selectTable = e;
    },
    handleSubmit(ref) {
      const tabArr = this.$refs.DecomposeEdit.tabArr;
      let arr = [];
      tabArr.forEach(item => {
        arr.push(item.children[0])
      })
      const params = {
        id: this.$refs.DecomposeEdit.$refs[ref].formData.id,
        budgetTotalAmount: this.$refs.DecomposeEdit.$refs[ref].formData.budgetTotalAmount,
        detailList: arr,
      };
      putApi('/finance/finbudget/costdisassemble', params).then(res => {
        if(res.data.code == 0) {
          this.$message.success('提交成功');
          this.getTableList();
          this.dialogEditDetailVisible = false;
        }
        else {
          this.$message.warning(res.data.msg);
        }
      })
    },
    // 
    getEditType() {
      postApi(`/finance/dict/list/all`, {typeCode:  "CW-CBFJ-LX"}).then(res => {
        if(res.data.code == 0) {
          this.formList.forEach(item => {
            if(item.value == 'editType') {
              item.optionsList = res.data.data;
            }
          })
        }
      })
    },
    handleRowHref(row) {
      this.applyDetailTitle = "预算总金额";
      this.applyId = row.id;
      this.assignId = row.assignId;
      this.dialogDetailVisible = true;
      this.projectId = row.projectId;
      this.amountType = "totalAmount";
    },
    handleCheckDisable() { // 禁用 checkbox 框
      return true
    },
  },
};
</script>
  
  <style lang="less" scoped></style>
  