<template>
  <div v-if="defaultPage" class="main-container">
    <dd-card title="条件搜索" spacer>
      <dd-search-form
        :form-config="formList"
        @search-click="handleSearchEvent"
        ref="ddSearchForm"
        labelWidth="180px"
      ></dd-search-form>
    </dd-card>
    <dd-card title="任务书列表" :height="tableHeight">
      <dd-table
        :columns="tableColumns"
         @select-change="handleSelectionChange"
        :data="tableData"
        :topBtn="true"
        ref="TableRef"
        border
        :isSelection="true"
        show-page
        :loading="loading"
      >
      <template #table-btn>
        <dd-button type="info" size="medium"  @click="handlePull"
            >同步</dd-button
          >
          <dd-button type="primary" icon="add" size="medium"  @click="handleAdd"
            >新增任务书</dd-button
          >
          <dd-button type="success" size="medium"  @click="handleExport"
            >导出</dd-button
          >
      </template>
        <template #table-pagination>
          <dd-pagination
            v-show="total > 0"
            :total="total"
            :page-size="size"
            :page-number="current"
            @page-change="handleCurrentChange"
            align="right"
          >
          </dd-pagination>
        </template>
      </dd-table>
    </dd-card>
    <dd-filter-drawer
      v-if="drawerVisible"
      width="30%"
      title-name="列筛选"
      :show="drawerVisible"
      :tableColumns="tableColumns"
      :columns="columnsList"
      @popup-close="drawerVisible = false"
      @handleColumnsChange="handleColumnsChange"
    >
    </dd-filter-drawer>
    <!-- 关联项目与合同页面弹窗 -->
    <dd-popup
      width="50%"
      height="50%"
      :showBtn="true"
      :show="addPopupVisible"
      title-name="关联项目与合同"
      @popup-close="handleClose"
      @popup-save="handleSave('addPopForm', 'baseForm', 0)"
    >
      <add-pop
        v-if="addPopupVisible"
        slot="main"
        ref="addPop"
        :rowData="rowData"
      ></add-pop>
    </dd-popup>
    <dd-popup
      width="80%"
      height="80%"
      :show="detailPopupVisible"
      title-name="详情"
      @popup-close="handleClose"
    >
      <detail-pop
        v-if="detailPopupVisible"
        slot="main"
        ref="addPop"
        :rowData="rowData"
      ></detail-pop>
    </dd-popup>

    <dd-popup
      width="50%"
      height="60%"
      :showBtn="true"
      :show="editPopupVisible"
      title-name="修改项目与合同"
      @popup-close="handleClose"
      @popup-save="handleSaveEdit('editPopForm', 'baseForm', 0)"
    >
      <edit-pop
        v-if="editPopupVisible"
        slot="main"
        ref="editPop"
        :rowData="rowData"
      ></edit-pop>
    </dd-popup>

    <dd-popup width="80%" height="80%" :showBtn="poptitleName == '详情' ? false : true" :saveBtn="false"
            :submitBtn="true" :show="addTaskVisible" :title-name="poptitleName" @popup-close="handleClose"
            @popup-submit="handleSaveTaskBook('addPopForm', 'baseForm')">
            <addTaskBook v-if="addTaskVisible" slot="main" ref="addTaskBook" :titleName="poptitleName"
                ></addTaskBook>
        </dd-popup>
  </div>
</template>

<script>
import { getApi, postApi, loadApi } from "@/api/request";
import addTaskBook from "./components/addTaskBook.vue";
// 关联项目与合同页面弹窗
import addPop from "./components/AddPop.vue";
import editPop from "./components/EditPop.vue";
import detailPop from "./components/DetailPop.vue";
import { downloadBinaryFile } from "@/utils/index";
export default {
  name: "taskPrjManage",
  $dynamicRoute: {
    name: "依据生产任务书立项管理",
    path: "/taskPrjManage/index",
  },
  components: {
    addPop,
    detailPop,
    editPop,
    addTaskBook
  },
  computed: {},
  data() {
    return {
      poptitleName: '新增',
      addTaskVisible: false,
      tableHeight: null,
      defaultPage: true,
      editPopupVisible: false,
      drawerVisible: false,
      loading: true,
      formData: {
        associationStatus: 'PRJ-ASSOCIATION-WGL'
      },
      formList: [
        {
          label: "任务书编号",
          value: "assignCode",
          inputType: "text",
          placeholderText: "请输入任务书编号",
        },
        {
          label: "任务书名称",
          value: "assignName",
          inputType: "text",
          placeholderText: "请输入任务书名称",
        },
        {
          label: "任务书关联状态",
          value: "associationStatus",
          inputType: "select",
          optionsList: [],
          defaultValue: 'PRJ-ASSOCIATION-WGL',
          placeholderText: "请选择任务书关联状态",
          optionsListConfig: {
            name: "name",
            code: "code",
          },
        },
        {
          label: "任务书年份",
          value: "assignYear",
          inputType: "date",
          dateType: "year",
          format: "yyyy",
          placeholderText: "请选择任务书年份",
        },
        {
          label: "下发部门",
          value: "downDeptId",
          inputType: "select",
          optionsList: [],
          filterable: true,
          placeholderText: "请选择下发部门",
          optionsListConfig: {
            name: "name",
            code: "deptId",
          },
        },
        {
          label: "任务生产部门",
          value: "receiveDeptId",
          inputType: "select",
          optionsList: [],
          filterable: true,
          placeholderText: "请选择任务生产部门",
          optionsListConfig: {
            name: "name",
            code: "deptId",
          },
        },
      ],
      tableColumns: [
        {
          title: "任务书单据编号",
          key: "assignCode",
          align: "center",
          width: 180,
        },
        {
          title: "任务书名称",
          key: "assignName",
          align: "center",
          width: 180,
        },
        {
          title: "所属区域",
          key: "regionAreaName",
          align: "center",
          width: 100,
        },
        {
          title: "现任务下发部门",
          key: "nowDownDeptName",
          align: "center",
          width: 120,
        },
        {
          title: "现生产负责部门",
          key: "nowReceiveDeptName",
          align: "center",
          width: 120,
        },
        {
          title: "客户经理",
          key: "cmmmanagerName",
          align: "center",
        },
        {
          title: "任务书编码",
          key: "assignNum",
          align: "center",
          width: 180,
        },
        {
          title: "任务书关联状态",
          key: "associationStatusName",
          align: "center",
        },
        {
          title: "关联项目编号",
          key: "prjCode",
          align: "center",
        },

        {
          title: "关联项目名称",
          key: "prjName",
          align: "center",
        },
        {
          title: "关联合同编号",
          key: "contractCode",
          align: "center",
        },
        {
          title: "关联合同名称",
          key: "contractName",
          align: "center",
        },

        {
          title: "操作",
          key: "handle",
          align: "center",
          width: 220,
          fixed: "right",
          event: [
            {
              label: "查看",
              icon: "el-icon-view",
              method: (row) => {
                this.handleRowDetail(row);
              },
            },
            {
              label: "关联项目与合同",
              icon: "el-icon-edit",
              method: (row) => {
                this.handleRowEditor(row);
              },
              type: "primary",
              rules: (row) => {
                let show = false;
                if (
                  row.associationStatus == "PRJ-ASSOCIATION-WGL" ||
                  row.associationStatus == null
                ) {
                  show = true;
                } else {
                  show = false;
                }
                return show;
              },
            },
            {
              label: "修改关联合同信息",
              icon: "el-icon-edit",
              method: (row) => {
                this.handleRowChange(row);
              },
              type: "primary",
              rules: (row) => {
                let show = false;
                if (
                  row.associationStatus == "PRJ-ASSOCIATION-YGL"
                ) {
                  show = true;
                } else {
                  show = false;
                }
                return show;
              },
            },
          ],
        },
      ],
      tableData: [],
      current: 1,
      size: 20,
      total: null,
      addPopupVisible: false,
      detailPopupVisible: false,
      rowData: null,
    };
  },
  created() {},
  mounted() {
    // 自动计算table高度
    this.$nextTick(() => {
      this.tableHeight = this.autoTableHeight(0);
    });
    this.getDataType();
    this.getTableData();
  },
  methods: {
    handleAdd() {
      this.addTaskVisible = true
    },
    handleSaveTaskBook(formName, formNameBase) {
      let flag = false;
      let flag1 = false;
      let flagKh = false;
      let flagXm = false;
      this.$refs.addTaskBook.$refs[formName].$refs[formNameBase].validate(
          (valid) => {
            if (valid) {
              flag = true;
              this.$refs.addTaskBook.modifyJbxx(0)
            } else {
              this.$refs.addTaskBook.modifyJbxx(1)
            }
          }
        );
        this.$refs.addTaskBook.$refs.addPopForm1.$refs[formNameBase].validate(
          (valid) => {
            if (valid) {
              flag1 = true;
            }
          }
        );
        this.$refs.addTaskBook.$refs.addPopFormKh.$refs[formNameBase].validate(
          (valid) => {
            if (valid) {
              flagKh = true;
              this.$refs.addTaskBook.modifyKhxgxx(0)
            } else {
              this.$refs.addTaskBook.modifyKhxgxx(1)
            }
          }
        );
        this.$refs.addTaskBook.$refs.addPopFormXm.$refs[formNameBase].validate(
          (valid) => {
            if (valid) {
              flagXm = true;
              this.$refs.addTaskBook.modifyXmglxx(0)
            } else {
              this.$refs.addTaskBook.modifyXmglxx(1)
            }
          }
        );

        let params1 = this.$refs.addTaskBook.$refs[formName].formData,
        params2 = this.$refs.addTaskBook.$refs.addPopForm1.formData,
        paramsKh = this.$refs.addTaskBook.$refs.addPopFormKh.formData,
        paramsXm = this.$refs.addTaskBook.$refs.addPopFormXm.formData,
        params = {
          ...params1,
          ...params2,
          ...paramsKh,
          ...paramsXm,
          applierId: sessionStorage.getItem("userId"),
          applier: sessionStorage.getItem("fullName"),
        };
      if (flag && flag1 && flagKh && flagXm) {
        if (this.rowData) {
          params.id = this.rowData.id;
        }
        postApi(`/project/assign/direct/save`, params).then((res) => {
          let { data } = res;
          if (data.code == 0) {
            this.$message.success("提交成功");
            this.handleClose();
            this.getTableData();
          }
        });
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handlePull() {
      getApi(`/basic/project/synchronize`).then(res => {
        if(res.data.code == 0) {
          this.$message.success('操作成功')
        }
        else {
          this.$message.warning('操作失败，请稍后重试')
        }
        this.getTableData();
      })
    },
    //条件查询表单--下拉选择数据填充（optionsList）
    setOPtionsList(formList, options) {
      formList.forEach((item) => {
        if (options) {
          if (item.value == options.value) {
            item.optionsList = options.optionsList;
          }
        }
      });
    },
    handleExport() {
      
      let { multipleSelection } = this;
      if (multipleSelection.length == 0) {
        this.$message.warning("请选择导出数据");
        return;
      }
      let idsArr = []
      multipleSelection.forEach(item => {
        idsArr.push(item.id)
      })
      let params = {
        ids: idsArr,
      };
      loadApi(`/project/assign/export/word`, params).then((res) => {
        let { data } = res;
        this.$refs.TableRef.$refs.baseTable.clearSelection();
        this.multipleSelection = [];
        downloadBinaryFile(
          data,
          decodeURIComponent(
            res.headers["content-disposition"].split(";")[1].split("=")[1]
          )
        );
      });
    },
    // 获取数据字典类型
    getDataType() {
      // 获取任务书关联状态数据
      postApi(`/project/prj/dict/list/all`, {
        typeCode: "PRJ-ASSOCIATION",
      }).then((res) => {
        let { data } = res;
        if (data && data.code == 0) {
          let options = {
            value: "associationStatus",
            optionsList: data.data,
          };
          data.data.forEach(item => {
            if(item.name == '未关联') {
              this.$refs.ddSearchForm.formData.associationStatus = item.code;
              this.formData.associationStatus = item.code;
            }
          })
          this.setOPtionsList(this.formList, options);
        }
      });
      //获取部门数据
      postApi(`/admin/dept/getdeptlistbyorgid`, {
        orgId: sessionStorage.getItem("orgId"),
      }).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          let options = {
            value: "receiveDeptId",
            optionsList: data.data,
          };
          let options1 = {
            value: "downDeptId",
            optionsList: data.data,
          };

          this.setOPtionsList(this.formList, options);
          this.setOPtionsList(this.formList, options1);
        }
      });
    },
    // 获取表格列表数据
    getTableData() {
      let { formData, current, size } = this;
      let params = {
        ...formData,
      };
      this.loading = true;
      postApi(`/project/assign/association/${current}/${size}`, params).then(
        (res) => {
          let { data } = res;
          if (data && data.code == 0) {
            this.loading = false;
            this.tableData = data.data.records;
            this.total = data.data.total;
          }
        }
      );
    },
    // 新增弹框关闭事件
    handleClose() {
      this.rowData = null;
      this.addPopupVisible = false;
      this.detailPopupVisible = false;
      this.editPopupVisible= false;
      this.addTaskVisible = false;
    },
    // 表格行内编辑事件
    handleRowEditor(row) {
      this.addPopupVisible = true;
      this.rowData = row;
    },
    handleRowChange(row) {
      this.editPopupVisible = true;
      this.rowData = row;
    },
    // 表格行内查看事件
    handleRowDetail(row) {
      this.detailPopupVisible = true;
      this.rowData = row;
    },
    /* 搜索栏 */
    handleSearchEvent(obj) {
      this.formData = obj;
      this.current = 1;
      this.getTableData();
    },
    /* 分页页码回调 */
    handleCurrentChange(size, number) {
      this.current = number;
      this.size = size;
      this.getTableData();
    },
    // 新增确认事件
    handleSave(formName, formNameBase) {
      let flag = false;
      this.$refs.addPop.$refs[formName].$refs[formNameBase].validate(
        (valid) => {
          if (valid) {
            flag = true;   
          }
        } 
      );
      let params = this.$refs.addPop.$refs[formName].formData;
      params = {
        ...params,
      };
      if (flag) {
        if (this.rowData) {
          params.id = this.rowData.id;
        }
        postApi(`/project/assign/associationprjassign`, params).then((res) => {
          let { data } = res;
          if (data.code == 0) {
            this.$message.success("保存成功");
            this.handleClose();
            this.getTableData();
          }
        });
      }
    },
    handleSaveEdit(formName, formNameBase) {
      let flag = false;
      this.$refs.editPop.$refs[formName].$refs[formNameBase].validate(
        (valid) => {
          if (valid) {
            flag = true;   
          }
        } 
      );
      let params = this.$refs.editPop.$refs[formName].formData;
      params = {
        ...params,
      };
      if (flag) {
        if (this.rowData) {
          params.id = this.rowData.id;
        }
        postApi(`/project/assign/update/contract?id=${this.rowData.id}&contractId=${params.contractId}`).then((res) => {
          let { data } = res;
          if (data.code == 0) {
            this.$message.success("保存成功");
            this.handleClose();
            this.getTableData();
          }
        });
      }
    },
  },
};
</script>

<style lang="less" scoped></style>
