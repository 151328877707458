import { render, staticRenderFns } from "./settlementAssociation.vue?vue&type=template&id=3f40b64d&scoped=true"
import script from "./settlementAssociation.vue?vue&type=script&lang=js"
export * from "./settlementAssociation.vue?vue&type=script&lang=js"
import style0 from "./settlementAssociation.vue?vue&type=style&index=0&id=3f40b64d&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3f40b64d",
  null
  
)

export default component.exports