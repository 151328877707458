<template>
    <div class="container">
        <dd-card title="" v-if="pageProp.pageType == 'change'">
            <dd-form :form-config="formList_remark" :labelWidth="'170px'" :flex="2" ref="assignForm_remark"
                formRef="assignForm_remark"></dd-form>
        </dd-card>

        <dd-card :title="pageProp.pageType == 'change' ? '可修改信息' : '填报信息'">
            <dd-form :form-config="formList" :labelWidth="'230px'" :flex="3" ref="assignForm_list"
                formRef="assignForm_list"></dd-form>
        </dd-card>
        <dd-card title="参考信息">
            <dd-form :form-config="formList_reference" :labelWidth="'230px'" :flex="3" ref="assignForm_reference"
                formRef="assignForm_reference"></dd-form>
        </dd-card>
        <dd-card title="" v-if="pageProp.pageType == 'change'">
            <dd-form :form-config="formList_person" :labelWidth="'230px'" :flex="3" ref="assignForm_person"
                formRef="assignForm_person"></dd-form>
        </dd-card>
    </div>
</template>

<script>
import { getApi, postApi } from '@/api/request';

export default {
    props: ['pageProp'],
    name: 'addEditDetails',
    data() {
        return {
            formList_remark: [
                {
                    label: "修改原因",
                    value: "modifierReason",
                    inputType: "text",
                    type: "textarea",
                    defaultValue: "",
                    rows: "1",
                    placeholderText: "请输入修改原因",
                    show: true,
                    rules: [
                        {
                            required: true,
                            message: "请输入修改原因",
                            trigger: "blur",
                        },
                    ],
                },
            ],
            formList_change: [
                {
                    label: "自建采购合同编号",
                    value: "code",
                    disabled: true,
                    inputType: "text",
                    placeholderText:
                        "请输入自建采购合同编号",
                },
                {
                    label: "自建采购合同名称",
                    value: "name",
                    disabled: true,
                    inputType: "text",
                    placeholderText:
                        "请输入自建采购合同名称",
                },
                {
                    label: "采购年度",
                    value: "procurementYear",
                    disabled: true,
                    inputType: "text",
                    placeholderText:
                        "请输入采购年度",
                },
                {
                    label: "乙方单位",
                    value: "partyBCompany",
                    disabled: true,
                    inputType: "text",
                    placeholderText:
                        "请输入乙方单位",
                },
                {
                    label: "采购合同类型",
                    value: "procurementType",
                    disabled: true,
                    inputType: "select",
                    optionsList: [],
                    optionsListConfig: {
                        name: "name",
                        code: "code",

                    },
                    placeholderText:
                        "请选择采购合同进行搜索",
                },
                {
                    label: "是否关联销售合同",
                    value: "relateType",
                    inputType: "select",
                    disabled: true,
                    filterable:false,
                    optionsList: [
                        { code: "1", name: "是" }, { code: "0", name: "否" }
                    ],
                    optionsListConfig: {
                        name: "name",
                        code: "code",
                    },
                    placeholderText:
                        "请选择关联销售合同类型"
                },
                {
                    label: "关联销售合同名称",
                    value: "saleContractName",
                    disabled: true,
                    inputType: "select",
                    filterable: true,
                    optionsList: [],
                    optionsListConfig: {
                        name: "name",
                        code: "name",
                    },
                    placeholderText:
                        "请选择关联销售合同名称",
                    remote: true,
                    change: ($event, value) => {
                        this.changesaleContract($event, value, 'name')
                    },
                    remoteMethod: (query) => {
                        this.queryContarctList({ name: query })
                    }
                },
                {
                    label: "关联销售合同编号",
                    value: "saleContractCode",
                    inputType: "select",
                    disabled: true,
                    optionsList: [],
                    filterable: true,
                    optionsListConfig: {
                        name: "code",
                        code: "code",
                    },
                    change: ($event, value) => {
                        this.changesaleContract($event, value, 'code')
                    },
                    remote: true,
                    remoteMethod: (query) => {
                        this.queryContarctList({ code: query })
                    },
                    placeholderText:
                        "请选择关联销售合同编号",
                },
                {
                    label: "采购合同变更前总金额",
                    value: "procurementInitialAmount",
                    disabled: true,
                    inputType: "text",
                    placeholderText:
                        "请输入采购合同变更前总金额",
                },
                {
                    label: "关联项目名称",
                    value: "prjName",
                    disabled: true,
                    inputType: "text",
                    placeholderText:
                        "请输入关联项目名称",
                },
                {
                    label: "关联项目编号",
                    value: "prjCode",
                    disabled: true,
                    inputType: "text",
                    placeholderText:
                        "请输入关联项目编号",
                },
                {
                    label: "采购合同变更后总金额",
                    value: "procurementFinallyAmount",
                    disabled: false,
                    inputType: "text",
                    placeholderText:
                        "请输入采购合同变更后总金额",
                    change: ($event, value) => {
                        this.changeProcurementFinallyAmount($event, value)
                    },
                    rules: [
                        {
                            required: true,
                            message: "请输入采购合同变更后总金额",
                            trigger: "blur",
                        },
                        {
                            required: true,
                            pattern: /^\d*\.?\d{0,2}$/,
                            message: "请输入数字，小数最多两位",
                            trigger: "blur",
                        }
                    ],
                },
                {
                    label: "应付外协约定比例(%)",
                    value: "payableOutsourceScale",
                    disabled: true,
                    inputType: "text",
                    placeholderText:
                        "请输入应付外协约定比例(%)",
                },
                {
                    label: "扣除费用",
                    value: "deductExpenses",
                    disabled: true,
                    inputType: "text",
                    placeholderText:
                        "请输入扣除费用",
                },
                {
                    label: "本次变更总金额",
                    value: "changeTotalAmount",
                    disabled: true,
                    inputType: "text",
                    placeholderText:
                        "请输入本次变更总金额",
                },
                {
                    label: "扣除费用详情备注",
                    placeholderText:
                        "请输入扣除费用详情备注",
                    disabled: true,
                    value: "detailRemark",
                    inputType: "text",
                    type: "textarea",
                    defaultValue: "",
                    rows: "2",
                },
                {
                    label: "备注",
                    placeholderText:
                        "请输入备注",
                    disabled: true,
                    value: "remark",
                    inputType: "text",
                    type: "textarea",
                    defaultValue: "",
                    rows: "2",
                },
            ],
            formList_add_edit: [
                {
                    label: "自建采购合同编号",
                    value: "code",
                    inputType: "text",
                    disabled: true,
                    placeholderText:
                        "自动生成",
                },
                {
                    label: "自建采购合同名称",
                    value: "name",
                    inputType: "text",
                    placeholderText:
                        "请输入自建采购合同名称",
                    rules: [
                        {
                            required: true,
                            message: "请输入自建采购合同名称",
                            trigger: "blur",
                        },
                    ],
                },
                {
                    label: "采购年度",
                    value: "procurementYear",
                    inputType: "otherDate",
                    dateType: "year",
                    format: 'yyyy',
                    placeholderText:
                        "请选择采购年度",
                    rules: [
                        {
                            required: true,
                            message: "请选择采购年度",
                            trigger: "blur",
                        },
                    ],
                },
                {
                    label: "采购合同类型",
                    value: "procurementType",
                    inputType: "select",
                    optionsList: [],
                    optionsListConfig: {
                        name: "name",
                        code: "code",

                    },
                    rules: [
                        {
                            required: true,
                            message: "请选择采购合同类型",
                            trigger: "blur",
                        },
                    ],
                    placeholderText:
                        "请选择采购合同进行搜索",
                },
                {
                    label: "采购合同总金额",
                    value: "procurementInitialAmount",
                    inputType: "text",
                    placeholderText:
                        "请输入采购合同总金额",
                    rules: [
                        {
                            required: true,
                            message: "请输入采购合同总金额",
                            trigger: "blur",
                        },
                        {
                            pattern: /^(\d*\.\d{1,2}|\d{1,9})$/,
                            required: true,
                            message: "请输入正确的格式",
                            trigger: "blur",
                        },
                    ],
                },

                {
                    label: "甲方单位",
                    value: "partyACompany",
                    inputType: "text",
                    disabled: true,
                    defaultValue: sessionStorage.getItem('orgName'),
                    placeholderText:
                        "请输入甲方单位",
                    rules: [
                        {
                            required: true,
                            message: "请输入甲方单位",
                            trigger: "blur",
                        },
                    ],
                },
                {
                    label: "乙方单位",
                    value: "partyBCompany",
                    inputType: "text",
                    placeholderText:
                        "请输入乙方单位",
                    rules: [
                        {
                            required: true,
                            message: "请输入乙方单位",
                            trigger: "blur",
                        },
                    ],
                },
                {
                    label: "是否关联销售合同",
                    value: "relateType",
                    inputType: "select",
                    optionsList: [
                        { code: "1", name: "是" }, { code: "0", name: "否" }
                    ],
                    optionsListConfig: {
                        name: "name",
                        code: "code",
                    },
                    clearable: false,
                    rules: [
                        {
                            required: true,
                            message: "请选择关联销售合同类型",
                            trigger: "blur",
                        },
                    ],
                    placeholderText:
                        "请选择关联销售合同类型",
                    change: this.relateTypeSelect,
                },
                {
                    label: "关联销售合同名称",
                    value: "saleContractName",
                    inputType: "select",
                    filterable: true,
                    optionsList: [],
                    optionsListConfig: {
                        name: "name",
                        code: "name",
                    },
                    rules: [
                        {
                            required: true,
                            message: "请选择关联销售合同名称",
                            trigger: "blur",
                        },
                    ],
                    placeholderText:
                        "请选择关联销售合同名称",
                    remote: true,
                    change: ($event, value) => {
                        this.changesaleContract($event, value, 'name')
                    },
                    remoteMethod: (query) => {
                        this.queryContarctList({ name: query })
                    }
                },
                {
                    label: "关联销售合同编号",
                    value: "saleContractCode",
                    inputType: "select",
                    optionsList: [],
                    filterable: true,
                    optionsListConfig: {
                        name: "code",
                        code: "code",
                    },
                    rules: [
                        {
                            required: true,
                            message: "请选择关联销售合同编号",
                            trigger: "blur",
                        },
                    ],
                    change: ($event, value) => {
                        this.changesaleContract($event, value, 'code')
                    },
                    remote: true,
                    remoteMethod: (query) => {
                        this.queryContarctList({ code: query })
                    },
                    placeholderText:
                        "请选择关联销售合同编号",
                },
                {
                    label: "关联项目名称",
                    value: "prjName",
                    disabled: true,
                    inputType: "text",
                },
                {
                    label: "关联项目编号",
                    value: "prjCode",
                    disabled: true,
                    inputType: "text",
                },
                {
                    label: "应付外协约定比例(%)",
                    value: "payableOutsourceScale",
                    inputType: "text",
                    change: this.handleChange,
                    placeholderText:
                        "请输入应付外协约定比例(%)",

                    rules: [
                        {
                            required: true,
                            message: "请输入应付外协约定比例(%)",
                            trigger: "blur",
                        },
                        {
                            required: true,
                            pattern: /^([0-9][0-9]{0,1}|100)$/,
                            message: "请输入0到100的数字",
                            trigger: "blur",
                        },
                    ],
                },
                {
                    label: "扣除费用",
                    value: "deductExpenses",
                    inputType: "text",
                    change: this.handleChange,
                    placeholderText:
                        "请输入扣除费用",
                    rules: [
                        {
                            required: true,
                            message: "请输入扣除费用",
                            trigger: "blur",
                        },
                        {
                            pattern: /^(-?\d*\.\d{1,2}|-?\d{1,9})$/,
                            required: true,
                            message: "请输入正确的格式",
                            trigger: "blur",
                        },

                    ],
                },
                {
                    label: "扣除费用详情备注",
                    placeholderText:
                        "请输入扣除费用详情备注",
                    value: "detailRemark",
                    inputType: "text",
                    type: "textarea",
                    defaultValue: "",
                    rows: "1",
                },
                {
                    label: "备注",
                    placeholderText:
                        "请输入备注",
                    value: "remark",
                    inputType: "text",
                    type: "textarea",
                    defaultValue: "",
                    rows: "2",
                },
            ],
            formList: [],
            formList_reference: [
                {
                    label: "预计采购合同总金额",
                    value: "saleContractPlanAmount",
                    inputType: "text",
                    disabled: true,
                    placeholderText:
                        "自动计算",
                },
                {
                    label: "销售合同价格形式",
                    value: "cfnature",
                    inputType: "text",
                    disabled: true,
                    placeholderText:
                        "关联销售合同",
                },
                {
                    label: "销售合同额",
                    value: "saleContractAmount",
                    inputType: "text",
                    disabled: true,
                    placeholderText:
                        "关联销售合同",
                },
                {
                    label: "销售合同结算金额",
                    value: "saleContractSettleAmount",
                    inputType: "text",
                    disabled: true,
                    placeholderText:
                        "关联销售合同",
                },
                {
                    label: "销售回款",
                    value: "saleContractRefund",
                    inputType: "text",
                    disabled: true,
                    placeholderText:
                        "关联销售合同",
                },

                {
                    label: "销售回款比例",
                    value: "saleContractRefundRatio",
                    inputType: "text",
                    disabled: true,
                    placeholderText:
                        "关联销售合同",
                },
            ],
            formList_person: [
                {
                    label: "制单人",
                    value: "applierName",
                    defaultValue: sessionStorage.getItem('fullName'),
                    disabled: true,
                    inputType: "text",
                },
                {
                    label: "最近改动人",
                    value: "modifierName",
                    inputType: "text",
                    defaultValue: sessionStorage.getItem('fullName'),
                    disabled: true,
                },
            ],
            contarctList: [],
        }
    },
    created() {
        if (this.pageProp.pageType == 'add' || this.pageProp.pageType == 'edit') {
            this.formList = this.formList_add_edit
        }
        else {
            this.formList = this.formList_change
        }
        this.getDataType();
        this.queryContarctList();
    },
    mounted() {
        if (this.pageProp.pageType != 'add') {
            setTimeout((res) => {
                this.getPageData();
            }, 500)
        }
    },
    methods: {
        getPageData() {
            getApi(`/contract/selfestablished/procurement/query?id=${this.pageProp.id}`).then(res => {
                if (res.data.code == 0) {
                    if (res.data.data) {
                        if (res.data.data.procurementYear) {
                            res.data.data.procurementYear = res.data.data.procurementYear.toString();
                        }
                    }
                    if (this.pageProp.pageType == 'change') {
                        res.data.data.changeTotalAmount = ((res.data.data.procurementFinallyAmount || 0 * 1) - (res.data.data.procurementInitialAmount || 0 * 1)).toFixed(2)
                    }
                    this.initFormData(res.data.data);                                    
                    this.relateTypeSelect(res.data.data.relateType);
                }
            })
        },
        changeProcurementFinallyAmount($event, value) {
            // changeTotalAmount
            // procurementInitialAmount
            // this.$refs.assignForm_list.formData[value]
            this.$refs.assignForm_list.formData.changeTotalAmount = ((this.$refs.assignForm_list.formData[value] || 0 * 1) - (this.$refs.assignForm_list.formData.procurementInitialAmount || 0 * 1)).toFixed(2)
        },
        initFormData(data) {
            
            // this.$refs.assignForm_list.formData = { ...data }
            // this.$refs.assignForm_reference.formData = {...data}

            const keysToReference = ["saleContractAmount", "cfnature","saleContractPlanAmount","saleContractRefund","saleContractRefundRatio","saleContractSettleAmount"];
            this.$refs.assignForm_reference.formData = Object.keys(data).reduce((acc, key) => {  
                if (keysToReference.includes(key)) {  
                    acc[key] = data[key];  
                }  
                return acc;  
            }, {});
            this.$refs.assignForm_list.formData = Object.keys(data).reduce((acc, key) => {  
                if (!keysToReference.includes(key)) {  
                    acc[key] = data[key];  
                }  
                return acc;  
            }, {});
            
            if(this.pageProp.pageType == 'change'){

                this.$refs.assignForm_person.formData = Object.keys(data).reduce((acc, key) => {  
                    if (key == "applierName" || key == "modifierName") {  
                        acc[key] = data[key];  
                    }  
                    return acc;  
                }, {});

                this.$refs.assignForm_remark.formData = Object.keys(data).reduce((acc, key) => {  
                if (key == "modifierReason") {  
                    acc[key] = data[key];  
                }  
                return acc;  
                }, {});
            }
        },
        getDataType() {
            // 采购合同类型
            postApi(`/contract/dicttype/list/all`, { code: "PC-CCONTRACTTYPE-STATUS" }).then(res => {
                if (res.data.code == 0) {
                    this.formList.forEach(item => {
                        if (item.value == 'procurementType') {
                            item.optionsList = res.data.data
                        }
                    })
                }
            })
        },
        queryContarctList(obj) {
            // 销售合同
            postApi(`/project/contractinfo/query`, obj).then(res => {
                if (res.data.code == 0) {
                    this.contarctList = res.data.data;
                    this.formList.forEach(item => {
                        if (item.value == 'saleContractName') {
                            item.optionsList = res.data.data
                        }
                        if (item.value == 'saleContractCode') {
                            item.optionsList = res.data.data
                        }
                    })
                    // let formData = this.$refs.assignForm_list.formData;
                    // this.formList.forEach(item => {
                    //     item.defaultValue = formData[item.value]
                    // })

                }
            })
        },
        handleChange(e, type) {
            let {
                payableOutsourceScale,deductExpenses
            } = this.$refs.assignForm_list.formData;
            let {saleContractSettleAmount} = this.$refs.assignForm_reference.formData;
            if(type == 'payableOutsourceScale' || type == 'deductExpenses'){
                if(saleContractSettleAmount != '' && payableOutsourceScale != '' && deductExpenses != ''){
                    this.$refs.assignForm_reference.formData.saleContractPlanAmount = 
                    Number(Number(saleContractSettleAmount) * Number(payableOutsourceScale) / 100 - Number(deductExpenses)).toFixed(2);
                        }
            }            
        },
        changesaleContract($event, value, key) {
            if ($event) {
                this.contarctList.forEach(item => {
                    if (item[key] == $event) {
                        if (key == 'name') {
                            this.$refs.assignForm_list.formData.saleContractCode = item.code
                        }
                        else {
                            this.$refs.assignForm_list.formData.saleContractName = item.name
                        }
                        this.$refs.assignForm_list.formData.prjName = item.prjName
                        this.$refs.assignForm_list.formData.prjCode = item.prjCode
                      this.$refs.assignForm_reference.formData.cfnature = item.cfnature
                        this.$refs.assignForm_reference.formData.saleContractAmount = item.foriginalamount
                        this.$refs.assignForm_reference.formData.saleContractSettleAmount = item.ftotalamount
                        this.$refs.assignForm_reference.formData.saleContractRefund = item.frealamount
                        this.$refs.assignForm_reference.formData.saleContractRefundRatio = item.saleContractRefundRatio
                        if(this.$refs.assignForm_reference.formData.saleContractSettleAmount != '' && 
                            this.$refs.assignForm_list.formData.payableOutsourceScale != '' && 
                            this.$refs.assignForm_list.formData.deductExpenses != ''
                        ){
                            this.$refs.assignForm_reference.formData.saleContractPlanAmount = 
                            Number((this.$refs.assignForm_reference.formData.saleContractSettleAmount 
                            * this.$refs.assignForm_list.formData.payableOutsourceScale) / 100 
                            - this.$refs.assignForm_list.formData.deductExpenses).toFixed(2)
                        }
                        this.$forceUpdate()
                    }
                })
            }
            else {
                this.$refs.assignForm_list.formData.saleContractCode = ''
                this.$refs.assignForm_list.formData.saleContractName = ''
                this.$refs.assignForm_list.formData.prjName = ''
                this.$refs.assignForm_list.formData.prjCode = ''
                this.$refs.assignForm_reference.formData.saleContractAmount = null
                this.$refs.assignForm_reference.formData.saleContractSettleAmount = null
                this.$refs.assignForm_reference.formData.saleContractRefund = null
                this.$refs.assignForm_reference.formData.saleContractRefundRatio = null
                this.$refs.assignForm_reference.formData.saleContractPlanAmount = null
                this.$refs.assignForm_reference.formData.cfnature = null
            }

        },
        relateTypeSelect(code) {
            this.formList_add_edit.forEach((item) => {
                if (item.value == "relateType") {
                    item.defaultValue = code;
                }
                if (code == "0") {
                    if (item.value == "saleContractName" || item.value == "saleContractCode" || item.value == "prjCode" || item.value == "prjName"
                        || item.value == "payableOutsourceScale" || item.value == "deductExpenses" || item.value == "detailRemark" ) {
                        item.defaultValue = "";
                    } else {
                        item.defaultValue = this.$refs.assignForm_list.formData[item.value];
                    }
                    if (item.value == "saleContractName" || item.value == "saleContractCode"
                        || item.value == "payableOutsourceScale" || item.value == "deductExpenses") {
                        item.disabled = true;
                        item.rules[0].required = false;
                        if (item.value == "payableOutsourceScale" || item.value == "deductExpenses") {
                            item.rules[1].required = false;
                        }
                    }
                    if (item.value == "detailRemark") {
                        item.disabled = true;
                    }

                } else if (code == "1") {
                    item.defaultValue = this.$refs.assignForm_list.formData[item.value];
                    if (item.value == "saleContractName" || item.value == "saleContractCode"
                        || item.value == "payableOutsourceScale" || item.value == "deductExpenses") {
                        item.disabled = false;
                        item.rules[0].required = true;
                        if (item.value == "payableOutsourceScale" || item.value == "deductExpenses") {
                            item.rules[1].required = true;
                        }
                    }
                    if (item.value == "detailRemark") {
                        item.disabled = false;
                    }
                }
            });
 
            this.formList_reference.forEach((item)=>{
                if (code == "0") {
                    this.$refs.assignForm_reference.formData[item.value] = null;
                }else if(code == "1"){
                    item.defaultValue = this.$refs.assignForm_reference.formData[item.value];
                }
            })
        }
    }
}
</script>

<style lang="less" scoped>
/deep/ .el-range-editor.is-disabled {
    background-color: #fbfbfb;
    color: @defaultFontColor;
}

/deep/ .el-range-editor.is-disabled input {
    color: @defaultFontColor;
}

/deep/.el-input.is-disabled .el-input__inner {
    background-color: #fbfbfb;
    color: @defaultFontColor;
}

/deep/.el-textarea.is-disabled .el-textarea__inner {
    background-color: #fbfbfb;
    color: @defaultFontColor;
    resize: none;
}
</style>