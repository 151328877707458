<template>
  <div class="home-container">
    <div class="left-box">
      <div class="left-top-box">
        <dd-card
          title="待办任务"
          spacer
          height="100%"
          style="position: relative"
        >
          <dd-button
            style="position: absolute; right: 15px; top: 5px"
            type="text"
            size="medium"
            @click="handleMore(1)"
            >查看更多>></dd-button
          >
          <dd-table
            :columns="toDocolumns"
            :data="toDoTableData"
            border
            :isSort="true"
            :isSelection="false"
            :topBtn="false"
          >
          </dd-table>
        </dd-card>
      </div>
      <div class="left-bottom-box">
        <div class="box">
          <dd-card title="已处理" height="100%" style="position: relative">
            <dd-button
              style="position: absolute; right: 15px; top: 5px"
              type="text"
              size="medium"
              @click="handleMore(2)"
              >查看更多>></dd-button
            >
            <div
              class="list"
              v-for="(val, idx) in yetTableData"
              :key="idx"
              @click="yclhandleInfo(val)"
            >
              <span
                ><span class="num">{{ idx + 1 }}.</span>{{ val.text }}</span
              >
              <span>{{ val.startTime }}</span>
            </div>
          </dd-card>
        </div>
        <div class="box">
          <dd-card title="已发起" height="100%">
            <div class="list-box">
              <div class="list" v-for="(val, idx) in yfqTableData" :key="idx">
                <span
                  ><span class="num">{{ idx + 1 }}.</span
                  >{{ val.subject }}</span
                >
                <span
                  >{{ val.reviewStatus }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{
                    val.time
                  }}</span
                >
              </div>
            </div>
          </dd-card>
        </div>
      </div>
    </div>
    <div class="right-box">
      <div class="top">
        <dd-card spacer>
          <div class="con">
            <div style="width: 75%">
              <div class="title">
                Hello&nbsp;{{ userName }}，<span>{{ welcomeText }}</span>
              </div>
              <div style="margin-bottom: 5px">部门：{{ depName }}</div>
              <div>工号：{{ codeNum }}</div>
            </div>
            <!-- <div class="weather">
              <div class="toptext">
                <div class="left">
                  <span>西安市</span>
                  <span class="wd"
                    >{{ weatherData.temp }}°<span style="font-size: 14px">{{
                      weatherData.text
                    }}</span></span
                  >
                </div>
                <div>
                  <i :class="'qi-' + weatherData.icon"></i>
                </div>
              </div>
              <div class="text" style="margin-bottom: 5px">
                体感温度: {{ weatherData.feelsLike }}°
              </div>
              <div class="text">
                {{ weatherData.windDir }} {{ weatherData.windScale }}级
                {{ weatherData.windSpeed }}km/h
              </div>
            </div> -->
          </div>
        </dd-card>
      </div>
      <div class="center">
        <dd-card title="财务管理" height="50%">
          <div class="auto_size flex_c">
            <div
              class="nav-card green"
              @click="
                () => {
                  this.$router.push('/finance/dayBook/apply');
                }
              "
            >
              日记账申请
            </div>
            <div
              class="nav-card blue"
              @click="
                () => {
                  this.$router.push('/finance/reportBill/apply');
                }
              "
            >
              报账申请
            </div>
          </div>
        </dd-card>
        <dd-card title="人事行政" height="50%" spacer>
          <div class="auto_size flex_c">
            <div
              class="nav-card orange"
              @click="
                () => {
                  this.$router.push('/personnel/administration/wage/apply');
                }
              "
            >
              个人工资
            </div>
            <div
              class="nav-card yellow"
              @click="
                () => {
                  this.$router.push('/personnel/administration/out/car');
                }
              "
            >
              外部租车
            </div>
            <div
              class="nav-card blue"
              @click="
                () => {
                  this.$router.push('/personnel/administration/private/car');
                }
              "
            >
              项目用车
            </div>
          </div>
        </dd-card>
      </div>
      <div class="bottom">
        <el-calendar v-model="nowDay"> </el-calendar>
      </div>
    </div>
    <!-- 日记账审批弹窗 -->
    <dd-popup
      width="70%"
      height="80%"
      showBtn
      :cancelBtn="false"
      :saveBtn="false"
      :show="infoPopupVisible"
      title-name="任务查看"
      @popup-close="handleClose"
    >
      <dd-button
        slot="button"
        type="warning"
        size="medium"
        @click="handleSubmit('true', 'taskBasicForm', 'expenseDetailForm')"
        >回退
      </dd-button>
      <dd-button
        slot="button"
        type="primary"
        size="medium"
        @click="handleSubmit('false', 'taskBasicForm', 'expenseDetailForm')"
        >同意
      </dd-button>
      <!-- <dd-button
        slot="button"
        type="danger"
        size="medium"
        @click="handleSubmit('end', 'taskBasicForm', 'expenseDetailForm')"
        >终止
      </dd-button> -->
      <dayBookDetail
        v-if="infoPopupVisible"
        slot="main"
        ref="addPop"
        :rowData="rowData"
      ></dayBookDetail>
    </dd-popup>
    <!-- 预算审批弹窗 -->
    <dd-popup
      width="70%"
      height="80%"
      showBtn
      :cancelBtn="false"
      :saveBtn="false"
      :show="budgetPopupVisible"
      title-name="任务查看"
      @popup-close="handleClose"
    >
      <dd-button
        slot="button"
        type="warning"
        size="medium"
        @click="handleBudgetSubmit('true', 'taskBasicForm')"
        >回退
      </dd-button>
      <dd-button
        slot="button"
        type="primary"
        size="medium"
        @click="handleBudgetSubmit('false', 'taskBasicForm')"
        >同意
      </dd-button>
      <!-- <dd-button
        slot="button"
        type="danger"
        size="medium"
        @click="handleBudgetSubmit('end', 'taskBasicForm')"
        >终止
      </dd-button> -->
      <budgetDetail
        v-if="budgetPopupVisible"
        slot="main"
        ref="budgetPop"
        :rowData="rowData"
      ></budgetDetail>
    </dd-popup>
    <!-- 外部用車审批弹窗 -->
    <dd-popup
      width="70%"
      height="80%"
      showBtn
      :cancelBtn="false"
      :saveBtn="false"
      :show="outerCarPopupVisible"
      title-name="任务查看"
      @popup-close="handleClose"
    >
      <dd-button
        slot="button"
        type="warning"
        size="medium"
        @click="handleSubmit('true', 'taskBasicForm', 'baseForm')"
        >回退
      </dd-button>
      <dd-button
        slot="button"
        type="primary"
        size="medium"
        @click="handleSubmit('false', 'taskBasicForm', 'baseForm')"
        >同意
      </dd-button>
      <!-- <dd-button
        slot="button"
        type="danger"
        size="medium"
        @click="handleSubmit('end', 'taskBasicForm', 'baseForm')"
        >终止
      </dd-button> -->
      <outerCarDetail
        v-if="outerCarPopupVisible"
        slot="main"
        ref="addPop"
        :rowData="rowData"
      ></outerCarDetail>
    </dd-popup>
    <!-- 任务书审批弹窗 -->
    <dd-popup
      width="77%"
      height="80%"
      showBtn
      :cancelBtn="false"
      :saveBtn="false"
      :show="taskBookPopupVisible"
      title-name="任务查看"
      @popup-close="handleClose"
    >
      <dd-button
        slot="button"
        type="warning"
        size="medium"
        @click="handleTaskBookSubmit('true', 'taskBasicForm', 'baseForm')"
        >回退
      </dd-button>
      <dd-button
        slot="button"
        type="primary"
        size="medium"
        @click="handleTaskBookSubmit('false', 'taskBasicForm', 'baseForm')"
        >同意
      </dd-button>
      <!-- <dd-button
        slot="button"
        type="danger"
        size="medium"
        @click="handleTaskBookSubmit('end', 'taskBasicForm', 'baseForm')"
        >终止
      </dd-button> -->
      <taskBookDetail
        v-if="taskBookPopupVisible"
        slot="main"
        ref="taskBookPop"
        :rowData="rowData"
      ></taskBookDetail>
    </dd-popup>
    <!-- WBS分解审批弹窗 -->
    <dd-popup
      width="70%"
      height="80%"
      showBtn
      :cancelBtn="false"
      :saveBtn="false"
      :show="taskResolveVisible"
      title-name="任务查看"
      @popup-close="handleClose"
    >
      <dd-button
        slot="button"
        type="warning"
        size="medium"
        @click="handleTaskResolveSubmit('true', 'taskBasicForm')"
        >回退
      </dd-button>
      <dd-button
        slot="button"
        type="primary"
        size="medium"
        @click="handleTaskResolveSubmit('false', 'taskBasicForm')"
        >同意
      </dd-button>
      <!-- <dd-button
        slot="button"
        type="danger"
        size="medium"
        @click="handleTaskResolveSubmit('end', 'taskBasicForm')"
        >终止
      </dd-button> -->
      <taskResolveDetail
        v-if="taskResolveVisible"
        slot="main"
        ref="taskResolvePop"
        :rowId="rowData.proInstanceId"
      ></taskResolveDetail>
    </dd-popup>
    <!-- 文档审批弹窗 -->
    <dd-popup
      width="70%"
      height="80%"
      showBtn
      :cancelBtn="false"
      :saveBtn="false"
      :show="docFilesVisible"
      title-name="任务查看"
      @popup-close="handleClose"
    >
      <dd-button
        slot="button"
        type="primary"
        size="medium"
        @click="handleDocFilesSubmit('false', 'taskBasicForm')"
        >同意
      </dd-button>
      <dd-button
        slot="button"
        type="danger"
        size="medium"
        @click="handleDocFilesSubmit('true', 'taskBasicForm')"
        >回退
      </dd-button>
      <fileDetail
        v-if="docFilesVisible"
        slot="main"
        ref="docFilesPop"
        :rowId="rowData.proInstanceId"
      ></fileDetail>
    </dd-popup>

    <!-- 新增页面弹窗 -->
    <dd-popup
      width="70%"
      height="80%"
      :showBtn="false"
      :show="yclinfoPopupVisible"
      title-name="任务查看"
      @popup-close="yclhandleClose"
    >
      <ycldayBookDetail
        v-if="yclinfoPopupVisible"
        slot="main"
        ref="addPop"
        :rowData="rowData"
      ></ycldayBookDetail>
    </dd-popup>
    <!-- 预算审批弹窗 -->
    <dd-popup
      width="70%"
      height="80%"
      :showBtn="false"
      :show="yclbudgetPopupVisible"
      title-name="任务查看"
      @popup-close="yclhandleClose"
    >
      <yclbudgetDetail
        v-if="yclbudgetPopupVisible"
        slot="main"
        ref="budgetPop"
        :rowData="rowData"
      ></yclbudgetDetail>
    </dd-popup>
    <!-- 外部用車审批弹窗 -->
    <dd-popup
      width="70%"
      height="80%"
      :showBtn="false"
      :show="yclouterCarPopupVisible"
      title-name="任务查看"
      @popup-close="yclhandleClose"
    >
      <yclouterCarDetail
        v-if="yclouterCarPopupVisible"
        slot="main"
        ref="addPop"
        :rowData="rowData"
      ></yclouterCarDetail>
    </dd-popup>
    <!-- 任务书审批弹窗 -->
    <dd-popup
      width="70%"
      height="80%"
      :showBtn="false"
      :show="ycltaskBookPopupVisible"
      title-name="任务查看"
      @popup-close="yclhandleClose"
    >
      <ycltaskBookDetail
        v-if="ycltaskBookPopupVisible"
        slot="main"
        ref="taskBookPop"
        :rowData="rowData"
      ></ycltaskBookDetail>
    </dd-popup>
    <!-- WBS分解审批弹窗 -->
    <dd-popup
      width="70%"
      height="80%"
      :showBtn="false"
      :show="ycltaskResolveVisible"
      title-name="任务查看"
      @popup-close="yclhandleClose"
    >
      <ycltaskResolveDetail
        v-if="ycltaskResolveVisible"
        slot="main"
        ref="taskResolvePop"
        :rowId="rowData.proInstanceId"
      ></ycltaskResolveDetail>
    </dd-popup>
    <!-- 文档审批弹窗 -->
    <dd-popup
      width="70%"
      height="80%"
      :showBtn="false"
      :show="ycldocFilesVisible"
      title-name="任务查看"
      @popup-close="yclhandleClose"
    >
      <yclfileDetail
        v-if="ycldocFilesVisible"
        slot="main"
        ref="taskResolvePop"
        :rowId="rowData.proInstanceId"
      ></yclfileDetail>
    </dd-popup>
        <!-- 预算编制审批 -->
        <dd-popup
      width="70%"
      height="80%"
      showBtn
      :cancelBtn="false"
      :saveBtn="false"
      :show="workOutVisible"
      title-name="任务查看"
      @popup-close="handleClose"
    >
      <dd-button
        slot="button"
        type="warning"
        size="medium"
        @click="handleWorkOutSubmit('true', 'workOutForm')"
        >回退
      </dd-button>
      <dd-button
        slot="button"
        type="primary"
        size="medium"
        @click="handleWorkOutSubmit('false', 'workOutForm')"
        >同意
      </dd-button>
      <!-- <dd-button
        slot="button"
        type="danger"
        size="medium"
        @click="handleWorkOutSubmit('end', 'workOutForm')"
        >终止
      </dd-button> -->
      <WorkOutDetail
        v-if="workOutVisible"
        slot="main"
        ref="workOutPop"
        :rowId="rowData.proInstanceId"
      ></WorkOutDetail>
    </dd-popup>

        <!-- 修改密码弹窗 -->
        <dd-popup
        width="30%"
        height="35%"
        :showBtn="true"
        :cancelBtn="false"
        :showClose="false"
        :saveBtn="false"
        :submitBtn="true"
        :show="addPopupVisible"
        title-name="修改密码"
        @popup-close="addPopupVisible = false"
        @popup-submit="handleSubmitPassWord"
      >
        <dd-form
          v-if="addPopupVisible"
          slot="main"
          :form-config="formBasicList"
          ref="addPopFormPassWord"
        >
        </dd-form>
      </dd-popup>
  </div>
</template>

<script>
import { clearAllStore, getStore } from "../../utils/store";
import { getApi, delApi, postApi, putApi } from "@/api/request";
// 日记账审批
import dayBookDetail from "../PersonalOffice/TodoTask/components/DaybookDetail.vue";
// 预算审批
import budgetDetail from "../PersonalOffice/TodoTask/components/BudgetDetail.vue";
// 外部租车审批
import outerCarDetail from "../PersonalOffice/TodoTask/components/OuterCarDetail.vue";
// 任务书审批
import taskBookDetail from "../PersonalOffice/TodoTask/components/TaskBookDetail.vue";
// wbs分解审批
import taskResolveDetail from "../PersonalOffice/TodoTask/components/TaskResolveDetail.vue";
// 文档审批
import fileDetail from "../PersonalOffice/TodoTask/components/FileDetail.vue";
// 预算编制审批
import WorkOutDetail from "../PersonalOffice/TodoTask/components/WorkOutDetail.vue";

import ycldayBookDetail from "../PersonalOffice/TaskSearch/components/DaybookDetail.vue";
import yclbudgetDetail from "../PersonalOffice/TaskSearch/components/BudgetDetail.vue";
import yclouterCarDetail from "../PersonalOffice/TaskSearch/components/OuterCarDetail.vue";
// 任务书审批
import ycltaskBookDetail from "../PersonalOffice/TaskSearch/components/TaskBookDetail.vue";
// wbs分解审批
import ycltaskResolveDetail from "../PersonalOffice/TaskSearch/components/TaskResolveDetail.vue";
// 文档审批
import yclfileDetail from "../PersonalOffice/TaskSearch/components/FileDetail.vue";
export default {
  name: "home",
  $dynamicRoute: {
    name: "首页",
    path: "/home",
  },
  components: {
    dayBookDetail,
    budgetDetail,
    outerCarDetail,
    taskBookDetail,
    taskResolveDetail,
    fileDetail,
    WorkOutDetail,

    ycldayBookDetail,
    yclbudgetDetail,
    yclouterCarDetail,
    ycltaskBookDetail,
    ycltaskResolveDetail,
    yclfileDetail,
  },
  computed: {},
  data() {
    const validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入新密码"));
      } else if (value === "123456") {
        callback(new Error("密码不能为123456"));
      } else {
        if (this.$refs.addPopFormPassWord.formData.newpassword2 !== "") {
          this.$refs.addPopFormPassWord.$refs.baseForm.validateField("newpassword2");
        }
        callback();
      }
    };
    const validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.$refs.addPopFormPassWord.formData.newpassword1) {
        callback(new Error("两次输入密码不一致"));
      } else {
        callback();
      }
    };
    return {
      userName: getStore({ name: "fullName" }),
      depName: getStore({ name: "depName" }),
      addPopupVisible: false,
      formBasicList: [
        {
          label: "原密码",
          value: "password",
          inputType: "text",
          type: "password",
          placeholderText: "请输入原密码",
          disabled: false,
          defaultValue: "",
          rules: [
            {
              required: true,
              message: "请输入原密码",
              trigger: "blur",
            },
            { min: 6, max: 12, message: "密码长度为6-12位", trigger: "blur" },
          ],
        },
        {
          label: "新密码",
          value: "newpassword1",
          inputType: "text",
          type: "password",
          placeholderText: "请输入新密码",
          disabled: false,
          defaultValue: "",
          rules: [
            {
              required: true,
              validator: validatePass,
              trigger: "blur",
            },
            {
              required: true,
              pattern: /^(?![a-zA-Z]+$)(?![A-Z0-9]+$)(?![A-Z\W_!@#$%^&*`~.()-+=]+$)(?![a-z0-9]+$)(?![a-z\W_!@#$%^&*`~.()-+=]+$)(?![0-9\W_!@#$%^&*`~.()-+=]+$)[a-zA-Z0-9\W_!@#$%^&*`~.()-+=]{8,16}$/,
              message: "数字，大写字母，小写字母，特殊字符四选三且长度在8到16位之间",
              trigger: "blur",
            },
          ],
        },
        {
          label: "确认密码",
          value: "newpassword2",
          inputType: "text",
          type: "password",
          placeholderText: "请输入确认密码",
          disabled: false,
          defaultValue: "",
          rules: [
            {
              required: true,
              validator: validatePass2,
              trigger: "blur",
            },
            {
              required: true,
              pattern: /^(?![a-zA-Z]+$)(?![A-Z0-9]+$)(?![A-Z\W_!@#$%^&*`~.()-+=]+$)(?![a-z0-9]+$)(?![a-z\W_!@#$%^&*`~.()-+=]+$)(?![0-9\W_!@#$%^&*`~.()-+=]+$)[a-zA-Z0-9\W_!@#$%^&*`~.()-+=]{8,16}$/,
              message: "数字，大写字母，小写字母，特殊字符四选三且长度在8到16位之间",
              trigger: "blur",
            },
          ],
        },
      ],
      codeNum: sessionStorage.getItem("username"),
      nowDay: new Date(),
      workOutVisible: false,
      infoPopupVisible: false,
      budgetPopupVisible: false,
      outerCarPopupVisible: false,
      taskBookPopupVisible: false,
      taskResolveVisible: false,
      docFilesVisible: false,

      yclinfoPopupVisible: false,
      yclbudgetPopupVisible: false,
      yclouterCarPopupVisible: false,
      ycltaskBookPopupVisible: false,
      ycltaskResolveVisible: false,
      ycldocFilesVisible: false,
      rowData: null,
      toDocolumns: [
        {
          title: "申请人",
          key: "username",
          align: "center",
        },
        {
          title: "主题",
          key: "subject",
          align: "center",
        },
        {
          title: "发起时间",
          key: "startTime",
          align: "center",
        },
        {
          title: "当前节点",
          key: "taskName",
          align: "center",
        },
        {
          title: "操作",
          align: "center",
          event: [
            {
              show: "true",
              label: "任务办理",
              icon: "el-icon-info",
              method: (row) => {
                this.handleInfo(row);
              },
              type: "success",
            },
          ],
        },
      ],
      toDoTableData: [],
      yetTableData: [],
      yfqTableData: [],
      welcomeText: "",
      weatherData: null,
    };
  },
  created() {
    // 创建一个新的日期对象
    var now = new Date();

    // 获取小时数（24小时制）
    var hour = now.getHours();
    
    if (hour < 12) {
      this.welcomeText = "上午好！";
    } else if (hour >= 12 && hour <= 17) {
      this.welcomeText = "下午好！";
    } else {
      this.welcomeText = "晚上好！";
    }
  },
  mounted() {
    // this.getWeather();
    const passwordCheck = sessionStorage.getItem('passwordCheck');
    this.$nextTick(() => {
      if(passwordCheck == false) {
        this.addPopupVisible = true;
      }
    })
    
    this.getTodoList();
    this.getYetTableData();
    this.getYfqTableData();
  },
  methods: {
    // 修改密码提交
    handleSubmitPassWord() {
      this.$refs.addPopFormPassWord.$refs.baseForm.validate((valid) => {
        if (valid) {
          let params = this.$refs.addPopFormPassWord.formData;
          putApi(`/admin/user/edit/pwd`, params).then((res) => {
            if (res.data && res.data.code == 0) {
              this.$message.success(
                "密码修改成功，请重新登录，3秒后自动跳入登录页面！"
              );
              this.addPopupVisible = false;
              setTimeout(() => {
                this.handleLoginOut();
              }, 3000);
            }
          });
        }
      });
    },
    handleLoginOut() {
      delApi(`/auth/token/logout`, {}).then((res) => {
        this.$store.commit("DEL_ALL_TAG");
        clearAllStore();
        this.$router.push({
          path: "/login",
        });
      });
    },
    // getWeather() {
    //   getApi(
    //     `https://devapi.qweather.com/v7/weather/now?location=101110101&key=e9ddb3b10d294d3bb5ab5c127edd2031`
    //   ).then((res) => {
    //     let { data } = res;
    //     if (data.code == "200") {
    //       this.weatherData = data.now;
    //     }
    //   });
    // },
    getTodoList() {
      let params = {
        current: 1,
        size: 10,
        category: "8ad03944634f6ecc01634f7b5f110012",
      };
      getApi(`/activiti/workflow/todo/list`, params).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          data.data.records.forEach((item) => {
            item.username = item.initator.fullName;
          });
          this.toDoTableData = data.data.records;
        }
      });
    },
    getYfqTableData() {
      let params = {
        pageNumber: 1,
        pageSize: 50,
      };
      postApi(`/activiti/workflow/task/submited`, params).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          this.yfqTableData = data.data.records;
        }
      });
    },
    handleMore(type) {
      if (type == 1) {
        this.$router.push("/personalOffice/todoTask");
      } else {
        this.$router.push("/personalOffice/taskSearch");
      }
    },
    handleClose() {
      this.pointInfo = "";
      this.infoPopupVisible = false;
      this.budgetPopupVisible = false;
      this.outerCarPopupVisible = false;
      this.taskBookPopupVisible = false;
      this.taskResolveVisible = false;
      this.docFilesVisible = false;
      this.workOutVisible = false;
    },
    handleInfo(row) {
      this.rowData = row;
      if (
        row.proDefKey == "PrjBudgetApply" ||
        row.proDefKey == "PrjBudgetRevise"
      ) {
        this.budgetPopupVisible = true;
      } else if (row.proDefKey == "PrjJournalApply") {
        this.infoPopupVisible = true;
      } else if (row.proDefKey == "RentalCar") {
        this.outerCarPopupVisible = true;
      } else if (row.proDefKey == "PrjAssign") {
        this.taskBookPopupVisible = true;
      } else if (row.proDefKey == "PrjAssignSubplan") {
        this.taskResolveVisible = true;
      } else if (row.proDefKey == "PrjAssignAttachfile") {
        this.docFilesVisible = true;
      } else if (row.proDefKey == 'FinBudgetEstablishment') {
        this.workOutVisible = true;
      }
    },
    // 预算编制审批
    handleWorkOutSubmit(type, formName) {
      let flag = false;
      this.$refs.workOutPop.$refs[formName].$refs[formName].validate(
        (valid) => {
          if (valid) {
            flag = true;
          } else {
            this.$message.warning("请输入审批意见！");
          }
        }
      );

      if (flag) {
        let obj = this.$refs.workOutPop.$refs[formName].formData; 
        let params = {
          ...obj,
          reject: type,
          applyId: this.$refs.workOutPop.$refs.assignForm.formData.id,
          taskId: this.rowData.taskId,
          proInstanceId: this.rowData.proInstanceId,
        };
        postApi(`/finance/budget/establishment/review`, params).then(res => {
          if(res.data.code == 0) {
            this.$message.success(res.data.data);
            this.workOutVisible = false;
            this.getTodoList();
          }
        })
      }
    },
    // 日记账审批提交
    handleSubmit(type, formName, formNames) {
      let { rowData } = this;
      if (
        rowData.proDefKey == "PrjBudgetApply" ||
        rowData.proDefKey == "PrjJournalApply"
      ) {
        let flag = false;
        this.$refs.addPop.$refs[formName].$refs[formName].validate((valid) => {
          if (valid) {
            flag = true;
          }
        });
        if (flag) {
          let obj = this.$refs.addPop.$refs[formName].formData;
          let obj1 = this.$refs.addPop.$refs[formNames].formData;
          let params = {
            ...obj,
            reject: type,
            reviewAmount: obj1.reviewAmount,
            applyId: this.$refs.addPop.detailDataSourcrs.id,
            taskId: this.rowData.taskId,
            proInstanceId: this.rowData.proInstanceId,
          };
          postApi(`/finance/applysheet/review`, params).then((res) => {
            if (res.data.code == 0) {
              this.$message.success(res.data.data);
              this.infoPopupVisible = false;
              this.getTodoList();
            }
          });
        }
      } else if (rowData.proDefKey == "RentalCar") {
        console.log(this.$refs.addPop);
        let flag = false;
        this.$refs.addPop.$refs[formName].$refs[formNames].validate((valid) => {
          if (valid) {
            flag = true;
          }
        });
        if (flag) {
          let { model } = this.$refs.addPop.$refs[formName].$refs[formNames];
          let params = {
            comment: model.comment,
            reject: type,
            applyId: this.$refs.addPop.detailDataSourcrs.id,
            taskId: this.rowData.taskId,
          };
          postApi(`/hr/rentalcar/applysheet/review`, params).then((res) => {
            if (res.data.code == 0) {
              this.$message.success(res.data.data);
              this.outerCarPopupVisible = false;
              this.getTodoList();
            }
          });
        }
      }
    },
    // 预算审批提交
    handleBudgetSubmit(type, formName, formNames) {
      let flag = false;
      this.$refs.budgetPop.$refs[formName].$refs[formName].validate((valid) => {
        if (valid) {
          flag = true;
        }
      });
      if (flag) {
        let obj = this.$refs.budgetPop.$refs[formName].formData;
        let oneTypeVOList = [];
        let { tabArr } = this.$refs.budgetPop;
        let amount = 0;
        tabArr.forEach((item) => {
          item.children.forEach((val) => {
            amount += val.amount;
          });
          oneTypeVOList.push(item.children[0]);
        });
        let params = {
          ...obj,
          reject: type,
          procDefKey: this.rowData.proDefKey,
          oneTypeVOList: oneTypeVOList,
          id: this.$refs.budgetPop.detailDataSourcrs.id,
          expenseApplyIds: this.$refs.budgetPop.detailDataSourcrs.id,
          taskIds: this.rowData.taskId,
          proInstanceId: this.rowData.proInstanceId,
          amount: amount,
        };
        postApi(`/finance/apply/front/review`, params).then((res) => {
          if (res.data.code == 0) {
            this.$message.success(res.data.data);
            this.budgetPopupVisible = false;
            this.getTodoList();
          }
        });
      } else {
        this.$message.warning("请输入审批意见！");
      }
    },
    // 任务书审批提交
    handleTaskBookSubmit(type, formName, formNames) {
      let flag = false;
      let infoFlag = false;
      this.$refs.taskBookPop.$refs[formName].$refs[formName].validate(
        (valid) => {
          if (valid) {
            flag = true;
          } else {
            this.$message.warning("请输入审批意见！");
          }
        }
      );
      if (this.rowData.taskDefinKey == "scbmfzr") {
        this.$refs.taskBookPop.$refs.addPopForm.$refs.baseForm.validate(
          (valid) => {
            if (valid) {
              infoFlag = true;
            } else {
              this.$message.warning("请选择生产负责人！");
            }
          }
        );
      } else {
        infoFlag = true;
      }
      if (flag && infoFlag) {
        let obj = this.$refs.taskBookPop.$refs[formName].formData;
        // let baseInfo = this.$refs.taskBookPop.$refs.addPopForm.formData;
        let params = {
          ...obj,
          reject: type,
          applyId: this.$refs.taskBookPop.detailData.id,
          taskId: this.rowData.taskId,
          proInstanceId: this.rowData.proInstanceId,
          // leaderId: baseInfo.leaderId,
          receiveDeptId:this.$refs.taskBookPop.$refs['addPopForm'].formData.receiveDeptId,
          leaderId:this.$refs.taskBookPop.$refs['addPopForm'].formData.leaderId,
          businessTypeId: this.$refs.taskBookPop.$refs['addPopForm'].formData.businessTypeId,
          produceType: this.$refs.taskBookPop.$refs['addPopForm'].formData.produceType
        };
        // 接收部门分管负责人
        if(this.rowData.taskDefinKey == 'jsbmfgfzr'){
          // params.receiveDeptId = this.$refs.taskBookPop.$refs['addPopForm'].formData.receiveDeptId;
          // params.leaderId = this.$refs.taskBookPop.$refs['addPopForm'].formData.leaderId;
          if(params.receiveDeptId == ''){
            return this.$message.warning("请选择任务生产部门！");
          }
        }
        // 接收部门负责人
        if(this.rowData.taskDefinKey == 'jsbmfzr'){
          // params.leaderId = this.$refs.taskBookPop.$refs['addPopForm'].formData.leaderId;
          if(params.leaderId == ''){
            return this.$message.warning("请选择项目经理！");
          }

          if(params.businessTypeId == ''){
            return this.$message.warning("请选择业务类型！");
          }
          if(params.produceType == ''){
            return this.$message.warning("请选择生产控制模式！");
          }
        }

        postApi(`/project/assign/review`, params).then((res) => {
          if (res.data.code == 0) {
            this.$message.success(res.data.data);
            this.taskBookPopupVisible = false;
            this.getTodoList();
          }
        });
      }
    },
    // WBS任务分解审批提交
    handleTaskResolveSubmit(type, formName) {
      let flag = false;
      this.$refs.taskResolvePop.$refs[formName].$refs[formName].validate(
        (valid) => {
          if (valid) {
            flag = true;
          } else {
            this.$message.warning("请输入审批意见！");
          }
        }
      );

      if (flag) {
        let obj = this.$refs.taskResolvePop.$refs[formName].formData;
        let params = {
          ...obj,
          reject: type,
          applyId: this.$refs.taskResolvePop.nodeTableData[0].subplanId,
          taskId: this.rowData.taskId,
          proInstanceId: this.rowData.proInstanceId,
        };
        postApi(`/project/subplannode/review`, params).then((res) => {
          if (res.data.code == 0) {
            this.$message.success(res.data.data);
            this.taskResolveVisible = false;
            this.getTodoList();
          }
        });
      }
    },
    // 文档审批提交
    handleDocFilesSubmit(type, formName) {
      let flag = false;
      this.$refs.docFilesPop.$refs[formName].$refs[formName].validate(
        (valid) => {
          if (valid) {
            flag = true;
          } else {
            this.$message.warning("请输入审批意见！");
          }
        }
      );

      if (flag) {
        let obj = this.$refs.docFilesPop.$refs[formName].formData;
        let params = {
          ...obj,
          reject: type,
          applyId: this.$refs.docFilesPop.detailData.id,
          taskId: this.rowData.taskId,
          proInstanceId: this.rowData.proInstanceId,
        };
        postApi(`/project/subplannode/attachfile/review`, params).then(
          (res) => {
            if (res.data.code == 0) {
              this.$message.success(res.data.data);
              this.docFilesVisible = false;
              this.getTodoList();
            }
          }
        );
      }
    },
    yclhandleClose() {
      this.yclinfoPopupVisible = false;
      this.yclbudgetPopupVisible = false;
      this.yclouterCarPopupVisible = false;
      this.ycltaskBookPopupVisible = false;
      this.ycltaskResolveVisible = false;
      this.ycldocFilesVisible = false;
      this.workOutVisible = false;
    },
    yclhandleInfo(row) {
      this.rowData = row;
      if (
        row.proDefKey == "PrjBudgetApply" ||
        row.proDefKey == "PrjBudgetRevise"
      ) {
        this.yclbudgetPopupVisible = true;
      } else if (row.proDefKey == "PrjJournalApply") {
        this.yclinfoPopupVisible = true;
      } else if (row.proDefKey == "RentalCar") {
        this.yclouterCarPopupVisible = true;
      } else if (row.proDefKey == "PrjAssign") {
        this.ycltaskBookPopupVisible = true;
      } else if (row.proDefKey == "PrjAssignSubplan") {
        this.ycltaskResolveVisible = true;
      } else if (row.proDefKey == "PrjAssignAttachfile") {
        this.ycldocFilesVisible = true;
      }
    },
    // 获取表格列表数据
    getYetTableData() {
      let params = {
        current: 1,
        size: 10,
        category: "8ad03944634f6ecc01634f7b5f110012",
      };
      getApi(`/activiti/workflow/search/list`, params).then((res) => {
        let { data } = res;
        if (data.code == 0) {
          data.data.records.forEach((item) => {
            item.text = `${item.initator.fullName}提交的${
              item.subject.split("-")[0]
            }`;
          });
          this.yetTableData = data.data.records;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.home-container {
  width: 100%;
  height: 100%;
  display: flex;
  background-color: #ffffff;
  position: relative;
  .left-box {
    width: 70%;
    height: 100%;

    /deep/.el-button--text {
      color: #002591;
    }
    /deep/.el-button--text:focus,
    .el-button--text:hover {
      color: #002591;
    }
    .left-top-box {
      height: 50%;
    }
    .left-bottom-box {
      height: 50%;
      display: flex;
      .box {
        width: 50%;
        .list-box {
          height: 100%;
          overflow: auto;
        }
        .list {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin: 5px 0;
          height: 28px;
          cursor: pointer;
          .num {
            display: inline-block;
            width: 25px;
            text-align: center;
            margin-right: 5px;
            font-style: italic;
            font-size: @fs16;
            font-weight: bold;
          }
        }
      }
      .box:nth-child(1) {
        border-right: 4px solid #e7eaec;
      }
    }
  }
  .right-box {
    width: 30%;
    height: 100%;
    border-left: 4px solid #e7eaec;
    .top {
      height: 15%;
      padding: 10px;
      box-sizing: border-box;
      .con {
        display: flex;
        .title {
          font-size: @fs30;
          font-weight: bold;
          margin-bottom: 13px;
        }

        .weather {
          width: 25%;
          .toptext {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 5px;
            .left {
              display: flex;
              flex-direction: column;
            }
            i {
              font-size: @fs30;
            }
            .wd {
              font-size: @fs24;
            }
          }
        }
      }
    }
    .center {
      height: 45%;
      .nav-card {
        width: 50%;
        height: 100px;
        margin: 5px;
        color: #ffffff;
        font-size: @fs18;
        font-weight: bold;
        text-align: center;
        line-height: 100px;
        border-radius: 10px;
        cursor: pointer;
      }
      :hover.nav-card {
        scale: 1.05;
        transition: 0.5s;
      }
      .green {
        background-color: #00b149;
      }
      .blue {
        background-color: #0080ff;
      }
      .orange {
        background-color: #ff9003;
      }
      .yellow {
        background-color: #feb204;
      }
    }
    .bottom {
      height: 40%;
      padding: 10px;
      box-sizing: border-box;
      /deep/.el-calendar__body {
        padding: 0px 20px 0px;
      }
      /deep/.el-calendar-table thead th {
        padding: 8px 0;
        background-color: #d7d7d7;
      }
      /deep/.el-calendar-table .el-calendar-day {
        height: 35px;
      }
    }
  }
}
</style>
